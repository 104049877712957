import { IEntity } from './IEntity';
import { IAddress } from './IProfile';

export interface Admin {
    id: string;
    FirstName: string;
    LastName: string;
}

export interface CompanyReferential {
    isDeleted?: boolean,
    name: string,
    id: string
}

export interface ICompany {
    id: string;
    Name: string;
    Address: IAddress;
    WebsiteUrl: string;
    Size: IEntity;
    Typology: IEntity;
    Picture: string;
    Presentation: string;
    LegalForm: {
        LogicalId: string;
        Name: string;
        Parents: string;
        id: string;
    };
    Registrations: {
        AliasType: {
            IsDeleted: boolean;
            Name: string;
            id: string;
        };
        AliasValue: string;
        Date: string;
    }[];
    AdminList?: Admin[];
    CanEdit: boolean;
    IsPublic?: boolean;
    isCurrent?: boolean;
    DataType?: 'Company' | 'Organization';
    FirstReferential?: CompanyReferential[];
    SecondReferential?: CompanyReferential[];
    ThirdReferential?: CompanyReferential[];
}

export enum CompanyGroupKey {
    ThirdParty = 'LegalEntity.Group.Thirdparty',
    OurGroup = 'LegalEntity.Group.Ourgroup'
}