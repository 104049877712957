import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useOrganization } from './organizationHooks';
import { IClusterTemplate } from '../entities/IClusters';
import { ICreateNewClusterPayload, createNewCluster } from '../actions/clustersActions';
import { useOverlayContext } from '../components/NewDesignCommon/Overlay';
import { CreatePOACluster, ICreatePOACluster } from '../components/Organization/Wizards/POAWizard/CreatePOACluster';

export const getEntityLink = (id: string, organizationUrlName: string, type: 'personal' | 'company' | 'group' | 'cluster' = 'personal') => {
    const links = {
        personal: `/profile/${id}`,
        company: `/company/${id}`,
        cluster: `/orgs/${organizationUrlName}/cluster/${id}`,
        group: `/admin-console/${id}/settings`
    };
    return links[type] || '';
};

export type NewClusterFormValues = Pick<ICreateNewClusterPayload, 'name' | 'privacy'>;

export const useCreateClusterPopup = () => {
    const { showOverlay, hideOverlay } = useOverlayContext();

    return useCallback((template: IClusterTemplate) => {
        showOverlay<ICreatePOACluster>({
            component: CreatePOACluster,
            componentProps: {
                clusterTemplateId: template?.id,
                onComplete: hideOverlay
            }
        });
    }, []);
};

export const useCreateNewCluster = () => {
    const dispatch = useDispatch();
    const { organization } = useParams<{ organization: string }>();
    const { currentOrganization } = useOrganization(organization);

    return useCallback((payload: NewClusterFormValues & { clusterTemplateId: ICreateNewClusterPayload['clusterTemplateId'] }) =>
        dispatch(
            createNewCluster({
                organizationId: currentOrganization?.id,
                organizationUrlName: currentOrganization?.details?.urlName,
                organizationName: currentOrganization?.details?.name,
                ...payload
            })
        )
        , [currentOrganization]);
};
