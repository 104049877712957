import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { createUserWithoutInvitation } from '../../../../actions/permissionsActions';
import { AlertType } from '../../../../entities/IAlert';
import { IValue } from '../../../../entities/IPickers';
import { colorStack } from '../../../../styleHelpers/colors';
import { fontSize } from '../../../../styleHelpers/fontSizes';
import { cardsShadow } from '../../../../styleHelpers/mixins/shadow';
import { isValidEmail } from '../../../../tools/emailChecker';
import { useAlert } from '../../../../tools/hooks';
import { Button } from '../../Buttons/Button';
import { Delete } from '../../Icons';
import { TextComponent } from '../../Inputs/TextComponent';

type CreateUserWithoutInvitation = ReturnType<typeof createUserWithoutInvitation>;

const Wrapper = styled.div`
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    background: ${colorStack.white};
    ${cardsShadow()};
    padding: 1rem;
    z-index: 99;
`;

const CloseForm = styled(Delete)`
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
`;

const TopText = styled.div`
    font-size: ${fontSize[13]};
    color: ${colorStack.label};
    margin: 0 0 1rem 0;
`;

const FormRow = styled.div`
    display: flex;
    margin: 0 0 1rem 0;
    flex-direction: column;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

interface IProps {
    isVisible: boolean;
    initialData?: {
        firstName: string;
        lastName: string;
    }
    closeHandler();
    returnData(e: React.MouseEvent<HTMLButtonElement>, elem: IValue, isRemove?: boolean);
}

export const NewUserForm: FC<IProps> = props => {
    const dispatch = useDispatch();
    const addAlert = useAlert();
    const intl = useIntl();
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [emailErrMsg, setEmailErrMsg] = useState<string>(undefined);

    useEffect(() => {
        if (!props.isVisible) {
            setFirstName('');
            setLastName('');
            setEmail('');
            setEmailErrMsg(undefined);
        }
    }, [props.isVisible]);

    useEffect(() => {
        props.initialData.firstName?.length > 0 && setFirstName(props.initialData.firstName);
        props.initialData.lastName?.length > 0 && setLastName(props.initialData.lastName);
    }, [props.initialData.firstName, props.initialData.lastName]);

    const changeFirstName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        setFirstName(text);
    }, []);

    const changeLstName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        setLastName(text);
    }, []);

    const changeEmail = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        setEmail(text);
    }, []);

    const createNewUser = useCallback(() => {
        dispatch<CreateUserWithoutInvitation>(createUserWithoutInvitation(email, firstName, lastName)).then(res => {
            props.returnData(undefined, {
                key: res.id,
                text: `${res.firstName} ${res.lastName}`,
                data: { ...res, avatarType: 'personal' }
            });
            addAlert(<FormattedMessage id="searchInput.alert.person" />, AlertType.Success);
            setFirstName('');
            setLastName('');
            setEmail('');
            props.closeHandler();
            setEmailErrMsg(undefined);
        }).catch(err => {
            setEmailErrMsg(err.data.message);
        });
    }, [email, firstName, lastName, props.returnData]);

    const checkEmailFormat = () => {
        if (!isValidEmail(email)) {
            setEmailErrMsg(intl.formatMessage({ id: 'searchInput.error.email' }));
        } else {
            setEmailErrMsg(undefined);
        }
    };

    return (
        <>
            {props.isVisible &&
                <Wrapper>
                    <CloseForm onClick={props.closeHandler} width={24} height={24} />
                    <TopText><FormattedMessage id="searchinput.topText.user" /></TopText>
                    <FormRow>
                        <TextComponent
                            type="Input"
                            required
                            label={<FormattedMessage id="searchInput.form.firstName" />}
                            value={firstName}
                            onChange={changeFirstName}
                        />
                    </FormRow>
                    <FormRow>
                        <TextComponent
                            type="Input"
                            required
                            label={<FormattedMessage id="searchInput.form.lastName" />}
                            value={lastName}
                            onChange={changeLstName}
                        />
                    </FormRow>
                    <FormRow>
                        <TextComponent
                            type="Input"
                            required
                            label={<FormattedMessage id="searchInput.form.emailAddress" />}
                            value={email}
                            onBlur={checkEmailFormat}
                            onChange={changeEmail}
                            error={!!emailErrMsg}
                            errorMsg={emailErrMsg}
                        />
                    </FormRow>
                    <ButtonWrapper>
                        <Button onClick={createNewUser} disabled={!firstName || !email || !lastName || !!emailErrMsg} type="button"><FormattedMessage id="global.create" /></Button>
                    </ButtonWrapper>
                </Wrapper>
            }
        </>
    );
};