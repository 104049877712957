import { faAlignLeft, faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';

import { IClusterSingleDynamicStake, IClusterStakeProperty, PropertieKey } from '../../../../../../entities/IClusterStake';
import { Avatar } from '../../../../../Common/Avatar/Avatar';
import * as Common from '../Common';
import { ProfilesSummary } from '../../../../../Common/ProfilesSummary/ProfilesSummary';
import { AttachmentSection } from './AttachmentSection';
import { IProfileType } from '../../../../../../entities/IGlobal';

interface IBeneficiaryLayoutProps {
    stake: IClusterSingleDynamicStake;
    propertyByKey: {
        [key in PropertieKey]?: IClusterStakeProperty;
    }
    downloadHandler();
}

export const BeneficiaryLayout: FC<IBeneficiaryLayoutProps> = ({ stake, propertyByKey, downloadHandler }) => {
    const userData = propertyByKey[PropertieKey.JourneyCreator]?.value;
    const managerData = propertyByKey[PropertieKey.JourneyCreatorManager]?.value;
    const legalEntityData = propertyByKey[PropertieKey.JourneyCreatorLegalEntity];
    const functionData = propertyByKey[PropertieKey.Function];
    const costCenterData = propertyByKey[PropertieKey.CostCenterSponso];
    return (
        <Common.Wrapper>
            <a id={stake.id} />
            <AttachmentSection
                title={stake?.name}
                downloadHandler={downloadHandler}
            />
            <Common.PartyInfoWrapper>
                <Common.DetailSection>
                    <Common.LeftSide>
                        {userData &&
                            <Avatar
                                picture={userData?.picture}
                                firstName={userData?.fistName}
                                lastName={userData?.lastName}
                                id={userData?.id}
                                type={IProfileType.Company}
                                size={{
                                    width: 80,
                                    height: 80
                                }}
                            />
                        }
                        <Common.EntityDetails>
                            <Common.Name to={`/profile/${userData?.id}`}>
                                {`${userData?.firstName || ''} ${userData?.lastName || ''}`}
                            </Common.Name>
                            {!!legalEntityData?.value?.Picture &&
                                <ProfilesSummary
                                    type={IProfileType.Company}
                                    fullWidth
                                    profile={{
                                        picture: legalEntityData?.value?.Picture,
                                        firstName: legalEntityData?.value?.Name,
                                        lastName: '',
                                        id: legalEntityData?.id
                                    }}
                                />
                            }
                        </Common.EntityDetails>
                    </Common.LeftSide>
                    <Common.RightSideRow>
                        {!!managerData &&
                            <>
                                <Common.IconWrapper>
                                    <FontAwesomeIcon icon={faUser} />
                                    <span>{propertyByKey[PropertieKey.JourneyCreatorManager]?.name}</span>
                                </Common.IconWrapper>
                                <ProfilesSummary
                                    type={IProfileType.Personal}
                                    fullWidth
                                    profile={{
                                        picture: managerData.picture,
                                        firstName: managerData.firstName,
                                        lastName: managerData.lastName,
                                        id: managerData.id,
                                        jobTitle: managerData.jobTitle
                                    }}
                                />
                            </>
                        }
                        {propertyByKey[PropertieKey.Email]?.value &&
                            <>
                                <Common.IconWrapper>
                                    <FontAwesomeIcon icon={faAlignLeft} />
                                    <span>{propertyByKey[PropertieKey.Email]?.name}</span>
                                </Common.IconWrapper>
                                <Common.Representatives>
                                    {propertyByKey[PropertieKey.Email]?.value}
                                </Common.Representatives>
                            </>
                        }
                        {!legalEntityData?.value?.Picture &&
                            <div>
                                <Common.AdditionalInfo>
                                    <span>{legalEntityData.name}</span>
                                </Common.AdditionalInfo>
                                <Common.Representatives>
                                    {legalEntityData?.value?.Name}
                                </Common.Representatives>
                            </div>
                        }
                        {!!functionData &&
                            <div>
                                <Common.AdditionalInfo>
                                    <span>{functionData.name}</span>
                                </Common.AdditionalInfo>
                                <Common.Representatives>
                                    {functionData?.value}
                                </Common.Representatives>
                            </div>
                        }
                        {!!costCenterData &&
                            <div>
                                <Common.AdditionalInfo>
                                    <span>{costCenterData.name}</span>
                                </Common.AdditionalInfo>
                                <Common.Representatives>
                                    {costCenterData?.value?.value}
                                </Common.Representatives>
                            </div>
                        }
                    </Common.RightSideRow>
                </Common.DetailSection>
            </Common.PartyInfoWrapper>
        </Common.Wrapper>
    );
};
