import React from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { colorStack } from '../../styleHelpers/colors';
import { fontSize } from '../../styleHelpers/fontSizes';
import { media } from '../../styleHelpers/breakpoint';
import { getData } from '../../tools/date';
import { Avatar } from './Avatar';
import { instanceConfig } from '../../instance';
import { IState } from '../../reducers/index';
import { ISingleConversation, ConversationMember } from '../../entities/Messaging/IMessaging';
import { IMessagingReducer } from '../../reducers/messagingReducer';
import { IProfileReducer } from '../../reducers/profileReducer';
import * as messageActions from '../../actions/messagingActions';
import { getConversationTitle } from '../../tools/messaging';

const { enableTracking } = instanceConfig;

// tslint:disable-next-line: no-var-requires
const withAITracking = enableTracking && require('react-appinsights').withAITracking;

const SingleConversationContainer = styled.div<{ containerInfo: SelfProps }>`
    display: flex;
    margin: 0 0 1px 0;
    cursor: pointer;
    .border{
        border: 3px solid ${colorStack.white};
    }
    .image-wrapper{
        width: 48px;
        height: 48px;
        ${media.desktop`
            width: 60px;
            height: 60px;
        `}
        position: relative;
        margin: 0;
        align-self: center;
            margin: 0 18px;
    }
    .metas{
            display: flex;
            flex-direction: column;
            width: 100%;
            flex: 1;
            padding: 15px 25px 15px 0;
            ${media.desktop`
                padding: 15px 10px 15px 0;
            `}
        .date{
            color: ${colorStack.darkBlue};
            font-size: ${fontSize[10]};
            align-self: flex-end
        }
        .name{
            color: ${colorStack.darkBlue};
            font-size: ${fontSize[13]};
            font-weight: 500;
        }
        .subtitle{
            color: ${colorStack.disabled};
            font-size: ${fontSize[10]};
        }
        .message {
            color: ${colorStack.darkBlue};
            font-size: ${fontSize[13]};
            margin: 10px 0 0 0;
        }
        .counter {
            background: ${colorStack.darkBlue};
            width: 33px;
            height: 22px;
            color: ${colorStack.white};
            font-size: ${fontSize[13]};
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            font-weight: 600;
            align-self: flex-end;
            position: relative;
            top: -13px;
        }
    }
    .active{
        width: 11px;
        height: 11px;
        background: ${colorStack.lightBlue};
        border-radius: 100%;
        display: block;
        float: right;
        margin: -6px 0 0 0;
    }
    ${props => props.containerInfo.active && css`
        background: ${colorStack.whiteGrey};
        .border{
            border-color: ${colorStack.whiteGrey};
        }
    `}
    ${props => props.containerInfo.unread && css`
        .border{
            border-color: ${colorStack.darkBlue};
        }
        .metas{
            .date{
                color: ${colorStack.darkBlue};
                font-weight: 700;
            }
            .name {
                 color: ${colorStack.darkBlue};
                 font-weight: 600;
            }
        }
    `}
`;

interface SelfProps {
    unread: boolean;
    active?: boolean;
    data?: ISingleConversation;
    isPopup?: boolean;
    changeMobileContext?();
}

interface SingleConversationState {
    currentConversationId: string;
}

type SingleConversationProps = SelfProps & IProfileReducer & IMessagingReducer & WrappedComponentProps & typeof messageActions;

class SingleConversation extends React.Component<SingleConversationProps, SingleConversationState> {
    state = {
        currentConversationId: undefined
    };

    handleClick = () => {
        const { clearCurrentMessages, getMessages, clearSettingsSearchWindow, setCurrentConversation, addActiveConversation, markAsRead, activeConversations, showConversationMenu, isPopup, activeConversationId, data, changeMobileContext } = this.props;
        changeMobileContext?.();

        if (isPopup && showConversationMenu && !Object.keys(activeConversations || {})?.includes(data.id)) {
            addActiveConversation(data);
            const lastMessageId = data?.lastMessage?.messageId;
            lastMessageId && markAsRead(lastMessageId, data.id);
        }

        if (!isPopup && activeConversationId !== this.state.currentConversationId) {
            this.setState({ currentConversationId: data.id });
            setCurrentConversation(data);
            clearCurrentMessages();
            clearSettingsSearchWindow();

            /* if (!data.id.includes('tempGroup-')) {
                getMessages()
                    .then(() => {
                        const { data, currentMessages } = this.props;
                        const id = data.lastMessage ? data.lastMessage.messageId : (currentMessages && currentMessages[0] && currentMessages[0].id);
                        id && markAsRead(id);
                    });
            } else {
                clearCurrentMessages();
            } */
        }
    }

    render() {
        const { unread, active, data, currentUserProfile } = this.props;
        const conversationMembers = data.conversationMembers || [];
        const convMembers = (data && conversationMembers.find((user: ConversationMember) => user.id !== currentUserProfile.id)) || conversationMembers[0] || {} as any;
        const lastMessage = data.lastMessage || {
            preview: ''
        } as typeof data.lastMessage;

        return (
            <SingleConversationContainer containerInfo={{ unread, active }} onClick={this.handleClick}>
                <div className="border"></div>
                <div className="image-wrapper">
                    <Avatar members={conversationMembers} currentUserId={currentUserProfile.id} />
                </div>
                <div className="metas">
                <span className="date">{lastMessage && getData(lastMessage.creationDate, this.props.intl.formatMessage({id: 'global.today'}))}</span>
                    <span className="name">{getConversationTitle(conversationMembers, data, convMembers)}</span>
                    {(conversationMembers.length < 3) && <span className="subtitle">{convMembers.cabinet} {convMembers.jobTitle}</span>}
                    {lastMessage && <span className="message">{`${lastMessage.preview}${lastMessage.preview.length >= 15 ? '...' : ''}`}</span>}
                    {unread &&
                        <span className="counter">+{data.unreadMessagesCount}</span>
                    }
                </div>
            </SingleConversationContainer>
        );
    }
}

export default connect<IProfileReducer & IMessagingReducer, typeof messageActions, SelfProps>(
    (state: IState) => ({
        ...state.messaging,
        ...state.profile
    }), {
    ...messageActions
}
)(injectIntl(SingleConversation));