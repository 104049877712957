export interface ICOIPost {
    formData: {
        declarationType: string,
        coiInformation: {
            creator: {
                id: string
            },
            jobTitle: string,
            legalEntity: {
                id: string
            },
            manager: {
                id: string
            }
        },
        coiDeclaration: {
            type: string,
            description: string,
            linkType: {
                id: string,
                name: string,
                key: string
            },
            thirdParty: {
                id: string
            },
            startingDate: string,
            endingDate: string,
            context: string
        }
        coiCertification: {
            certificateClauses: string,
            certificationDate: Date,
            certificationSignee: {
                id: string
            }
        }
    }
}

export enum CoiType {
    CoiNoLink = 40,
    CoiElectedPosition = 30,
    CoiFamilyInterest = 30,
    CoiFinance = 30,
    CoiAssociationMandate = 30,
    CoiOtherLink = 30
}