import React, { FC } from 'react';

import { IAddress } from '../../../entities/IProfile';
import { IAddressGlobal } from '../../../entities/IGlobal';
import { CountrySwitch } from './GoogleAddressEditor';

// IAddress interface is deprecated
interface IGoogleAddressViewProps {
    address: IAddress;
}
interface IGoogleAddressViewGlobalProps {
    address: IAddressGlobal;
}

export const GoogleAddressView: FC<IGoogleAddressViewProps> = ({ address }) => (
    <>
        {
            Object.keys(CountrySwitch).indexOf(address?.CountryShort) > -1 ?

                `${address?.Number || ''}${address?.Street && ` ${address?.Street}`}${(address?.Street || address?.Number) && ', '}${address?.City || ''}${address?.Country && ', '}${address?.Country || ''}` :

                `${address?.Street}${address?.Number && ` ${address?.Number}`}${(address?.Street || address?.Number) && ', '}${address?.City}${address?.Country && ', '}${address?.Country || ''}`
        }
    </>
);

export const GoogleAddressViewGlobal: FC<IGoogleAddressViewGlobalProps> = ({ address }) => (
    <>{
        Object.keys(CountrySwitch).indexOf(address?.countryShort) > -1 ?

            `${address?.number}${address?.street && ` ${address?.street}`}${(address?.street || address?.number) && ', '}${address?.city}${address?.country && ', '}${address?.country}` :

            `${address?.street}${address?.number && ` ${address?.number}`}${(address?.street || address?.number) && ', '}${address?.city}${address?.country && ', '}${address?.country}`
    }
    </>
);
