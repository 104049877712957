import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { IState } from '../../../reducers';
import { IAvatarReducer } from '../../../reducers/avatarReducer';
import { setUserColor } from '../../../actions/avatarActions';
import { getEntityLink } from '../../../tools/clusterTools';
import { IProfileType } from '../../../entities/IGlobal';

type SetUserColor = ReturnType<typeof setUserColor>;

const Wrapper = styled.div<{ size?: { width: number, height: number, onlyWrapperSize: boolean }, extended: boolean, isFullWidth?: boolean }>`
    position: relative;
    z-index: 9;
    max-width: 100%;
    ${props => props.isFullWidth && css`
        width: 100%;
    `}
    border-radius: 100%;
    a, span {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-decoration: none;
        &:hover {
            text-decoration: none;
        }
    }
    ${props => (props.size && !props.extended) && css`
        height: ${`${props.size.height}px`};
        width: ${`${props.size.width}px`};
    `}
    ${props => (props.size && props.size.onlyWrapperSize) && css`
        height: ${`${props.size.height}px`};
        width: ${`${props.size.width}px`};
    `}
`;

const CurrentUserProfilePicture = styled.img<{ size?: { width: number, height: number, onlyWrapperSize: boolean }; type: IProfileType }>`
    border-radius: 100%;
    height: 32px;
    width: 32px;
    ${props => (props.type === IProfileType.Company || props.type === IProfileType.Cluster) && css`
        border-radius: 8px;
    `}
    ${props => (props.size && !props.size.onlyWrapperSize) && css`
        height: ${`${props.size.height}px`};
        width: ${`${props.size.width}px`};
    `}
    background-color: ${`${colorStack.white}`};
`;

const TextWrapper = styled.div<{ fontSize: number; bgColor: string; size?: { width: number, height: number, onlyWrapperSize: boolean }; type: IProfileType }>`
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    border-radius: 100%;
    ${props => props.bgColor && css`
        background: ${props.bgColor};
    `}
    ${props => (props.type === IProfileType.Company || props.type === IProfileType.Cluster) && css`
        border-radius: 8px;
    `}
    ${props => (props.size && !props.size.onlyWrapperSize) && css`
        height: ${`${props.size.height}px`};
        width: ${`${props.size.width}px`};
    `}
    > div {
        margin: auto 0;
        ${props => props.fontSize && css`
            ${fontSizeAndHeight[props.fontSize]};
        `};
        color: ${colorStack.darkBlue};
        font-weight: 500;
    }
`;

const Name = styled.div<{fontSize: number}>`
    color: ${colorStack.content};
    ${props => props.fontSize && css`
        ${fontSizeAndHeight[props.fontSize]};
    `};
    margin: 0 0 0 .5rem;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

interface IProps {
    picture: string;
    firstName: string;
    lastName: string;
    id: string;
    disabled?: boolean;
    extended?: boolean;
    isFullWidth?: boolean;
    type?: IProfileType;
    size?: {
        width: 16 | 24 | 32 | 40 | 48 | 56 | 64 | 72 | 80 | 88 | 96 | 104 | 112 | 120 | 128 | 136 | 144 | 152 | 160 | 168 | 176 | 184 | 192 | 200;
        height: 16 | 24 | 32 | 40 | 48 | 56 | 64 | 72 | 80 | 88 | 96 | 104 | 112 | 120 | 128 | 136 | 144 | 152 | 160 | 168 | 176 | 184 | 192 | 200;
        onlyWrapperSize?: boolean;
    };
    labelSize?: 8 | 10 | 13 | 16 | 20 | 22 | 25 | 31 | 39 | 49 | 61 | 76 | 95;
    preventRedirection?: boolean;
    organizationUrlName?: string;
}

const AvatarDetails: FC<IProps> = props => {
    const { usersColor } = useSelector<IState, IAvatarReducer>(state => state.avatar);

    return (
        <>
            {props.picture ? (
                <CurrentUserProfilePicture
                    src={props.picture}
                    alt="User picture"
                    size={{ width: props.size?.width || 32, height: props.size?.height || 32, onlyWrapperSize: props.size?.onlyWrapperSize || false }}
                    type={props.type || IProfileType.Personal} />
            ) : (
                <TextWrapper
                    bgColor={usersColor?.[props.id]}
                    size={{ width: props.size?.width || 32, height: props.size?.height || 32, onlyWrapperSize: props.size?.onlyWrapperSize || false }}
                    type={props.type || IProfileType.Personal}
                    fontSize={props.labelSize || 13}
                >
                    <div>
                        {props.firstName?.slice(0, 1)}
                        {props.lastName?.slice(0, 1)}
                    </div>
                </TextWrapper>
            )}
            {props.extended &&
                <Name fontSize={props.labelSize || 16}>{props.firstName} {props.lastName}</Name>
            }
        </>
    );
};

export const Avatar: FC<IProps> = props => {
    const dispatch = useDispatch();
    const [targetType, setTargetType] = useState<string>(undefined);

    useEffect(() => {
        if (!props.picture) {
            dispatch<SetUserColor>(setUserColor(props.id));
        }
        setTargetType(IS_TEAMS_INSTANCE ? '_self' : '_blank');

    }, [IS_TEAMS_INSTANCE]);

    return (
        <Wrapper
            size={{
                width: props.size?.width || 32,
                height: props.size?.height || 32,
                onlyWrapperSize: props.size?.onlyWrapperSize || false
            }}
            extended={props.extended}
            isFullWidth={props.isFullWidth}
        >
            {props.preventRedirection ? (
                <span>
                    <AvatarDetails
                        picture={props?.picture}
                        firstName={props?.firstName}
                        lastName={props?.lastName}
                        id={props?.id}
                        type={props?.type}
                        extended={props?.extended}
                        size={{
                            width: props?.size?.width || 32,
                            height: props?.size?.height || 32,
                            onlyWrapperSize: props?.size?.onlyWrapperSize
                        }}
                        labelSize={props.labelSize}
                    />
                </span>
            ) : (
                <a href={getEntityLink(props?.id, props?.organizationUrlName, props?.type)} target={targetType}>
                    <AvatarDetails
                        picture={props?.picture}
                        firstName={props?.firstName}
                        lastName={props?.lastName}
                        id={props?.id}
                        type={props?.type}
                        extended={props?.extended}
                        size={{
                            width: props?.size?.width || 32,
                            height: props?.size?.height || 32,
                            onlyWrapperSize: props?.size?.onlyWrapperSize
                        }}
                        labelSize={props.labelSize}
                    />
                </a>
            )}
        </Wrapper>
    );
};
