import store from './../store';
import { instanceConfig } from '../instance';

const { gtm } = instanceConfig;

class GoogleAnalyticsService {
    private data: any;

    constructor(
        event = "GenericEvent",
        env = {
            work: "preprod", // Work environment
            language: "FR", // Selected language
            currency: "EUR" // Currency
        },
        user = {
            logged: 0, // Is the user logged into an account ?
            id: undefined, // ID of the user account if logged, undefined otherwise
            type: undefined, // Type of the user account if logged, undefined otherwise
            premium: 0, // Is the user account premium ?
            profile_completed: 0 // Is the user profile completed or empty ?
        },
        page?

    ) {
        this.data = { event, env, page, user };
    }

    public ActionEvent = (page?: any) => {
        if (gtm) {
            const data = this.loadUserData();
    
            if (page !== undefined) {
                data.page = page;
            }
    
            (window["dataLayer"] || []).push(data);
        }
    }

    private loadUserData = () => {
        const { config, context, profile } = store.getState();

        if (config.appConfig)
            this.data.env.work = config.appConfig.environment;

        if (context.isLoggedIn && profile.currentProfileLoaded) {
            this.data.user.logged = 1;
            this.data.user.id = profile.currentUserProfile.id || undefined;
            this.data.user.type = profile.currentUserProfile.profileType || undefined;
            this.data.user.profile_completed = 1;
        }

        return this.data;
    }
}

export default GoogleAnalyticsService;