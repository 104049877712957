import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';

import { ISearchUser } from '../../../../../entities/ISearch';
import { ProfilesSummary } from '../../../../Common/ProfilesSummary/ProfilesSummary';
import { ErrorText } from './StakeStyles';
import { IPeopleContentProps, Wrapper } from './PeopleContent';
import { IProfileType, IUserBrief } from '../../../../../entities/IGlobal';
import { UsersPicker } from '../../../../Common/Pickers/UsersPicker';

const PickerWrapper = styled.div`
    margin-top: 6px;
`;

export const NewDesignPeopleContent: FC<IPeopleContentProps> = ({ error, data, editStake, propertieId, setEditingData, stakeOptions }) => {
    const [personValue, setPersonValue] = useState<{ key: string; text: string; data: IUserBrief; }[]>(undefined);

    useEffect(() => {
        setPersonValue(data?.value?.id ? [{
            ...data?.value,
            key: data?.key,
            data: data?.value
        }] : undefined);
    }, []);

    const onChangeSelectedPerson = (value: { key: string; text: string; data: IUserBrief; }[]) => {
        setPersonValue(value);
        setEditingData(({ ...data, value: value?.[0]?.data, id: propertieId }));
    };

    const getUserData = (user: IUserBrief) => {
        setEditingData(({ ...data, value: user, id: propertieId }));
    };

    return (
        <Wrapper>
            {editStake ? (
                <>
                    <PickerWrapper>
                        <UsersPicker
                            value={personValue}
                            onSelectElement={onChangeSelectedPerson}
                            getSaveData={getUserData}
                        />
                    </PickerWrapper>
                    {error && <ErrorText>{error}</ErrorText>}
                </>
            ) : (
                    <>
                        {(!!personValue?.[0]?.data && !!personValue?.[0]?.data?.id) &&
                            <ProfilesSummary
                                fullWidth={stakeOptions?.fullWidth}
                                key={personValue?.[0]?.data?.id}
                                type={IProfileType.Personal}
                                profile={{
                                    picture: personValue?.[0]?.data?.picture,
                                    firstName: personValue?.[0]?.data?.firstName,
                                    lastName: personValue?.[0]?.data?.lastName,
                                    id: personValue?.[0]?.data?.id
                                }}
                            />
                        }
                    </>
                )}
        </Wrapper>
    );
};
