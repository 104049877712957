import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut, faUserCog, faUserUnlock, faQuoteLeft, faCopyright, faMoneyBill} from '@fortawesome/pro-light-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

import { colorStack } from '../../styleHelpers/colors';
import { fontSize } from '../../styleHelpers/fontSizes';
import { instanceConfig } from '../../instance';
import { IFullUserProfile } from '../../entities/IProfile';
import { history } from '../../history';
import { media } from '../../styleHelpers/breakpoint';
import { SingleElement } from './Components/SingleElement';
import { IConfigReducer } from '../../reducers/configReducer';
import { IState } from '../../reducers';
import { LegalClusterEnv } from '../../entities/IConfiguration';
import { IBillingSubscription } from '../../entities/Billing/Billing';
import { getBillingSubscriptions } from '../../actions/billingActions';

type GetBillingSubscriptions = ReturnType<typeof getBillingSubscriptions>;

const { headerMenu } = instanceConfig;

const UserMenu = styled.div`
    padding: .5rem;
    ${media.desktop`
        position: absolute;
        border: 1px solid ${colorStack.middleGrey};
        top: 42px;
        right: 0;
        background: ${colorStack.white};
        border-radius: 4px;
    `}
`;

const Title = styled.div`
    font-size: ${fontSize[13]};
    color: ${colorStack.middleGrey};
    font-weight: 600;
    margin: 0 0 1rem 0;
`;

const LogoutButton = styled.button`
    cursor: pointer;
    outline: 0;
    border: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colorStack.disabled};
    transition: color .2s ease;
    border-top: 1px solid ${colorStack.middleGrey};
    span, svg {
        margin: 15px 5px;
    }
    span {
        font-weight: 500;
    }
    &:hover {
        color: ${colorStack.darkBlue};
    }
`;

interface IUserMenuDropdownProps {
    currentUserProfile: IFullUserProfile;
    closeDropdown?(): void;
}

export const UserMenuDropdown: FC<IUserMenuDropdownProps> = ({ currentUserProfile, closeDropdown }) => {
    const dispatch = useDispatch();
    const { platformName, isLocalHost } = useSelector<IState, IConfigReducer>(state => state.config);
    const [subscriptions, setSubscriptions] = useState<IBillingSubscription[]>(undefined);

    useEffect(() => {
        dispatch<GetBillingSubscriptions>(getBillingSubscriptions()).then((res) => setSubscriptions(res));
    }, []);

    const onLogout = useCallback(() => {
        if (IS_TEAMS_INSTANCE) {
            history.push('/teams/logout-start');
        } else {
            history.push('/logout');
        }
    }, []);

    return (
        <UserMenu>
            <Title>
                <FormattedMessage id="topnav.account" />
            </Title>
            {headerMenu.profile &&
                <SingleElement
                textNode={`${currentUserProfile?.firstName} ${currentUserProfile?.lastName}`}
                link={`/profile/${currentUserProfile?.id}`}
                imagePath={currentUserProfile?.picture}
                additionalInfo={<FormattedMessage id="topnav.seeProfile" />}
                actionHandler={closeDropdown}
                />
            }
            {(isLocalHost || platformName === LegalClusterEnv.lcDev || platformName === LegalClusterEnv.lcRec || platformName === LegalClusterEnv.lcDemo || platformName === LegalClusterEnv.lcProd) && subscriptions?.length > 0 &&
                <SingleElement
                    textNode={<FormattedMessage id="topnav.billing" />}
                    link={'/billing'}
                    icon={faMoneyBill}
                    actionHandler={closeDropdown}
                />
            }
            {headerMenu.settings &&
                <SingleElement
                    textNode={<FormattedMessage id="topnav.settings" />}
                    link="/settings"
                    icon={faUserCog}
                    actionHandler={closeDropdown}
                />
            }
            {headerMenu.terms &&
                <SingleElement
                    textNode={<FormattedMessage id="topnav.information.terms" />}
                    link="/terms"
                    icon={faQuoteLeft}
                    actionHandler={closeDropdown}
                />
            }
            {headerMenu.privacyPolicy &&
                <SingleElement
                    textNode={<FormattedMessage id="topnav.information.privacy" />}
                    link="/copyrights"
                    icon={faCopyright}
                    actionHandler={closeDropdown}
                />
            }
            <LogoutButton onClick={onLogout}>
                <FontAwesomeIcon icon={faSignOut} size="lg" />
                <FormattedMessage id="topnav.logout" />
            </LogoutButton>
        </UserMenu>
    );
};
