import React, { FC, useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FormattedMessage } from 'react-intl';

import { colorStack } from '../../../../../../styleHelpers/colors';
import { cardsShadow } from '../../../../../../styleHelpers/mixins/shadow';
import { useDropdown } from '../../../../../../tools/hooks';
import { IDatesStatus } from '../../../../../../entities/IDates';

const Wrapper = styled.div`
    position: relative;
    border-radius: 4px;
    margin: 1rem 0 0 0;
    ${cardsShadow()};
`;

const GhostInput = styled.div<{ colors: string[] }>`
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    padding: .5rem;
    cursor: pointer;
    color: ${colorStack.label};
    ${props => props.colors && css`
        color: ${props.colors[0]};
        background: ${props.colors[1]};
    `}
`;
const Options = styled.div`
    border-radius: 0 0 4px 4px;
    background: ${colorStack.ligthGrey};
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const SingleOption = styled.button<{ colors: string[] }>`
    text-align: left;
    cursor: pointer;
    padding: .5rem 1rem;
    ${props => props.colors && css`
        color: ${props.colors[0]};
        background: ${props.colors[1]};
    `}
`;

export interface IOption {
    key: string;
    text: string;
    data: any;
}

interface IProps {
    selectedKey: IDatesStatus;
    onChange(item: IDatesStatus);
}

export const StatusDropdown: FC<IProps> = props => {
    const [wrapperRef, dropdownOpen, toggleDropdown, closeDropdown] = useDropdown();

    const colors = useMemo(() => ({
        [IDatesStatus.InProgress]: [colorStack.middleBlue, colorStack.whiteBlue],
        [IDatesStatus.NotStarted]: [colorStack.black, colorStack.ligthGrey],
        [IDatesStatus.Done]: [colorStack.lightGreen, colorStack.whiteGreen],
        [IDatesStatus.Overdue]: [colorStack.red, colorStack.whiteRed]
    }), []);

    const onSelect = useCallback((item: IDatesStatus) => {
        props.onChange(item);
        closeDropdown();
    }, []);

    return (
        <Wrapper ref={wrapperRef}>
            <GhostInput onClick={toggleDropdown} colors={colors[props.selectedKey]}>
                {props?.selectedKey &&
                    <FormattedMessage id={`dates.status.${props.selectedKey}`} />
                }
                <FontAwesomeIcon icon={dropdownOpen ? faChevronUp : faChevronDown} />
            </GhostInput>
            {dropdownOpen &&
                <Options>
                    <SingleOption onClick={() => onSelect(IDatesStatus.NotStarted)} colors={colors[IDatesStatus.NotStarted]}>
                        <FormattedMessage id={`dates.status.${IDatesStatus.NotStarted}`} />
                    </SingleOption>
                    <SingleOption onClick={() => onSelect(IDatesStatus.InProgress)} colors={colors[IDatesStatus.InProgress]}>
                        <FormattedMessage id={`dates.status.${IDatesStatus.InProgress}`} />
                    </SingleOption>
                    <SingleOption onClick={() => onSelect(IDatesStatus.Done)} colors={colors[IDatesStatus.Done]}>
                        <FormattedMessage id={`dates.status.${IDatesStatus.Done}`} />
                    </SingleOption>
                    <SingleOption onClick={() => onSelect(IDatesStatus.Overdue)} colors={colors[IDatesStatus.Overdue]}>
                        <FormattedMessage id={`dates.status.${IDatesStatus.Overdue}`} />
                    </SingleOption>
                </Options>
            }
        </Wrapper>
    );
};