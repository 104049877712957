import React, { FC, useContext, Fragment } from 'react';
import { ThemeProvider } from 'styled-components';

import { newDesignTheme } from './newDesignTheme';

interface INewDesignContext {
    active: boolean;
}

const NewDesignContext = React.createContext<INewDesignContext>({
    active: false
});

export const useNewDesignContext = () => {
    return useContext(NewDesignContext);
};

export const NewDesignSwitch: FC<INewDesignContext> = ({ active, children }) => (
    <NewDesignContext.Provider value={{ active }}>
        {active ? (
            <ThemeProvider theme={newDesignTheme}>
                <Fragment>{children}</Fragment>
            </ThemeProvider>
        ) : (
            children
        )}
    </NewDesignContext.Provider>
);
