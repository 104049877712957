import { get, uniqBy } from 'lodash';
import * as actionTypes from '../actions/actionTypes/notificationsTypes';
import { INotification } from '../entities/INotification';

export interface INotificationReducer {
    newNotificationCount: number;
    notificationsCount: any;
    notificationsActive: INotification[];
    notificationsNonActive: INotification[];
    navMenuNotifications: INotification[];
    notificationsWithButtons: any;
    rfpActiveNotificationCount: number;
    reciverRmsStatus: number;
    activeNotificationsPage: number;
    nonActiveNotificationsPage: number;
    notificationsVisible: boolean;
}

const defaultState = (): INotificationReducer => ({
    newNotificationCount: 0,
    notificationsCount: undefined,
    notificationsActive: undefined,
    notificationsNonActive: undefined,
    navMenuNotifications: [],
    notificationsWithButtons: {
        P2PInvitation: true
    },
    rfpActiveNotificationCount: 0,
    reciverRmsStatus: undefined,
    activeNotificationsPage: 0,
    nonActiveNotificationsPage: 0,
    notificationsVisible: false
});

export default (state = defaultState(), action): INotificationReducer => {
    switch (action.type) {
        case actionTypes.GET_NOTIFICATIONS_COUNT: {
            return {
                ...state,
                notificationsCount: action.notificationsCount
            };
        }
        case actionTypes.GET_NOTIFICATIONS_ACTIVE_DATA: {
            return {
                ...state,
                notificationsActive: action.notificationsActive,
                rfpActiveNotificationCount: (get(action, 'notificationsActive', []) || [])
                    .filter(notification => ['RfpCreated', 'RfpUpdated', 'RfpChangedStatus', 'RfpDeadlineExpired', 'ProposalCreated', 'ProposalChangedStatus', 'ProposalUpdated', 'RfpClosed']
                        .indexOf(notification.key) !== -1).length
            };
        }
        case actionTypes.GET_NOTIFICATIONS_NONACTIVE_DATA: {
            return {
                ...state,
                notificationsNonActive: action.notificationsNonActive
            };
        }
        case actionTypes.UPDATE_RECIVER_RMS_STATUS: {
            return {
                ...state,
                reciverRmsStatus: action.reciverRmsStatus as number
            };
        }
        case actionTypes.NEW_NOTIFICATION_COUNT_LOADED: {
            return {
                ...state,
                newNotificationCount: action.count
            };
        }
        case actionTypes.NEW_NOTIFICATION_MARKED_ASS_SEEN: {
            return {
                ...state,
                newNotificationCount: 0
            };
        }
        case actionTypes.LOAD_MORE_ACTIVE_NOTIFICATIONS: {
            return {
                ...state,
                notificationsActive: uniqBy([...(state.notificationsActive || []), ...action.activeNotifications], 'id'),
                activeNotificationsPage: state.activeNotificationsPage + 1
            };
        }
        case actionTypes.LOAD_MORE_NONACTIVE_NOTIFICATIONS: {
            return {
                ...state,
                notificationsNonActive: [...state.notificationsNonActive || [], ...action.nonActiveNotifications],
                nonActiveNotificationsPage: state.nonActiveNotificationsPage + 1
            };
        }
        case actionTypes.SIGNALR_NOTIFICATION_RECIVED: {
            const activNotLength = (state.notificationsActive || []).length;
            const prevNotArray = activNotLength < 5
                ? state.notificationsActive
                : state.notificationsActive.slice(0, activNotLength - 1);

            const navNotLength = (state.navMenuNotifications || []).length;
            const prevNavNotArray = navNotLength < 8
                ? state.navMenuNotifications
                : state.navMenuNotifications.slice(0, navNotLength - 1);
            return {
                ...state,
                newNotificationCount: state.newNotificationCount + 1,
                notificationsActive: uniqBy([action.notification, ...prevNotArray], 'id'),
                navMenuNotifications: uniqBy([action.notification, ...prevNavNotArray], 'id')
            };
        }
        case actionTypes.SET_NOTIFICATION_VISIBILITY_FLAG: {
            return {
                ...state,
                notificationsVisible: action.notificationsVisible
            };
        }
        case actionTypes.NAV_MENU_NOTIFICATION_UPDATED: {
            return {
                ...state,
                navMenuNotifications: action.navMenuNotifications
            };
        }
        default: return state;
    }
};