import React, { ComponentProps, ComponentType, LazyExoticComponent, Suspense } from 'react';

import { Layout } from '../components/Layout/Layout';
import { ChunkLoader } from '../components/Common/ChunkLoader';

type RouteLayoutStaticConfig = Pick<ComponentProps<typeof Layout>, 'isTeamsConfigPage'>;
type RouteLayoutConfig = Pick<ComponentProps<typeof Layout>, 'isHomeRoute'|'isTeamsRoute'>;

const lazyRoute = <T extends ComponentType<any>>(RouteComponent: LazyExoticComponent<T>, { isTeamsConfigPage }: RouteLayoutStaticConfig = {}) =>
    ({ isHomeRoute, isTeamsRoute, ...props }: ComponentProps<T> & RouteLayoutConfig) => (
        <Layout isHomeRoute={isHomeRoute} isTeamsRoute={isTeamsRoute} isTeamsConfigPage={isTeamsConfigPage}>
            <Suspense fallback={<ChunkLoader/>}>
                <RouteComponent {...props as ComponentProps<T>} />
            </Suspense>
        </Layout>
    );

export default lazyRoute;
