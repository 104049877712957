import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { IClusterPersonInCharge } from '../../../entities/IClusters';
import { Avatar } from '../Avatar/Avatar';

const PeopleInCharge = styled.div<{ width?: string }>`
    display: flex;
    align-items: center;
    ${props => props.width && css`
        width: ${props.width};
    `}
`;

interface IPeopleInChargeBrief {
    peopleInCharge: IClusterPersonInCharge[];
    margin?: string;
    containerWidth?: string;
}

export const PeopleInChargeBrief: FC<IPeopleInChargeBrief> = ({ peopleInCharge, containerWidth, margin }) => (
    <PeopleInCharge width={containerWidth}>
        {peopleInCharge.map(person =>
            <NavLink key={person.id} to={`/profile/${person.id}`}>
                <Avatar
                    picture={person.picture}
                    firstName={person.firstName}
                    lastName={person.lastName}
                    id={person.id}
                    size={{
                        width: 24,
                        height: 24
                    }}
                />
            </NavLink>
        )}
    </PeopleInCharge>
);