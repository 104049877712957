import React, { SFC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { spinner } from '../../../styleHelpers/global/keyframes';

interface CssProps {
    size: number;
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const Circle = styled.div<CssProps>`
    box-sizing: border-box;
    animation-name: ${spinner};
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.53, 0.21, 0.29, 0.67);
    width: ${props => `calc(${props.size} * 12px)`};
    height: ${props => `calc(${props.size} * 12px)`};
    border-radius: 50%;
    border-width: ${props => `calc(${props.size} * 0.5px)`};
    border-style: solid;
    border-color: ${colorStack.darkBlue} ${colorStack.whiteBlue} ${colorStack.whiteBlue};
    border-image: initial;
`;

const Label = styled.span<CssProps>`
    color: ${colorStack.darkBlue};
    margin: 5px 0 0 0;
    font-size: ${props => `calc(${props.size} * 0.45rem)`};
`;

const sizeNum = {
    buttonSize: 1.15,
    xSmall: 1,
    small: 1.3,
    medium: 1.6,
    large: 2
};

interface ISpinnerProps {
    className?: string;
    label?: string | React.ReactNode;
    size: 'buttonSize' | 'xSmall' | 'small' | 'medium' | 'large';
}

export const Spinner: SFC<ISpinnerProps> = ({ label, size, className }) => (
    <Wrapper className={className || ''}>
        <Circle size={sizeNum[size]}></Circle>
        {label &&
            <Label size={sizeNum[size]}>{label}</Label>
        }
    </Wrapper>
);
