import { Dropdown, IDropdownProps } from '@fluentui/react';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { newDesignBoxShadowString, headersShadow } from '../../../styleHelpers/mixins/shadow';
import { LabelWithEllipsis } from '../Label/Label';
import { MandatoryFieldStar } from '../MandatoryFieldStar/MandatoryFieldStar';

const Wrapper = styled.div<{ disabled?: boolean; $width?: number | 'auto' }>`
    position: relative;
    ${props =>
        props.$width && typeof props.$width === 'number'
            ? css`
                  width: ${`${props.$width}px`};
              `
            : css`
                  width: auto;
              `}
    > div {
        width: 100%;
    }
    .custom-input {
        max-width: 100%;
        ${headersShadow()};
        border-radius: 4px;
        font-size: ${fontSize[16]};
        > span {
            border: none;
        }
    }
    .ms-Dropdown .ms-Dropdown-title {
        font-size: ${fontSize[16]};
        font-weight: 400;
        &.ms-Dropdown-titleIsPlaceHolder {
            color: ${colorStack.middleGrey};
            font-weight: 300;
            font-style: italic;
            font-family: 'Roboto', sans-serif;
        }
    }
    ${props =>
        props.disabled &&
        css`
            .ms-Dropdown-caretDownWrapper i {
                color: ${colorStack.middleGrey};
            }
            .ms-Dropdown .ms-Dropdown-title {
                background: ${colorStack.ligthGrey};
            }
        `}
`;

const ColorBar = styled.div`
    background-color: ${colorStack.red};
    transition: background-color 0.3s;
    height: 3px;
    width: 100%;
    border-radius: 0 0 4px 4px;
    position: absolute;
    z-index: 99;
    bottom: 18px;
    color: ${colorStack.red};
`;

const ErrorText = styled.p`
    color: ${colorStack.red};
    font-family: 'Roboto', sans-serif;
    font-size: ${fontSize[10]};
    text-align: left;
    top: 100%;
    z-index: 1;
`;

export type ISelectInputProps = IDropdownProps & {
    inputLabel?: React.ReactNode;
    required?: boolean;
    defaultSelect?: boolean;
};

export const SelectInput: FC<ISelectInputProps> = props => {
    const intl = useIntl();
    return (
        <Wrapper disabled={props.disabled} $width={props.dropdownWidth} data-lc="js-lc-select-input">
            {props.inputLabel && (
                <LabelWithEllipsis>
                    {props.inputLabel}
                    {props.required && <MandatoryFieldStar />}
                </LabelWithEllipsis>
            )}
            <Dropdown
                selectedKey={props.selectedKey}
                onChange={props.onChange}
                options={
                    props.options
                        ? props.defaultSelect
                            ? [{ key: 0, text: intl.formatMessage({ id: 'global.select' }) }, ...props.options]
                            : props.options
                        : props.options
                }
                disabled={props.disabled || !props.options}
                placeholder={props.placeholder}
                dropdownWidth={props.dropdownWidth}
                className="custom-input"
                data-lc="js-lc-custom-input"
                styles={{
                    dropdownItemSelected: {
                        background: colorStack.whiteBlue,
                        fontSize: fontSize[16],
                        padding: '9px 16px',
                        fontFamily: `'Roboto'!important`,
                        fontWeight: '500'
                    },
                    dropdownItem: {
                        color: colorStack.content,
                        fontSize: fontSize[16],
                        padding: '9px 16px',
                        fontFamily: `'Roboto'!important`,
                        selectors: {
                            ':focus': {
                                background: colorStack.ligthGrey
                            }
                        }
                    },
                    callout: {
                        borderRadius: '4px',
                        border: 'none',
                        boxShadow: newDesignBoxShadowString(),
                        overflow: 'hidden',
                        margin: '3px 0 0 0',
                        maxHeight: '200px!important'
                    },
                    title: {
                        fontFamily: `'Roboto'!important`,
                        lineHeight: '42px!important',
                        height: '42px!important',
                        fontWeight: '400'
                    },
                    caretDownWrapper: { height: '42px', lineHeight: '42px' },
                    caretDown: { color: colorStack.content, fontSize: fontSize[16], fontWeight: '700' }
                }}
            />
            {props.errorMessage && (
                <>
                    <ColorBar />
                    <ErrorText>{props.errorMessage}</ErrorText>
                </>
            )}
        </Wrapper>
    );
};
