import React, { createRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { rgba } from 'polished';
import Textarea from 'react-textarea-autosize';
import { last } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons';

import { IState } from '../../reducers/index';
import { colorStack } from '../../styleHelpers/colors';
import { fontSize } from '../../styleHelpers/fontSizes';
import { media } from '../../styleHelpers/breakpoint';
import placeholder from '../../styleHelpers/mixins/placeholder';
import { ISingleMessage, ICreateMessage, AllMessageTypes, TranslationKey } from '../../entities/Messaging/IMessaging';
import { Loader } from '../Common/Loader/Loader';
import { checkAttachmentSize, checkAttachmentType } from '../../tools/attachment';
import { IMessagingReducer } from '../../reducers/messagingReducer';
import { IProfileReducer } from '../../reducers/profileReducer';
import * as messageActions from '../../actions/messagingActions';

interface ISendButtonProps {
    blocked: boolean;
}

const FileUploadButtonWrapper = styled.div`
    width: 21px;
    height: 19px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 70px;
    ${media.tabletSm`
        right: 80px;
    `}
    cursor: pointer;

    label {
        color: ${colorStack.darkBlue};
        cursor: pointer;
        font-size: ${fontSize[16]};
    }

    input[type=file] {
        opacity: 0;
        position: absolute;
        width: 0.1px;
        height: 0.1px;
    }
`;

const SendMessageContainer = styled.div`
    width: 100%;
    margin: 0 0 .5rem 0;
    .error-message {
        margin: 0 0 10px 50px;
    }
`;
const SendMessageContainerInner = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 5px 0 0;
    width: 100%;
    position: relative;
    align-items: center;
    ${media.tabletSm`
        padding: 0 0 0 15px;
    `}
    img {
        display: block;
        width: 100%;
        cursor: pointer;
    }
`;

const SendButton = styled.div<ISendButtonProps>`
    color: ${props => props.blocked ? rgba(colorStack.darkBlue, 0.5) : colorStack.darkBlue};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    ${media.tabletSm`
        width: 50px;
        height: 50px;
    `}
    cursor: pointer;
`;

const SendInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 25px;
    background: ${colorStack.white};
    box-shadow: inset 0px 0px 5px ${rgba(colorStack.black, 0.2)};
    margin: 0 5px 0 0;
    padding: 3px 44px 3px 15px;
    width: calc(100% - 52px);

    textarea {
        width: 100%;
        border: none;
        font-size: ${fontSize[16]};
        color: ${colorStack.darkBlue};
        margin: 10px 0;
        padding: 1px;
        resize: none;
        min-height: 0;
        font-weight: 100;

        ${placeholder({ 'color': colorStack.disabled })};
    }

    ${media.tabletSm`
        margin: 0 15px 0 0;
        padding: 3px 46px 3px 24px;
        width: calc(100% - 65px);
    `}

    .attachment-wrapper {
        width: 100%;
        flex: 1;
        padding: 0 0 20px 0;
        font-weight: 500;
        font-size: ${fontSize[13]};
        display: flex;
        align-items: center;
        &::before{
            content: '';
            display: inline-block;
            background: url('../assets/images/icons/messaging/file.png') center center no-repeat;
            width: 20px;
            height: 20px;
            background-size: contain;
            margin: 0 10px 0 0;
        }
        .remove-attachment{
            background: url('../assets/images/icons/messaging/close.png') center center no-repeat;
            width: 10px;
            height: 10px;
            background-size: contain;
            margin: 0 0 0 10px;
            display: inline-block;
            cursor: pointer;
        }
        .single-attachment {
            word-break: break-all;
        }
    }
`;

const LoaderWrapper = styled.div`
    position: absolute;
    left: 20px;
`;

interface SelfState {
    inputText: string;
    blockInput: boolean;
    attachmentsArray: {}[];
    attachmentError: boolean;
    attachmentErrorMessage: string;
}

interface SelfProps {
    sendAction();
    conversationIdToDispatch?: string;
    updateMessages?(message: ISingleMessage);
    isPopup?: boolean;
}

type SendMessageProps = IMessagingReducer & IProfileReducer & SelfProps & typeof messageActions;

class SendMessage extends React.Component<SendMessageProps, SelfState> {

    inputRef: React.RefObject<HTMLInputElement> = createRef();

    state = {
        inputText: '',
        blockInput: false,
        attachmentsArray: [],
        attachmentError: false,
        attachmentErrorMessage: ''

    };

    componentDidUpdate(prevProps) {
        if (this.props.activeConversationId !== prevProps.activeConversationId) {
            this.setState({
                inputText: ''
            });
        }
    }

    createSendObject = (activeConversation) => {
        return ({
            content: this.state.inputText,
            conversationId: activeConversation.id,
            createdDate: new Date().toISOString(),
            id: `tempMessageId-${this.props.currentMessages ? this.props.currentMessages.length : 0}`,
            tempId: `tempMessageId-${this.props.currentMessages ? this.props.currentMessages.length : 0}`,
            senderUserId: this.props.currentUserProfile.id,
            type: this.state.attachmentsArray.length > 0 ? 'Attachment' : 'Text',
            status: '',
            attachData: this.state.attachmentsArray[0]
        }) as ISingleMessage;
    }

    checkConnection = () => {
        this.setState({
            blockInput: true
        }, () => {
            const connection = this.props.getSignalRHubConnection();
            if (connection && (connection as any).connectionState === 0) { // because for some stupid reason connectionState is private, but should be public
                connection.start().then(() => {
                    this.sendMessage();
                });
            } else {
                this.sendMessage();
            }
        });
    }

    sendMessage = async () => {
        const { activeConversationId, currentConversation, currentUserProfile, conversationIdToDispatch, activeConversations, isPopup } = this.props;
        const { blockInput, inputText, attachmentsArray } = this.state;
        const conversationToDispatch = conversationIdToDispatch ? activeConversations[conversationIdToDispatch].conversation : currentConversation;
        const activeConversation: ICreateMessage = {
            conversationMembers: conversationToDispatch.conversationMembers,
            createDate: new Date().toISOString(),
            content: this.state.inputText.trim(),
            type: this.state.attachmentsArray.length > 0 ? AllMessageTypes.Attachment : AllMessageTypes.Text,
            translationId: TranslationKey.NotTranslatable,
            conversationId: conversationToDispatch.id,
            title: conversationToDispatch.title,
            tempId: conversationIdToDispatch || activeConversationId,
            id: conversationIdToDispatch || activeConversationId
        };

        if (activeConversation.id.includes('tempGroup-') && blockInput && (inputText.trim().length > 0 || attachmentsArray.length > 0)) {
            const res: ISingleMessage = await this.props.createMessages(activeConversation as any, !isPopup);
            if (res) {
                this.props.updateSingleMessage(res);
                if (attachmentsArray.length > 0) {
                    this.props.uploadAttachment(res.id, attachmentsArray[0]);
                }
            }

            this.setState({
                inputText: '',
                blockInput: false,
                attachmentsArray: []
            });
            this.props.sendAction();

        } else if (blockInput && (inputText.trim().length > 0 || attachmentsArray.length > 0)) {
            const res: ISingleMessage = await this.props.createMessages(this.createSendObject(activeConversation), !isPopup);
            if (res) {
                this.props.updateMessages ? this.props.updateMessages(res) : this.props.updateSingleMessage(res);
                if (attachmentsArray.length > 0) {
                    this.props.uploadAttachment(res.id, attachmentsArray[0]);
                }
            }

            this.setState({
                inputText: '',
                blockInput: false,
                attachmentsArray: []
            });
            this.props.sendAction();
        } else {
            this.setState({ blockInput: false });
        }
    }

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        this.setState({
            inputText: value
        });
    }

    onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            event.stopPropagation();
            this.checkConnection();
        }
    }

    fileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files[0];
        const attachSize = checkAttachmentSize(file.size, 'messaging');
        const attachType = checkAttachmentType(last(file.name.split('.')).toLowerCase(), 'messaging');
        if (attachSize.isValid && attachType.isValid) {
            this.setState(currentState => ({
                attachmentsArray: [...currentState.attachmentsArray, file],
                attachmentError: false,
                attachmentErrorMessage: attachSize.message
            }));
        } else {
            this.setState({
                attachmentError: true,
                attachmentErrorMessage: attachType.message || attachSize.message
            });
        }
    }

    removeAttachment = () => {
        this.setState({
            attachmentsArray: []
        });
    }

    render() {
        const { inputText, attachmentsArray, attachmentError, attachmentErrorMessage, blockInput } = this.state;
        return (
            <SendMessageContainer>
                {attachmentError &&
                    <div className="error-message">{attachmentErrorMessage}</div>
                }
                <SendMessageContainerInner className="lcr-section">
                    <LoaderWrapper>
                        <Loader size={'small'} loading={blockInput}/>
                    </LoaderWrapper>
                    <SendInputWrapper>
                        <FormattedMessage id="messaging.placeholder.writeMessageHere" >
                            {txt => <Textarea minRows={1} maxRows={5} placeholder={txt} value={inputText} onChange={this.onChange} onKeyDown={this.onKeyDown} />}
                        </FormattedMessage>
                        {attachmentsArray.length > 0 &&
                            <div className="attachment-wrapper">
                                {attachmentsArray.map((attachment: any, index: number) =>
                                    <div className="single-attachment" key={index}>
                                        {attachment.name}
                                        <span className="remove-attachment" onClick={this.removeAttachment}></span>
                                    </div>
                                )}
                            </div>
                        }
                    </SendInputWrapper>
                    <SendButton blocked={blockInput} onClick={this.checkConnection}>
                        <FontAwesomeIcon icon={faPaperPlane} />
                    </SendButton>
                    {attachmentsArray.length === 0 &&
                        <FileUploadButtonWrapper>
                            <label htmlFor="attachment">
                                <FontAwesomeIcon icon={faPaperclip} />
                            </label>
                            <input id="attachment" type="file" multiple={false} onChange={this.fileInputChange} ref={this.inputRef} />
                        </FileUploadButtonWrapper>
                    }

                </SendMessageContainerInner>
            </SendMessageContainer>
        );
    }
}

export default connect(
    (state: IState) => ({
        ...state.messaging,
        ...state.profile
    }), {
        ...messageActions
    }
)(SendMessage);
