import React, { FC, useCallback, MouseEvent, useRef, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colorStack } from '../../../styleHelpers/colors';
import { ILevel1MenuItem } from '../../../entities/IMenu';
import { WorkspaceLogo } from '../../Common/WorkspaceLogo/WorkspaceLogo';
import { Tooltip } from '../../Common/Tooltip/ToolTip';

const Wrapper = styled(Link) <{ $isHidden: boolean }>`
    cursor: pointer;
    display: flex;
    position: relative;
    justify-content: flex-start;
    align-items: center;
    min-height: 52px;
    padding: .5rem 1.1rem;
    transition: background-color .2s ease;
    &:hover {
        text-decoration: none;
        background-color: ${colorStack.lightBlue};
    }
    ${({ $isHidden }) => $isHidden && css`
        visibility: hidden;
    `}
`;

const Icon = styled(FontAwesomeIcon)`
    min-width: 32px;
`;

const ContextInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const ContextName = styled.div`
    white-space: nowrap;
    overflow: hidden;
    margin-left: .5rem;
    max-width: 15rem;
    text-overflow: ellipsis;
`;

const TooltipMenuWrapper = styled.div`
    margin: 0px;
    position: relative;
`;

interface IMenuItem extends ILevel1MenuItem {
    isHidden?: boolean;
    onClick?(orgUrlName: string);
}

export const MenuItem: FC<IMenuItem> = ({ imagePath, faIcon, svgIcon, name, link, orgUrlName, isHidden, fullMenu, onClick }) => {

    const onElementClick = useCallback(() => {
        onClick?.(orgUrlName);
    }, [orgUrlName, onClick]);

    const menuItemRef = useRef<HTMLDivElement>();
    const isLongText = useMemo(() => {
        return menuItemRef?.current?.scrollWidth > menuItemRef?.current?.offsetWidth;
    }, [menuItemRef?.current]);

    return (
        <Wrapper onClick={onElementClick} to={link || '#'} $isHidden={isHidden}>
            {svgIcon ? (
                <>{svgIcon}</>
            ) : faIcon ? (
                <Icon icon={faIcon} size="lg" />
            ) : (
                <WorkspaceLogo
                    picture={imagePath}
                    name={name}
                    id={name}
                    size={{ width: 32, height: 32 }}
                />
            )}
            <ContextInfoWrapper>
                {(fullMenu && name) && (
                    <TooltipMenuWrapper>
                        <ContextName ref={menuItemRef}>
                            {name}
                        </ContextName>
                    </TooltipMenuWrapper>
                )}
            </ContextInfoWrapper>
            {isLongText && <Tooltip>{name}</Tooltip>}
        </Wrapper>
    );
};
