import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { IProps, StepsPopup } from '../components/Common/StepsPopup/StepsPopup';
import { getUsefulLinks } from '../actions/adminActions';
import { UsefulLinksGrid } from '../components/Home/UsefulLinks/UsefulLinksGrid';
import { useOverlayContext } from '../components/NewDesignCommon/Overlay';
import { IUsefulLink } from '../entities/IAdmin';
import { Lcid } from '../entities/ILanguage';
import { history } from '../history';
import { isGiftContext, isInvitationContext, isQuotationContext } from './pathContext';

interface INameByLcid {
    [key: string]: {
        [key in Lcid]?: string;
    };
}

interface IHomeLinksState {
    links: IUsefulLink[];
    nameByLcid: INameByLcid;
    showAllLinks: boolean;
    page: number;
    count: number;
}

export const useUsefulLinksPopup = () => {
    const { showOverlay, hideOverlay } = useOverlayContext();

    return useCallback(() => {
        showOverlay<IProps>({
            component: StepsPopup,
            componentProps: {
                showPopup: true,
                title: (
                    <FormattedMessage
                        id="individualhome.usefulLinks"
                    />
                ),
                confirmDisabled: true,
                steps: [
                    {
                        content: <UsefulLinksGrid />
                    }
                ],
                finishHandler: hideOverlay
            }
        });
    }, []);
};

export const useUsefulLinksState = (): [IHomeLinksState, () => void] => {
    const dispatch = useDispatch();
    const [state, setState] = useState<IHomeLinksState>({
        links: [],
        nameByLcid: undefined,
        showAllLinks: false,
        page: 0,
        count: 0
    });

    const setNextPage = useCallback(() => {
        setState((currentState) => ({
            ...currentState,
            page: currentState.page + 1
        }));
    }, []);

    useEffect(() => {
        dispatch(getUsefulLinks(state.page, 10)).then((response) => {
            if ((response.Items || []).length > 0) {
                setState((currentState) => ({
                    ...currentState,
                    count: response.Count,
                    links: [...currentState.links, ...response.Items],
                    nameByLcid: {
                        ...(currentState.nameByLcid || {}),
                        ...response.Items.reduce(
                            (allLinks, link) => ({
                                ...allLinks,
                                [link.id]: {
                                    ...link.name.reduce(
                                        (names, name) => ({
                                            ...names,
                                            [name.lcid]: name.label
                                        }),
                                        {}
                                    )
                                }
                            }),
                            {}
                        )
                    }
                }));
            }
        });
    }, [state.page]);

    return [state, setNextPage];
};

export const useUsefulLinksRedirect = () =>
    useCallback((e: React.MouseEvent, path: string) => {
        if (
            isGiftContext(path) ||
            isInvitationContext(path) ||
            isQuotationContext(path)
        ) {
            e.preventDefault();
            history.push(path);
        }
    }, []);
