import { action } from '../tools/reduxTools';
import { IModuleRole, IAvailableRole, RoleType, IAssignedPrincipal, IAssignedRole, IGetInvitation } from '../entities/IPermissions';
import { IUserBrief } from '../entities/IGlobal';

export const getModuleRoles = () => action<Promise<IModuleRole[]>>(async (dispatch, getState, http) => {
    const response = await http.permissionsApiGet(`/Roles/module/all`);
    return response || [];
});

export const getAllRoles = (type: RoleType) => action<Promise<IAvailableRole[]>>((dispatch, getState, http) => {
    return http.permissionsApiGet(`/Roles/${type}/all`);
});

export const createUserWithoutInvitation = (email: string, firstName: string, lastName: string, roleId?: string, resourceChildId?: string, resourceType?: string) => action<Promise<IUserBrief>>((dispatch, getState, http) => {
    const { userLanguage } = getState().language;

    const body = {
        shadowProfile: {
            firstName,
            lastName,
            email
        },
        profilePrivileges: roleId ? {
            resourceType,
            resourceChildId,
            roleId
        // tslint:disable-next-line:no-null-keyword
        } : null
    };

    return http.profilesApiPost(`/users/accounts/create-shadow-profile?lcid=${userLanguage}`, body);
});

export const getResourcePrincipals = (resourceId: string, resourceType: RoleType) => action<Promise<IAssignedPrincipal>>((dispatch, getState, http) => {
    return http.permissionsApiGet(`/resources/${resourceId}/principals?resourceTypes=${resourceType}`);
});

export const changeAssignedRole = (resourceId: string, principalChildId: string, roleId: string, resourceType: RoleType, principalType: string) => action<Promise<IAssignedRole>>((dispatch, getState, http) => {
    return http.permissionsApiPut(`/resources/${resourceId}/assigned-roles`, {
        principalChildId,
        principalType,
        roleId,
        resourceType
    });
});

export const deleteAssignedRole = (resourceId: string, principalChildId: string, resourceType: RoleType, principalType?: string) => action<Promise<IAssignedRole>>((dispatch, getState, http) => {
    return http.permissionsApiDelete(`/resources/${resourceId}/assigned-roles`, {
        principalChildId,
        principalType,
        resourceType
    });
});

export const addAssignedRole = (resourceId: string, principalChildId: string, roleId: string, resourceType: RoleType, message?: string, principalType?: string) => action<Promise<void>>((dispatch, getState, http) => {
    return http.permissionsApiPost(`/resources/${resourceId}/assigned-roles`, {
        principalChildId,
        principalType,
        roleId,
        resourceType,
        message
    });
});

export const inviteUserToResource = (resourceId: string, roleId: string, receiverEmail: string, resourceType: RoleType, message?: string, receiverId?: string) => action<Promise<void>>((dispatch, getState, http) => {
    return http.permissionsApiPost(`/resources/${resourceId}/invitations`, {
        receiverId,
        receiverEmail,
        message,
        role: roleId,
        resourceType
    });
});

export const getSentInvitations = (resourceId: string) => action<Promise<IGetInvitation[]>>(async (dispatch, getState, http) => {
    return http.permissionsApiGet(`/resources/${resourceId}/invitations`);
});

export const cancelInvitation = (resourceId: string, invitationId: string) => action<Promise<void>>(async (dispatch, getState, http) => {
    await http.permissionsApiPost(`/resources/${resourceId}/invitations/${invitationId}/cancel`);
});

export const resendInvitation = (resourceId: string, invitationId: string) => action<Promise<void>>(async (dispatch, getState, http) => {
    await http.permissionsApiPost(`/resources/${resourceId}/invitations/${invitationId}/remind`);
});