export const isGiftContext = (path: string) => {
    return path?.indexOf('/create-gift') !== -1;
};

export const isInvitationContext = (path: string) => {
    return path?.indexOf('/create-invitation') !== -1;
};

export const isQuotationContext = (path: string) => {
    return path?.indexOf('/quotation') !== -1;
};

export const isCoiContext = (path: string) => {
    return path?.indexOf('/create-coi') !== -1;
};

export const isSponsoringContext = (path: string) => {
    return path?.indexOf('/create-sponsoring') !== -1;
};

export const isContractContext = (path: string) => {
    return path?.indexOf('/create-contract') !== -1;
};

export const isLegalSupportContext = (path: string) => {
    return path?.indexOf('/create-legal-support') !== -1;
};

export const isLobbyingtContext = (path: string) => {
    return path?.indexOf('/create-lobbying') !== -1;
};