
import { useEffect } from 'react';
import { createGlobalStyle, css, useTheme } from 'styled-components';
import reset from 'styled-reset';

import { colorStack } from './colors';
import { fontSize } from './fontSizes';
import { media } from './breakpoint';
import placeholder from './mixins/placeholder';
import { OldCss } from './old-css';
import { lcrButton } from './global/lcr-button';
import { lcrTag } from './global/lcr-tag';
import { FontFaces } from './fonts';
import { gridStyles } from './gridStyles';

export const LcrStyle = createGlobalStyle`
    ${reset}
    ${FontFaces}
    ${OldCss}
    ${lcrButton}
    ${lcrTag}
    ${gridStyles}
`;

const injectStylesheet = (stylesheetURL: string) => {
    const link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = stylesheetURL;
    document.head.appendChild(link);
};

// Loads theme fontface URL, avoid to use @import
// see https://github.com/styled-components/styled-components/issues/2254#issuecomment-1059612584
export const ThemeFontImport = () => {
    const theme = useTheme();
    useEffect(() => {
        if (theme.fontImport)
            injectStylesheet(theme.fontImport);
    }, [theme.fontImport]);

    // tslint:disable-next-line: no-null-keyword
    return null;
};

export const GlobalStyle = createGlobalStyle`
    html {
        font-size: ${fontSize[16]};
    }
    body {
        color: ${colorStack.darkBlue};
        font-family: 'Roboto', sans-serif;
        background: ${colorStack.bodyBg};
        font-size: ${fontSize[16]};
        ${({ theme }) => theme.iOS && css`
            -webkit-text-size-adjust: 100%
        `}
    }
    body .ms-Fabric {
        color: ${colorStack.darkBlue};
        font-size: ${fontSize[16]};
        -webkit-font-smoothing: auto;
        font-family: 'Roboto', sans-serif;
    }
    button {
        outline: 0;
    }

    * {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
    }

    input, textarea, select {
        ${placeholder({ 'color': `${colorStack.disabled} !important` })};
    }
    .ms-Dropdown-titleIsPlaceHolder span {
        color: ${colorStack.disabled} !important;
        font-size: ${fontSize[13]} !important;
    }

    h1{
        font-size: ${fontSize[49]};
        font-weight: 600;
        margin: 1rem 0;
        padding: 0;
        &.lcr-invite-title{
            margin: 0 0 15px;
            font-weight: 500;
            font-size: ${fontSize[25]};
        }
    }
    h2 {
        color: ${colorStack.darkBlue};
        font-weight: 600;
        font-size: ${fontSize[25]};
        margin: 0 0 25px 0;
        span{
            position: relative;
            &:after{
                position: absolute;
                bottom: -5px;
                left: 0;
                right: 0;
                content: '';
                border-bottom: 4px solid ${colorStack.lightOrange};
            }
        }
    }
    h3{
        margin: 0 0 10px 0;
        font-size: ${fontSize[20]};
        color: ${colorStack.darkBlue};
        font-weight: 500;
    }
    h4{
        margin:0 0 7px;
        font-size: ${fontSize[16]};
        color:${colorStack.darkBlue};
        font-weight:500;
        max-width: 100%;
    }
    h5{
        margin:0 0 7px;
        font-size: ${fontSize[13]};
        color:${colorStack.darkBlue};
        font-weight:normal;
    }
    h6{
        margin:0 0 5px;
        font-size: ${fontSize[13]};
        color:${colorStack.darkBlue};
        font-weight:normal;
    }
    a{
        text-decoration:none;
        color:${colorStack.darkBlue};
        outline: none;
        cursor:pointer;
        overflow-wrap: break-word;
        &:hover {
            color: ${colorStack.darkBlue};
        }
        &.blue-link {
            color: ${colorStack.middleBlue};
        }
        &.action-link {
            color: ${colorStack.lightBlue};
            &:hover {
                text-decoration: underline;
                color: ${colorStack.lightBlue};
            }

            &:visited {
                color: ${colorStack.lightBlue};
            }
        }
    }
    a:visited{
        text-decoration:none;
        color:${colorStack.darkBlue};
    }
    a:hover{
        text-decoration:underline;
        color:${colorStack.darkBlue};
    }
    strong, b {
        font-weight: 600;
    }
    em, i{
        font-style: italic;
    }
    .error-message {
        margin: 10px 0;
        font-weight: 700;
        color: ${colorStack.red};
        font-size: ${fontSize[13]};
    }
    .checkbox-fabric-ui {
        float: left;
    }

    .checkbox-fabric-ui-description {
        float: left;
        margin-left: 10px;
    }

    .checkbox-fabric-ui-description > a {
        text-decoration: underline;
    }

    .marginloading{
        margin-top: 10%;
    }
    .text-center{
        text-align: center;
    }
    p {
        margin: 0 auto;
        max-width: 100%;
        font-weight: 500;
        text-align: center;
        font-size: ${fontSize[13]};
        color: ${colorStack.darkBlue};
        &.lcr-entry-text{
            font-weight: 400;
            text-align: left;
        }
    }
    textarea{
        font-size: ${fontSize[13]};
        ${placeholder({ 'color': colorStack.darkBlue })};
    }
    label{
        color: ${colorStack.disabled};
        font-size: ${fontSize[13]};
        padding-bottom: 5px;
        font-weight: 400;
        display: block;
    }
    .lcr-quote{
        margin:20px 30px 0;
        color:${colorStack.disabled};
        text-align:center;
        font-size: ${fontSize[16]};
        font-style:italic;
    }

    /*TODO -> temporary solution*/
    .lcr-register-page-button-wrapper p{
        margin: 0 2rem 0 0;
    }
    button.ms-Checkbox .ms-Checkbox-label .ms-Checkbox-checkbox{
        background: #fff;
        border-color: #e8e8e8;
        border-radius: 3px;
    }
    button.ms-Checkbox .ms-Checkbox-label .ms-Checkbox-text{
        font-weight: 500;
    }
    button.ms-Checkbox{
        width: 100%;
        text-align: left;
        position: relative;
        &.is-checked{
            .ms-Checkbox-checkbox:after{
                content: '✔';
                position: absolute;
                top: 3px;
                left: 8px;
                font-size: ${fontSize[16]};
                color: ${colorStack.blue};
                opacity: 1;
                transform: scale(1);
            }
        }
        &.no-width{
            width: auto;
        }
        .ms-Checkbox-checkbox:after {
            transition: all .2s;
            opacity: 0;
            transform: scale(0);
        }
    }

    input[type="checkbox"]+label:before, input[type="checkbox"]+div:before {
        margin: 0;
    }

    .ms-Checkbox:hover > .ms-Checkbox-label > .ms-Checkbox-checkbox {
        background: transparent;
    }

    .ms-Checkbox-label > .ms-Checkbox-checkbox {
        height: 18px;
        border: 0;
        background: transparent;
    }

    .lcr-entry{
        &--write-article{
            text-align: center;
            a {
                margin: 20px auto 0 auto;
            }
        }
        &.lcr-comment {
            .lcr-entry-left{
                flex: 1;
                margin: 0 0 20px 0;
            }
        }
        &.lcr-post{
            &-small{
                .lcr-entry-bottom{
                    word-break: break-word;
                    &.no-padding{
                        padding: 15px 0;
                    }
                }
            }
            .lcr-entry-author{
                justify-content: space-between;
                .lcr-entry-authorimage{
                    margin: 0;

                }
            }
            .lcr-entry-top{
                .lcr-entry-hover{
                    padding: 16px 12px 7px 12px;
                    .lcr-entry-metas{
                        width: calc(100% - 68px);
                    }
                }
            }
            .lcr-entry-type{
                font-size: ${fontSize[13]};
            }
        }
        .lcr-entry-action{
            a{
                float: left;
            }
            .lcr-icon-goarrow{
                float:left;
                margin-top:3px;
                margin-right:10px;
                color:${colorStack.lightBlue};
            }
        }

        &.lcr-highlight .lcr-entry-title {
            span {
                display: inline-block;

                &:after {
                    bottom: -10px;
                }
            }
        }
        &.lcr-person{
            height: 100%;
            flex: 1 auto;
            .lcr-tags{
                flex: 1 auto;
            }
            .lcr-entry-title{
                word-wrap: break-word;
            }
        }
    }

    .lcr-rating {
        .lcr-rating-slider {
            @media (max-width: 1023px) {
                width: 100%;
                padding: 0 50px 20px;
                background: transparent;
            }

            @media (max-width: 650px) {
                padding: 0 0 20px;
            }
        }

        .lcr-rating-headers {
            @media (max-width: 1023px) {
                width: 100%;
                background: transparent;
            }
        }

        .lcr-rating-badge {
            @media (max-width: 1023px) {
                background: transparent;
            }
        }
    }
    .lcr-section{
        max-width: 100%;
    }
    .StripeElement {
        border: 1px solid ${colorStack.ligthGrey};
        padding: 0 10px;
        height: 33px;
        width: 100%;
        border-radius: 3px
    }
    .StripeElement:hover {
        border-color: ${colorStack.darkBlue};
        transition: border-color .3s
    }
    .lcr-fabric-dialog {
        .ms-Dialog-title {
            text-align: left;
        }

        &.lcr-upload-dialog.ms-Dialog-main {
            width: 100%;
            max-width: 600px;
        }

        .lcr-button {
            font-size: ${fontSize[13]};
        }
    }
    .ms-Layer--fixed .ms-Panel.lcr-drive-share-panel .ms-Overlay {
        background-color: rgba(255,255,255,.4);
    }
    body .ms-Panel.lcr-drive-share-panel .ms-Panel-content {
        padding: 0;
    }
    .lcr-rich-tooltip {
        background: ${colorStack.ligthGrey};
        position: initial;

        ${media.desktop`
            position: absolute;
        `}
    }
    body .ms-Panel .ms-Panel-header .ms-Panel-headerText {
        font-size: ${fontSize[16]};
    }
    body .ms-Panel .ms-Panel-content {
        height: 100%;
    }
    input[type=date]:read-only, input[type=datetime]:read-only, input[type=datetime-local]:read-only, input[type=email]:read-only, input[type=month]:read-only, input[type=number]:read-only, input[type=password]:read-only, input[type=range]:read-only, input[type=search]:read-only, input[type=tel]:read-only, input[type=text]:read-only, input[type=time]:read-only, input[type=url]:read-only, input[type=week]:read-only, textarea:read-only, input[type=date][readonly], input[type=datetime][readonly], input[type=datetime-local][readonly], input[type=email][readonly], input[type=month][readonly], input[type=number][readonly], input[type=password][readonly], input[type=range][readonly], input[type=search][readonly], input[type=tel][readonly], input[type=text][readonly], input[type=time][readonly], input[type=url][readonly], input[type=week][readonly], textarea[readonly], input[type=date]:disabled, input[type=datetime]:disabled, input[type=datetime-local]:disabled, input[type=email]:disabled, input[type=month]:disabled, input[type=number]:disabled, input[type=password]:disabled, input[type=range]:disabled, input[type=search]:disabled, input[type=tel]:disabled, input[type=text]:disabled, input[type=time]:disabled, input[type=url]:disabled, input[type=week]:disabled, textarea:disabled {
        color: ${colorStack.black};
    }

    .ms-Slider-container .ms-Slider-line .ms-Slider-active {
        width: 0;
    }

    .slick-slider {
        position: relative;

        display: block;
        box-sizing: border-box;

        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
    }

    .slick-list {
        position: relative;
        display: block;
        overflow: hidden;
        margin: 0;
        padding: 0;
    }

    .slick-list:focus {
        outline: none;
    }

    .slick-list.dragging {
        cursor: pointer;
        cursor: hand;
    }

    .slick-slider .slick-track,
    .slick-slider .slick-list {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    .slick-track {
        position: relative;
        top: 0;
        left: 0;
        display: block;
    }

    .slick-track:before,
    .slick-track:after {
        display: table;
        content: '';
    }

    .slick-track:after {
        clear: both;
    }

    .slick-loading .slick-track {
        visibility: hidden;
    }

    .slick-slide {
        display: none;
        float: left;
        height: 100%;
        min-height: 1px;
    }

    [dir="rtl"] .slick-slide {
        float: right;
    }

    .slick-slide img {
        display: block;
    }

    .slick-slide.slick-loading img {
        display: none;
    }

    .slick-slide.dragging img {
        pointer-events: none;
    }

    .slick-initialized .slick-slide {
        display: block;
    }

    .slick-loading .slick-slide {
        visibility: hidden;
    }

    .slick-vertical .slick-slide {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }

    .slick-arrow.slick-hidden {
        display: none;
    }

    .ms-Persona.invite-email-persona {
        .ms-Image {
            border-radius: 0;
        }
    }

    .big-fisrt-letter::first-letter {
        text-transform: uppercase;
    }

`;
