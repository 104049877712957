import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string-for-all';

import { getEnvironmentUrl } from '../actions/contextActions';

const RedirectTeams: FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const { email, tabType } = queryString.parse(location.search);

        email
            ? dispatch(getEnvironmentUrl(email as string)).then((response) => {
                const url = window.location.origin !== response.applicationConfig.redirectURI && tabType === 'static'
                    ? response.applicationConfig.redirectURI
                    : `${response.applicationConfig.redirectURI}/teams/config`;
                window.location.replace(url);
            })
            : window.location.replace('https://www.business.legalcluster.com/');

    }, []);

    return <></>;
};

export default RedirectTeams;
