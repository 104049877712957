import React, { FC, useMemo } from 'react';
import { Form, useFormikContext } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../../styleHelpers/colors';
import { fontSize } from '../../../../styleHelpers/fontSizes';
import { media } from '../../../../styleHelpers/breakpoint';
import { ClusterPrivacy } from '../../../../entities/IClusters';
import { NewClusterFormValues } from '../../../../tools/clusterTools';
import { TextComponent } from '../../../Common/Inputs/TextComponent';
import { Privacy } from '../../../Common/Privacy/Privacy';

const Label = styled.div`
    font-size: ${fontSize[13]};
    color: ${colorStack.label};
    margin-top: 18px;
    margin-bottom: 18px;
`;

const FormField = styled.div<{ half?: boolean }>`
    width: 100%;
    padding-bottom: 18px;

    ${(props) => props.half && css`
        ${media.tabletSm`
            width: 50%;
        `}
    `}
`;

export interface ICreatePOAClusterStep {
    isDisabled: boolean;
    parentErrors?: { [key in keyof NewClusterFormValues]?: string };
    onChange: () => void;
}

export const CreatePOAClusterStep: FC<ICreatePOAClusterStep> = ({ isDisabled, parentErrors, onChange }) => {
    const { errors, values, handleBlur, handleChange } = useFormikContext<NewClusterFormValues>();
    const intl = useIntl();
    const privacyTranslations = useMemo(() => ({
        [ClusterPrivacy.Private]: intl.formatMessage({ id: 'poa.wizard.step1.status.private' }),
        [ClusterPrivacy.Public]: intl.formatMessage({ id: 'poa.wizard.step1.status.public' }),
        [ClusterPrivacy.Secret]: intl.formatMessage({ id: 'poa.wizard.step1.status.secret' })
    }), []);
    const privacyOptions = useMemo(() =>
        Object.keys(ClusterPrivacy).map((key) => ({ key, text: privacyTranslations[key] }))
    , [privacyTranslations]);

    const mixedErrors = { ...errors, ...parentErrors };

    return (
        <Form onChange={onChange}>
            <FormField>
                <Label>
                    <FormattedMessage id="poa.wizard.step1.label.title" />
                    <sup> *</sup>
                </Label>
                <TextComponent
                    name="name"
                    value={values.name}
                    error={!!mixedErrors.name}
                    errorMsg={mixedErrors.name || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isDisabled}
                />
            </FormField>
            <FormField>
                <Privacy field="privacy" />
            </FormField>
        </Form>
    );
};
