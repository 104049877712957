export const CLEAR_FILE_REPOSITORY_STATE = 'CLEAR_FILE_REPOSITORY_STATE';
export const SELECT_FILE_REPOSITORY_ACCOUNT = 'SELECT_FILE_REPOSITORY_ACCOUNT';
export const LOAD_FILE_REPOSITORY_ACCOUNTS = 'LOAD_FILE_REPOSITORY_ACCOUNTS';
export const SET_INITIAL_FILE_REPOSITORY_ACCOUNTS = 'SET_INITIAL_FILE_REPOSITORY_ACCOUNTS';
export const LOAD_FILE_REPOSITORY_DIRECTORY_REQUESTED = 'LOAD_FILE_REPOSITORY_DIRECTORY_REQUESTED';
export const LOAD_FILE_REPOSITORY_DIRECTORY_COMPLETED = 'LOAD_FILE_REPOSITORY_DIRECTORY_COMPLETED';
export const FILE_REPOSITORY_SHOW_UPLOAD_DIALOG = 'FILE_REPOSITORY_SHOW_UPLOAD_DIALOG';
export const FILE_REPOSITORY_HIDE_UPLOAD_DIALOG = 'FILE_REPOSITORY_HIDE_UPLOAD_DIALOG';
export const FILE_REPOSITORY_UPLOAD_START = 'FILE_REPOSITORY_UPLOAD_START';
export const FILE_REPOSITORY_UPLOAD_PROGRESS = 'FILE_REPOSITORY_UPLOAD_PROGRESS';
export const FILE_REPOSITORY_UPLOAD_ERROR = 'FILE_REPOSITORY_UPLOAD_ERROR';
export const FILE_REPOSITORY_UPLOAD_COMPLETED = 'FILE_REPOSITORY_UPLOAD_COMPLETED';
export const FILE_REPOSITORY_INITIAL_FAILED = 'FILE_REPOSITORY_INITIAL_FAILED';
export const LOAD_LEGALDRIVE_ISCONFIGURED_COMPLETE = 'LOAD_LEGALDRIVE_ISCONFIGURED_COMPLETE';
export const LOAD_LEGALDRIVE_ROOT_REQUESTED = "LOAD_LEGALDRIVE_ROOT_REQUESTED";
export const LOAD_LEGALDRIVE_ROOT_COMPLETED = "LOAD_LEGALDRIVE_ROOT_COMPLETED";