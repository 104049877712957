import React, { SFC } from 'react';
import styled from 'styled-components';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import Highlight from 'react-highlighter';
import { useDispatch } from 'react-redux';
import { rgba } from 'polished';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { formatBytes } from '../../../tools/files';
import { IAttachment } from '../../../entities/Messaging/IMessaging';
import { downloadAttachment, downloadMessagingFileAsBlob } from '../../../actions/messagingActions';
import { isMimeImage, isMimePdf } from '../../..//tools/generalTools';
import { ImageBox } from './ImageBox';

interface WprapperProps {
    user: 'current' | 'other';
}

const Wrapper = styled.div`
    background: ${(props: WprapperProps) => props.user === 'current' ? colorStack.white : rgba(colorStack.darkBlue, .2)};
    padding: 1rem;
    margin: 10px;
    border-radius: 4px;
    > div {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .attach-ico {
       width: 33px;
       margin: 10px;
       border-radius: 4px;
    }
    ${(props: WprapperProps) => props.user === 'current' ? (
        `.attach-name {
            font-size: ${fontSize[13]};
            color: ${colorStack.black};
            word-break: break-all;
        }
        .attach-size {
            font-size: ${fontSize[10]};
            color: ${colorStack.disabled};
        }`
    ) : (
            `.attach-name {
            font-size: ${fontSize[13]};
            color: ${colorStack.white};
            word-break: break-all;
        }
        .attach-size {
            font-size: ${fontSize[10]};
            color: ${colorStack.white};
        }`
        )}
`;

interface IAttachmentBoxProps {
    user: 'current' | 'other';
    attach: IAttachment;
    search: string;
    setPdfDocument(document: string | ArrayBuffer, pdfName: string, documentId: string);
    setImageDocument(imageName: string, downloadImageUri: string);
}

type DownloadAttachment = ReturnType<typeof downloadAttachment>;
type DownloadFileAsBlob = ReturnType<typeof downloadMessagingFileAsBlob>;

export const AttachmentBox: SFC<IAttachmentBoxProps> = (props) => {
    const dispatch = useDispatch();

    const fileHandler = () => {
        if (!isMimePdf(props.attach.fileType) && !isMimeImage(props.attach.fileType)) {
            dispatch<DownloadAttachment>(downloadAttachment(props.attach.uri, props.attach.name));
        }
        if (isMimeImage(props.attach.fileType)) {
            props.setImageDocument(props.attach.name, props.attach.uri);
        } else if (isMimePdf(props.attach.fileType)) {
            dispatch<DownloadFileAsBlob>(downloadMessagingFileAsBlob(props.attach.uri, false)).then((blob: Blob) => {
                let reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    const base64data = reader.result;
                    props.setPdfDocument(base64data, props.attach.name, props.attach.uri);
                };
            });
        }
    };
    return (
        <Wrapper user={props.user}>
            {isMimeImage(props.attach.fileType) &&
                <ImageBox file={props.attach} fileHandler={fileHandler} />
            }
            {!isMimeImage(props.attach.fileType) &&

                <div onClick={fileHandler}>
                    {(props.user === 'current') ? (
                        <img src="/assets/images/icons/messaging/file.png" className="attach-ico" />
                    ) : (
                        <img src="/assets/images/icons/messaging/file-white.png" className="attach-ico" />
                    )}
                    {(props.attach.size && props.attach.size > 0) ? (
                        <span className="meta">
                            <span className="attach-name">
                                <Highlight search={props.search}>{props.attach.name}</Highlight>
                            </span>
                            <span className="attach-size">Size: {formatBytes(props.attach.size)}</span>
                        </span>
                    ) : (
                        <Spinner
                            size={SpinnerSize.xSmall}
                            label="Uploading attachment..."
                            labelPosition="right"
                            className="attach-spinner"
                        />
                    )}
                </div>
            }
        </Wrapper>
    );
};
