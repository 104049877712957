import * as actionTypes from '../actions/actionTypes/sogeTypes';
import { Lcid } from '../entities/ILanguage';
import { ISGStylesRoot, ISGBookStyles } from '../entities/LegalDoc/ISogeStyles';
import { ITableOfContentsData, IReferentialState, ITableOfContentsVersion, INormsConfiguration } from '../entities/ISoge';
import { VersionStatus, ISogeVersionDetails } from '../entities/LegalDoc/INormativeDocumentationVersion';
import { getStyleKey } from '../tools/legalDocTools/sogeTools';
import { removeDuplicatesByKey } from '../tools/arrayTools';

export interface ISogeReducer {
    documentationLcid: Lcid;
    documentationId: string;
    normativeDocumentationName: string;
    allVersions: ITableOfContentsVersion[];
    languageData: {
        [versionId: string]: {
            [key in Lcid]: {
                uniqueVersionId: string;
                status: VersionStatus;
            }
        };
    };
    sgTableOfContentsContainer: {
        [uniqueVersionId: string]: {
            tableOfContent: ITableOfContentsData;
        };
    };
    sgStylesContainer: {
        [uniqueVersionId: string]: {
            styles?: {
                [styleId: string]: ISGBookStyles;
            };
            relatedBookIdToStyle?: {
                [id: string]: string;
            };
            relatedCommonLevelVersionIdToStyle?: {
                [commonLevelOrVersionId: string]: string;
            };
            stylesList?: ISGStylesRoot[];
        };
    };
    stylesLoaded: boolean;
    stylesContainerLoaded: boolean;
    stylesVersionId: string;
    currentUniqueVersionId: string;
    currentEditVersionId: string;
    currentEditVersion?: ISogeVersionDetails;
    newDataLoading: boolean;
    normReferentials: IReferentialState;
    currentRealVersionId: string;
    normsConfiguration: INormsConfiguration;
    normsConfigurationRequested?: boolean;
}

const defaultState = (): ISogeReducer => ({
    documentationLcid: undefined,
    documentationId: undefined,
    normativeDocumentationName: undefined,
    sgStylesContainer: undefined,
    stylesLoaded: false,
    stylesContainerLoaded: false,
    stylesVersionId: undefined,
    currentEditVersionId: undefined,
    currentUniqueVersionId: undefined,
    languageData: undefined,
    newDataLoading: false,
    sgTableOfContentsContainer: undefined,
    normReferentials: undefined,
    currentRealVersionId: undefined,
    allVersions: undefined,
    normsConfiguration: undefined
});

export default (state = defaultState(), action): ISogeReducer => {
    switch (action.type) {
        case actionTypes.SOGE_SET_DOC_LANG: {
            return {
                ...state,
                documentationLcid: action.lcid
            };
        }

        case actionTypes.SOGE_SET_NORMATIVE_DOC_ID: {
            return {
                ...state,
                documentationId: action.documentationId,
                normativeDocumentationName: action.normativeDocumentationName
            };
        }

        case actionTypes.SOGE_SET_STYLES: {
            const styles = action.styles as ISGStylesRoot[];

            return {
                ...state,
                sgStylesContainer: {
                    ...state.sgStylesContainer,
                    [action.stylesVersionId]: {
                        styles: styles.reduce((rootStyles, styleRootObject) => ({
                            ...rootStyles,
                            [styleRootObject.id]: styleRootObject.styles.reduce((stylesElem, style) => ({
                                byId: {
                                    ...stylesElem.byId,
                                    [style.id]: getStyleKey(style.id, style.name)
                                },
                                byName: {
                                    ...stylesElem.byName,
                                    [style.name]: getStyleKey(style.id, style.name)
                                },
                                allStyles: {
                                    ...stylesElem.allStyles,
                                    [getStyleKey(style.id, style.name)]: style
                                }
                            }), {
                                byId: {},
                                byName: {},
                                allStyles: {}
                            })
                        }), {}),
                        relatedBookIdToStyle: styles.reduce((relatedBookIdToStyle, styleRootObject) => ({
                            ...relatedBookIdToStyle,
                            ...styleRootObject.relatedObjects.reduce((acc, relatedObject) => ({
                                ...acc,
                                [relatedObject.id]: styleRootObject.id
                            }), {})
                        }), {}),
                        relatedCommonLevelVersionIdToStyle: styles.reduce((relatedBookIdToStyle, styleRootObject) => ({
                            ...relatedBookIdToStyle,
                            ...styleRootObject.relatedObjects.reduce((acc, relatedObject) => ({
                                ...acc,
                                [relatedObject.commonLevelOrVersionId]: styleRootObject.id
                            }), {})
                        }), {}),
                        stylesList: action.styles
                    }
                },
                stylesLoaded: true,
                newDataLoading: false,
                stylesVersionId: action.stylesVersionId
            };
        }

        case actionTypes.SOGE_GET_VERSION_DETAILS: {
            return {
                ...state,
                currentEditVersionId: action.versionId,
                currentEditVersion: action.versionDetails
            };
        }

        case actionTypes.SOGE_SET_NORMATIVE_DOC_NAME: {
            return {
                ...state,
                normativeDocumentationName: action.name
            };
        }

        case actionTypes.SOGE_SET_STYLES_CONTAINER: {
            const newUniqueIds = action?.versionIds.filter(version => !Object.keys(state.sgStylesContainer || {}).includes(version.uniqueVersionId));
            return {
                ...state,
                languageData: {
                    ...state.languageData,
                    ...action?.versionIds.reduce((languageVersions, version) => ({
                        ...languageVersions,
                        [version.versionId]: {
                            ...state.languageData?.[version.versionId],
                            [action.lcid]: {
                                uniqueVersionId: version.uniqueVersionId,
                                status: version.status
                            }
                        }
                    }), {})
                },
                sgStylesContainer: {
                    ...state.sgStylesContainer,
                    ...newUniqueIds.reduce((uniqueVersionIds, id) => ({
                        ...uniqueVersionIds,
                        [id]: undefined
                    }), {})
                }
            };
        }

        case actionTypes.SOGE_SET_CURRENT_UNIQUE_VERSION_ID: {
            return {
                ...state,
                currentUniqueVersionId: action.currentUniqueVersionId
            };
        }

        case actionTypes.SOGE_SET_TABLE_OF_CONTENTS: {
            return {
                ...state,
                sgTableOfContentsContainer: {
                    ...state.sgTableOfContentsContainer,
                    [action.currentUniqueVersionId]: {
                        tableOfContent: action.tableOfContent
                    }
                },
                newDataLoading: false,
                currentUniqueVersionId: action.currentUniqueVersionId
            };
        }

        case actionTypes.SOGE_SET_LOADING_NEW_DATA: {
            return {
                ...state,
                newDataLoading: true
            };
        }

        case actionTypes.SOGE_SET_REFERENTIALS: {
            return {
                ...state,
                normReferentials: action.normReferentials
            };
        }

        case actionTypes.SET_CURRENT_REAL_VERSION_ID: {
            return {
                ...state,
                currentRealVersionId: action.currentRealVersionId
            };
        }

        case actionTypes.SOGE_SET_ALL_VERSIONS: {
            const mergedVersions: ITableOfContentsVersion[] = [...(state.allVersions || []), ...(action.allVersions || [])];
            return {
                ...state,
                allVersions: removeDuplicatesByKey(mergedVersions, version => version.id)
            };
        }

        case actionTypes.GET_NORM_CONFIG: {
            return {
                ...state,
                normsConfiguration: action.normsConfiguration
            };
        }

        default: {
            return state;
        }
    }
};
