import { IClusterSingleMessage, ITeam } from './IClusters';
import { Lcid } from './ILanguage';
import { IModuleMember } from './IPermissions';

export enum OrganizationRole {
    Owner = 10,
    Member = 30,
    Manager = 20,
    OutsideCollaborator = 40
}

export enum ChannelsRole {
    Owner = 10,
    Member = 20,
    Editor = 30
}

export enum OrganizationRoles {
    Owner = 'Owner',
    Member = 'Member',
    Guest = 'Guest'
}

export enum ExpandedOrganizationRoles {
    Owner = 'Organizations.Owner',
    Member = 'Organizations.Member',
    Guest = 'Organizations.OutsideCollaborator'
}

export enum PlatformRole {
    Owner = 'Platform.Owner',
    Member = 'Platform.Member',
    Guest = 'Platform.Guest'
}

export interface IOrganizationTag {
    status: TagStatus;
    name: string;
}

export enum TagStatus {
    Disabled = 0,
    Active = 1
}

export interface IOrganizationMember {
    firstName: string;
    lastName: string;
    childName?: string;
    childId?: string;
    id: string;
    picture: string;
    position: string;
    role: OrganizationRole;
    joiningDate: Date;
    jobTitle: string;
    department: {
        name: string;
        id: string;
    };
    cabinet: string;
    emailContact?: string;
    principalType?: string;
    principalChildId?: string;
}

export interface IOrganizationOwner extends IOrganizationMember {
    joiningDate: Date;
}

export const rolesPlural = {
    10: 'Owner',
    30: 'Member',
    20: 'Manager',
    40: 'OutsideCollaborator'
};

export interface IOrganization {
    name: string;
    description: string;
    isLawFirm: boolean;
    logo: string;
    businessSectors: {
        name: string;
        id: string;
    }[]
    company: {
        websiteUrl: string;
        name: string;
        picture: string;
        id: string;
    }
    urlName: string;
    tenantId?: string;
}

export interface IOrganizationMembers {
    items: IOrganizationMember[];
    size: number;
    page: number;
    count: number;
}

export interface IOrganizationAccount {
    id: string;
    name: string;
}

export interface IOrganizationLink {
    id: string;
    name: string;
    url: string;
}

export interface IChannelActivity {
    beforeLastReply: IClusterSingleMessage;
    lastReply: IClusterSingleMessage;
    repliesCount: number;
    threadMessage: IClusterSingleMessage;
}

export interface ILatestActivitiy {
    mostRecentActivityDate: string | Date;
    team: ITeam;
    notReadTeamActivities: {
        mostRecentActivityDate: string | Date;
        teamChannel: {
            dataType: string;
            id: string;
            isDefault: boolean;
            resourceId: string;
            teamId: string;
            title: string;
            version: string;
            membersSettings: {
                isPrivate: boolean;
                members: IOrganizationMember[];
            };
        };
        notReadChannelActivities: IChannelActivity[];
    }[];
}

export interface IOrgModuleVisibility {
    name: string;
    additionalInformation: Object;
    visibility: boolean;
    organizationId: string;
    organizationName: string;
    id: string;
}

export enum OrganizationModule {
    Clusters = 'Clusters',
    RFP = 'RFP',
    Documentation = 'Documentation',
    Panels = 'Panels'
}

export interface IOrganizationModule {
    name: OrganizationModule;
    additionalInformation: object;
    visibility: boolean;
    organizationId: string;
    organizationName: string;
    moduleMembers: IModuleMember[];
    id: string;
}

export enum OrganizationOptionKey {
    Home = 0,
    Filters = 1
}

export interface IOrganizationOption {
    key: OrganizationOptionKey;
    value: boolean;
    organizationId: string;
}

export interface IDataGridSettings {
    columnsSettings: {
        width: number;
        field: string;
        headerText: string;
        isVisible: boolean;
    }[];
}

export interface IOrganizationType {
    name: string;
    id: string;
    key?: TypeOfOrganization;
}

export interface ICurrenciesType {
    name: string;
    id: number;
    key?: number;
}

export enum TypeOfOrganization {
    General = 'GENERAL',
    Corporate = 'CORPORATE',
    Gift = 'GIFT',
    Quotation = 'QUOTATION',
    Panels = 'PANELS',
    Contract = 'CONTRACT',
    POA = 'POA',
    COI = 'COI',
    Sponsoring = 'SPONSORING',
    Norms = 'NORMS',
    Lobbying = 'LOBBYING',
    Invitation = 'INVITATION',
    LegalSupport = 'LEGALSUPPORT',
    AssetsManagment = 'ASSETSMANAGEMENT',
    NormsOrganization = 'Norms',
    Landlord = 'LANDLORD'
}

export enum TypeOfCampaign {
    Declaration = 'declaration',
    Communication = 'communication'
}

export interface ICallToAtion {
    icon: string;
    isDisplayable: boolean;
    label: {
        label: string;
        lcid: number;
    }[];
    order: number;
    id: string;
    redirectionSuffix: string;
    requiredPermission: string;
}