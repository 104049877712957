import React, { useCallback, FC, ReactNode, useMemo, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { faTrash, faArrowUpLong } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';

import { IClusterSingleDynamicStake, IClusterDynamicStakeListItem, IClusterStakeProperty, PropertieKey } from '../../../entities/IClusterStake';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSize, fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { media } from '../../../styleHelpers/breakpoint';
import { Button } from '../Buttons/Button';
import { StakeCollection, ItemNameById } from '../../Organization/ClustersV2/ClusterOverview/StakeContainers/StakeCollection';
import { StakeCoupleCollection, IStakeError } from '../../Organization/ClustersV2/ClusterOverview/StakeContainers/StakeCoupleCollection';
import { NewDesignStakeLayout } from '../../Organization/ClustersV2/ClusterOverview/StakeContainers/NewDesignStakeLayout';
import { PartyContainer } from '../../Organization/ClustersV2/ClusterOverview/StakeContainers/PartyContainer';
import { isStanderdView, isPartyView, prepareProperties } from '../../../tools/stakes';
import { useNewDesignContext } from '../../NewDesignCommon/NewDesignSwitch/NewDesignSwitch';
import { newDesignThemeSwitch } from '../../NewDesignCommon/NewDesignSwitch/newDesignTheme';

const Wrapper = styled.div<{ presentationSection?: boolean; }>`
    ${props => !props.presentationSection && css`
        border: 1px solid ${colorStack.ligthGrey};
        border-radius: 4px;
        margin: 0 0 1rem 0;
        padding: 1rem;
        background: ${colorStack.white};
    `}
    ${(props) => newDesignThemeSwitch(props, () =>
    css`
            border: none;
            padding: 0;
            margin: 0;
            background: none;
        `
)}
`;

const Separator = styled.div`
    height: 1px;
    margin: 10px -${fontSize[16]};
    background-color: ${colorStack.ligthGrey};
`;

const StakeRowWrapper = styled.div<{ partiesSection?: boolean }>`
    display: flex;
    align-items: flex-start;
    margin: 0 0 .5rem 0;
    flex-direction: column;
    align-items: center;
    ${media.desktop`
        flex-direction: row;
    `};
`;

const Title = styled.div`
    ${fontSizeAndHeight[16]};
    color: ${colorStack.darkBlue};
    font-weight: 500;
    font-style: italic;
`;

const Top = styled.div`
    display: flex;
    margin: 0 0 1rem 0;
    justify-content: space-between;
`;

const ButtonWrapper = styled.div`
    display: flex;
    button {
        margin: 0 0 0 .5rem;
    }
`;

const BottomSectionWrapper = styled.div`
    padding-top: .8rem;
    margin-top: .8rem;
    border-top: 1px solid ${colorStack.ligthGrey};
`;

const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const ReorderButton = styled.div`
    margin: 0 10px 0 0;
    cursor: pointer;
`;

interface IStakesWrapperProps {
    stake: IClusterSingleDynamicStake;
    singleStakes?: IClusterDynamicStakeListItem;
    pleMainObj?: any;
    editMode?: boolean;
    editField?: string;
    singleIndex?: number;
    errors?: IStakeError;
    isMultistake?: boolean;
    context?: 'PLE' | 'Cluster',
    isPresentationSection?: boolean;
    bottomSection?: ReactNode;
    currentClusterId?: string;
    organizationId?: string;
}

export const StakesWrapper: FC<IStakesWrapperProps> = props => {
    const formikContext = useFormikContext<any>();
    const newDesign = useNewDesignContext();
    const removeSingleStake = useCallback(() => {
        if (props.context === 'Cluster') {
            formikContext.setFieldValue(props.editField, props.singleStakes?.clusterStakes.filter((stake, index) => index !== props.singleIndex));
        }
        if (props.context === 'PLE') {
            formikContext.setFieldValue(props.editField, props.pleMainObj.filter((stake, index) => index !== props.singleIndex));
        }
    }, [props.singleStakes?.clusterStakes, props.editField, props.singleIndex]);

    const setEditingData = useCallback((propertie: IClusterStakeProperty | [IClusterStakeProperty, IClusterStakeProperty]) => {
        if (Array.isArray(propertie)) {
            const [parent, child] = propertie;
            formikContext.setFieldValue(`${props.editField}[${props.singleIndex}]`, {
                ...props.stake,
                properties: props.stake.properties.map(elem => {
                    if (elem.id !== parent.id && elem.id !== child.id) {
                        return elem;
                    }

                    return elem.id === parent.id ? parent : child;
                })
            });
            return;
        }
        if (props.context === 'Cluster' && propertie.key !== PropertieKey.PLECLE) {
            formikContext.setFieldValue(`${props.editField}[${props.singleIndex}]`, {
                ...props.stake,
                properties: props.stake.properties.map(elem => elem.id === propertie.id ? propertie : elem)
            });
        }
        if (props.context === 'PLE' || propertie.key === PropertieKey.PLECLE) {
            formikContext.setFieldValue(propertie.id, propertie.value);
        }
    }, [props.stake, props.editField, props.singleIndex, props.context, formikContext]);

    const [coupleProperties, singleProperties] = useMemo(() => prepareProperties(props?.stake?.properties), [props?.stake?.properties]);

    const reorderHandler = useCallback(() => {
        const reorderArray = props.singleStakes.clusterStakes;
        reorderArray.splice(props.singleIndex - 1, 0, reorderArray.splice(props.singleIndex, 1)[0]);
        formikContext.setFieldValue(props.editField, reorderArray);
    }, [props.editField, props.singleStakes, props.singleIndex]);

    return (
        <>
            {(isStanderdView(props.stake.type) || props.editMode) &&
                <Wrapper presentationSection={props.isPresentationSection}>
                    {props.stake?.id && !newDesign.active && <a id={props.stake.id} />}
                    {!newDesign.active &&
                        <Top>
                            {props.stake.name && (
                                <Title>{props.stake.name}</Title>
                            )}
                            {props.editMode &&
                                <ButtonWrapper>
                                    {props.isMultistake &&
                                        <ButtonsWrapper>
                                            {props.singleIndex > 0 &&
                                                <ReorderButton onClick={reorderHandler}>
                                                    <FontAwesomeIcon icon={faArrowUpLong} />
                                                </ReorderButton>
                                            }
                                            <Button onClick={removeSingleStake} ico={<FontAwesomeIcon icon={faTrash} />} bgColor="transparent" fontColor={colorStack.red}><FormattedMessage id="global.delete" /></Button>
                                        </ButtonsWrapper>
                                    }
                                </ButtonWrapper>
                            }
                        </Top>
                    }
                    {coupleProperties.map(couple =>
                        <StakeCoupleCollection
                            couple={couple}
                            editStake={props.editMode}
                            setEditingData={setEditingData}
                            errors={props.errors}
                            key={couple?.[0]?.id}
                        />
                    )}
                    {coupleProperties.length > 0 && singleProperties.length > 0 && (
                        <Separator />
                    )}
                    <NewDesignStakeLayout stakeKey={props?.stake?.key} withPadding={!props?.isMultistake}>
                        {singleProperties.map(propertie =>
                            <Fragment key={propertie.id}>
                                {propertie.id !== ItemNameById.Name &&
                                    <StakeRowWrapper key={propertie.id}>
                                        <StakeCollection
                                            base={!!propertie?.value?.baseNumber}
                                            errors={props.errors}
                                            stakeDefinitionId={props.stake.stakeDefinitionId}
                                            propertie={propertie}
                                            setEditingData={setEditingData}
                                            editStake={props.editMode}
                                            context={props.context}
                                            stakeListName={props?.singleStakes?.stakeListName}
                                            currentClusterId={props?.currentClusterId}
                                            organizationId={props?.organizationId}
                                        />
                                    </StakeRowWrapper>
                                }
                            </Fragment>
                        )}
                    </NewDesignStakeLayout>
                    {props.bottomSection && (
                        <BottomSectionWrapper>
                            {props.bottomSection}
                        </BottomSectionWrapper>
                    )}
                </Wrapper>
            }
            {(isPartyView(props.stake.type) && !props.editMode) &&
                <PartyContainer
                    stake={props.stake}
                />
            }
        </>
    );
};