import React, { FC } from 'react';
import moment from 'moment';
import { IntlShape } from 'react-intl';

interface IDateStandardFormatProps {
    date?: string | Date | number;
    showTime?: boolean;
    showNumeric?: boolean;
    className?: string;
    nextMonth?: boolean;
    nextYear?: boolean;
    context?: IntlShape;
    withWhitespace?: boolean;
}

const getNextMonth = (currentDate: Date) => new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
const getNextYear = (currentDate: Date) => new Date(currentDate.setFullYear(currentDate.getFullYear() + 1));

export const dateStandardFormatString = (options: IDateStandardFormatProps): string => {
    if (!options.context) {
        return;
    }
    const currentDate = new Date();
    let parsedDate = options.date || new Date();

    if (options.nextMonth) {
        parsedDate = getNextYear(currentDate);
    }
    if (options.nextYear) {
        parsedDate = getNextMonth(currentDate);
    }

    return moment.utc(parsedDate).local().format('DD MMM YYYY');
};

export const DateStandardFormat: FC<IDateStandardFormatProps> = ({ date = new Date(), showTime, className = '', showNumeric, children, nextMonth, nextYear, withWhitespace }) => {
    const currentDate = new Date();
    let parsedDate = date;

    if (nextMonth) {
        parsedDate = getNextYear(currentDate);
    }
    if (nextYear) {
        parsedDate = getNextMonth(currentDate);
    }

    let pattern = showTime ? 'DD MMM YYYY HH:mm:ss' : 'DD MMM YYYY';

    return (
        <>
            {moment.utc(parsedDate).local().format(pattern)}
        </>
    );
};
