import { IntlShape } from 'react-intl';

import { ClusterNature, ClusterPrivacy, ClusterVisibility } from '../entities/IClusters';
import { CellHeight } from './dataGridTools';

export const clusterNatureTranslation = (nature: ClusterNature, intl: IntlShape) => {
    const clusterNature = {
        [ClusterNature.Project]: intl.formatMessage({ id: 'cluster.nature.project' }),
        [ClusterNature.Dispute]: intl.formatMessage({ id: 'cluster.nature.dispute' }),
        [ClusterNature.Contract]: intl.formatMessage({ id: 'cluster.nature.contract' }),
        [ClusterNature.Support]: intl.formatMessage({ id: 'cluster.nature.support' }),
        [ClusterNature.Other]: intl.formatMessage({ id: 'cluster.nature.other' }),
        [ClusterNature.SendingGift]: intl.formatMessage({ id: 'cluster.nature.sendingGift' }),
        [ClusterNature.ReceivingGift]: intl.formatMessage({ id: 'cluster.nature.receivingGift' })
    };
    return clusterNature[nature] || '';
};

export const clusterPrivacyTranslation = (privacy: ClusterPrivacy, intl: IntlShape) => {
    const clusterPrivacy = {
        [ClusterPrivacy.Private]: intl.formatMessage({ id: 'privacyType.private' }),
        [ClusterPrivacy.Public]: intl.formatMessage({ id: 'privacyType.public' }),
        [ClusterPrivacy.Secret]: intl.formatMessage({ id: 'privacyType.secret' })
    };
    return clusterPrivacy[privacy] || '';
};

export const clusterVisibilityTranslation = (clusterVisibility: ClusterVisibility, intl: IntlShape) => {
    const visibility = {
        [ClusterVisibility.Hidden]: intl.formatMessage({ id: 'clusterVisibility.hidden' }),
        [ClusterVisibility.Visible]: intl.formatMessage({ id: 'clusterVisibility.visible' })
    };
    return visibility[clusterVisibility] || '';
};

export const expandMenuOptionsTranslation = (intl: IntlShape) => {
    const expandMenuOptions = {
        [CellHeight.Small]: intl.formatMessage({ id: 'clusterStatus.epandTool.small' }),
        [CellHeight.Medium]: intl.formatMessage({ id: 'clusterStatus.epandTool.medium' }),
        [CellHeight.Tall]: intl.formatMessage({ id: 'clusterStatus.epandTool.tall' }),
        [CellHeight.ExtraTall]: intl.formatMessage({ id: 'clusterStatus.epandTool.extraTall' })
    };

    return {
        options: expandMenuOptions,
        getTranslation: (cellHeight: CellHeight) => expandMenuOptions[cellHeight] || ''
    };
};

export const intlToString = (id: string, intl: IntlShape) => {
    if (id) {
        return intl.formatMessage({ id: id });
    }

    return '';
};