import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { rgba } from 'polished';

import { colorStack } from '../../../styleHelpers/colors';
import { Button } from '../Buttons/Button';
import { IPopupSizes } from '../../../entities/IGlobal';

const Wrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background: ${rgba(colorStack.label, .75)};
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
    .custom-iframe-wopi {
        width: 100%;
        height: 70vh;
    }
`;

const InnerWrapper = styled.div`
    padding: 50px 60px 40px 60px;
    max-height: 95vh;
    border-radius: 16px;
    background: ${colorStack.white};
`;

const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Content = styled.div<{size: IPopupSizes}>`
    margin: 0 0 40px 0;
    ${props => props.size === IPopupSizes.Small && css`
        width: 560px;
    `}
    ${props => props.size === IPopupSizes.Medium && css`
        width: 660px;
    `}
    ${props => props.size === IPopupSizes.Big && css`
        width: 760px;
    `}
    ${props => props.size === IPopupSizes.Monster && css`
        width: 960px;
    `}
    ${props => props.size === IPopupSizes.Full && css`
        width: calc(100vw - 130px);
    `}
`;

interface IProps {
    showPopup: boolean;
    noButtonText?: React.ReactNode;
    yesButtonText?: React.ReactNode;
    loading?: boolean;
    size?: IPopupSizes;
    disabled?: boolean;
    noHandler?();
    yesHandler?(e?: React.MouseEvent<HTMLButtonElement>);
}

export const ConfirmPopup: FC<IProps> = props => {
    return (
        <>
            {props.showPopup &&
                <Wrapper>
                    <InnerWrapper>
                        <Content size={props.size || IPopupSizes.Small}>
                            {props.children}
                        </Content>
                        <Buttons>
                            {props.noHandler &&
                                <Button bgColor={colorStack.white} borderColor={colorStack.middleGrey} fontColor={colorStack.darkGrey} onClick={props.noHandler}>{props.noButtonText || <FormattedMessage id="global.no" />}</Button>
                            }
                            {props.yesHandler &&
                                <Button onClick={props.yesHandler} loading={props.loading} disabled={props.loading || props.disabled}>{props.yesButtonText || <FormattedMessage id="global.yes" />}</Button>
                            }
                        </Buttons>
                    </InnerWrapper>
                </Wrapper>
            }
        </>
    );
};