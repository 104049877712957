import React, { useEffect, useState, useCallback, FC } from 'react';
import styled from 'styled-components';
import { IDropdownOption } from '@fluentui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';

import { IClusterStakeProperty } from '../../../../../entities/IClusterStake';
import { colorStack } from '../../../../../styleHelpers/colors';
import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { ErrorText } from './StakeStyles';
import { getDaysOfMonth, months } from '../../../../../tools/date';
import { DatePickerWrapper } from '../../../../Common/DatePicker/DatePicker';
import { SelectInput } from '../../../../Common/SelectInput/SelectInput';

const Wrapper = styled.div`
    span {
        font-size: ${fontSize[13]};
        color: ${colorStack.darkBlue};
    }
`;

const BalancedWrapper = styled.div`
    display: flex;
    align-items: center;
    span {
        margin: 0 0 0 .5rem;
    }
`;

const SimpleCalendar = styled.div`
    display: flex;
    align-items: center;
`;

const SelectWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 0 1rem 0 0;
    label {
        padding: 0;
    }
    > div {
        width: 70px;
        margin: 0 0 0 .5rem;
    }
`;

interface IDateContentProps {
    data: IClusterStakeProperty;
    editStake: boolean;
    propertieId: string;
    error: string;
    setEditingData(propertie: IClusterStakeProperty);
}

export const DateContent: FC<IDateContentProps> = ({ error, data, editStake, propertieId, setEditingData }) => {
    // tslint:disable-next-line:no-null-keyword
    const [content, setContent] = useState<Date>(data?.value?.date || null);
    const [day, setDay] = useState<string>(undefined);
    const [month, setMonth] = useState<string>(undefined);
    const intl = useIntl();
    useEffect(() => {
        if (data.id !== 'BalanceSheetDate' && data?.key !== 'balance-sheet-date') {
            // tslint:disable-next-line:no-null-keyword
            !editStake && setContent(data.value?.date || null);
        }
        if (data.value?.date) {
            setDay(new Date(data.value?.date).getDate().toString());
            setMonth((new Date(data.value?.date).getMonth() + 1).toString());
        }
    }, [data, editStake]);

    const changeDate = useCallback((date: Date) => {
        const changeHour = date ? new Date(new Date(date).setHours(7)) : date;
        setContent(changeHour);
        setEditingData({
            ...data,
            value: { date: changeHour },
            id: propertieId
        });
    }, [data, propertieId, setEditingData]);

    const changeSimpleDate = useCallback((dayData: string) => {
        setDay(dayData);
        setEditingData({
            ...data,
            value: { date: new Date(`${month}/${dayData}/2000`) },
            id: propertieId
        });
    }, [data, month, day]);

    return (
        <Wrapper>
            {editStake ? (
                <>
                    {(data.id !== 'BalanceSheetDate' && data?.key !== 'balance-sheet-date') ? (
                        <DatePickerWrapper
                            // tslint:disable-next-line:no-null-keyword
                            value={content ? new Date(content) : null}
                            onChange={changeDate}
                            placeholder={intl.formatMessage({ id: 'cluster.placeholder.selectDate' })}
                        />
                    ) : (
                            <SimpleCalendar>
                                <SelectWrapper>
                                    <label><FormattedMessage id="global.label.month"  /></label>
                                    <SelectInput
                                        selectedKey={month}
                                        dropdownWidth={80}
                                        onChange={(e, option: IDropdownOption) => setMonth(option.data)}
                                        options={months.map(elem => ({
                                            key: elem,
                                            text: elem,
                                            data: elem
                                        }))}
                                    />
                                </SelectWrapper>
                                <SelectWrapper>
                                    <label><FormattedMessage id="global.label.day"  /></label>
                                    <SelectInput
                                        selectedKey={day}
                                        dropdownWidth={80}
                                        disabled={!month}
                                        onChange={(e, option: IDropdownOption) => changeSimpleDate(option.data)}
                                        options={getDaysOfMonth[Number(month) - 1]?.map(elem => ({
                                            key: elem,
                                            text: elem,
                                            data: elem
                                        }))}
                                    />
                                </SelectWrapper>
                            </SimpleCalendar>
                        )}
                    {error &&
                        <ErrorText>{error}</ErrorText>
                    }
                </>
            ) : (
                    <>
                        {(data.id === 'BalanceSheetDate' || data?.key === 'balance-sheet-date') ? (
                            <BalancedWrapper>
                                {data.value?.date &&
                                    <>
                                        <FontAwesomeIcon icon={faSync} />
                                        <FormattedMessage id="clusterstake.everyYearOn"  />
                                        <span>{new Date(data.value?.date).getMonth() + 1}/{new Date(data.value?.date).getDate()}</span>
                                    </>
                                }
                            </BalancedWrapper>
                        ) : (
                                <>
                                    {data.value?.date && moment.utc(data?.value?.date).local().format('DD MMM YYYY')}
                                </>
                            )}
                    </>
                )}
        </Wrapper>
    );
};