import React, { SFC } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import boxShadow from '../../../styleHelpers/mixins/shadow';
import { newDesignThemeSwitch } from '../../../components/NewDesignCommon/NewDesignSwitch/newDesignTheme';

interface IWhiteBoxProps {
    withBackground?: boolean;
    noBorder?: boolean;
    className?: string;
    fullWidth?: boolean;
    marginBottom?: boolean;
}

export const Box = styled.div<IWhiteBoxProps>`
    padding: 12px;
    border-radius: 4px;
    margin: 0 0 1px 0;
    break-inside: avoid;
    ${boxShadow()};

    ${props => props.noBorder && css`
        box-shadow: none;
    `}

    ${props => props.withBackground && css`
        background: ${colorStack.white};
    `}

    ${props => props.fullWidth && css`
        width: 100%;
    `}

    ${props => props.marginBottom && css`
        margin-bottom: 1rem;
    `}

    ${(props) => newDesignThemeSwitch(props, () =>
        css`
            padding: 10px ${fontSize[16]};
        `
    )}
`;

export const WhiteBox: SFC<IWhiteBoxProps> = ({ children, fullWidth, withBackground = true, className = '', noBorder, marginBottom }) => (
    <Box withBackground={withBackground} fullWidth={fullWidth} className={className} noBorder={noBorder} marginBottom={marginBottom}>{children}</Box>
);
