import React, { useEffect, useState, useCallback, FC } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { IClusterStakeProperty } from '../../../../../entities/IClusterStake';
import { colorStack } from '../../../../../styleHelpers/colors';
import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { ProfilesSummary } from '../../../../Common/ProfilesSummary/ProfilesSummary';
import { ErrorText } from './StakeStyles';
import { Modify } from '../../../../../tools/generalTools';
import { IProfileType } from '../../../../../entities/IGlobal';
import { IValue } from '../../../../../entities/IPickers';
import { LegalEntitiesPicker } from '../../../../Common/Pickers/LegalEntitiesPicker';

export const Wrapper = styled.div`
    span {
        font-size: ${fontSize[13]};
        color: ${colorStack.darkBlue};
    }
`;

const PickerWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

type ILegalEntityContent = Modify<IClusterStakeProperty, {
    value: any; // interface depends if newDesign or no
}>;

export interface IProps {
    data: ILegalEntityContent;
    editStake: boolean;
    propertieId: string;
    error: string;
    context?: 'PLE' | 'Cluster';
    setEditingData(propertie: any);
}

export const LegalEntityContent: FC<IProps> = ({ context, error, data, editStake, propertieId, setEditingData }) => {
    const [content, setContent] = useState<IValue[]>(undefined);
    const { organization } = useParams<{ organization: string }>();
    useEffect(() => {
        const finalData = Array.isArray(data?.value) ? data?.value : [data?.value];
        !editStake && setContent(finalData?.map(elem => elem ? ({
            key: elem.id,
            text: elem.Name || elem.name,
            data: elem
            // tslint:disable-next-line:no-null-keyword
        }) : null) || []);
    }, [data, editStake]);

    const setFieldValue = useCallback((value: IValue[]) => {
        setContent(value);
        setEditingData({
            ...data,
            value: context === 'PLE' ? ({
                Name: value?.[0]?.data.Name,
                id: value?.[0]?.data.id
            }) as any : value?.[0]?.data,
            id: propertieId
        });
    }, [propertieId, data, setEditingData, context]);

    const getSaveData = useCallback((value: any) => {
        setEditingData({
            ...data,
            value: value,
            id: propertieId
        });
    }, [propertieId, data, setEditingData]);

    return (
        <Wrapper>
            {editStake ? (
                <>
                    <PickerWrapper>
                        <LegalEntitiesPicker
                            allCompanies={context === 'Cluster'}
                            value={content}
                            onSelectElement={setFieldValue}
                            getSaveData={getSaveData}
                        />
                    </PickerWrapper>
                    {error &&
                        <ErrorText>{error}</ErrorText>
                    }
                </>
            ) : (
                <>
                    {!!content &&
                        <ProfilesSummary
                            key={content?.[0]?.data.id}
                            organizationUrlName={organization}
                            type={context === 'PLE' ? IProfileType.Company : IProfileType.Cluster}
                            profile={content?.[0]?.data ? {
                                picture: content?.[0]?.data.Picture,
                                firstName: content?.[0]?.data.Name || content?.[0]?.data.name,
                                id: content?.[0]?.data.id
                            } : undefined}
                        />
                    }
                </>
            )}
        </Wrapper>
    );
};
