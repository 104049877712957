import React from 'react';
import styled from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { TextWithEllipsis } from '../TextWithEllipsis/TextWithEllipsis';

export const Label = styled.label`
    display: block;
    padding: 0;
    margin: 0 0 3px 0;
    font-size: ${fontSize[13]};
    font-style: normal;
    font-weight: 400;
    font-family: ${(props) => props.theme?.font?.primary || 'Roboto'};
    color: ${colorStack.label};
`;

export const LabelWithEllipsis = ({ children }) => {
    return (
        <Label>
            <TextWithEllipsis>{children}</TextWithEllipsis>
        </Label>
    );
};