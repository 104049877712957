import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { rgba } from 'polished';

import { media } from '../../../styleHelpers/breakpoint';
import { colorStack } from '../../../styleHelpers/colors';
import boxShadow from '../../../styleHelpers/mixins/shadow';
import { Spinner } from '../Spinner/Spinner';
import { fontSize } from '../../../styleHelpers/fontSizes';

export const ClusterHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    margin: 0 0 1rem 0;
`;

export const HeaderLeft = styled.div`
    display: flex;
    align-items: center;
    > div {
        display: flex;
        flex-direction: column;
        margin: 0 0 0 1rem;
        h1 {
            padding: 0;
            margin: 0 0 3px 0;
            font-size: ${fontSize[16]};
        }
        span {
            font-size: ${fontSize[13]};
            color: ${colorStack.disabled};
        }
    }
`;

export const DatagridWrapper = styled.div<{ $fullScreen?: boolean }>`
    background: ${colorStack.white};
    ${boxShadow()}
    padding: 1rem;
    height: 100%;
    border-radius: 4px;
    ${props =>
        props.$fullScreen &&
        css`
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 100001;
        `};
    > .e-control,
    .loader-wrapper {
        height: calc(100% - 50px);
    }
`;

export const ToolsBar = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ToolsRightMenu = styled.div`
    display: flex;
    align-items: center;
    button {
        margin: 0 1rem;
        display: flex;
        align-items: center;
        outline: none;
        cursor: pointer;
        &:last-child {
            margin-right: 0;
        }
        &:first-child {
            margin-left: 0;
        }
        &.active {
            span {
                font-weight: 600;
            }
        }
        &.disabled {
            pointer-events: none;
            opacity: 0.5;
        }
        span {
            color: ${colorStack.darkBlue};
            font-size: ${fontSize[13]};
            margin: 0 0 0 10px;
        }
    }
`;

export const ExportSubTools = styled.div`
    display: flex;
    align-items: center;
    padding: 1rem;
    margin: 0 1rem 1rem 1rem;
    button {
        display: flex;
        align-items: center;
        margin: 0 15px 0 0;
        outline: none;
        cursor: pointer;
        span {
            margin: 0 0 0 10px;
        }
    }
`;

export const MainTools = styled.div`
    display: flex;
    padding: 0 0 1rem 0;
    flex-direction: column;
    ${media.desktop`
        align-items: center;
        flex-direction: row;
    `}
`;

export const SearchInputBox = styled.div`
    display: flex;
    align-items: center;
    color: ${colorStack.darkGrey};
    margin: 0 0 1rem 0;
    ${media.desktop`
        margin: 0 1rem 0 auto;
    `}
`;

export const StatusWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const NameButton = styled.button`
    cursor: pointer;
    text-align: left;
    font-weight: 500;
    color: ${colorStack.darkBlue};
    &:hover {
        text-decoration: underline;
    }
`;

export const StandardSingleCell = styled.p`
    text-align: left;
    font-size: ${fontSize[13]};
    white-space: normal;
    font-weight: 400;
`;

export const PeopleImageWrapper = styled.div`
    width: 28px;
    height: 28px;
    margin: 0 10px 0 0;
`;

export const DataColumnWrapper = styled.div`
    display: flex;
    width: 1000px;
`;

export const ButtonWrapper = styled.div`
    margin: 10px auto 0 0;
    ${media.desktop`
        margin: 0 0 0 auto;
    `}
`;

export const DropdownWrapper = styled.div`
    display: flex;
    align-items: center;
    min-width: 200px;
    margin: 0 0 0 1rem;
    svg {
        margin: 0 0 0 0.5rem;
        cursor: pointer;
    }
    .ms-Dropdown-title {
        max-width: 220px;
    }
`;

export const SingleStake = styled(Link)`
    display: block;
    cursor: pointer;
    padding: 0.5rem;
    transition: background-color 0.2s ease;
    &:hover {
        background: ${colorStack.ligthGrey};
        font-weight: 500;
        text-decoration: none;
    }
`;

export const StakesWrapper = styled.div`
    height: 500px;
    min-width: 300px;
`;

export const ToolsRow = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 0 0 0 auto;
    align-items: center;
`;

export const GridButton = styled.button`
    cursor: pointer;
    margin: 0 0 0 1rem;
    font-size: ${fontSize[20]};
    color: ${colorStack.disabled};
`;

export const TagsWrapper = styled.div`
    color: ${colorStack.blue};
    background: ${rgba(colorStack.blue, 0.07)};
    padding: 8px;
    display: inline-block;
    border-radius: 4px;
    margin: 0 10px 0 0;
    overflow: hidden;
`;

export const BarsButton = styled.button`
    color: ${colorStack.darkBlue};
    cursor: pointer;
    margin: 0 0 0 1rem;
    font-size: ${fontSize[20]};
`;

export const ColumnChooser = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 1rem 0;
`;

export const SingleColumnChooser = styled.div`
    display: flex;
    align-items: center;
    padding: 5px;
    margin: 0 20px 0 0;
`;

export const FiltersWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1000px;
    width: 100%;
    ${media.desktop`
        margin: 0;
        width: 66%;
    `}
`;
export const FilterButton = styled.div`
    position: relative;
    margin: 0 30px 1rem 0;
    cursor: pointer;
`;

export const FilterButtonInner = styled.div`
    color: ${colorStack.darkGrey};
    display: flex;
    align-items: center;
    > span {
        font-weight: 500;
        margin: 0 5px 0 0;
    }
    > svg {
        margin: -8px 0 0 0;
    }
`;

export const FiltersToolsMore = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    ${media.desktop`
        margin: 0 0 0 auto;
    `}
`;

export const ClearButton = styled.div`
    display: flex;
    align-items: center;
    color: ${colorStack.darkGrey};
    cursor: pointer;
    > span {
        margin: 0 0 0 10px;
    }
`;

export const SearchSubTools = styled.div`
    display: flex;
    align-items: flex-start;
    background: ${colorStack.ligthGrey};
    padding: 1rem 1rem 0 1rem;
    margin: 0 0 1rem 0;
    flex-direction: column;
    ${media.desktop`
        flex-direction: row;
    `}
`;

export const NameTemplateWrapper = styled.div`
    display: flex;
    align-items: center;
    font-weight: 500;
    color: ${colorStack.darkBlue};
    span {
        cursor: pointer;
        &:first-child {
            width: 24px;
            height: 24px;
            margin: 0 15px 0 0;
            padding: 0;
        }
    }
    &:hover {
        span {
            text-decoration: underline;
        }
    }
`;

export const ExpandWrapper = styled.div`
    display: flex;
    position: relative;
    font-weight: 0.75rem;
`;

export const SelectedOption = styled.div<{ isActive?: boolean; count?: number }>`
    ${boxShadow()};
    display: flex;
    align-items: center;
    margin: 0 0.5rem 0 0;
    padding: 6px;
    border-radius: 4px;
    transition: 0.2s ease background-color;
    opacity: 0.6;
    &:last-child {
        margin: 0 0.5rem 0 0;
    }
    > span {
        margin: 0 0 0 0.3rem;
    }
    ${props =>
        props.isActive &&
        css`
            background: ${colorStack.whiteBlue};
            > span,
            svg {
                color: ${colorStack.content};
                fill: ${colorStack.content};
            }
            opacity: 1;
        `}
    &:hover {
        background: ${colorStack.whiteBlue};
        > span,
        svg {
            color: ${colorStack.content};
            fill: ${colorStack.content};
        }
        opacity: 1;
    }
    cursor: pointer;
`;

export const ExpandOption = styled.div<{ isSelected: boolean }>`
    display: flex;
    align-items: center;
    opacity: 0.6;
    ${props =>
        props.isSelected &&
        css`
            opacity: 1;
        `}
    padding: .5rem 0;
    svg {
        margin: 0 0.5rem 0 1rem;
    }
    &:hover {
        background: ${colorStack.whiteBlue};
        opacity: 1;
    }
    cursor: pointer;
`;

export const ExpandTitle = styled.span`
    margin: 0.5rem 0 0.5rem 1rem;
    font-size: ${fontSize[13]};
    font-weight: 400;
    color: ${colorStack.disabled};
`;

export const ExpandMenu = styled.div<{ isVisible: boolean }>`
    z-index: 2;
    min-width: 300px;
    top: 34px;
    background: ${colorStack.white};
    display: none;
    position: absolute;
    border-radius: 4px;
    ${boxShadow()};
    ${props =>
        props.isVisible &&
        css`
            display: flex;
            flex-direction: column;
        `}
`;

export const GridTools = styled.div`
    display: flex;
    align-items: center;
    button {
        margin: 0 1rem;
        display: flex;
        align-items: center;
        outline: none;
        cursor: pointer;
        &:last-child {
            margin-right: 0;
        }
        &:first-child {
            margin-left: 0;
        }
        &.active {
            span {
                font-weight: 600;
            }
        }
        &.disabled {
            pointer-events: none;
            opacity: 0.5;
        }
        span {
            font-size: ${fontSize[13]};
        }
    }
`;

export const Template = styled.div<{ length?: number }>`
    span {
        margin: auto 0;
        width: 50%;
        flex: 1;
    }
`;

export const SeeMore = styled.button`
    cursor: pointer;
    margin: 0 0 0 1rem;
    white-space: nowrap;
    color: ${colorStack.darkBlue};
    font-weight: 600;
`;

export const UserName = styled.button`
    color: ${colorStack.darkBlue};
    margin: 0 0.5rem 0 0;
    overflow: hidden;
    max-width: 95%;
    &:visited {
        color: ${colorStack.darkBlue};
    }
    cursor: pointer;
`;

export const UsersCounter = styled.div`
    border: 1px solid ${colorStack.darkBlue};
    border-radius: 3px;
    background: white;
    color: ${colorStack.darkBlue};
    margin: auto 0.5rem auto auto;
    max-height: 24px;
    padding: 4px;
    font-weight: 600;
    cursor: pointer;
`;

export const UserImage = styled.div`
    height: 20px;
    width: 20px;
    margin: 0 0.5rem 0 0;
`;

export const UserWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
    width: 100%;
    position: relative;
    &:hover {
        .tooltip {
            display: block;
        }
    }
`;

export const DateContainer = styled.span`
    &:hover {
        .date-tooltip {
            display: block;
        }
    }
`;

export const LastModifiedWrapper = styled.div`
    display: flex;
`;

export const UserTemplateWrapper = styled.div`
    display: flex;
`;

export const UsersList = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;

export const PersonName = styled.div`
    font-weight: 600;
    color: ${colorStack.darkBlue};
    margin: auto 0;
    font-size: ${fontSize[13]};
    max-width: 200px;
    white-space: break-spaces;
`;

export const Tooltip = styled.div<{ lastCell?: boolean }>`
    display: none;
    ${boxShadow()};
    position: absolute;
    border: 1px solid ${colorStack.ligthGrey};
    left: 0;
    top: 20px;
    ${props =>
        props.lastCell &&
        css`
            left: auto;
            right: 95%;
            top: 0;
        `}
    background: ${colorStack.white};
    padding: 1rem;
    min-width: 300px;
    z-index: 999;
`;

export const TopSection = styled.div`
    display: flex;
`;

export const Meta = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0 0 0;
    span {
        font-size: ${fontSize[13]};
        color: ${colorStack.disabled};
        font-weight: 400;
    }
`;

export const PopupHeader = styled.div`
    display: flex;
`;

export const PopupLink = styled(Link)`
    color: ${colorStack.darkBlue};
    margin: 0 0.5rem 0 0;
    &:visited {
        color: ${colorStack.darkBlue};
    }
`;

export const PositionItems = styled.div`
    display: flex;
    span {
        font-size: ${fontSize[13]};
    }
`;

export const LeftSide = styled.div`
    display: flex;
    border-left: 1px solid ${colorStack.ligthGrey};
    border-right: 1px solid ${colorStack.ligthGrey};
    padding: 0 0 0 0.5rem;
    &:first-child {
        border-left: 0;
        padding-left: 0;
    }
`;

export const RightSide = styled.div`
    display: flex;
    padding: 0 0 0 0.5rem;
`;

export const GridRefresh = styled(Spinner)`
    margin: 0 0.5rem;
`;

export const ColumnTreeWrapper = styled.div`
    height: 500px;
    margin: 1rem 1rem 3rem 1rem;
`;

export const ButtonGroup = styled.div`
    display: flex;
    justify-content: space-around;
    margin: 1rem 0 0 0;
`;

export const TooltipImage = styled.div`
    height: 80px;
    width: 80px;
    margin: 0 1rem 0 0;
`;
