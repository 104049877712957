import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { IState } from '../../../reducers';
import { ILanguageReducer } from '../../../reducers/languageReducer';
import { media } from '../../../styleHelpers/breakpoint';
import { useUsefulLinksState, useUsefulLinksRedirect } from '../../../tools/usefulLinksHooks';
import { LinkImage } from '../../Common/Images/LinkImage';
import { Loader } from '../../Common/Loader/Loader';
import ScrollBox from '../../Common/ScrollBox/ScrollBox';

const Wrapper = styled.div`
    width: 100%;
    height: 52vh;
`;

const ListWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    
    a {
        width: 100%;
        margin: 0 .5rem .5rem 0;
        height: 150px;

        ${media.tablet`
            width: 47%;
        `}
    }
`;

const ImageWrapper = styled.a`
    &:hover {
        text-decoration: none;
    }
`;

export const UsefulLinksGrid = () => {
    const [state, nextPage] = useUsefulLinksState();
    const redirectTo = useUsefulLinksRedirect();
    const { userLanguage } = useSelector<IState, ILanguageReducer>(
        (state) => state.language
    );

    if (state.links.length <= 0) {
        return <Loader loading={true} />;
    }

    return (
        <Wrapper>
            <ScrollBox
                onScrollResults={nextPage}
                hasMore={state.count > state.links.length}
            >
                <ListWrapper>
                    {state.links.map((link, i) => (
                        <ImageWrapper
                            key={i}
                            target="_blank"
                            href={link.url}
                            onClick={(e) => redirectTo(e, link.url)}
                        >
                            <LinkImage image={link.picture}>
                                <span>
                                    {state.nameByLcid?.[link.id]?.[userLanguage]}
                                </span>
                            </LinkImage>
                        </ImageWrapper>
                    ))}
                </ListWrapper>
            </ScrollBox>
        </Wrapper>
    );
};
