import { IPanelSearchItem } from '../../entities/IPanel';
import { PanelPermissions } from '../../entities/IPermissions';

export const GET_SEARCH_LAWYERS = 'GET_SEARCH_LAWYERS';
export const CLEAR_PANELS_LAWYERS = 'CLEAR_PANELS_LAWYERS';

export interface IPanelTypes {
    GET_SEARCH_LAWYERS: {
        searchResultLawyer: IPanelSearchItem[];
        permissions: PanelPermissions[];
    }
}