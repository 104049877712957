import { get } from 'lodash';

import { INotification, NotificationType, NotificationChannel } from '../entities/INotification';
import { history } from '../history';

export const notificationRedirect = (notification: INotification, orgName?: string) => {
    if (notification.key === NotificationType.ChannelMessageMentioned || notification.key === NotificationType.SendingGift || notification.key === NotificationType.ReceivingGift) {
        if (notification.data.ThreadId) {
            history.push(`/orgs/${notification.data.OrganizationUrlName}/cluster/${notification.data.ClusterId}/activity/${notification.data.ThreadId}`);
        } else {
            history.push(`/orgs/${notification.data.OrganizationUrlName}/cluster/${notification.data.ClusterId}/activity`);
        }
    }

    if (notification.key === NotificationType.AddedToCluster) {
        history.push(`/orgs/${notification.data.OrganizationUrlName}/cluster/${notification.data.ClusterId}`);
    }

    if (notification.key === NotificationType.AddMemberToOrganization) {
        history.push(`/orgs/${notification.data.OrganizationUrlName}/dashboard`);
    }

    if (notification.key === NotificationType.CampaignSubmitted) {
        window.open(notification.data.RedirectUrl, notification.data.RedirectUrl.includes(location.host) ? '_self' : '_blank');
    }

    if (notification.key === NotificationType.RequestAccessToCluster) {
        history.push(`/orgs/${notification.data.OrganizationUrlName}/cluster/${notification.data.ClusterId}/settings?tab=Members&subTab=AccessRequest`);
    }
    if (notification.key === NotificationType.RequestAccessToResource) {
        history.push(`/orgs/${orgName}/cluster/${notification.data.ResourceId}/settings?tab=Members`);
    }

    if (notification.data.Domain === NotificationType.Profile) {
        history.push(`/profile/${notification.senderDisplayDetails.userId}`);
    }

    if (notification.data.Domain === NotificationType.LegalscoreDetails) {
        history.push(`/legalscore/${notification.receiverId}`);
    }

    if (notification.data.Domain === NotificationType.EvaluationPage) {
        history.push(`/case/evaluate?caseId=${notification.data.CaseId}&juryEmail=${notification.data.JuryEmail}`);
    }

    if (notification.data.Domain === NotificationType.Equitis) {
        history.push(`/orgs/${notification.data.OrganizationUrlName}/quotation/${notification.data.QuotationId}`);
    }
    if ((notification.data.Domain || notification.key) === NotificationType.ClusterDateReminder) {
        history.push(`/orgs/${notification.data.OrganizationUrlName}/cluster/${notification.data.ClusterId}/overview`);
    }

    if (notification.key === NotificationType.SharedElement) {
        if (get(notification, 'data.ItemUrl')) {
            window.open(notification.data.ItemUrl, '_blank');
        } else {
            history.push('/drive?account=shared');
        }
    }

    if (notification.key === NotificationType.ChannelMessageCreated) {
        history.push(`/orgs/${notification.data.OrganizationUrlName}/cluster/${notification.data.ClusterId}/activity/${notification.data.Id}`);
    }

    if (
        notification.key === NotificationType.RfpCreated ||
        notification.key === NotificationType.RfpDeadlineExpired ||
        notification.key === NotificationType.RfpChangedStatus ||
        notification.key === NotificationType.RfpClosed ||
        notification.key === NotificationType.RfpUpdated
    ) {
        history.push('/manage-proposals');
    }

    if (
        notification.key === NotificationType.ProposalChangedStatus ||
        notification.key === NotificationType.ProposalCreated ||
        notification.key === NotificationType.ProposalUpdated
    ) {
        history.push('/manage-requests');
    }

    if (notification.key === NotificationType.ConversationMembersAdded) {
        history.push('/messaging');
    }

    if (notification.key === NotificationType.ValidationRequestCreated) {
        history.push(`/orgs/${notification.data.OrganizationUrlName}/cluster/${notification.data.ResourceId}`);
    }

    if (notification.channelId === NotificationChannel.LegalDoc && (notification.data?.PathToBranch || notification.data?.UrlToBranch) && !notification.data?.UrlToAmendment) {
        notification.data?.PathToBranch && history.push(notification.data.PathToBranch);
        notification.data?.UrlToBranch && (window.location = notification.data.UrlToBranch);
    }

    if (notification.channelId === NotificationChannel.LegalDoc && (notification.data?.UrlToAmendment)) {
        notification.data?.UrlToAmendment && (window.location = notification.data.UrlToAmendment);
    }

    if (notification.channelId === NotificationChannel.BackgroudOperation && (notification.data?.RedirectUrl)) {
        notification.data?.RedirectUrl !== null && (window.location = notification.data.RedirectUrl);
    }

    if (notification.key === NotificationType.SignatureUpdated) {
        history.push(`/orgs/${notification.data.OrganizationUrlName}/cluster/${notification.data.ClusterId}/documents?path=Documents`);
    }

    if ([NotificationType.ValidationRequestCompleted, NotificationType.ValidationRequestRejected, NotificationType.ValidationRequestWithdrawn, NotificationType.ValidationRequestNewStageStarted, NotificationType.ValidationRequestClosedNotificationForCreatorUser as string].includes(notification.key)) {
        history.push(`/orgs/${notification.data.OrganizationUrlName}/cluster/${notification.data.ResourceId}/activity?panel=true&validationId=${notification.data.ValidationRequestId}`);
    }
};