import { createBrowserHistory, Location } from 'history';

import store from './store';
import { IMenuItem } from './entities/IMenu';
import * as actionTypes from './actions/actionTypes/menuTypes';

const basename = document.getElementsByTagName('base')[0].getAttribute('href');

export const history = createBrowserHistory({
    basename
});

history.listen((state: Location) => {
    switch (state.pathname) {
        case '/':
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.home
            });
            break;

        case '/directory':
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.people
            });
            break;

        case '/news':
        case '/news/view':
        case '/news/edit':
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.publications
            });
            break;

        case '/network':
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.people
            });
            break;

        case '/companies':
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.entities
            });
            break;

        case '/admin-console':
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.administration
            });
            break;

        case '/messaging':
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.messaging
            });
            break;

        case '/notifications':
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.notifications
            });
            break;
        case '/billing':
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.billing
            });
            break;

        case '/settings':
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.settings
            });
            break;

        case '/profile':
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.profile
            });
            break;

        case '/network':
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.network
            });
            break;

        case '/invite':
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.invite
            });
            break;

        case `/orgs/${state.pathname.split('/')?.[2]}/dashboard`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.explore
            });
            break;

        case `/orgs/${state.pathname.split('/')?.[2]}/clusters`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.dashboard
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/settings`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.settings
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/panel`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.panel
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/rfp/myrequests`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.myrequests
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/entities/datagrid`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.entitiesDashboard
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/clusters/participation`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.participationDashboard
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/entities/graph`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.chart
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/clusters/mandate-holders`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.mandateDashboard
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/clusters/coi-dashboard`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.dashboard
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/clusters/contract-dashboard`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.dashboard
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/clusters/gift-dashboard`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.dashboard
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/allFAQ`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.AllFAQ
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/composite-side-letter`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.AllRights
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/clausier`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.Clausier
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/questions`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.AllQNA
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/clusters/legal-support-dashboard`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.dashboard
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/clusters/project-dashboard`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.projectDashboard
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/clusters/actions-dashboard`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.actionsDashboard
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/directory`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.workspaceUsers
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/poa-holders`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.POA
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/clusters/poa-holders`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.poaDashboard
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/clusters/sponsoring-dashboard`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.sponsoringDashboard
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/clusters/landlord-dashboard`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.landLordDashboard
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/clusters/landlord-lease`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.landlordLeaseDashboard
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/clusters/landlord-lot`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.landlordLotDashboard
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/clusters/landlord-occupant`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.landlordOccupantDashboard
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/clusters/landlord-owner`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.landlordOwnerDashboard
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/clusters/signature`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.signature
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/clusters/approval`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.approval
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/campaigns`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.campaignDashboard
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/campaign/${state.pathname.split('/')?.[4]}`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.campaignDashboard
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/clusters/dates`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.keyDate
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/documentation/collections`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.norms
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/documentation/collections/`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.norms
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/documentation/collections/current`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.norms
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/documentation/search/current`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.searchInNorms
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/documentation/norms-management`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.normsManagement
            });
            break;
        case `/orgs/${state.pathname.split('/')?.[2]}/quotation`:
            store.dispatch({
                type: actionTypes.UPDATE_MENU_TITLE,
                menuItem: IMenuItem.askQuotation
            });
            break;

        default:
            break;
    }
});
