import React, { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../../../../styleHelpers/colors';
import { TagPicker, ITag } from '../../../../Common/TagPicker/TagPicker';
import { Avatar } from '../../../../Common/Avatar/Avatar';
import { ISearchLegalEntitiesValue } from '../../../../../entities/ILegalEntities';

const Wrapper = styled.div`
    position: relative;
    &:hover {
        cursor: text;
    }
`;

const SingleElem = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    color: ${colorStack.darkBlue};
    padding: 4px 12px;
    height: 33px;
    span {
        margin-left: 8px;
        height: 18px;
        overflow: hidden;
    }
`;

interface IFocusedElementTag {
    firstName: string;
    lastName: string;
    picture: string;
    id: string;
}

interface Data {
    firstName: string;
    lastName: string;
    picture: string;
    id: string;
    Picture?: string;
}
interface IProps {
    focusedElementTag: ITag<Data>[];
    error?: boolean;
    isAvatarVisible?: boolean;
    noAvatar?: boolean;
    noResults?: React.ReactNode;
    searchHandler(query: string);
    onSelectElement(value: ITag<Data>[] | ITag<ISearchLegalEntitiesValue>[]): void;
    onBlur?(e: React.FocusEvent<any>);
    setCustomName?(value: string | ITag<IFocusedElementTag>[] | ITag<ISearchLegalEntitiesValue>[]): void;
}

let debounce: ReturnType<typeof setTimeout>;

export const Picker: FC<IProps> = props => {

    return (
        <Wrapper>
            <TagPicker<Data>
                selectedTags={props.focusedElementTag}
                onChange={props.onSelectElement}
                resolveTags={query => props.searchHandler(query)}
                limit={1}
                noResults={props.noResults}
                noClearButton
                newDesign
                clearTagsOnInputChange
                onBlur={props.onBlur}
                error={props.error}
                onClickOutside={props.setCustomName}
                renderTag={tag => <SingleElem>
                    {props.isAvatarVisible && !props.noAvatar && tag.data && <Avatar
                        picture={tag.data?.picture || tag.data?.Picture}
                        firstName={tag.data?.firstName}
                        lastName={tag.data?.lastName}
                        id={tag.data?.id}
                    />}
                    <span>{tag.value}</span>
                </SingleElem>}
                renderSuggestion={tag => <SingleElem>
                    {!props.noAvatar && <Avatar
                        picture={tag.data.picture || tag.data.Picture}
                        firstName={tag.data.firstName}
                        lastName={tag.data.lastName}
                        id={tag.data.id}
                    />}
                    <span>{tag.value}</span>
                </SingleElem>
                }
            />
        </Wrapper>
    );
};