import React, { useEffect, useState, useCallback, FC } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { IOrganizationMember } from '../../../../../entities/IOrganization';
import { IClusterStakeProperty } from '../../../../../entities/IClusterStake';
import { getSearchResult } from '../../../../../actions/searchActions';
import { FacetsNames } from '../../../../../entities/ISearch';
import { colorStack } from '../../../../../styleHelpers/colors';
import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { ProfilesSummary } from '../../../../Common/ProfilesSummary/ProfilesSummary';
import { DebouncedPeoplePicker } from '../../../../Common/DebouncedPeoplePicker';
import { ErrorText } from './StakeStyles';
import { IProfileType } from '../../../../../entities/IGlobal';

type GetSearchResult = ReturnType<typeof getSearchResult>;

export const Wrapper = styled.div`
    span {
        font-size: ${fontSize[13]};
        color: ${colorStack.darkBlue};
    }
`;

export interface IPeopleContentProps {
    data: IClusterStakeProperty;
    editStake: boolean;
    propertieId: string;
    error?: string;
    context?: 'PLE' | 'Cluster';
    stakeOptions?: {
        fullWidth?: boolean;
    }
    setEditingData(propertie: any);
}

export const PeopleContent: FC<IPeopleContentProps> = ({ context, error, data, editStake, propertieId, setEditingData, stakeOptions }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [content, setContent] = useState<IOrganizationMember>(data?.value);
    const referentialIdentity = data.configuration?.referentialIdentity;

    useEffect(() => {
        !editStake && setContent(data.value);
    }, [data, editStake]);

    const transformPickerResult = useCallback((profile: IOrganizationMember) => ({
        id: profile.id,
        text: `${profile.firstName} ${profile.lastName}`,
        imageInitials: `${(profile.firstName || '').slice(0, 1)}${(profile.lastName || '').slice(0, 1)}`,
        imageUrl: profile.picture,
        secondaryText: [profile.jobTitle, profile.cabinet].filter(val => val).join(' - ')
    }), []);

    const resolvePeople = useCallback(async (query: string = ''): Promise<{}> => {
        const members = await dispatch<GetSearchResult>(getSearchResult(query || '', 0, 20, Object.keys(FacetsNames).reduce((result, item) => {
            result[item] = [];
            return result;
        }, {})));
        return (members.items || []).map(({ firstName, lastName, jobTitle, picture, id, cabinet }) => ({
            firstName: firstName, lastName: lastName, jobTitle: jobTitle, picture: picture, id, cabinet: cabinet
        }));
    }, [context]);

    const setFieldValue = useCallback((users: IOrganizationMember[]) => {
        if (users) {
            setContent(users[0]);
            setEditingData({
                ...data,
                value: referentialIdentity === 'Custom' ? ({
                    FirstName: users[0].firstName,
                    LastName: users[0].lastName,
                    id: users[0].id,
                    Picture: users[0].picture,
                    Position: undefined,
                    role: undefined,
                    joiningDate: undefined,
                    JobTitle: users[0].jobTitle,
                    Department: undefined,
                    Cabinet: { Name: users[0].cabinet }
                }) : users[0],
                id: propertieId
            });
        }
    }, [propertieId, data, setEditingData]);

    return (
        <Wrapper>
            {editStake ? (
                <>
                    <div>
                        <DebouncedPeoplePicker
                            noResultsFoundText={intl.formatMessage({ id: 'cluster.placeholder.noresult' })}
                            transformResults={transformPickerResult}
                            resolveQuery={resolvePeople}
                            selectedItems={!!content ? [content] : []}
                            onChange={(picker, result) => setFieldValue(result as IOrganizationMember[])}
                            itemLimit={1}
                        />
                    </div>
                    {error &&
                        <ErrorText>{error}</ErrorText>
                    }
                </>
            ) : (
                    <>
                        {!!content &&
                            <ProfilesSummary
                                fullWidth={stakeOptions?.fullWidth}
                                key={content.id}
                                type={IProfileType.Personal}
                                profile={{ ...content }}
                            />
                        }
                    </>
                )}
        </Wrapper>
    );
};
