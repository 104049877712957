import React, { FC } from 'react';
import { faCaretCircleDown, faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IClusterSingleDynamicStake, IClusterSingleDynamicStakeSubType, IClusterStakeProperty, PropertieKey } from '../../../../../../entities/IClusterStake';
import { Avatar } from '../../../../../Common/Avatar/Avatar';
import * as Common from '../Common';
import { AttachmentSection } from './AttachmentSection';
import { DateSection } from './DateSection';
import { ProfilesSummary } from '../../../../../Common/ProfilesSummary/ProfilesSummary';
import { IProfileType } from '../../../../../../entities/IGlobal';

interface IPartiesLayoutProps {
    stake: IClusterSingleDynamicStake;
    propertyByKey: {
        [key in PropertieKey]?: IClusterStakeProperty;
    }
    downloadHandler();
}

export const PartiesLayout: FC<IPartiesLayoutProps> = ({ stake, propertyByKey, downloadHandler }) => (
    <Common.Wrapper>
        <a id={stake.id} />
        <AttachmentSection
            title={stake.subType === IClusterSingleDynamicStakeSubType.PartyStakeTitle ? stake.name : propertyByKey?.position?.value?.value}
            isAttachmentAvailable={propertyByKey?.attachments?.value}
            downloadHandler={downloadHandler}
        />
        <Common.PartyInfoWrapper>
            <Common.DetailSection>
                <Common.LeftSide>
                    {propertyByKey?.[PropertieKey.MandateHolder]?.value &&
                        <div>
                            <Avatar
                                picture={propertyByKey?.[PropertieKey.MandateHolder]?.value?.picture || propertyByKey?.[PropertieKey.MandateHolder]?.value?.Picture}
                                firstName={propertyByKey?.[PropertieKey.MandateHolder]?.value?.name || propertyByKey?.[PropertieKey.MandateHolder]?.value?.Name}
                                lastName=""
                                id={propertyByKey?.[PropertieKey.MandateHolder]?.value?.id}
                                type={propertyByKey?.[PropertieKey.MandateHolder]?.value?.type === 'Personal' ? IProfileType.Personal : IProfileType.Company}
                                size={{
                                    width: 64,
                                    height: 64
                                }}
                            />
                            {(propertyByKey?.[PropertieKey.MandateHolder]?.value?.name || propertyByKey?.[PropertieKey.MandateHolder]?.value?.Name) &&
                                <Common.EntityDetails>
                                    <Common.Name to={propertyByKey?.[PropertieKey.MandateHolder]?.value?.type === 'Personal' || propertyByKey?.[PropertieKey.MandateHolder]?.value?.DataType === 'Person' ? `/profile/${propertyByKey[PropertieKey.MandateHolder]?.value?.id}` : `/company/${propertyByKey[PropertieKey.MandateHolder]?.value?.id}`}>
                                        {`${propertyByKey[PropertieKey.MandateHolder]?.value?.name || propertyByKey[PropertieKey.MandateHolder]?.value?.Name || ''}`}
                                    </Common.Name>
                                    <Common.AdditionalInfo>
                                        {propertyByKey[PropertieKey.Position]?.value?.value}
                                    </Common.AdditionalInfo>
                                </Common.EntityDetails>
                            }
                        </div>
                    }
                </Common.LeftSide>
                <Common.MiddleSection>
                    {propertyByKey?.representatives?.value &&
                        <>
                            <Common.IconWrapper>
                                <FontAwesomeIcon icon={faUser} />
                                <span>{propertyByKey.representatives?.name}</span>
                            </Common.IconWrapper>
                            <Common.Representatives>
                                {propertyByKey.representatives.value?.length ? (
                                    propertyByKey.representatives.value.slice(0, 2).map(user => (
                                        <ProfilesSummary
                                            key={user.id}
                                            type={IProfileType.Personal}
                                            fullWidth
                                            profile={{
                                                picture: user.picture,
                                                firstName: user.firstName,
                                                lastName: user.lastName,
                                                id: user.id,
                                                jobTitle: user.subTitle
                                            }}
                                        />
                                    ))
                                ) : (
                                    <ProfilesSummary
                                        key={propertyByKey.representatives.value.id}
                                        type={IProfileType.Personal}
                                        fullWidth
                                        profile={{
                                            picture: propertyByKey.representatives.value.picture,
                                            firstName: propertyByKey.representatives.value.firstName,
                                            lastName: propertyByKey.representatives.value.lastName,
                                            id: propertyByKey.representatives.value.id,
                                            jobTitle: propertyByKey.representatives.value.subTitle
                                        }}
                                    />
                                )}
                            </Common.Representatives>
                        </>
                    }
                    {propertyByKey?.terms?.value?.value &&
                        <>
                            <Common.Terms>
                                <Common.IconWrapper>
                                    <FontAwesomeIcon icon={faCaretCircleDown} />
                                    <span>{propertyByKey.terms.name}</span>
                                </Common.IconWrapper>
                                <span>{propertyByKey.terms.value?.value}</span>
                            </Common.Terms>
                        </>
                    }
                </Common.MiddleSection>
                <Common.RightSide>
                    {(propertyByKey[PropertieKey.DateStart] || propertyByKey[PropertieKey.DateEnd] || propertyByKey[PropertieKey.LastRenewalDate]) &&
                        <DateSection
                            propertyByKey={propertyByKey}
                        />
                    }
                </Common.RightSide>
            </Common.DetailSection>
        </Common.PartyInfoWrapper>
    </Common.Wrapper>
);