import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { getUserProfileByQuery } from '../../../actions/profileActions';
import { IProfileType } from '../../../entities/IGlobal';
import { IPickerProps, CorePicker, ButtonsTypes } from './CorePicker';

type GetUserProfileByQuery = ReturnType<typeof getUserProfileByQuery>;

const Wrapper = styled.div``;

export const UsersPicker: FC<IPickerProps> = props => {
    const dispatch = useDispatch();

    const getData = useCallback((search: string) => {
        const body = {
            search,
            hideCurrentUser: false
        };
        return dispatch<GetUserProfileByQuery>(getUserProfileByQuery(body, 0, 100)).then(res => {
            return res.items.filter((elem) => !props.filter?.includes(elem.id) || []).map(person => ({
                key: person.id,
                data: {...person, avatarType: IProfileType.Personal},
                text: `${person.firstName} ${person.lastName}`
            }));
        });
    }, []);

    return (
        <Wrapper>
            <CorePicker {...props} value={props.value?.map(elem => ({...elem, data: {...elem.data, type: 'Personal'}}))} getData={getData} buttonsType={ButtonsTypes.Person} />
        </Wrapper>
    );
};