
import { Dispatch } from 'redux';

import { IBodyForCopyMoveActions, IDirectoryContent, IUploadFile, IFilesystemItemType } from '../entities/IClustersFilesystem';
import { updateClusterPermissions } from '../actions/clustersActions';
import { ClusterPermissions } from '../entities/IPermissions';
import { IState } from '../reducers';
import { action } from '../tools/reduxTools';
import { joinPath } from '../tools/clusterFilesystemTools';
import { CreateNewDocType } from '../components/Organization/ClustersV2/ClusterDocuments/TopMenu/StandardMenu';

export const getDirectoryTree = (clusterId: string, notUpdatePermission?: boolean) => action<Promise<IDirectoryContent>>((dispatch: Dispatch, getState, http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/documents/directory-content`, {
        name: '',
        path: '',
        includeFiles: false,
        recursive: true
    }).then((response: IDirectoryContent & { permissions: ClusterPermissions[] }) => {
        if (!notUpdatePermission) {
            dispatch(updateClusterPermissions(response.permissions));
        }
        return response;
    });
});

export const getDirectoryContent = (clusterId: string, currentPath: string[], notUpdatePermission?: boolean) => action<Promise<IDirectoryContent>>((dispatch: Dispatch, getState, http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/documents/directory-content`, {
        name: currentPath[currentPath.length - 1] || '',
        path: joinPath(currentPath.slice(0, currentPath.length - 1)),
        includeFiles: true,
        recursive: false
    }).then((response: IDirectoryContent & { permissions: ClusterPermissions[] }) => {
        if (!notUpdatePermission) {
            dispatch(updateClusterPermissions(response.permissions));
        }
        return response;
    });
});

export const createDirectoryInPath = (clusterId: string, name: string, currentPath: string[]) => action<Promise<IDirectoryContent>>((dispatch, getState, http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/documents/create-directory`, {
        name,
        path: joinPath(currentPath)
    });
});

export const uploadFileInPath = (clusterId: string, file: IUploadFile, currentPath: string[]) => action<Promise<IDirectoryContent>>((dispatch, getState, http) => {
    const form = new FormData();
    form.append('file', file.file);
    form.append('path', joinPath(currentPath));
    form.append('name', file.name);

    return http.clustersApiPost(`/clusters/${clusterId}/documents/upload`, form);
});

export const uploadMultipleFilesInPath = (clusterId: string, files: IUploadFile[], currentPath: string[]) => action<Promise<IDirectoryContent[]>>((dispatch, getState, http) => {
    const form = new FormData();
    form.append('path', joinPath(currentPath));
    files.forEach(file => {
        form.append('files', file.file);
    });

    return http.clustersApiPost(`/clusters/${clusterId}/documents/upload-multiple`, form);
});

export const uploadFolderInPath = (clusterId: string, files: IUploadFile[], currentPath: string[]) => action<Promise<IDirectoryContent[]>>((dispatch, getState, http) => {
    const form = new FormData();
    form.append('path', joinPath(currentPath));
    files.forEach(file => {
        form.append('files', file.file);
    });

    return http.clustersApiPost(`/clusters/${clusterId}/documents/upload-folder`, form);
});

export const deleteFileTemporarily = (clusterId: string, currentPath: string[], name: string) => action<Promise<IDirectoryContent>>((dispatch, getState, http) => {
    return http.clustersApiDelete(`/clusters/${clusterId}/documents/move-item/to-trashbin`, {
        name,
        path: joinPath(currentPath)
    });
});

export const deleteMultipleFilesTemporarily = (clusterId: string, currentPath: string[], items: string[]) => action<Promise<IDirectoryContent>>((dispatch, getState, http) => {
    return http.clustersApiDelete(`/clusters/${clusterId}/documents/move-multiple/to-trashbin`, {
        items,
        path: joinPath(currentPath)
    });
});

export const deleteFilePermanently = (clusterId: string, currentPath: string[], name: string) => action<Promise<IDirectoryContent>>((dispatch, getState, http) => {
    return http.clustersApiDelete(`/clusters/${clusterId}/documents/delete-item`, {
        name,
        path: joinPath(currentPath)
    });
});

export const deleteMultipleFilesPermanently = (clusterId: string, currentPath: string[], items: string[]) => action<Promise<IDirectoryContent>>((dispatch, getState, http) => {
    return http.clustersApiDelete(`/clusters/${clusterId}/documents/delete-multiple`, {
        items,
        path: joinPath(currentPath)
    });
});

export const changeItemName = (clusterId: string, currentPath: string[], name: string, newName: string) => action<Promise<IDirectoryContent>>((dispatch, getState, http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/documents/rename-item`, {
        name,
        newName,
        path: joinPath(currentPath)
    });
});

export const getFileMetadata = (clusterId: string, currentPath: string[], name: string) => action<Promise<IDirectoryContent>>((dispatch, getState, http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/documents/metadata`, {
        name,
        path: joinPath(currentPath) || 'Conversation files',
        includeFiles: true
    });
});

export const getMultipleMetadata = (clusterId: string, currentPath: string[], items: string[]) => action<Promise<Blob>>(async (dispatch, getState: () => IState, http) => {
    const { context, config } = getState();
    try {
        const response = await fetch(`${config.clustersApiUrl}/clusters/${clusterId}/documents/metadata-multiple`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${context.token}`,
                'Ocp-Apim-Subscription-Key': config.subKey,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                items,
                path: joinPath(currentPath),
                includeFiles: true,
                recursive: true
            })
        });
        if (response.ok) {
            return response.blob();
        } else {
            return response.json();
        }
    } catch (e) {
        return e;
    }
});

export const moveItemsToLocation = (clusterId: string, path: string[], newPath: string[], items: string[]) => action<Promise<IDirectoryContent>>((dispatch, getState, http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/documents/move-multiple`, {
        path: joinPath(path),
        newPath: joinPath(newPath),
        items
    });
});

export const restoreFromTrashbin = (clusterId: string, path: string[],  items: string[]) => action<Promise<IDirectoryContent>>((dispatch, getState, http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/documents/restore`, {
        path: joinPath(path),
        items
    });
});

export const changeFileDescription = (clusterId: string, body: {path: string, name: string, description: string} ) => action<Promise<any>>((dispatch, getState, http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/documents/description-item`, body);
});

export const moveFileBeetwenClusters = (clusterId: string, body: { sourceClusterId: string, items: { item: string, finalItemName: string, type: IFilesystemItemType }[], path: string, sourcePath: string} ) => action<Promise<IDirectoryContent[]>>((dispatch, getState, http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/documents/upload-from-cluster`, body);
});

export const getWopiInfo = (fileId: string, edit?: boolean) => action<Promise<any>>((dispatch, getState, http) => {
    return http.clustersApiGet(`/clusters-wopi/files/${fileId}/source-info${edit ? '?action=edit' : ''}`);
});

export const copyDocumentsFromCluster = (clusterId: string, body: IBodyForCopyMoveActions) => action<Promise<any>>((dispatch, getState, http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/documents/copy-from-cluster`, body);
});

export const moveDocumentsFromCluster = (clusterId: string, body: IBodyForCopyMoveActions) => action<Promise<any>>((dispatch, getState, http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/documents/move-from-cluster`, body);
});

export const createNewOfficeDoc = (clusterId: string, body: {name: string, type: CreateNewDocType, path: string}) => action<Promise<IDirectoryContent>>((dispatch, getState, http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/documents/create-document`, body);
});
