import * as actionTypes from '../actions/actionTypes/languageTypes';
import { ILanguage, Lcid, IClusterMessageTranslation, ISingleLcidTranslations } from '../entities/ILanguage';

export interface ILanguageReducer {
    userLanguage: Lcid;
    languageLoaded: boolean;
    managedLanguages: ILanguage[];
    clusterActivityTranslation: IClusterMessageTranslation;
    translations: {
        [key in Lcid]?: ISingleLcidTranslations;
    }
}

const defaultState = (): ILanguageReducer => ({
    userLanguage: Lcid.Fr,
    languageLoaded: false,
    managedLanguages: undefined,
    clusterActivityTranslation: undefined,
    translations: {}
});

export default (state = defaultState(), action): ILanguageReducer => {
    switch (action.type) {
        case actionTypes.SET_USER_LANGUAGE: {
            return {
                ...state,
                userLanguage: action.lcid || Lcid.Fr
            };
        }

        case actionTypes.LOAD_NEW_LANG: {
            return {
                ...state,
                languageLoaded: false
            };
        }

        case actionTypes.FINISH_LOAD_NEW_LANG: {
            return {
                ...state,
                languageLoaded: true
            };
        }

        case actionTypes.SET_MANAGE_LANGUAGES: {
            return {
                ...state,
                managedLanguages: action.managedLanguages
            };
        }

        case actionTypes.SET_CLUSTER_TRANSLATION_MESSAGES: {
            return {
                ...state,
                clusterActivityTranslation: action.clusterActivityTranslation
            };
        }

        case actionTypes.SET_TRANSLATIONS: {
            const payload: actionTypes.ILanguageTypes['SET_TRANSLATIONS'] = action;
            return {
                ...state,
                translations: {
                    ...(state.translations || {}),
                    [payload.lcid]: payload.translations
                },
                languageLoaded: true
            };
        }

        default: {
            return state;
        }
    }
};
