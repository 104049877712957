import React, { forwardRef, PropsWithChildren, RefObject } from 'react';
import styled, { css } from 'styled-components';
import { faExclamationCircle, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { inputsShadow } from '../../../styleHelpers/mixins/shadow';
import { Spinner } from '../Spinner/Spinner';
import { LabelWithEllipsis } from '../Label/Label';
import { MandatoryFieldStar } from '../MandatoryFieldStar/MandatoryFieldStar';

const ColorBar = styled.div`
    background-color: transparent;
    transition: background-color .3s;
    height: 3px;
    width: 100%;
    border-radius: 0 0 4px 4px;
    position: absolute;
    z-index: 1;
    bottom: 0;
    & + * {
        position: absolute;
        right: 1rem;
        color: ${colorStack.red};
    }
`;
const Wrapper = styled.div<{ error: boolean; isSelectWidth: boolean; type: string, outerShadow?: boolean }>`
    &:focus + ${ColorBar} {
        background-color: ${colorStack.middleBlue};
    }
    border-radius: 4px;
    position: relative;
    input, textarea {
        ${inputsShadow()};
        position: relative;
        border-radius: 4px;
        border: none;
        resize: none;
        outline: none;
        background: white;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        padding: 4px 12px;
        color: ${colorStack.content};
        font-size: ${fontSize[16]};
        -webkit-font-smoothing: antialiased;
        &:focus + ${ColorBar} {
            background-color: ${colorStack.middleBlue};
        }
        &::placeholder {
            color: ${colorStack.middleGrey};
            font-weight: 400!important;
            font-style: italic;
            font-family: 'Roboto', sans-serif;
            &:disabled {
                color: ${colorStack.middleGrey};
            }
        }
    }
    ${props => !props.isSelectWidth && css`
        width: 100%;
    `}
    ${props => props.isSelectWidth && css`
        max-width: 20%;
    `}
    ${props => props.error && css`
        ${ColorBar} {
            background-color: ${colorStack.red};
        }
    `}
    input {
        height: 42px;
        :disabled {
            background: ${colorStack.whiteGrey};
        }
    }
`;

const Loader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ErrorIco = styled.div`
    color: ${colorStack.red};
`;

const InputWrapper = styled.div<{ rightPaddig?: boolean; specialLabel: boolean, withAvatar?: boolean, disabled?: boolean; leftInputPadding?: number }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    position: relative;
    ${props => props.rightPaddig && css`
        > input, > textarea {
            padding: 4px 52px 4px 12px
        }
    `}
    ${props => props.withAvatar && css`
        input {
            padding: 4px 12px 4px 40px;
        }
    `}
    ${props => props.leftInputPadding && css`
        input {
            padding-left: ${`${props.leftInputPadding + 15}px`}
        }
    `}
    ${props => props.disabled && css`
        > input, > textarea {
            background: ${colorStack.ligthGrey}!important;
            color: ${colorStack.disabled}!important;
        }
    `}
`;

const ErrorText = styled.p`
    color: ${colorStack.red};
    font-size: ${fontSize[10]};
    text-align: left;
    position: absolute;
    top: 100%;
    z-index: 1;
`;

const ClearButton = styled.button`
    font-size: ${fontSize[16]};
    cursor: pointer;
    color: ${colorStack.content};
`;

const IconsBox = styled.div`
    position: absolute;
    right: 0;
    width: auto;
    display: flex;
    bottom: 12px;
    right: 10px;
    align-items: center;
    > div {
        margin: 0 .5rem;
    }
`;

const RightSideElementBox = styled.div<{ disabled?: boolean }>`
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    width: 42px;
    height: 42px;
    color: ${colorStack.white};
    ${props => props.disabled ? css`
        & {
            cursor: not-allowed;
            pointer-events: none;
        }
        &:hover {
        }
        * {
            cursor: not-allowed;
        }
        background: ${colorStack.ligthGrey};
    ` : css`
        cursor: pointer;
        background: ${colorStack.middleBlue};
    `}
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    align-self: center;
    align-items: center;
    justify-content: center;
`;

interface IProps {
    error?: boolean;
    value: string;
    placeholder?: string;
    maxLenght?: number;
    name?: string;
    type?: 'Input' | 'Textarea' | 'Password';
    disabled?: boolean;
    loading?: boolean;
    isSearchable?: boolean;
    isSelectWidth?: boolean;
    rightSideElement?: React.ReactNode;
    rightSideElementAction?: () => void;
    rightSideElementDisabled?: boolean;
    options?: { value: string; label: string; }[];
    label?: React.ReactNode;
    errorMsg?: string | React.ReactNode;
    specialLabel?: boolean;
    autoFocus?: boolean;
    withAvatar?: boolean;
    required?: boolean;
    leftInputPadding?: number;
    errorWithoutIco?: boolean;
    outerShadow?: boolean;
    onChange?(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
    onBlur?(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>);
    onFocus?(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>);
    onClearClick?();
    onClick?();
}

export const TextComponent = forwardRef<HTMLInputElement | HTMLTextAreaElement, PropsWithChildren<IProps>>((props, ref) => {

    return (
        <Wrapper isSelectWidth={props.isSelectWidth} error={props.error} type={props.type} outerShadow={props.outerShadow} data-lc="js-lc-text-component" className="text-component">
            {props.type === 'Textarea' ? (
                <InputWrapper specialLabel={props.specialLabel} disabled={props.disabled} rightPaddig={(props.errorMsg !== ' ' && props.errorMsg && props.error) || props.loading}>
                    {props.label &&
                        <LabelWithEllipsis>
                            {props.label}
                            {props.required &&
                                <MandatoryFieldStar />
                            }
                        </LabelWithEllipsis>
                    }
                    <textarea
                        ref={ref as RefObject<HTMLTextAreaElement>}
                        onChange={props.onChange}
                        rows={4}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        value={props.value || ''}
                        placeholder={props.placeholder}
                        disabled={props.disabled}
                        maxLength={props.maxLenght}
                    />
                    <ColorBar />
                    {props.loading &&
                        <Loader>
                            <Spinner size="small" />
                        </Loader>
                    }
                    {props.errorMsg !== ' ' && props.errorMsg && props.error &&
                        <>
                            {!props.errorWithoutIco &&
                                <FontAwesomeIcon icon={faExclamationCircle} />
                            }
                            <ErrorText>{props.errorMsg}</ErrorText>
                        </>
                    }
                </InputWrapper>
            ) : (
                <InputWrapper rightPaddig={(props.errorMsg !== ' ' && props.errorMsg && props.error) || props.loading} specialLabel={props.specialLabel} withAvatar={props.withAvatar} disabled={props.disabled} leftInputPadding={props.leftInputPadding}>
                    {props.label &&
                        <LabelWithEllipsis>
                            {props.label}
                            {props.required &&
                                <MandatoryFieldStar />
                            }
                        </LabelWithEllipsis>
                    }
                    <input
                        ref={ref as RefObject<HTMLInputElement>}
                        type={props.type === 'Password' ? 'password' : 'text'}
                        disabled={props.disabled}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onClick={props?.onClick}
                        onFocus={props.onFocus}
                        value={props.value || ''}
                        placeholder={props.placeholder}
                        name={props.name}
                        autoFocus={props.autoFocus}
                        autoComplete="new-password"
                        maxLength={props.maxLenght}
                    />
                    <ColorBar />
                    <IconsBox>
                        {props.loading &&
                            <Loader>
                                <Spinner size="small" />
                            </Loader>
                        }
                        {props.errorMsg !== ' ' && props.errorMsg && props.error &&
                            <ErrorIco>
                                {!props.errorWithoutIco ? <FontAwesomeIcon icon={faExclamationCircle} /> : <span />}
                            </ErrorIco>
                        }
                        {props.onClearClick && (
                            <ClearButton
                                onClick={props.onClearClick}
                                type="button"
                            ><FontAwesomeIcon icon={faTimes} /></ClearButton>
                        )}
                    </IconsBox>
                    {props.rightSideElement && (
                        <RightSideElementBox onClick={props.rightSideElementAction} disabled={props.rightSideElementDisabled}>
                            {props.rightSideElement}
                        </RightSideElementBox>
                    )}
                    {props.errorMsg !== ' ' && props.errorMsg && props.error &&
                        <ErrorText>{props.errorMsg}</ErrorText>
                    }
                </InputWrapper>
            )}
        </Wrapper >
    );
});
