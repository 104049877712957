import React, { FC, useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import * as microsoftTeams from '@microsoft/teams-js';
import jwtDecode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string-for-all';

import { TeamsAuthResponse } from '../entities/IContext';
import { AuthConstants } from '../tools/authTools';
import { IState } from '../reducers';
import { IContextReducer } from '../reducers/contextReducer';
import { setLoggedInUser } from '../actions/contextActions';
import { history } from '../history';
import { fontSizeAndHeight } from '../styleHelpers/fontSizes';
import { colorStack } from '../styleHelpers/colors';
import { GlobalLoader } from './GlobalLoader';
import { Button } from './Common/Buttons/Button';

const TeamsAuthWrapper = styled.div`
    padding: 1rem;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: center;
`;

const STitle = styled.div`
    ${fontSizeAndHeight[31]};
    margin-bottom: 80px;
    text-align: center;
`;

const SFooter = styled.div`
    ${fontSizeAndHeight[13]};
    text-align: center;
    font-weight: 500;
    margin-top: 70px;
    span:first-of-type {
        display: block;
        margin-bottom: 10px;
    }
    a {
        color: ${colorStack.middleBlue};
    }
`;

export const TeamsAuth: FC = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [loading, setLoading] = useState<boolean>(true);
    const [errorMsg, setErrorMsg] = useState<string>(undefined);
    const { tokenRenewalRequested } = useSelector<IState, IContextReducer>(state => state.context);

    useEffect(() => {
        microsoftTeams.initialize();

        if (tokenRenewalRequested) {
            localStorage.removeItem(AuthConstants.ACKey);
            localStorage.removeItem(AuthConstants.IDKey);
            dispatch(setLoggedInUser(undefined, undefined, undefined));
            setLoading(false);
        }

        if (localStorage.getItem(AuthConstants.ACKey)) {
            const query = queryString.parse(location.search) || {};
            const nextRequestUrl = query?.nextRequestUrl as string;
            const accessToken = localStorage.getItem(AuthConstants.ACKey);
            const id = jwtDecode(localStorage.getItem(AuthConstants.IDKey));
            dispatch(setLoggedInUser(
                accessToken,
                id.oid,
                {
                    email: id?.email,
                    displayName: id?.name
                }
            ));

            history.push(nextRequestUrl || '/');
        } else {
            setLoading(false);
        }
    }, []);

const onLoginClick = useCallback(() => {
    setLoading(true);

    microsoftTeams.authentication.authenticate({
        url: `${window.location.origin}/teams/auth-start`,
        width: 600,
        height: 535,
        successCallback(result) {
            setLoading(false);
            const query = queryString.parse(location.search) || {};
            const nextRequestUrl = query?.nextRequestUrl as string;

            try {
                const authResponse = JSON.parse(result) as TeamsAuthResponse;
                const id = authResponse?.id_token && jwtDecode(authResponse.id_token);

                if (authResponse?.access_token && id) {
                    localStorage.setItem(AuthConstants.ACKey, authResponse.access_token);
                    localStorage.setItem(AuthConstants.IDKey, authResponse.id_token);

                    dispatch(setLoggedInUser(
                        authResponse.access_token,
                        id.oid,
                        {
                            email: id?.email,
                            displayName: id?.name
                        }
                    ));

                    history.push(nextRequestUrl || '/');
                } else {
                    setErrorMsg('Login failed.');
                }
                setErrorMsg(JSON.stringify(id));
            } catch (e) {
                setErrorMsg(e);
            }
        },
        failureCallback(reason) {
            setLoading(false);
            setErrorMsg(reason);
        }
    });
}, []);

return loading ? (
    <GlobalLoader isLoading />
) : (
    <TeamsAuthWrapper>
        <STitle>
            <FormattedMessage id="teams.login.title"/>
        </STitle>
        <Button onClick={onLoginClick}>
            <FormattedMessage id="teams.login" />
        </Button>
        <SFooter>
            <FormattedMessage id="teams.login.contact" />
            <span dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: 'teams.login.link' }, {
                    a: (...chunks) => `<a href="business.legalcluster.com/contact">${chunks}</span>`
                })}}
            />
        </SFooter>
        {errorMsg && <p>{errorMsg}</p>}
    </TeamsAuthWrapper>
);
};
