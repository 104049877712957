import jwtDecode from 'jwt-decode';

import { history } from '../history';
import * as contextActions from '../actions/contextActions';
import store from '../store';
import { instanceConfig } from '../instance';
import { localStorage } from './storage';

export interface AuthState {
    csrf: string;
    nextRequestUrl: string;
}

export enum AuthConstants {
    RequestAuthRedirect = '/login?nextRequestUrl=',
    TeamsRequestAuthRedirect = '/teamsauth?nextRequestUrl=',
    RequestAuthRoute = '/login',
    RequestAuthEndRoute = '/auth-callback',
    TeamsRequestAuthRoute = '/teamsauth',
    SessionKey = 'lcrUserLoggedIn',
    ACKey = 'lcrAc',
    IDKey = 'lcrId',
    PasswordResetMode = 'lcrPwdReset',
    CSRFState = 'lcrState'
}

export enum MsalErrorCode {
    PasswordResetRequested = 'AADB2C90118',
    PasswordResetCancelled = 'AADB2C90091'
}

export const getLoginReturnUrl = (returnUrl?: string): string => {
    return encodeURIComponent(returnUrl || `${window.location.pathname}${window.location.search}`);
};
export const getLoginPath = (returnUrl?: string, extraParams?: string): string => {
    const pathBase = IS_TEAMS_INSTANCE ? AuthConstants.TeamsRequestAuthRedirect : AuthConstants.RequestAuthRedirect;
    return `${pathBase}${getLoginReturnUrl(returnUrl)}${extraParams || ''}`;
};

export const redirectToLogin = (returnUrl?: string) => {
    if (!(window.location.pathname === AuthConstants.RequestAuthRoute || window.location.pathname === AuthConstants.TeamsRequestAuthRoute)) {
        store.dispatch(contextActions.requestTokenRenewal());
        history.push(getLoginPath(returnUrl));
    }
};

export const uuidv4 = () => {
    const crypto = window.crypto || window.msCrypto;
    return (`${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`).replace(/[018]/g, (c: any) => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
};

export const getHashParameters = (hash: string): { [key: string]: string } => {
    const hashParams = {};
    (hash || location.hash).substr(1).split('&').forEach((item) => {
        const s = item.split('='),
            k = s[0],
            v = s[1] && decodeURIComponent(s[1]);
        hashParams[k] = v;
    });
    return hashParams;
};

interface UserId {
    exp: number;
    nbf: number;
    ver: string;
    iss: string;
    sub: string;
    aud: string;
    nonce: string;
    iat: string;
    auth_time: string;
    oid: string;
    email: string;
    name: string;
    given_name: string;
    family_name: string;
    tid: string;
    at_hash: string;
}

export const getContextUserId = (): UserId => {
    const storedIdToken = localStorage.getItem(AuthConstants.IDKey);
    if (!storedIdToken) {
        return undefined;
    } else {
        try {
            return jwtDecode(storedIdToken) as UserId;
        } catch (e) {
            return undefined;
        }
    }
};

export const isIdTokenExpired = (userId: UserId): boolean => {
    if (!userId?.exp) {
        return undefined;
    }

    return (userId.exp * 1000) < Date.now();
};
