import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { IDropdownOption } from '@fluentui/react';
import { geocodeByAddress } from 'react-places-autocomplete';
import { useDispatch } from 'react-redux';

import { colorStack } from '../../../../styleHelpers/colors';
import { cardsShadow } from '../../../../styleHelpers/mixins/shadow';
import { Delete } from '../../Icons';
import { fontSize } from '../../../../styleHelpers/fontSizes';
import { TextComponent } from '../../Inputs/TextComponent';
import { Button } from '../../Buttons/Button';
import { GoogleAddressEditor, IAddressObject } from '../../GoogleAddress/GoogleAddressEditor';
import { SelectInput } from '../../SelectInput/SelectInput';
import { IAddressGlobal, IContextList, IProfileType, IReferencial, Referentials } from '../../../../entities/IGlobal';
import { getReferentials } from '../../../../actions/globalActions';
import { newUpdateCompany } from '../../../../actions/legalEntitiesActions';
import { AlertType } from '../../../../entities/IAlert';
import { useAlert } from '../../../../tools/hooks';
import { IValue } from '../../../../entities/IPickers';

type GetReferentials = ReturnType<typeof getReferentials>;
type NewUpdateCompany = ReturnType<typeof newUpdateCompany>;

const Wrapper = styled.div`
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    background: ${colorStack.white};
    ${cardsShadow()};
    padding: 1rem;
    z-index: 99;
`;

const CloseForm = styled(Delete)`
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
`;

const TopText = styled.div`
    font-size: ${fontSize[13]};
    color: ${colorStack.label};
    margin: 0 0 1rem 0;
`;

const FormRow = styled.div`
    display: flex;
    margin: 0 0 1rem 0;
    flex-direction: column;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Label = styled.div`
    font-size: ${fontSize[12]};
    color: ${colorStack.label};
    font-weight: 400;
    margin-bottom: 18px;
    span {
        display: inline-flex;
        align-items: center;
    }
`;

interface IProps {
    isVisible: boolean;
    initialData?: {
        name: string;
    }
    closeHandler();
    returnData(e: React.MouseEvent<HTMLButtonElement>, elem: IValue, isRemove?: boolean);
}

enum ReferentialsAliasType {
    StakeAliasTypeRcs = 'Stake.AliasType.Rcs'
}

export const NewCompanyForm: FC<IProps> = props => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const addAlert = useAlert();
    const [name, setName] = useState<string>('');
    const [addressObj, setAddressObj] = useState<IAddressGlobal>(undefined);
    const [entityLegalForm, setEntityLegalForm] = useState<IReferencial[]>(undefined);
    const [aliasType, setAliasType] = useState<IReferencial[]>(undefined);
    const [legal, setLegal] = useState<IReferencial>(undefined);
    const [rcs, setRcs] = useState<string>(undefined);
    const [creatingCompany, setCreatingCompany] = useState<boolean>(false);
    const [legalobj, setLegalObj] = useState<{ id: string, Name: string, IsDeleted: boolean }>(undefined);
    const [isUseInitialName, setIsUseInitialName] = useState<boolean>(false);

    useEffect(() => {
        !isUseInitialName && props.initialData.name && setName(props.initialData.name);
    }, [props.initialData.name, isUseInitialName]);

    useEffect(() => {
        if (legal) {
            setLegalObj({
                id: legal.id,
                Name: legal.name,
                IsDeleted: false
            });
        } else {
            setLegalObj(undefined);
        }
    }, [legal]);

    useEffect(() => {
        dispatch<GetReferentials>(getReferentials('', Referentials.LegalForm, IContextList.LegalEntity)).then(response => {
            setEntityLegalForm(response);
        });
        dispatch<GetReferentials>(getReferentials('', Referentials.AliasType, IContextList.Corporate)).then(response => {
            setAliasType(response);
        });
    }, []);

    const changeEntityRcsValue = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setRcs(e.target.value);
    }, []);

    const createNewCompany = useCallback(() => {
        setIsUseInitialName(false);
        setCreatingCompany(true);
        const newEntity = {
            name: name,
            address: addressObj,
            registrations: [{
                aliasType: aliasType?.find(e => e.Key === ReferentialsAliasType.StakeAliasTypeRcs),
                aliasValue: rcs,
                date: new Date()
            }],
            legalForm: legalobj
        };
        dispatch<NewUpdateCompany>(newUpdateCompany(newEntity)).then(res => {
            props.returnData(undefined, {
                key: res.id,
                text: res.name,
                data: {...res, avatarType: IProfileType.Company, type: IProfileType.Company}
            });
            addAlert(<FormattedMessage id="searchInput.alert.company" />, AlertType.Success);
            setCreatingCompany(false);
            props.closeHandler();
        });
    }, [props.returnData, name, addressObj, rcs, aliasType]);

    const changeAddress = useCallback((value: IAddressObject | string) => {
        const valueObject = typeof value === 'string' ? ({
            number: '',
            street: '',
            city: value,
            state: '',
            country: '',
            countryShort: '',
            zipCode: ''
        }) : ({
            number: value?.street_number || '',
            street: (value?.route || value?.neighborhood) || '',
            city: value?.locality || '',
            state: value?.administrative_area_level_1 || '',
            country: value?.country || '',
            countryShort: addressObj.CountryShort || '',
            zipCode: value?.postal_code || ''
        });
        setAddressObj(valueObject);
    }, [addressObj]);

    const selectAddress = useCallback((address: any) => {
        geocodeByAddress(address)
            .then(results => {
                const addressObject: IAddressObject = {};
                const componentForm = {
                    street_number: 'short_name',
                    route: 'long_name',
                    neighborhood: 'long_name',
                    locality: 'long_name',
                    administrative_area_level_1: 'short_name',
                    country: 'long_name',
                    postal_code: 'short_name'
                };
                for (const value of results[0].address_components) {
                    let addressType = value.types[0];
                    if (componentForm[addressType]) {
                        if (addressType === 'country') {
                            setAddressObj(currentState => ({
                                ...currentState,
                                CountryShort: value.short_name
                            }));
                        }
                        addressObject[addressType] = value[componentForm[addressType]];
                    }
                }
                changeAddress(addressObject);
            });

    }, [changeAddress, addressObj]);

    const changeName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        setName(text);
        setIsUseInitialName(true);
    }, [setName]);

    const changeSelect = useCallback((el: IReferencial) => {
        setLegal(el);
    }, []);

    return (
        <>
            {props.isVisible &&
                <Wrapper>
                    <CloseForm onClick={props.closeHandler} width={24} height={24} />
                    <TopText><FormattedMessage id="searchInput.topText.company" /></TopText>
                    <FormRow>
                        <TextComponent
                            type="Input"
                            label={<FormattedMessage id="searchInput.form.leName" />}
                            required
                            value={name}
                            onChange={changeName}
                        />
                    </FormRow>
                    <FormRow>
                        <Label>
                            <FormattedMessage id="searchInput.form.leAddress" />
                        </Label>
                        <GoogleAddressEditor
                            onChangeAddress={(newValue: string) => changeAddress(newValue.replace(/^\s+/g, ''))}
                            onSelectAddress={selectAddress}
                            address={addressObj}
                        />
                    </FormRow>
                    <FormRow>
                        <Label>
                            <FormattedMessage id="searchInput.form.legalForm" />
                        </Label>
                        <SelectInput
                            selectedKey={legal?.id}
                            placeholder={intl.formatMessage({ id: 'global.selectValue' })}
                            onChange={(e, option: IDropdownOption) => changeSelect(option.data)}
                            options={entityLegalForm?.map((elem) => ({
                                key: elem.id,
                                text: elem.name,
                                data: elem
                            }))}
                        />
                    </FormRow>
                    <FormRow>
                        <TextComponent
                            type="Input"
                            label={<FormattedMessage id="searchInput.form.rcs" />}
                            value={rcs}
                            onChange={changeEntityRcsValue}
                        />
                    </FormRow>
                    <ButtonWrapper>
                        <Button onClick={createNewCompany} disabled={!name || creatingCompany} type="button"><FormattedMessage id="global.create" /></Button>
                    </ButtonWrapper>
                </Wrapper>
            }
        </>
    );
};