import React, { FC } from 'react';
import { Icon } from '@fluentui/react';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';

const generalIcon = (iconName: string) => <Icon iconName={iconName}/>;
export const brandIcon = (iconName: string) => <Icon {...getFileTypeIconProps({ extension: iconName, size: 20, imageFileType: 'png' }) } />;

const iconClassName = {
    'html': generalIcon('FileCode'),
    'plain': generalIcon('TextDocument'),
    'bmp': generalIcon('FileImage'),
    'gif': generalIcon('FileImage'),
    'jpeg': generalIcon('FileImage'),
    'jpg': generalIcon('FileImage'),
    'png': generalIcon('FileImage'),
    'svg': generalIcon('FileImage'),
    'pdf': generalIcon('PDF'),
    'accdb': brandIcon('accdb'),
    'csv': brandIcon('csv'),
    'docx': brandIcon('docx'),
    'doc': brandIcon('doc'),
    'dotx': brandIcon('dotx'),
    'mpp': brandIcon('mpp'),
    'odp': brandIcon('odp'),
    'ods': brandIcon('ods'),
    'odt': brandIcon('odt'),
    'one': brandIcon('one'),
    'potx': brandIcon('potx'),
    'ppsx': brandIcon('ppsx'),
    'pptx': brandIcon('pptx'),
    'pub': brandIcon('pub'),
    'vsdx': brandIcon('vsdx'),
    'xls': brandIcon('xls'),
    'xlsx': brandIcon('xlsx'),
    'xltx': brandIcon('xltx')
};

export const FileIcon: FC<{ mimeType: any }> = ({ mimeType }) => {
    const icon = iconClassName[mimeType];
    return icon || <Icon iconName="OpenFile"/>;
};