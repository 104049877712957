import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-light-svg-icons';

import { IState } from '../../../reducers';
import { IContextReducer } from '../../../reducers/contextReducer';
import { Size } from '../../../styleHelpers/sizes';
import { media } from '../../../styleHelpers/breakpoint';
import { colorStack } from '../../../styleHelpers/colors';
import { DesktopNavMenu } from '../../NavMenu/DesktopNavMenu';
import { Button } from '../../Common/Buttons/Button';
import { LcLogo } from '../../Common/CutomIcons/LcLogo';

const TopBarWrapper = styled.div`
    background: ${colorStack.white};
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 99998;
    height: ${Size.TopBarHeightMobile}px;
    ${media.desktop`
        height: ${Size.TopBarHeightDesktop}px;
    `}
`;

const Content = styled.div`
    height: ${Size.TopBarHeightMobile}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${media.desktop`
        height: ${Size.TopBarHeightDesktop}px;
    `}
`;

const Logo = styled(Link)`
    display: none;
    ${media.desktop`
        display: block;
        margin-left: 1.1rem;
        > img {
            height: 2rem;
        }
    `}
`;

const ClientLogo = styled.img`
    display: block;
    margin-right: 1rem;
    height: 2rem;
`;

const MobileMenu = styled(Button)`
    ${media.desktop`
        display: none;
    `}
`;

interface IProps {
    onMobileMenuClick();
}

export const TopBar: FC<IProps> = ({ onMobileMenuClick }) => {
    const { isLoggedIn } = useSelector<IState, IContextReducer>(state => state.context);
    const isClusterContext = location.pathname.split('/').includes('cluster');
    const isOrgContext = location.pathname.split('/').includes('orgs');

    const isVisible = useMemo(() => {
        return true;

        // Removes top bar on Teams app. Initially created for MS validation.
        // Disabled due to the deployment of the app to BYCN.
        // let visibility = true;
        //
        // if (IS_TEAMS_INSTANCE) {
        //     if (isClusterContext || isOrgContext) {
        //         visibility = false;
        //     }
        // }
        // return visibility;
    }, [IS_TEAMS_INSTANCE, isClusterContext]);

    return (
        <>
            {isVisible &&
                <TopBarWrapper>
                    <Content>
                        <MobileMenu bgColor="transparent" borderColor="transparent" fontColor={colorStack.middleBlue} onClick={onMobileMenuClick}>
                            <FontAwesomeIcon icon={faBars} size="lg" />
                        </MobileMenu>
                        <Logo to="/">
                            <LcLogo width={32} />
                        </Logo>
                        {isLoggedIn && (
                            <DesktopNavMenu />
                        )}
                        {CONFIG.mainConfig?.clientLogo && (
                            <ClientLogo src={CONFIG.mainConfig.clientLogo} />
                        )}
                    </Content>
                </TopBarWrapper>
            }
        </>
    );
};
