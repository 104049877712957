import React, { FC, useCallback, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';

const SWrapper = styled.div<{disabled: boolean}>`

    [type="radio"] {
        position: absolute;
        left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label
    {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        display: inline-block;
        color: ${colorStack.content};
        ${({ disabled }) => disabled && css`
            color: ${colorStack.disabled};
        `}
        font-size: ${fontSize[16]};
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 19px;
        height: 19px;
        border: 1px solid ${colorStack.ligthGrey};
        border-radius: 100%;
        background: ${colorStack.white}
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 13px;
        height: 13px;
        background: ${colorStack.middleBlue};
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 100%;
        transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
        ${({ disabled }) => disabled && css`
        color: ${colorStack.disabled};
    `}
    }
    [type="radio"]:checked + label:after {
        opacity: 1;
        transform: scale(1);
        ${({ disabled }) => disabled && css`
            background-color: ${colorStack.disabled};
        `}
    }
`;

interface IProps {
    label: string | ReactNode;
    name?: string,
    value?: string | boolean,
    checked?: boolean;
    disabled?: boolean;
    testingDataAttr?: string;
    onClick?(e: React.MouseEvent<HTMLButtonElement>);
}

export const RadioButton: FC<IProps> = props => {

    const onClickHandler = useCallback((e) => {
        if (!props.disabled) {
            props.onClick?.(e);
        }
    }, [props.onClick, props.disabled]);

    return (
        <SWrapper disabled={props.disabled}
            onClick={onClickHandler}
        >
            <input
                type="radio"
                name={props.name}
                value={props.value && props.value.toString()}
                checked={props.checked}
            />
            <label data-lc={props.testingDataAttr}>{props.label}</label>
        </SWrapper>
    );
};
