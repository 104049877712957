import React, { FC, useEffect, useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

import { IClusterStakeProperty } from '../../../../../entities/IClusterStake';
import { colorStack } from '../../../../../styleHelpers/colors';
import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { Switch } from '../../../../Common/Switch/Switch';
import { ErrorText } from './StakeStyles';
import { useNewDesignContext } from '../../../../NewDesignCommon/NewDesignSwitch/NewDesignSwitch';
import { RadioButton } from '../../../../Common/Inputs/RadioButton';
import { newDesignThemeSwitch } from '../../../../NewDesignCommon/NewDesignSwitch/newDesignTheme';

const Wrapper = styled.div`
    span {
        font-size: ${fontSize[13]};
        color: ${colorStack.darkBlue};
    }
    ${(props) => newDesignThemeSwitch(props, () =>
        css`
            span {
                font-size: ${fontSize[13]};
                font-family: ${props.theme.font.primary};
                font-weight: 400;
                color: ${colorStack.content};
            }
        `
    )}
`;

const RadioGroup = styled.div`
    padding-top: 12px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    & > :first-child {
        margin-right: 60px;
    }
`;

interface IBooleanContentProps {
    data: IClusterStakeProperty;
    editStake: boolean;
    propertieId: string;
    error: string;
    setEditingData(propertie: IClusterStakeProperty);
}

export const BooleanContent: FC<IBooleanContentProps> = ({ error, data, editStake, propertieId, setEditingData }) => {
    // tslint:disable-next-line:no-null-keyword
    const [content, setContent] = useState<boolean | null>(null);
    const newDesign = useNewDesignContext();

    useEffect(() => {
        setContent(data.value);
    }, [data, editStake]);

    const changeState = useCallback((value) => {
        // tslint:disable-next-line:no-null-keyword
        setContent(state => value === state ? null : value);
        setEditingData({
            ...data,
            // tslint:disable-next-line:no-null-keyword
            value: value === data.value ? null : value,
            id: propertieId
        });
    }, [data, propertieId, setEditingData, content]);

    if (newDesign.active) {
        const yesMessage = <FormattedMessage id="global.yes" />;
        const noMessage = <FormattedMessage id="global.no" />;

        return (
            <Wrapper>
                {editStake ? (
                    <>
                        <RadioGroup>
                            <RadioButton
                                label={yesMessage}
                                checked={content === true ? true : false}
                                onClick={() => changeState(true)}
                                data-lc="js-lc-radio-true"
                            />
                            <RadioButton
                                label={noMessage}
                                checked={content === false ? true : false}
                                onClick={() => changeState(false)}
                                data-lc="js-lc-radio-false"
                            />
                        </RadioGroup>
                        {error && <ErrorText>{error}</ErrorText>}
                    </>
                ) : (
                   <>
                        {content === true && yesMessage}
                        {content === false && noMessage}
                   </>
                )}
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            {editStake ? (
                <>
                    <Switch state={content} changeHandler={changeState} showText customActiveColor={colorStack.middleGreen} allowNoValue />
                    {error &&
                        <ErrorText>{error}</ErrorText>
                    }
                </>
            ) : (
                <Switch state={content} changeHandler={changeState} showText onlyView customActiveColor={colorStack.middleGreen} allowNoValue />
            )}
        </Wrapper>
    );
};