import { EUserPrivacy } from './IRoleManager';

export interface IModuleMember {
    role: number | string;
    isDeleted: boolean;
    firstName: string;
    lastName: string;
    jobTitle: string;
    department: string;
    picture: string;
    cabinet: string;
    id: string;
}

export interface IModuleRole {
    role: number;
    roleName: string;
}

export enum PrincipalType {
    Personal = 0,
    Group = 1
}

export interface IRole {
    id: string;
    name: string;
    resourceType: number;
    actions: string[];
    childId: string;
}

export interface IAvailableRole {
    role: number;
    roleName: string;
    id: string;
}

export enum PrincipalTypeRole {
    Group = 'Group',
    Personal = 'Personal'
}

export interface IPrincipal {
    principalType: PrincipalTypeRole;
    privacy?: EUserPrivacy;
    id: string;
    childId: string;
    childName: string;
    picture: string;
    type: PrincipalType;
}

export interface IPrincipal {
    id: string;
    childId: string;
    childName: string;
    picture: string;
    roleName: string;
    roleId: string;
}
export interface IAssignedPrincipal {
    principals: IPrincipal[];
    permissions: string[];
}

export interface IResource {
    id: string;
    resourceType: number;
    childId: string;
    childName: string;
    canRequestAccess: boolean;
    isInvitable: boolean;
}

export interface IAssignedRole {
    id: string;
    principal: IPrincipal;
    resource: IResource;
    role: IRole;
    isDeleted: boolean;
}

export interface IGetInvitation {
    date: string | Date;
    id: string;
    message?: string;
    receiver: {
        cabinet: string;
        department: string;
        email: string;
        firstName: string;
        id: string;
        jobTitle: string;
        lastName: string;
        picture: string;
    };
    resource: {
        canRequestAccess: boolean;
        childId: string;
        childName: string;
        id: string;
        isInvitable: string;
        metadata: {};
        parentId: string;
        resourceType: 'Organization' | 'Cluster';
    }
    role: string;
    sender: {
        cabinet: string;
        department: string;
        firstName: string;
        id: string;
        jobTitle: string;
        lastName: string;
        picture: string;
    };
    status: number;
}

export enum RoleType {
    RequestForProposal = 'RequestForProposal',
    Proposal = 'Proposal',
    Organization = 'Organization',
    Cluster = 'Cluster',
    ClusterChannel = 'ClusterChannel',
    Panel = 'Panel',
    Module = 'Module',
    Platform = 'Platform',
    OrganizationsModule = 'OrganizationsModule',
    ReferentialsModule = 'ReferentialsModule',
    PublicationsModule = 'PublicationsModule',
    UsersModule = 'UsersModule',
    TranslationsModule = 'TranslationsModule',
    Group = 'Group',
    Personal = 'Personal'
}

export enum ResourcePermissions {
    ManageMembers = 'Resource.Members.Manage',
    MembersView = 'Resource.Members.View',
    RequestAccess = 'Clusters.Privileges.RequestAccess',
    ResourceMembersJoin = 'Resource.Members.Join'
}

export enum DocumentsPermissions {
    CopyLink = 'Clusters.Documents.CopyLink',
    Download = 'Clusters.Documents.Download',
    AskApproval = 'Clusters.Documents.AskApproval',
    AskSignature = 'Clusters.Documents.AskSignature',
    EditViaWebBrowser = 'Clusters.Documents.EditViaWebBrowser',
    Rename = 'Clusters.Documents.Rename',
    Description = 'Clusters.Documents.Description',
    Copy = 'Clusters.Documents.Copy',
    Move = 'Clusters.Documents.Move',
    Restore = 'Clusters.Documents.Restore',
    Delete = 'Clusters.Channels.Delete',
    DeletePermenantly = 'Clusters.Documents.Delete.Permenantly',
    DeleteTemporarily = 'Clusters.Documents.Delete.Temporarily',
    View = 'Clusters.Documents.View',
    FileLibrary = 'Clusters.Documents.Upload.FileLibrary',
    Upload = 'Clusters.Documents.Upload'
}

export enum ClusterPermissionsBase {
    ViewCluster = 'Clusters.View',
    ClusterPreview = 'Cluster.Preview',
    EditCluster = 'Clusters.Edit',
    SetOrganizationalData = 'Clusters.Organization.Data.Set',
    ViewOrganizationData = 'Clusters.Organization.Data.View',
    CreateChannel = 'Clusters.Channels.Create',
    CreatePrivateChannels = 'Clusters.PrivateChannels.Create',
    DeleteChannel = 'Clusters.Channels.Delete',
    UploadDocuments = 'Clusters.Documents.Upload',
    ResourceMembersRequestAccess = 'Resource.Members.RequestAccess',
    ViewDocuments = 'Clusters.Documents.View',
    UploadDocumentsInConversation = 'Clusters.Documents.Upload.Conversation',
    UploadDocumentsInFileLibrary = 'Clusters.Documents.Upload.FileLibrary',
    DeleteTemporarilyDocuments = 'Clusters.Documents.Delete.Temporarily',
    DeletePermenantlyDocuments = 'Clusters.Documents.Delete.Permenantly',
    ClusterDataExport = 'Cluster.Data.Export',
    ClustersEditInViewMode = 'Clusters.Edit.InViewMode',
    ConversationMessagesView = 'Teams.Channels.Conversations.Threads.Messages.View',
    ConversationMessagesCreate = 'Teams.Channels.Conversations.Threads.Messages.Create',
    ConversationMessagesDelete = 'Teams.Channels.Conversations.Threads.Messages.Delete',
    ClusterDuplicate = 'Cluster.Duplicate',
    ClusterLinksView = 'ClusterLinks.View',
    ClusterLinksEdit = 'ClusterLinks.Edit',
    ClusterDelete = 'Cluster.Delete',
    ClusterQuestionDelete = 'Cluster.Question.Delete'
}

export enum OrganizationPermissionsBase {
    ViewOrganization = 'Organizations.View',
    ViewClusters = 'Clusters.View.All',
    CreateCluster = 'Clusters.Create',
    ManageOrganization = 'Organizations.Manage',
    ReferentialsVisibility = 'Organizations.Referentials.ManageVisibility',
    ViewLinks = 'Organizations.Links.View',
    JoinPublicCluster = 'Clusters.Privileges.Join.Public',
    ClustersPrivilegesJoinSecret = 'Clusters.Privileges.Join.Secret',
    JoinAnyCluster = 'Clusters.Privileges.Join',
    OrganizationsAccountsView = 'Organizations.Accounts.View'
}

export enum PanelPermissions {
    ViewLawyer = 'Panels.Lawyers.View',
    ManageLawyer = 'Panels.Lawyers.Manage'
}

export enum PlatformPermissions {
    PlatformManagementView = 'Platform.Management.View',
    OrganizationsPrivilegesJoinAsOwner = 'Organizations.Privileges.JoinAsOwner',
    OrganizationsViewAll = 'Organizations.View.All',
    OrganizationsCreate = 'Organizations.Create',
    OrganizationsManageAll = 'Organizations.Manage.All',
    ResourceMembersManage = 'Resource.Members.Manage',
    ResourceMembersView = 'Resource.Members.View',
    GroupsCreate = 'Groups.Create',
    GroupsViewAll = 'Groups.View.All',
    ReferentialsView = 'Referentials.View',
    AssociationsView = 'Associations.View',
    AssociationsAdd = 'Associations.Add',
    AssociationsDelete = 'Associations.Delete',
    AssociationsEdit = 'Associations.Edit',
    SchoolView = 'School.View',
    SchoolAdd = 'School.Add',
    SchoolEdit = 'School.Edit',
    JurisdictionView = 'Jurisdiction.View',
    JurisdictionAdd = 'Jurisdiction.Add',
    JurisdictionEdit = 'Jurisdiction.Edit',
    ReferentialsClientManageItems = 'Referentials.Client.Manage.Items',
    ReferentialsClientManageLabel = 'Referentials.Client.Manage.Label',
    ReferentialsLegalEntityManage = 'Referentials.LegalEntity.Manage',
    ReferentialsLegalclusterManageItems = 'Referentials.Legalcluster.Manage.Items',
    ReferentialsLegalclusterManageLabel = 'Referentials.Legalcluster.Manage.Label',
    ReferentialsNormManageItems = 'Referentials.Norm.Manage.Items',
    ReferentialsNormManageLabel = 'Referentials.Norm.Manage.Label',
    TranslationsManage = 'Translations.Manage',
    UsefulLinksCreate = 'UsefulLinks.Create',
    UsefulLinksManageAll = 'UsefulLinks.Manage.All',
    UsersOnBehalfEdit = 'Users.OnBehalf.Edit',
    UserEditManager = 'User.Edit.Manager',
    UsersOnBehalfEditManager = 'Users.OnBehalf.Edit.Manager',
    UsersOnBehalfChangeEmail = 'Users.OnBehalf.ChangeEmail',
    LegalScoreOnBehalfRequest = 'LegalScore.OnBehalf.Request',
    InvitationsOnBehalfInvite = 'Invitations.OnBehalf.Invite',
    ArticlesView = 'Articles.View',
    ArticlesViewMine = 'Articles.View.Mine',
    ArticlesCreate = 'Articles.Create',
    ArticlesPublish = 'Articles.Publish',
    ArticlesPromote = 'Articles.Promote',
    ArticlesOnBehalfView = 'Articles.OnBehalf.View',
    ArticlesOnBehalfCreate = 'Articles.OnBehalf.Create',
    ArticlesOnBehalfDelete = 'Articles.OnBehalf.Delete',
    ArticlesOnBehalfPublish = 'Articles.OnBehalf.Publish',
    UsersDirectoryGlobal = 'Users.Directory.Global',
    UsersDirectoryMyNetwork = 'Users.Directory.MyNetwork',
    UsersOther = 'Users.Other',
    CompaniesAdd = 'Companies.Add',
    CompaniesView = 'Companies.View',
    CompaniesDataGridView = 'Companies.DataGrid.View',
    CompaniesDataGridEdit = 'Companies.DataGrid.Edit',
    UsefulLinksView = 'UsefulLinks.View',
    RFPCreateSimplified = 'RFP.Create.Simplified',
    RFPViewAll = 'RFP.View.All',
    LegalScoreRequest = 'LegalScore.Request',
    LegalScoreTrigger = 'LegalScore.Trigger',
    PlatformDirectoryView = 'Users.Search',
    PlatformEntitiesView = 'Companies.Search'
}

export type ClusterPermissions = ClusterPermissionsBase | ResourcePermissions;

export type OrganizationPermissions = OrganizationPermissionsBase | ResourcePermissions;