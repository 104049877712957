import React, { useEffect, useState, useCallback, FC } from 'react';
import styled, { css } from 'styled-components';
import { IDropdownOption } from '@fluentui/react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { IClusterStakeProperty, StakeDetailsSubType } from '../../../../../entities/IClusterStake';
import { getReferentials } from '../../../../../actions/globalActions';
import { colorStack } from '../../../../../styleHelpers/colors';
import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { ErrorText } from './StakeStyles';
import { SingleRadioItem } from '../../../../Common/Buttons/SingleRadioItem';
import { SelectInput } from '../../../../Common/SelectInput/SelectInput';

const Wrapper = styled.div<{ radioSelect: boolean }>`
    ${props => props.radioSelect ? css`
        display: flex;
    ` : css`
        > span {
            font-size: ${fontSize[13]};
            color: ${colorStack.darkBlue};
        }
    `}
`;

type GetReferentials = ReturnType<typeof getReferentials>;

interface ISingleSelectContentProps {
    data: IClusterStakeProperty;
    propertieId: string;
    error: string;
    editStake: boolean;
    setEditingData(propertie: IClusterStakeProperty);
}

export const SingleSelectContent: FC<ISingleSelectContentProps> = ({ error, data, editStake, propertieId, setEditingData }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [selectContent, setSelectContent] = useState<{ Name: string, id: string, Key: string }[]>(undefined);
    const [selectValue, setSelectValue] = useState<string>(data?.value?.value);
    const [selectedValueId, setSelectedValueId] = useState<string>(data?.value?.key);
    const referentialIdentity = data.referentialIdentity;
    const referentialContext = data.configuration?.referentialContext;
    const referentialType = data.configuration?.referentialType;

    const changeSelect = useCallback((dataObj: { id: string, Name: string, Key: string }, option?: any) => {
        setSelectValue(dataObj.Name);
        setSelectedValueId(dataObj.id);
        setEditingData({
            ...data,
            // tslint:disable-next-line:no-null-keyword
            value: dataObj.id !== 'empty' ? referentialIdentity === 'Custom' ? ({ id: dataObj.id, Name: dataObj.Name, IsDeleted: false }) : ({ key: dataObj.id, value: dataObj.Name, uniqueKey: dataObj.Key }) : null,
            id: propertieId
        });
    }, [selectContent, data, setEditingData, referentialIdentity, referentialContext, referentialType]);

    useEffect(() => {
        if (editStake) {
            const context = data.configuration?.referentialContext || (data.configuration as any)?.ReferentialContext;
            const type = data.configuration?.referentialType || (data.configuration as any)?.ReferentialType;
            setSelectValue(data?.value?.value || data?.configuration?.value?.Value || data?.configuration?.value?.value);
            setSelectedValueId(data?.value?.key || data?.configuration?.value?.Key || data?.configuration?.value?.key);
            if (context && type) {
                dispatch<GetReferentials>(getReferentials('', type, context)).then(response => {
                    if (!data.value && (data.configuration?.value?.Name && !data.configuration?.value?.id)) {
                        const defaultValue = response.find(elem => elem.name === data.configuration.value.Name);
                        if (defaultValue) {
                            changeSelect({ Name: defaultValue.name, id: defaultValue.id, Key: defaultValue.Key });
                        }
                    }
                    setSelectContent(response.map(elem => ({
                        Name: elem.name,
                        id: elem.id,
                        Key: elem.Key
                    })));
                });
            }
        }
    }, [editStake]);

    return (
        <Wrapper radioSelect={data.subType === StakeDetailsSubType.Radio}>
            {editStake ? (
                <>
                    {(selectContent && !data.subType) &&
                        <SelectInput
                            selectedKey={selectedValueId}
                            placeholder={intl.formatMessage({ id: 'global.selectValue' })}
                            onChange={(e, option: IDropdownOption) => changeSelect(option.data, option)}
                            options={[{
                                id: 'empty',
                                Name: intl.formatMessage({ id: 'global.selectValue' })
                            }, ...selectContent]?.map(elem => ({
                                key: elem.id,
                                text: elem.Name,
                                data: elem
                            }))}
                        />
                    }
                    {(selectContent && data.subType === StakeDetailsSubType.Radio) &&
                        selectContent?.map(item => (
                            <SingleRadioItem
                                key={item.id}
                                id={item.id}
                                name={item.Name}
                                selectedItemId={selectedValueId}
                                onSelect={changeSelect}
                            />
                        ))
                    }
                    {error &&
                        <ErrorText>{error}</ErrorText>
                    }
                </>
            ) : (
                <span>{selectValue}</span>
            )}
        </Wrapper>
    );
};