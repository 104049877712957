import queryString from 'query-string-for-all';

import { getSogePdfOrWordFullUrl } from '../tools/legalDocTools/sogeTools';
import * as ISoge from '../entities/ISoge';
import { IDocumentationStyle, ISGStylesRoot } from '../entities/LegalDoc/ISogeStyles';
import { ISogeVersionDetails, IIDocumentVersionPublishResult } from '../entities/LegalDoc/INormativeDocumentationVersion';
import { Lcid } from '../entities/ILanguage';
import * as actionTypes from './actionTypes/sogeTypes';
import { IState } from '../reducers';
import { action, downloadFile } from '../tools/reduxTools';
import { getReferentialIdentities, getFullReferentialElementsByIdentityId } from './adminActions';
import { INormReferential, IFullReferentialElement } from '../entities/IAdmin';
import { IFacetsSearchResponse } from '../entities/IEntity';
import { ISearchResponse } from '../entities/IGlobal';
import { TrackingType } from '../entities/IAnalytics';
import { Http } from '../tools/http';
import { ISingleArticleElem } from '../entities/AssetManagement/actions';

export const setSogeDocumentationLanguage = (lcid: Lcid) => ({
    type: actionTypes.SOGE_SET_DOC_LANG,
    lcid
});

export const getTableOfContent = (documentationId: string, versionId?: string, lcid: Lcid = Lcid.En) => action<Promise<ISoge.ITableOfContentsData>>(async (dispatch, getState, http) => {
    const url = !!versionId
        ? `/normative-documentations/${documentationId}/versions/${versionId}/table-of-contents`
        : `/normative-documentations/${documentationId}/table-of-contents`;

    dispatch({
        type: actionTypes.SOGE_SET_LOADING_NEW_DATA
    });
    const response: ISoge.ITableOfContentsData = await http.sogeApiGet(`${url}?lcid=${lcid}`)
        .then((tableOfContent: ISoge.ITableOfContentsData) => {
            dispatch({
                type: actionTypes.SOGE_SET_TABLE_OF_CONTENTS,
                tableOfContent,
                currentUniqueVersionId: tableOfContent.id
            });
            return tableOfContent;
        });

    return response;
});

export const reorderSingleElement = (documentationId: string, versionId: string, parentUniqueLevel: string, itemId: string, body: ISoge.IReorderElementsPayload) => action<Promise<ISoge.ITableOfContentsData>>((dispatch, getState, http) => {
    return http.sogeApiPut(`/normative-documentations/${documentationId}/versions/${versionId}/structure-levels/${parentUniqueLevel}/children/${itemId}/change-order`, body);
});

export const getStructure = (documentationId: string, levelId: string, versionId: string, lcid: Lcid = Lcid.En) => action<Promise<ISoge.IStructureResponse>, IState>(async (dispatch, getState, http) => {
    const structureLevel = await http.sogeApiGet(`/normative-documentations/${documentationId}/versions/${versionId}/structure-levels/${levelId}/contents?lcid=${lcid}`);
    return structureLevel;
});

export const getCodeArchives = (documentationId: string, lcid: Lcid = Lcid.En) => action<Promise<ISoge.INormativeDocumentationsArchiveResponse>>((dispatch, getState, http) => {
    return http.sogeApiGet(`/normative-documentations/${documentationId}/archives?lcid=${lcid}`);
});

export const getArticlesSearchResults = (body: ISoge.IArticlesSearchRequest, versionId: string, normativeDocumentationId: string, lcid: Lcid = Lcid.En, page: number = 0, size = 20) => action<Promise<IFacetsSearchResponse<ISoge.ISearchArticle, ISoge.IFacets>>>((dispatch, getState, http) => {
    const queryParams = queryString.stringify({ lcid, page, size });
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/search?${queryParams}`, body);
});

export const getArticlesWithFilters = (normativeDocumentationId: string) => action<Promise<ISingleArticleElem[]>>((dispatch, getState, http) => {
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/document-elements?bookType=LpaContract`, []);
});

export const getNormativeDocumentations = (organizationId: string) => action<Promise<string>>(async (dispatch, getState, http) => {
    const response = await http.sogeApiGet(`/organizations/${organizationId}/normative-documentations`);
    const normativeDocumentation = response?.[0];
    const documentationId = normativeDocumentation?.id;
    const normativeDocumentationName = normativeDocumentation?.name;

    documentationId && dispatch({
        type: actionTypes.SOGE_SET_NORMATIVE_DOC_ID,
        documentationId,
        normativeDocumentationName
    });

    return documentationId;
});

export const getSogeArticle = (versionId: string, documentationId: string, articleId: string, lcid: Lcid = Lcid.En) => action<Promise<ISoge.ISogeArticle>, IState>(async (dispatch, getState, http) => {
    const response: ISoge.ISogeArticle = await http.sogeApiGet(`/normative-documentations/${documentationId}/versions/${versionId}/articles/${articleId}?lcid=${lcid}`);
    return response;
});

export const getSogeTableOfContentSearchResult = (documentationId: string, versionId?: string, lcid: Lcid = Lcid.En, searchPhrase?: string) => action<Promise<ISoge.ITableOfContentsData>>(async (dispatch, getState, http) => {
    const response: ISoge.ITableOfContentsData = await http.sogeApiGet(`/normative-documentations/${documentationId}/versions/${versionId}/search-table-of-contents?lcid=${lcid}&searchPhrase=${searchPhrase}`);
    return response;
});

export const getDocumentationStyles = (normativeDocumentationId: string, uniqueVersionId: string) => action<Promise<ISGStylesRoot[]>>(async (dispatch, getState, http) => {
    const { soge } = getState();
    if (!soge.sgStylesContainer?.[uniqueVersionId]?.styles) {
        const response = (await http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${uniqueVersionId}/styles`) || []) as IDocumentationStyle[];
        const styles = response || [];

        dispatch({
            type: actionTypes.SOGE_SET_STYLES,
            styles,
            stylesVersionId: uniqueVersionId
        });
    }
    dispatch({
        type: actionTypes.SOGE_SET_CURRENT_UNIQUE_VERSION_ID,
        currentUniqueVersionId: uniqueVersionId
    });
});

export const setUniqueVersionId = (uniqueVersionId: string) => ({
    type: actionTypes.SOGE_SET_CURRENT_UNIQUE_VERSION_ID,
    currentUniqueVersionId: uniqueVersionId
});

export const getVersions = (documentationId: string, lcid?: Lcid) => action<Promise<ISoge.ITableOfContentsVersion[]>>((dispatch, getState, http) => {
    return http.sogeApiGet(`/normative-documentations/${documentationId}/versions${lcid ? `?lcid=${lcid}` : ''}`)
        .then((versions: ISoge.ITableOfContentsVersion[]) => {
            dispatch({
                type: actionTypes.SOGE_SET_STYLES_CONTAINER,
                versionIds: versions?.map(version => ({
                    versionId: version.versionId,
                    uniqueVersionId: version.id,
                    status: version.documentVersion.status
                })),
                lcid: lcid || Lcid.En
            });
            dispatch({
                type: actionTypes.SOGE_SET_ALL_VERSIONS,
                allVersions: versions
            });
            return versions;
        });
});

export const searchVersions = (documentationId: string, versionStatus: ISoge.VersionFilterStatus, query = '', page = 0, size = 25) => action<Promise<IFacetsSearchResponse<ISoge.ISgDocVersion>>>((dispatch, getState, http) => {
    return http.sogeApiPost(`/normative-documentations/${documentationId}/versions/search`, {
        query,
        page,
        size,
        versionStatus
    });
});

export const clearVersionCache = (documentationId: string, versionId: string) => action<Promise<void>>((dispatch, getState, http) => {
    return http.sogeApiDelete(`/normative-documentations/${documentationId}/versions/${versionId}/cache`);
});

export const getBookWithBaseArticles = (documentationId: string, uniqueVersionId: string, uniqueLevelId: string, lcid: Lcid = Lcid.En) => action<Promise<ISoge.IStructureLevelRootWithBaseArticles>>((dispatch, getState, http) => {
    return http.sogeApiGet(`/normative-documentations/${documentationId}/versions/${uniqueVersionId}/structure-levels/${uniqueLevelId}/with-base-articles?lcid=${lcid}`);
});

export const getFlattenTableOfContent = (documentationId: string, uniqueVersionId: string, lcid: Lcid = Lcid.En) => action<Promise<ISoge.IFlattenTableOfContent>>((dispatch, getState, http) => {
    return http.sogeApiGet(`/normative-documentations/${documentationId}/versions/${uniqueVersionId}/table-of-contents/flat?lcid=${lcid}`);
});

export const getBranches = (normativeDocumentationId: string, uniqueVersionId: string, branchType: ISoge.BranchFilterTypes = ISoge.BranchFilterTypes.None, query?: string, page?: number, size?: number) => action<Promise<IFacetsSearchResponse<ISoge.IBranchBookGroup>>>(async (dispatch, getState, http) => {
    const { userLanguage: lcid } = getState().language;
    const queryParams = queryString.stringify({ lcid });

    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions/${uniqueVersionId}/branches/search?${queryParams}`, {
        branchType,
        size: size || 50,
        page,
        query
    });
});

export const getBranchWithoutContent = (normativeDocumentationId: string, versionId: string, branchId: string) => action<Promise<ISoge.IFullBranch>>(async (dispatch, getState, http) => {
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}`);
});

export const getBranchesForElement = (normativeDocumentationId: string, elementCommonId: string, versionId?: string) => action<Promise<ISoge.IFullBranch[]>>((dispatch, getState, http) => {
    const query = versionId && queryString.stringify({ versionId });
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/branches/document-elements/${elementCommonId}?${query || ''}`);
});

export const getRelatedBranches = (normativeDocumentationId: string, versionId: string, branchId: string) => action<Promise<ISoge.IFullBranch[]>>((dispatch, getState, http) => {
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}/related-branches`);
});

export const getVersionOverview = (normativeDocumentationId: string, uniqueVersionId: string, lcid: Lcid) => action<Promise<ISoge.IVersionOverviewResponse>>((dispatch, getState, http) => {
    const query = queryString.stringify({ lcid });
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${uniqueVersionId}/overview?${query}`);
});

export const getVersionBranchesStatus = (normativeDocumentationId: string, versionId: string) => action<Promise<ISoge.StatusedBranches>>((dispatch, getState, http) => {
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/statused`);
});

export const getBranchVersionContent = (normativeDocumentationId: string, versionId: string, elementCommonId: string, lcid = Lcid.En) => action<Promise<ISoge.IBranchWithContents>>((dispatch, getState, http) => {
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/document-elements/${elementCommonId}/contents?lcid=${lcid}`);
});

export const getBranchContent = (normativeDocumentationId: string, versionId: string, elementCommonId: string, branchId: string) => action<Promise<ISoge.IBranchWithContents>>((dispatch, getState, http) => {
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}/document-elements/${elementCommonId}/contents`);
});

export const getOnlyBranchContent = (normativeDocumentationId: string, versionId: string, elementCommonId: string, branchId: string) => action<Promise<ISoge.IBranchWithContents>>((dispatch, getState, http) => {
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}/document-elements/${elementCommonId}/contents-structure`);
});

export const getArticleDetails = (normativeDocumentationId: string, versionId: string, branchId: string, articleCommonId: string) => action<Promise<ISoge.IArticleDetails>>((dispatch, getState, http) => {
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}/articles/${articleCommonId}/details`);
});

export const getArticleVersions = (normativeDocumentationId: string, versionId: string, articleCommonId: string, lcid: Lcid = Lcid.En) => action<Promise<ISoge.ISogeOtherArticleVersion[]>>((dispatch, getState, http) => {
    const query = queryString.stringify({ lcid });
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/articles/${articleCommonId}/versions?${query}`);
});

export const updateArticleDetails = (normativeDocumentationId: string, versionId: string, branchId: string, articleCommonId: string, articleDetails: ISoge.IArticleDetails, lcid = Lcid.En) => action<Promise<ISoge.IArticleDetails>>((dispatch, getState, http) => {
    const query = queryString.stringify({ lcid });
    return http.sogeApiPut(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}/articles/${articleCommonId}/details?${query}`, articleDetails);
});

export const getStructureLevelDetails = (normativeDocumentationId: string, versionId: string, branchId: string, commonLevelId: string) => action<Promise<ISoge.ILevelDetails>>((dispatch, getState, http) => {
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}/structure-levels/${commonLevelId}/details`);
});

export const updateStructureLevelDetails = (normativeDocumentationId: string, versionId: string, branchId: string, commonLevelId: string, levelDetails: ISoge.ILevelDetails) => action<Promise<ISoge.ILevelDetails>>((dispatch, getState, http) => {
    return http.sogeApiPut(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}/structure-levels/${commonLevelId}/details`, levelDetails);
});

export const getArticleMetadataSuggestions = (normativeDocumentationId: string, versionId: string, branchId: string, articleId: string, sectionName: string, query: string) => action<Promise<IFacetsSearchResponse<ISoge.ISgArticleTag>>>((dispatch, getState, http) => {
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}/elements/${articleId}/metadata/${sectionName}`, {
        query,
        maxItems: 10
    });
});

export const getVersionDetails = (normativeDocumentationId: string, versionId: string) => action<Promise<ISogeVersionDetails>>((dispatch, getState, http) => {
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/details`).then((versionDetails: ISogeVersionDetails) => {
        versionDetails && dispatch({
            type: actionTypes.SOGE_GET_VERSION_DETAILS,
            versionDetails,
            versionId
        });

        return versionDetails;
    });
});

export const getApprovalRequests = (normativeDocumentationId: string, versionId: string, filter?: ISoge.ApprovalRequestFilterTypes, query?: string, page?: number, size?: number) => action<Promise<IFacetsSearchResponse<ISoge.ISgApprovalRequest>>>((dispatch, getState, http) => {
    const queryParams = queryString.stringify({
        query,
        filter,
        page,
        size: 1000
    });
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/requests/approvals/search?${queryParams}`);
});

export const editNorm = (normativeDocumentationId: string, name: string) => action<Promise<void>>(async (dispatch, getState, http) => {
    await http.sogeApiPut(`/normative-documentations/${normativeDocumentationId}`, { name });
    dispatch({ type: actionTypes.SOGE_SET_NORMATIVE_DOC_NAME, name });
});

export const getDocumentElements = (normativeDocumentationId: string, versionId: string, lcid: Lcid) => action<Promise<ISoge.ISogeElement[]>>((dispatch, getState, http) => {
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/document-elements?lcid=${lcid}`);
});

export const searchDocumentElements = (normativeDocumentationId: string, versionId: string, query: string, lcid: Lcid, page = 0, size = 25) => action<Promise<ISoge.INormativeDocumentationsSearchResponse<ISoge.ISogeElement>>>((dispatch, getState, http) => {
    const queryParams = queryString.stringify({
        query,
        lcid,
        page,
        size
    });

    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/document-elements/search?${queryParams}`);
});

export const createVersion = (normativeDocumentationId: string, name: string, referenceVersionId: string) => action<Promise<ISoge.ISgDocVersion>>((dispatch, getState, http) => {
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions`, {
        name,
        referenceVersionId
    });
});

export const getAmendmentRequest = (normativeDocumentationId: string, versionId: string, elementCommonId: string, amendmentId: string, lcid: Lcid) => action<Promise<ISoge.IAmendmentRequest>>((dispatch, getState, http) => {
    const query = queryString.stringify({ elementCommonId, amendmentId, lcid });
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/requests/amendments?${query}`);
});

export const getAmendmentById = (normativeDocumentationId: string, amendmentId: string) => action<Promise<ISoge.IAmendmentRequest>>((dispatch, getState, http) => {
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/requests/amendments/${amendmentId}`);
});

export const createAmendmentRequest = (normativeDocumentationId: string, versionId: string, file: File, elementCommonId: string, structureLevelRootCommonLevelId: string, documentElementType: ISoge.DocumentElementType, lcid: Lcid, branchId: string) => action<Promise<ISoge.IAmendmentRequest>>((dispatch, getState, http) => {
    const form = new FormData();
    form.append('fileStream', file);
    const query = queryString.stringify({ elementCommonId, structureLevelRootCommonLevelId, documentElementType, lcid, branchId });

    return http.sogeApiPut(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/requests/amendments/documents/word-document?${query}`, form);
});

export const compareFeatureToMainBranch = (normativeDocumentationId: string, versionId: string, elementCommonId: string, targetVersionId: string, lcid?: Lcid, targetLcid?: Lcid, commonItemId?: string) => action<Promise<ISoge.IBranchCompare>>((dispatch, getState, http) => {
    const query = queryString.stringify({
        lcid,
        targetLcid,
        commonItemId
    });

    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/document-elements/${elementCommonId}/compare-feature/${targetVersionId}?${query}`);
});

export const compareMainToMainBranch = (normativeDocumentationId: string, versionId: string, sourceRootCommonId: string, targetVersionId: string, targetRootCommonId: string, lcid: Lcid, targetLcid: Lcid, commonItemId: string) => action<Promise<ISoge.IBranchCompare>>((dispatch, getState, http) => {
    const query = queryString.stringify({
        targetRootCommonId,
        lcid,
        targetLcid,
        commonItemId
    });

    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/document-elements/${sourceRootCommonId}/compare-main/${targetVersionId}?${query}`);
});

export const compareBetweenAnyTwoBranches = (normativeDocumentationId: string, versionId: string, elementCommonId: string, lhsFeatureBranchId: string, rhsFeatureBranchId: string) => action<Promise<ISoge.IBranchCompare>>((dispatch, getState, http) => {
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/document-elements/${elementCommonId}/compare/lhsFeatureBranchId/${lhsFeatureBranchId}/rhsFeatureBranchId/${rhsFeatureBranchId}`);
});

export const compareBetweenFeatureBranches = (normativeDocumentationId: string, versionId: string, branchId: string) => action<Promise<ISoge.IFeatureBranchCompare>>((dispatch, getState, http) => {
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}/comparable-branches`);
});

export const getAmendmentReport = (normativeDocumentationId: string, versionId: string, amendmentId: string, documentId: string) => action<Promise<ISoge.IValidationReport>>((dispatch, getState, http) => {
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/requests/amendments/${amendmentId}/documents/${documentId}/report`);
});

export const getAmendmentContent = (normativeDocumentationId: string, versionId: string, amendmentId: string, lcid: Lcid) => action<Promise<ISoge.IAmendmentRequestContents>>((dispatch, getState, http) => {
    const query = queryString.stringify({ lcid });
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/requests/amendments/${amendmentId}/contents?${query}`);
});

export const downloadWordDocument = (normativeDocumentationId: string, versionId: string, elementId: string, structureLevelRootCommonLevelId: string, lcid: Lcid, branchId: string, fileName: string, sgContext: 'consultation' | 'management', versionName: string) => action<Promise<Blob>, IState>((dispatch, getState) => {
    const { context, config, analytics } = getState();

    const event: [string, string, string, number] = ['Word document download', `Documentation ${sgContext}`, `${fileName} ${versionName || ''}`, 1];
    analytics.tracker[TrackingType.Smh]?.trackEvent(...event);
    analytics.tracker[TrackingType.Sg]?.trackEvent(...event);

    const documentUrl = getSogePdfOrWordFullUrl(
        config.legalDocApiUrl,
        normativeDocumentationId,
        versionId,
        elementId,
        structureLevelRootCommonLevelId,
        lcid,
        branchId,
        'word'
    );

    return downloadFile(documentUrl, fileName, context.token, config.subKey);
});

export const downloadPdfDocument = (normativeDocumentationId: string, versionId: string, elementId: string, structureLevelRootCommonLevelId: string, lcid: Lcid, branchId: string, fileName: string, sgContext: 'consultation' | 'management', versionName: string) => action<Promise<Blob>, IState>((dispatch, getState) => {
    const { context, config, analytics } = getState();

    const event: [string, string, string, number] = ['Pdf document download', `Documentation ${sgContext}`, `${fileName} ${versionName || ''}`, 1];
    analytics.tracker[TrackingType.Smh]?.trackEvent(...event);
    analytics.tracker[TrackingType.Sg]?.trackEvent(...event);

    const documentUrl = getSogePdfOrWordFullUrl(
        config.legalDocApiUrl,
        normativeDocumentationId,
        versionId,
        elementId,
        structureLevelRootCommonLevelId,
        lcid,
        branchId,
        'pdf'
    );

    return downloadFile(documentUrl, fileName, context.token, config.subKey);
});

export const downloadUploadedAmendment = (normativeDocumentationId: string, versionId: string, amendmentId: string, documentId: string, fileName: string) => action<Promise<Blob>, IState>((dispatch, getState) => {
    const { context, config } = getState();

    return downloadFile(`${config.legalDocApiUrl}/normative-documentations/${normativeDocumentationId}/versions/${versionId}/requests/amendments/${amendmentId}/documents/${documentId}`, fileName, context.token, config.subKey);
});

export const getVersionDatagridSettings = (normativeDocumentationId: string, lcid: Lcid) => action<Promise<ISoge.DataGridSettings>>((dispatch, getState, http) => {
    const query = queryString.stringify({ lcid });
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/datagrid/settings?${query}`);
});

export const getBranchDatagridSettings = (normativeDocumentationId: string, lcid: Lcid) => action<Promise<ISoge.DataGridSettings>>((dispatch, getState, http) => {
    const query = queryString.stringify({ lcid });
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/branches/datagrid/settings?${query}`);
});

export const searchDocumentsElements = (normativeDocumentationId: string, versionId: string, page: number, query: string, body: { [key: string]: { name: string, id: string }[] }, lcid: Lcid) => action<Promise<ISoge.SearchDocumentElementsSearch>>((dispatch, getState, http) => {
    let result = {};
    for (let key in body) {
        if (body[key].length) {
            result[key] = body[key];
        }
    }
    const queryParams = queryString.stringify({
        size: 1000,
        page,
        query,
        lcid
    });
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/document-elements/search-extended?${queryParams}`, result);
});

export const branchDatagridSearch = (normativeDocumentationId: string, versionId: string, page: number, query: string, filters: { [key: string]: { name: string, id: string }[] }) => action<Promise<ISoge.BranchSearch>>((dispatch, getState, http) => {
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/search-extended`, {
        size: 1000,
        page,
        query,
        filters: {}
    });
});

export const setVersionDatagridSetting = (normativeDocumentationId: string, lcid: Lcid, body: ISoge.DataGridSettings) => action<Promise<ISoge.DataGridSettings>>((dispatch, getState, http) => {
    const query = queryString.stringify({ lcid });
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/datagrid/settings?${query}`, body);
});

export const setBranchDatagridSettings = (normativeDocumentationId: string, lcid: Lcid, body: ISoge.DataGridSettings) => action<Promise<ISoge.DataGridSettings>>((dispatch, getState, http) => {
    const query = queryString.stringify({ lcid });
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/branches/datagrid/settings?${query}`, body);
});

export const getBranchesForAmendment = (normativeDocumentationId: string, versionId: string, elementCommonId: string, lcid: Lcid) => action<Promise<ISoge.IBranch[]>>((dispatch, getState, http) => {
    const query = queryString.stringify({ lcid });
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/document-elements/${elementCommonId}?${query}`);
});

export const getCheckStructureBetweenLanguages = (normativeDocumentationId: string, versionId: string, elementCommonId: string, lhsFeatureBranchId: string, rhsFeatureBranchId: string) => action<Promise<ISoge.IBranchConsistencyMatch>>((dispatch, getState, http) => {
   return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/document-elements/${elementCommonId}/validate/lhsFeatureBranchId/${lhsFeatureBranchId}/rhsFeatureBranchId/${rhsFeatureBranchId}`);
});

export const createBranch = (normativeDocumentationId: string, versionId: string, name: string, description: string, relatedItemCrossId: string, relatedItemType: ISoge.DocumentElementType, lcid: Lcid, structureLevelRootCommonLevelId: string) => action<Promise<ISoge.IFullBranch>>((dispatch, getState, http) => {
    const query = queryString.stringify({ lcid });
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches?${query}`, {
        name,
        description,
        relatedItemCrossId,
        relatedItemType,
        structureLevelRootCommonLevelId
    });
});

export const commitAmendment = (normativeDocumentationId: string, versionId: string, amendmentId: string, amendmentCommitId: string, branchId: string, lcid: Lcid) => action<Promise<ISoge.IBranch>>((dispatch, getState, http) => {
    const query = queryString.stringify({ branchId, lcid });
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/requests/amendments/${amendmentId}/commits/${amendmentCommitId}?${query}`);
});

export const searchAmendments = (normativeDocumentationId: string, versionId: string, query?: string, page?: number, size?: number, payload?: ISoge.IAmendmentSearchPayload) => action<Promise<IFacetsSearchResponse<ISoge.IListAmendment, ISoge.IAmendmentSearchFacets>>>((dispatch, getState, http) => {
    const queryParams = queryString.stringify({ query, size: size || 60, page });
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/requests/amendments/search?${queryParams}`, payload || {});
});

export const updateBranchDescription = (normativeDocumentationId: string, versionId: string, branchId: string, lcid: Lcid, description: string, isMinorChange: boolean) => action<Promise<ISoge.IFullBranch>>((dispatch, getState, http) => {
    const query = queryString.stringify({ lcid });
    return http.sogeApiPut(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}?${query}`, {
        description,
        isMinorChange
    });
});

export const updateAmendmentDescription = (normativeDocumentationId: string, versionId: string, amendmentId: string, lcid: Lcid, description: string) => action<Promise<ISoge.IAmendmentRequest>>((dispatch, getState, http) => {
    const query = queryString.stringify({ lcid });
    return http.sogeApiPut(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/requests/amendments/${amendmentId}?${query}`, {
        description
    });
});

export const createStructureLevel = (normativeDocumentationId: string, versionId: string, branchId: string, parentCommonLevelId: string, lcid: Lcid, newStructure: ISoge.ICreateStructure) => action<Promise<ISoge.IStructure>>((dispatch, getState, http) => {
    const query = queryString.stringify({ lcid });
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}/structure-levels/${parentCommonLevelId}?${query}`, newStructure);
});

export const createArticle = (normativeDocumentationId: string, versionId: string, branchId: string, parentCommonLevelId: string, lcid: Lcid, newArticle: ISoge.IArticleAdd) => action<Promise<ISoge.IStructure>>((dispatch, getState, http) => {
    const query = queryString.stringify({ lcid });
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}/structure-levels/${parentCommonLevelId}/articles?${query}`, newArticle);
});

export const deleteArticle = (normativeDocumentationId: string, versionId: string, branchId: string, articleId: string, lcid: Lcid, placeholder: boolean) => action<Promise<void>>((dispatch, getState, http) => {
    const query = queryString.stringify({ lcid, placeholder });
    return http.sogeApiDelete(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}/articles/${articleId}?${query}`);
});

export const downloadArticleAttachment = (normativeDocumentationId: string, versionId: string, articleId: string, articleVersionId: string, fileId: string, extension: string) => action<Promise<Blob>, IState>((dispatch, getState) => {
    const { context, config } = getState();
    const fileName = extension ? `Attachment.${extension}` : 'Attachment';

    return downloadFile(`${config.legalDocApiUrl}/normative-documentations/${normativeDocumentationId}/versions/${versionId}/articles/${articleId}/versions/${articleVersionId}/files/${fileId}`, fileName, context.token, config.subKey);
});

export const deleteLevel = (normativeDocumentationId: string, versionId: string, branchId: string, commonLevelId: string, lcid: Lcid, placeholder: boolean) => action<Promise<void>>((dispatch, getState, http) => {
    const query = queryString.stringify({ lcid, placeholder });
    return http.sogeApiDelete(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}/structure-levels/${commonLevelId}?${query}`);
});

export const renameStructureLevel = (normativeDocumentationId: string, versionId: string, branchId: string, commonLevelId: string, renameStructure: ISoge.ICreateStructure & { lcid: Lcid; id: string; }) => action<Promise<ISoge.IStructure>>((dispatch, getState, http) => {
    return http.sogeApiPut(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}/structure-levels/${commonLevelId}`, renameStructure);
});

export const renameStructureArticle = (normativeDocumentationId: string, versionId: string, branchId: string, lcid: Lcid, article: ISoge.IRenameArticle) => action<Promise<boolean>>((dispatch, getState, http) => {
    const { commonArticleId, newName, ignoreArticleNumberDupplicatedError } = article;
    return http.sogeApiPut(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}/articles/${commonArticleId}/rename?lcid=${lcid}`, {
        newName,
        ignoreArticleNumberDupplicatedError
    });
});

export const changeElementOrder = (normativeDocumentationId: string, versionId: string, branchId: string, parentCommonLevelId: string, childCommonId: string, previousElementCommonId: string, type: ISoge.StructureLevelType, lcid: Lcid) => action<Promise<void>>((dispatch, getState, http) => {
    return http.sogeApiPut(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}/structure-levels/${parentCommonLevelId}/children/${childCommonId}/change-order`, {
        previousElementCommonId,
        type,
        lcid
    });
});

export const moveStructureElement = (normativeDocumentationId: string, versionId: string, branchId: string, movedItemId: string, body: ISoge.IMoveElementPayload) => action<Promise<void>>((dispatch, getState, http) => {
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}/structure-levels/children/${movedItemId}/move`, body);
});

export const getMoveStructureLevelSummary = (normativeDocumentationId: string, versionId: string, branchId: string, movedItemId: string, body: ISoge.IMoveElementPayload) => action<Promise<ISoge.IMoveLevelSummary>>((dispatch, getState, http) => {
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}/structure-levels/children/${movedItemId}/move/auto-numbering`, body);
});

export const moveMultipleElements = (normativeDocumentationId: string, versionId: string, branchId: string, body: ISoge.IMoveMultipleElementsPayload) => action<Promise<void>>((dispatch, getState, http) => {
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}/structure-levels/move/multiple`, body);
});

export const getBranchReferenceTextValidators = (normativeDocumentationId: string, versionId: string, branchId: string, lcid: Lcid) => action<Promise<ISoge.IValidator[]>>((dispatch, getState, http) => {
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}/document-elements/management-details/validators`, {
        lcid
    });
});

export const createApproval = (normativeDocumentationId: string, versionId: string, approval: ISoge.ICreateApproval) => action<Promise<ISoge.IApprovalRequest>>((dispatch, getState, http) => {
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/requests/approvals`, {
        ...approval
    });
});

export const getApprovalRequest = (normativeDocumentationId: string, versionId: string, approvalRequestId: string) => action<Promise<ISoge.IApprovalRequest>>((dispatch, getState, http) => {
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/requests/approvals/${approvalRequestId}`);
});

export const getAllApprovals = (normativeDocumentationId: string, versionId: string) => action<Promise<unknown>>((dispatch, getState, http) => {
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/requests/approvals/search`);
});

export const editApprover = (normativeDocumentationId: string, versionId: string, approvalRequestId: string, approverId: string, isMandatory: boolean) => action<Promise<ISoge.IApprover>>((dispatch, getState, http) => {
    return http.sogeApiPut(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/requests/approvals/${approvalRequestId}/approvers/${approverId}`, {
        isMandatory
    });
});

export const deleteApprover = (normativeDocumentationId: string, versionId: string, approvalRequestId: string, approverId: string) => action<Promise<void>>((dispatch, getState, http) => {
    return http.sogeApiDelete(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/requests/approvals/${approvalRequestId}/approvers/${approverId}`);
});

export const addApprover = (normativeDocumentationId: string, versionId: string, approvalRequestId: string, newApprover: ISoge.IIValidatorPayload) => action<Promise<ISoge.IApprover>>((dispatch, getState, http) => {
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/requests/approvals/${approvalRequestId}/approvers`, {
        ...newApprover
    });
});

export const changeStatusSelectedApprover = (normativeDocumentationId: string, versionId: string, approvalRequestId: string, approverId: string, body: { status: number }) => action<Promise<unknown>>((dispatch, getState, http) => {
    return http.sogeApiPut(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/requests/approvals/${approvalRequestId}/approvers/${approverId}/change-status`, body);
});

export const completeApproval = (normativeDocumentationId: string, versionId: string, approvalRequestId: string) => action<Promise<unknown>>((dispatch, getState, http) => {
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/requests/approvals/${approvalRequestId}/complete`);
});

export const abandonBranch = (normativeDocumentationId: string, versionId: string, branchId: string, lcid: Lcid) => action<Promise<void>>((dispatch, getState, http) => {
    const queryParams = queryString.stringify({ lcid });
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}/abandon?${queryParams}`);
});

export const abandonApproval = (normativeDocumentationId: string, versionId: string, approvalId: string) => action<Promise<void>>((dispatch, getState, http) => {
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/requests/approvals/${approvalId}/abandon`);
});

export const uploadApproverAttachment = (normativeDocumentationId: string, versionId: string, approvalRequestId: string, approverId: string, files: File[]) => action<Promise<ISoge.IApprover>>((dispatch, getState, http) => {
    const form = new FormData();
    files.forEach(file => {
        form.append('files', file);
    });

    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/requests/approvals/${approvalRequestId}/approvers/${approverId}/attachments`, form);
});

export const uploadStaticBook = (normativeDocumentationId: string, versionId: string, structureLevelRootCommonLevelId: string, lcid: Lcid, file: File) => action<Promise<Blob>>(async (dispatch, getState, http) => {
    const { context, config } = getState();
    const queryParams = queryString.stringify({ structureLevelRootCommonLevelId, lcid });
    const form = new FormData();
    form.append('fileStream', file);

    return fetch(`${config.legalDocApiUrl}/normative-documentations/${normativeDocumentationId}/versions/${versionId}/books/contents/pdf-document/static?${queryParams}`, {
        body: form,
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${context.token}`,
            'Ocp-Apim-Subscription-Key': config.subKey
        }
    }).then(async (response) => {
        if (response.ok) {
            return response.blob();
        } else {
            return response.json();
        }
    });
});

export const uploadBookImage = (normativeDocumentationId: string, versionId: string, structureLevelRootId: string, file: File) => action<Promise<Blob>>(async (dispatch, getState, http) => {
    const { context, config } = getState();
    const form = new FormData();
    form.append('image', file);

    return fetch(`${config.legalDocApiUrl}/normative-documentations/${normativeDocumentationId}/versions/${versionId}/books/structure-levels/${structureLevelRootId}/image`, {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${context.token}`,
            'Ocp-Apim-Subscription-Key': config.subKey
        },
        body: form
    }).then(async (response) => {
        if (response.ok) {
            return response.blob();
        } else {
            return response.json();
        }
    });
});

export const downloadApproverAttachment = (normativeDocumentationId: string, versionId: string, approvalRequestId: string, approverId: string, attachmentId: string, fileName: string) => action<Promise<Blob>, IState>((dispatch, getState) => {
    const { context, config } = getState();

    return downloadFile(`${config.legalDocApiUrl}/normative-documentations/${normativeDocumentationId}/versions/${versionId}/requests/approvals/${approvalRequestId}/approvers/${approverId}/attachments/${attachmentId}`, fileName, context.token, config.subKey);
});

export const publishDocumentVersion = (normativeDocumentationId: string, versionId: string) => action<Promise<IIDocumentVersionPublishResult>>((dispatch, getState, http) => {
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}`);
});

export const getDocumentationVersionStatistics = (normativeDocumentationId: string, versionId: string, lcid: Lcid) => action<Promise<ISoge.IOverviewStatisticsList>>((dispatch, getState, http) => {
    const queryParams = queryString.stringify({ lcid });
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/statistics?${queryParams}`);
});

export const getSgGlobalReferentials = () => action<Promise<ISoge.IReferentialState>>(async dispatch => {
    const identities: INormReferential[] = (await dispatch(getReferentialIdentities('NormativeDocumentation'))) || [];
    const identitiesIds = identities.map(identity => identity.id);
    const identitiesItems: ISearchResponse<IFullReferentialElement>[] = (await Promise.all(identitiesIds.map(id => dispatch(getFullReferentialElementsByIdentityId(id))))) || [];
    let allItemsById = {};

    const identitiesById = identities.reduce((identitiesObject, identity, index) => {
        const items = (identitiesItems[index]?.items || []);
        const itemById = items.reduce((itemsObject, item) => ({
            ...itemsObject,
            [item.id]: item
        }), {});

        allItemsById = { ...allItemsById, ...itemById };

        return {
            ...identitiesObject,
            [identity.id]: {
                ...identity,
                itemsIds: items.map(item => item.id),
                itemById
            }
        };
    }, {});

    const identitiesByType = identities.reduce((identitiesObject, identity, index) => {
        return {
            ...identitiesObject,
            [identity.referentialType]: [...(identitiesObject[identity.referentialType] || []), identity]
        };
    }, {});

    const referentialState: ISoge.IReferentialState = {
        identitiesIds,
        identitiesById,
        identitiesByType,
        allItemsById,
        loaded: true
    };

    dispatch({
        type: actionTypes.SOGE_SET_REFERENTIALS,
        normReferentials: referentialState
    });

    return referentialState;
});

export const setCurrentVersionRealVersionId = (currentRealVersionId: string) => ({
    type: actionTypes.SET_CURRENT_REAL_VERSION_ID,
    currentRealVersionId
});

export const reactivateBranch = (normativeDocumentationId: string, versionId: string, branchId: string, lcid: Lcid) => action<Promise<void>>((dispatch, getState, http) => {
    const queryParams = queryString.stringify({ lcid });
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}/reactivate?${queryParams}`);
});

export const getSupportedStructureConfig = (normativeDocumentationId: string, versionId: string, branchId: string, commonLevelId: string, lcid: Lcid) => action<Promise<ISoge.IStructureLevelConfiguration>>((dispatch, getState, http) => {
    const queryParams = queryString.stringify({ lcid });
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}/structure-levels/${commonLevelId}/supported-configuration?${queryParams}`);
});

export const getBooks = (normativeDocumentationId: string, versionId: string, lcid: Lcid) => action<Promise<ISoge.IBooksResponse>>((dispatch, getState, http) => {
    const queryParams = queryString.stringify({ lcid });
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/books?${queryParams}`);
});

export const getBooksLanguageAndFormat = (normativeDocumentationId: string, versionId: string, lcid: Lcid) => action<Promise<ISoge.IBooksWithOtherVersionsResponse>>((dispatch, getState, http) => {
    const queryParams = queryString.stringify({ lcid });
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/books/with-matches?${queryParams}`);
});

export const editBook = (normativeDocumentationId: string, versionId: string, bookTitle: string, rootCommonLevelId: string, preamble: string, colorBlockHex: string, lcid: Lcid) => action<Promise<ISoge.ICreateBookResponse>>(async (dispatch, getState, http) => {
    const queryParams = queryString.stringify({ lcid });
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/books/edit?${queryParams}`, {
        rootCommonLevelId,
        bookTitle,
        preamble,
        colorBlockHex
    });
});

export const createBook = (normativeDocumentationId: string, versionId: string, bookTitle: string, preamble: string, colorBlockHex: string, lcid: Lcid, rootCommonLevelId?: string, bookLetter?: string) => action<Promise<ISoge.ICreateBookResponse>>(async (dispatch, getState, http) => {
    const queryParams = queryString.stringify({ lcid });
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/books/add?${queryParams}`, {
        bookTitle,
        preamble,
        colorBlockHex,
        rootCommonLevelId,
        bookLetter
    });
});

export const deletebook = (normativeDocumentationId: string, versionId: string, structureLevelRootCommonLevelId: string, lcid: Lcid, structureLevelRootId: string) => action<Promise<ISoge.ICreateBookResponse>>(async (dispatch, getState, http) => {
    const queryParams = queryString.stringify({ lcid, structureLevelRootId });
    return http.sogeApiDelete(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/books/${structureLevelRootCommonLevelId}?${queryParams}`);
});

export const reorderBooks = (normativeDocumentationId: string, versionId: string, lcid: Lcid, body: { commonLevelId: string, orderIndex: number }[]) => action<Promise<void>>(async (dispatch, getState, http) => {
    const queryParams = queryString.stringify({ lcid });
    return http.sogeApiPut(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/books/change-order?${queryParams}`, body);
});

export const getUnmatchedBooks = (normativeDocumentationId: string, versionId: string, lcid: Lcid) => action<Promise<ISoge.IUnmatchedBooksResponse[]>>(async (dispatch, getState, http) => {
    const queryParams = queryString.stringify({ lcid });
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/books/unmatched?${queryParams}`);
});

export const getBookTexts = (normativeDocumentationId: string, versionId: string, commonLevelId: string, lcid: Lcid) => action<Promise<ISoge.IBookTextsResponse>>((dispatch, getState, http) => {
    const queryParams = queryString.stringify({ lcid });
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/books/${commonLevelId}/texts?${queryParams}`);
});

export const getBookImage = (normativeDocumentationId: string, versionId: string, structureLevelRootId: string, fileId: string) => ((dispatch, getState, http: Http) => {
    const { context, config } = getState();
    return fetch(`${config.legalDocApiUrl}/normative-documentations/${normativeDocumentationId}/versions/${versionId}/books/structure-levels/${structureLevelRootId}/image/${fileId}`, {
        headers: {
            'Authorization': `Bearer ${context.token}`,
            'Ocp-Apim-Subscription-Key': config.subKey,
            'Content-Type': 'application/json'
        }
    }).then(res => {
        return res.blob();
    });
}) as any;

export const getAllTexts = (normativeDocumentationId: string, versionId: string, lcid: Lcid, page: number = 0, size: number = 50) => action<Promise<IFacetsSearchResponse<ISoge.IReferenceTextWithLevelAndBook>>>((dispatch, getState, http) => {
    const queryParams = queryString.stringify({ lcid, page, size });
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/books/texts?${queryParams}`);
});

export const getReferenceTextSearchResults = (normativeDocumentationId: string, versionId: string, lcid: Lcid, query: string = '', filters: ISoge.SearchFiltersType, page: number = 0, size: number = 20) => action<Promise<IFacetsSearchResponse<ISoge.ISearchReferenceText, ISoge.IFacets>>>((dispatch, getState, http) => {
    const queryParams = queryString.stringify({ lcid, page, size, query });
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/texts/search?${queryParams}`, {
        query,
        filters
    });
});

export const exportSearchDocument = (normativeDocumentationId: string, versionId: string, lcid: Lcid, query: string = '', filters: ISoge.SearchFiltersType, exportType: number, page: number = 0, size: number = 20) => action<Promise<IFacetsSearchResponse<ISoge.ISearchReferenceText, ISoge.IFacets>>>((dispatch, getState, http) => {
    const queryParams = queryString.stringify({ exportType, lcid, page, size });
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/texts/export?${queryParams}`, {
        query,
        filters
    });
});

export const exportArticleSearchDocument = (normativeDocumentationId: string, versionId: string, lcid: Lcid, query: string = '', filters: ISoge.SearchFiltersType, exportType: number, page: number = 0, size: number = 20) => action<Promise<IFacetsSearchResponse<ISoge.ISearchReferenceText, ISoge.IFacets>>>((dispatch, getState, http) => {
    const queryParams = queryString.stringify({ exportType, lcid, page, size });
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/articles/export?${queryParams}`, {
        query,
        filters
    });
});

export const downloadExportedPdfDocument = (normativeDocumentationId: string, versionId: string, fileName: string, FileId: string) => action<Promise<Blob>, IState>((dispatch, getState) => {
    const { context, config } = getState();
    const queryParams = queryString.stringify({ FileId });

    return downloadFile(`${config.legalDocApiUrl}/normative-documentations/${normativeDocumentationId}/versions/${versionId}/texts/export/pdf?${queryParams}`, fileName, context.token, config.subKey);
});

export const downloadExportedArticlePdfDocument = (normativeDocumentationId: string, versionId: string, fileName: string, FileId: string) => action<Promise<Blob>, IState>((dispatch, getState) => {
    const { context, config } = getState();
    const queryParams = queryString.stringify({ FileId });

    return downloadFile(`${config.legalDocApiUrl}/normative-documentations/${normativeDocumentationId}/versions/${versionId}/articles/export/pdf?${queryParams}`, fileName, context.token, config.subKey);
});

export const downloadExportedWordDocument = (normativeDocumentationId: string, versionId: string, fileName: string, FileId: string) => action<Promise<Blob>, IState>((dispatch, getState) => {
    const { context, config } = getState();
    const queryParams = queryString.stringify({ FileId });

    return downloadFile(`${config.legalDocApiUrl}/normative-documentations/${normativeDocumentationId}/versions/${versionId}/texts/export/docx?${queryParams}`, fileName, context.token, config.subKey);
});

export const downloadExportedArticleWordDocument = (normativeDocumentationId: string, versionId: string, fileName: string, FileId: string) => action<Promise<Blob>, IState>((dispatch, getState) => {
    const { context, config } = getState();
    const queryParams = queryString.stringify({ FileId });

    return downloadFile(`${config.legalDocApiUrl}/normative-documentations/${normativeDocumentationId}/versions/${versionId}/articles/export/docx?${queryParams}`, fileName, context.token, config.subKey);
});

export const getNormsConfiguration = (normativeDocumentationId: string) => action<Promise<ISoge.INormsConfiguration>>((dispatch, getState, http) => {
    dispatch({ type: actionTypes.NORMS_CONFIG_REQUESTED });
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/configuration`).then((response) => {
        dispatch({
            type: actionTypes.GET_NORM_CONFIG,
            normsConfiguration: response
        });
        return response;
    });
});

export const updateMasterReferentials = (id: string, body: string[]) => action<Promise<string>>((dispatch, getState, http) => {
    return http.sogeApiPut(`/normative-documentations/referentials/add-parent/${id}`, body);
});

export const removeMasterReferentialParent = (id: string, body: string[]) => action<Promise<string>>((dispatch, getState, http) => {
    return http.sogeApiPut(`/normative-documentations/referentials/remove-parent/${id}`, body);
});

export const getNewNumberSuggestion = (
    normativeDocumentationId: string,
    versionId: string,
    branchId: string,
    commonLevelId: string,
    lcid: Lcid,
    headerType?: string,
    previousElementCommonId?: string
) => action<Promise<string | ISoge.INumberingSuggestion>>((dispatch, getState, http) => {
    const queryParams = queryString.stringify({ lcid, previousElementCommonId });

    if (headerType) {
        return http.sogeApiGet<ISoge.INumberingSuggestion>(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}/structure-levels/${commonLevelId}/numbering/${headerType}?${queryParams}`);
    } else {
        return http.sogeApiGet<string>(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}/structure-levels/${commonLevelId}/articles/create/auto-numbering?${queryParams}`);
    }
});

export const moveBetweenBooks = (normativeDocumentationId: string, versionId: string, movedItemCommonId: string, payload: ISoge.IMoveBetweenBooksPayload) => action<Promise<void>>((dispatch, getState, http) => {
    return http.sogeApiPost(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/books/structure-levels/children/${movedItemCommonId}/move`, payload);
});

export const getBranchAuditTrail = (normativeDocumentationId: string, versionId: string, branchId: string) => action<Promise<ISoge.IAuditTrailResponse>>((dispatch, getState, http) => {
    return http.sogeApiGet(`/normative-documentations/${normativeDocumentationId}/versions/${versionId}/branches/${branchId}/audit-trail`);
});
