import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ChunkLoader } from '../Common/ChunkLoader';

export const NullPage: React.SFC<RouteComponentProps> = (props) => {
    useEffect(() => {
        props.history.replace('/');
    }, []);

    return (
        <ChunkLoader />
    );
};
