import React, { useEffect, useState, useCallback, FC } from 'react';
import styled from 'styled-components';
import { faBuilding } from '@fortawesome/pro-duotone-svg-icons';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { colorStack } from '../../../styleHelpers/colors';
import boxShadow from '../../../styleHelpers/mixins/shadow';
import { addressFormatterGlobal } from '../../../tools/addressFormatter';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { IMainInformation, MainInformationEditField } from '../../../entities/CLE/ICLE';
import { ExternalLink } from '../../Common/ExternalLink';
import { getReferentials } from '../../../actions/globalActions';
import { StakesWrapper } from '../../Common/CLEComponents/StakesWrapper';
import { StakeDetailsType, PropertieKey, StakeDetailsSubType } from '../../../entities/IClusterStake';
import ImageCropper from '../../Common/ImageCropper/ImageCropper';
import { SingleRegistrationRow } from './SingleRegistrationRow';
import { referentialConfiguration } from '../../../tools/selectConfiguration';
import { toValidLink } from '../../../tools/links';
import { IRefferentialObj, IRegistrations } from '../../../entities/ILegalEntities';
import { objectKeysToCamelCase } from '../../../tools/objectTools';
import { Referentials, IContextList, IReferencial } from '../../../entities/IGlobal';
import { TranslateReferentials } from '../../../tools/referentials';

type GetReferentials = ReturnType<typeof getReferentials>;

const Wrapper = styled.div`
    background: ${colorStack.white};
    border-radius: 4px;
    padding: 1rem;
    ${boxShadow()};
    margin-bottom: 1rem;
    display: flex;
    word-wrap: break-word;
`;

const PhotoWrapper = styled.div`
    width: 128px;
    border-radius: 4px;
    margin: 0 1rem 0 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
        width: 100%;
        max-height: 100%;
    }
    svg {
        color: ${colorStack.disabled};
    }
`;

const Address = styled.div`
    ${fontSizeAndHeight[16]};
    color: ${colorStack.darkBlue};
    margin: 0 0 1rem 0;
    overflow: hidden;
    width: 100%;
`;

const Meta = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 250px;
    margin: 0 1rem 1rem 0;
    span {
        color: ${colorStack.darkBlue};
        ${fontSizeAndHeight[16]};
    }
`;

const Label = styled.div`
    ${fontSizeAndHeight[13]};
    color: ${colorStack.disabled};
    margin: 0 0 .5rem 0;
`;

const MetasBox = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Name = styled.div`
    color: ${colorStack.darkBlue};
    ${fontSizeAndHeight[16]};
    font-weight: 700;
    margin-bottom: 1rem;
`;

const Content = styled.div`
    width: calc(100% - 128px - 1rem);
`;

const Logo = styled.div`
    width: 128px;
    height: 128px;
    border: 1px solid ${colorStack.ligthGrey};
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    justify-content: center;
    margin: 0 0 1rem 0;
    svg {
        margin-bottom: .5rem;
    }
    img {
        max-width: 100%;
        max-height: 100%;
        width: 100%; /** IE11 fix ;/ */
    }
`;

const AddRegistration = styled.button`
    color: ${colorStack.darkBlue};
    margin: 10px 0;
    cursor: pointer;
    outline: none;
    text-align: left;
    svg {
        margin: 0 10px 0 0;
    }
`;

const RegistrationTable = styled.div`
    border: 1px solid ${colorStack.ligthGrey};
`;
const HeadTable = styled.div`
    border-bottom: 1px solid ${colorStack.ligthGrey};
    display: flex;
    div {
        padding: .5rem;
        font-weight: 600;
        color: ${colorStack.darkBlue};
        &:first-child {
            width: 220px;
            border-right: 1px solid ${colorStack.ligthGrey};
        }
        &:nth-child(2) {
            width: calc(100% - 420px);
            border-right: 1px solid ${colorStack.ligthGrey};
        }
        &:nth-child(3) {
            width: 232px;
            border-right: 1px solid ${colorStack.ligthGrey};
        }
    }

`;
const BodyTable = styled.div`
    background: ${colorStack.whiteGrey};
`;

const RegistrationWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

interface IProps {
    data: IMainInformation;
    editMode: boolean;
    context: 'Cluster' | 'PLE';
    changeBlobPicture?(blob: Blob);
}

export const MainInformation: FC<IProps> = props => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const formikContext = useFormikContext<any>();
    const [aliasType, setAliasType] = useState<IReferencial[]>(undefined);
    const [showCropper, setShowCropper] = useState<boolean>(false);

    useEffect(() => {
        dispatch<GetReferentials>(getReferentials('', Referentials.AliasType, IContextList.Corporate)).then(response => {
            setAliasType(response);
        });
    }, []);

    const cropperHandler = useCallback(() => {
        setShowCropper(currentState => !currentState);
    }, []);

    const returnImage = useCallback((blobPictureData: File, Picture: string) => {
        setShowCropper(currentState => !currentState);
        props.changeBlobPicture(blobPictureData);
        formikContext.setFieldValue(MainInformationEditField[props.context]?.picture, Picture);
    }, [props.context]);

    const removeRowCode = useCallback((elemIndex: number) => {
        formikContext.setFieldValue(MainInformationEditField[props.context]?.registrations, props.data?.registrations.filter((elem, index) => index !== elemIndex));
    }, [props.data?.registrations, props.context]);

    const aliasChange = useCallback((elemIndex: number, elemAliasType: IRefferentialObj) => {
        formikContext.setFieldValue(MainInformationEditField[props.context]?.registrations, props.data?.registrations.map((elem, index) => index === elemIndex ? {
            ...elem,
            aliasType: elemAliasType
        } : elem));
    }, [props.data?.registrations, props.context]);

    const codeChange = useCallback((elemIndex: number, aliasValue: string) => {
        formikContext.setFieldValue(MainInformationEditField[props.context]?.registrations, props.data?.registrations.map((elem, index) => index === elemIndex ? {
            ...elem,
            aliasValue
        } : elem));
    }, [props.data?.registrations, props.context]);

    const addRegistration = useCallback(() => {
        const newRegistration = {
            aliasType: aliasType?.[0],
            aliasValue: '',
            showInHeader: false
        };
        formikContext.setFieldValue(MainInformationEditField[props.context]?.registrations, [...(props.data?.registrations || []), newRegistration]);
    }, [props.data?.registrations, props.context, aliasType]);

    return (
        <Wrapper>
            <a data-scrollId="1" data-scrollName={intl.formatMessage({ id: 'cluster.label.legalEntity' })} />
            {props.data && (!props.editMode ? (
                <>
                    <PhotoWrapper>
                        {props.data?.picture ? (
                            <img src={props.data?.picture} />
                        ) : (
                            <FontAwesomeIcon icon={faBuilding} size="4x" />
                        )}
                    </PhotoWrapper>
                    <Content>
                        {(props.data?.name && props.context !== 'PLE') &&
                            <>
                                {!!props.data?.pleId ? (
                                    <Link
                                        to={`/company/${props.data.pleId}`}
                                        target="_blank"
                                    >
                                        <Name>
                                            {props.data?.name}
                                        </Name>
                                    </Link>
                                ) : (
                                    <Name>{props.data?.name}</Name>
                                )}
                            </>
                        }
                        {props.data?.address &&
                            <Meta>
                                <Label><FormattedMessage id="ple.title.address" /></Label>
                                <Address>{props.data?.address && addressFormatterGlobal(props.data?.address)}</Address>
                            </Meta>
                        }
                        <MetasBox>
                            {props.data?.typeOfLegalEntity?.name &&
                                <Meta>
                                    <Label><FormattedMessage id="ple.label.typeOfEntity" /></Label>
                                    <span>{props.data?.typeOfLegalEntity?.name}</span>
                                </Meta>
                            }
                            {props.data?.legalForm?.name &&
                                <Meta>
                                    <Label><FormattedMessage id="ple.label.legalForm" /></Label>
                                    <span>{props.data?.legalForm?.name}</span>
                                </Meta>
                            }
                            {props.data?.registeredCapital?.number &&
                                <Meta>
                                    <Label><FormattedMessage id="ple.label.registeredCapital" /></Label>
                                    <span>{props.data?.registeredCapital?.number} <TranslateReferentials type={Referentials.Currency} context={IContextList.Platform} uniqueKey={props.data.registeredCapital?.unit?.uniqueKey}/></span>
                                </Meta>
                            }
                            {(props.data?.registrations?.length > 0 && (props.data?.registrations[0]?.aliasType?.name || props.data?.registrations[0]?.aliasValue)) &&
                                <Meta>
                                    <Label><FormattedMessage id="ple.label.aliasAndRegistration" /></Label>
                                    {(objectKeysToCamelCase<IRegistrations[]>(props.data?.registrations || [])).map(registration =>
                                        <div key={registration?.aliasType?.id}>
                                            <span>
                                                {registration?.aliasType?.name}
                                                {registration?.aliasType?.name && registration?.aliasValue && (
                                                    <span> : </span>
                                                )}
                                                {registration?.aliasValue}
                                            </span>
                                        </div>
                                    )}
                                </Meta>
                            }
                            {props.data?.nationality?.name &&
                                <Meta>
                                    <Label><FormattedMessage id="ple.label.nationality" /></Label>
                                    <span>{props.data?.nationality?.name}</span>
                                </Meta>
                            }
                            {props.data?.group?.name &&
                                <Meta>
                                    <Label><FormattedMessage id="ple.label.group" /></Label>
                                    <span>{props.data?.group?.name}</span>
                                </Meta>
                            }
                            {props.data?.websiteUrl &&
                                <Meta>
                                    <Label><FormattedMessage id="ple.label.website" /></Label>
                                    <ExternalLink to={toValidLink(props.data?.websiteUrl)}>{toValidLink(props.data?.websiteUrl)}</ExternalLink>
                                </Meta>
                            }
                            {props.data?.phoneNumber &&
                                <Meta>
                                    <Label><FormattedMessage id="ple.label.phoneNumber" /></Label>
                                    <span>{props.data?.phoneNumber}</span>
                                </Meta>
                            }
                        </MetasBox>
                    </Content>
                </>
            ) : (
                <>
                    <PhotoWrapper>
                        <Logo onClick={cropperHandler}>
                            {props.data?.picture ? (
                                <img src={props.data?.picture} />
                            ) : (
                                <>
                                    <FontAwesomeIcon icon={faBuilding} size="4x" />
                                    <FormattedMessage id="global.changeLogo" />
                                </>
                            )}
                        </Logo>
                        {showCropper &&
                            <ImageCropper
                                currentImageUrl={props.data?.picture}
                                returnImage={returnImage}
                                closeHandler={cropperHandler}
                            />
                        }
                    </PhotoWrapper>
                    <Content>
                        <StakesWrapper
                            stake={({
                                name: intl.formatMessage({ id: 'ple.title.editSummary' }),
                                properties: [{
                                    name: intl.formatMessage({ id: 'ple.title.name' }),
                                    value: props.data?.name,
                                    type: StakeDetailsType.Text,
                                    subType: StakeDetailsSubType.Simple,
                                    id: MainInformationEditField[props.context]?.name,
                                    configuration: undefined,
                                    key: undefined
                                },
                                {
                                    name: intl.formatMessage({ id: 'ple.title.address' }),
                                    value: props.data?.address,
                                    type: StakeDetailsType.Address,
                                    subType: undefined,
                                    id: MainInformationEditField[props.context]?.address,
                                    configuration: undefined,
                                    key: undefined
                                },
                                {
                                    name: intl.formatMessage({ id: 'ple.label.typeOfEntity' }),
                                    value: { key: props.data?.typeOfLegalEntity?.id },
                                    type: StakeDetailsType.SingleSelect,
                                    subType: undefined,
                                    id: MainInformationEditField[props.context]?.typeOfLegalEntity,
                                    configuration: referentialConfiguration.TypeOfLegalEntity,
                                    referentialIdentity: 'Custom',
                                    key: undefined
                                },
                                {
                                    name: intl.formatMessage({ id: 'ple.label.legalForm' }),
                                    value: { key: props.data?.legalForm?.id },
                                    type: StakeDetailsType.SingleSelect,
                                    subType: undefined,
                                    id: MainInformationEditField[props.context]?.legalForm,
                                    configuration: referentialConfiguration.LegalForm,
                                    referentialIdentity: 'Custom',
                                    key: undefined
                                },
                                {
                                    name: intl.formatMessage({ id: 'ple.label.nationality' }),
                                    value: { key: props.data?.nationality?.id },
                                    type: StakeDetailsType.SingleSelect,
                                    subType: undefined,
                                    id: MainInformationEditField[props.context]?.nationality,
                                    configuration: referentialConfiguration.Nationality,
                                    referentialIdentity: 'Custom',
                                    key: undefined
                                },
                                {
                                    name: intl.formatMessage({ id: 'ple.label.group' }),
                                    value: { key: props.data?.group?.id },
                                    type: StakeDetailsType.SingleSelect,
                                    subType: undefined,
                                    id: MainInformationEditField[props.context]?.group,
                                    configuration: referentialConfiguration.Group,
                                    referentialIdentity: 'Custom',
                                    key: undefined
                                },
                                {
                                    name: intl.formatMessage({ id: 'ple.label.website' }),
                                    value: props.data?.websiteUrl,
                                    type: StakeDetailsType.Text,
                                    subType: StakeDetailsSubType.Simple,
                                    id: MainInformationEditField[props.context]?.websiteUrl,
                                    configuration: undefined,
                                    key: undefined
                                },
                                {
                                    name: intl.formatMessage({ id: 'ple.label.registeredCapital' }),
                                    value: { number: props.data?.registeredCapital?.number, unit: props.data?.registeredCapital?.unit },
                                    isEditable: true,
                                    type: StakeDetailsType.Number,
                                    subType: StakeDetailsSubType.Currency,
                                    id: MainInformationEditField[props.context]?.capital,
                                    configuration: {
                                        availableUnits: {
                                            configuration: 10,
                                            referentialContext: IContextList.Platform,
                                            referentialType: Referentials.Currency,
                                            valueKeys: [],
                                            referentialIdentity: ''
                                        }
                                    },
                                    key: props.context === 'Cluster' ? PropertieKey.PLECLE : undefined
                                },
                                {
                                    name: intl.formatMessage({ id: 'ple.label.phoneNumber' }),
                                    value: props.data?.phoneNumber,
                                    type: StakeDetailsType.Text,
                                    subType: StakeDetailsSubType.Simple,
                                    id: MainInformationEditField[props.context]?.phoneNumber,
                                    configuration: undefined,
                                    key: undefined
                                }],
                                type: 0
                            })}
                            editMode={props.editMode}
                            context="PLE"
                            singleIndex={0}
                        />
                        <RegistrationWrapper>
                            <RegistrationTable>
                                <HeadTable>
                                    <div><FormattedMessage id="legalEntity.table.aliasesAndRegistrations" /></div>
                                    <div><FormattedMessage id="legalEntity.table.code" /></div>
                                    <div></div>
                                </HeadTable>
                                <BodyTable>
                                    {props.data?.registrations?.map((elem, index) =>
                                        <SingleRegistrationRow
                                            key={index}
                                            elem={elem}
                                            elemIndex={index}
                                            removeRow={removeRowCode}
                                            aliasChange={aliasChange}
                                            codeChange={codeChange}
                                            aliasType={aliasType}
                                        />
                                    )}
                                </BodyTable>
                            </RegistrationTable>
                            <AddRegistration onClick={addRegistration} type="button" data-lc="js-lc-overview-addRegistrationCode">
                                <FontAwesomeIcon icon={faPlusCircle} />
                                <FormattedMessage id="legalEntity.button.addRegistrationCode" />
                            </AddRegistration>
                        </RegistrationWrapper>
                    </Content>
                </>
            ))}

        </Wrapper>
    );
};