import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { LabelWithEllipsis } from '../Label/Label';
import { SelectCard } from '../SelectCard/SelectCard';
import { MandatoryFieldStar } from '../MandatoryFieldStar/MandatoryFieldStar';

const Wrapper = styled.div`
    width: 100%;
`;

const SelectWrapper = styled.div`
    width: 100%;
    justify-content: space-between;
    margin: 0 0 1rem 0;
    display: flex;
`;

interface IProps {
    field: string;
}

export const Privacy: FC<IProps> = (props => {
    return (
        <Wrapper>
            <LabelWithEllipsis>
                <FormattedMessage id="contract.popup.step2.text3" />
                <MandatoryFieldStar />
            </LabelWithEllipsis>
            <SelectWrapper>
                <SelectCard
                    field={props.field}
                    value="Public"
                    imageKey="iconPublic"
                    name="contract.popup.step2.namePublic"
                    description="contract.popup.step2.descriptionPublic"
                    maxSize={200}
                />
                <SelectCard
                    field={props.field}
                    value="Private"
                    imageKey="iconPrivate"
                    name="contract.popup.step2.namePrivate"
                    description="contract.popup.step2.descriptionPrivacy"
                    maxSize={200}
                />
                <SelectCard
                    field={props.field}
                    value="Secret"
                    imageKey="iconSecret"
                    name="contract.popup.step2.nameSecret"
                    description="contract.popup.step2.descriptionSecret"
                    maxSize={200}
                />
            </SelectWrapper>
        </Wrapper>
    );
});
