import React, { FC, useCallback, useEffect, useReducer } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { IStakeCoupleCollection } from './StakeCoupleCollection';
import { IReferencial } from '../../../../../entities/IGlobal';
import { useReferentialsPicker } from '../../../../../tools/pickerHooks';
import { uuidv4 } from '../../../../../tools/authTools';
import { Tag } from '../../../../Common/TagPicker/Tag';
import { TagPickerColor, TagPicker, ITag } from '../../../../Common/TagPicker/TagPicker';
import { SpliceList } from '../../../../Common/SpliceList/SpliceList';
import { Label } from '../../../../Common/Label/Label';
import { NewDesignStakeLayout } from './NewDesignStakeLayout';
import { ErrorText } from '../StakeComponents/StakeStyles';
import { fontSize } from '../../../../../styleHelpers/fontSizes';

interface IPropertyValue {
    key: string;
    value: string;
    parentId?: string;
}

interface IScopeItem {
    id: string;
    aorValue: IPropertyValue[];
    itemsValue: IPropertyValue[];
}

interface IState {
    aorTags: ITag<IReferencial>[];
    itemsTags: ITag<IReferencial>[];
    scopeItems: IScopeItem[];
    totalAorValue: IPropertyValue[];
    totalItemsValue: IPropertyValue[];
    changed: boolean;
}

const initialState = {
    aorTags: [],
    itemsTags: [],
    scopeItems: [],
    totalAorValue: [],
    totalItemsValue: [],
    changed: false
};

type TagKeys = 'aorTags' | 'itemsTags';

type Action =
    | { type: 'INIT', totalAorValue: IPropertyValue[], totalItemsValue?: IPropertyValue[] }
    | { type: 'ADD_ITEM', aorValue?: IPropertyValue[] }
    | { type: 'UPDATE_ITEM', id: string, aorValue: IPropertyValue[], itemsValue: IPropertyValue[] }
    | { type: 'REMOVE_ITEM', id: string }
    | { type: 'SET_TAGS', key: TagKeys, tags: ITag<IReferencial>[] }
    | { type: 'SET_UNCHANGED' };

class StateAdapter {
    init(totalAorValue: IPropertyValue[], totalItemsValue: IPropertyValue[]): IState {
        const itemsByAorIds = (totalItemsValue || []).reduce((byIds, item) => {
            byIds.set(item.parentId, byIds.has(item.parentId) ? [...byIds.get(item.parentId), item] : [item]);
            return byIds;
        }, new Map());
        return (totalAorValue || [])
            .reduce((state, value) => this.addItem(state, [value], itemsByAorIds.get(value.key)), { ...initialState });
    }

    addItem(state: IState, aorValue: IPropertyValue[] = [], itemsValue: IPropertyValue[] = []): IState {
        const newItem = { id: uuidv4(), aorValue, itemsValue };
        const newItems = [...state.scopeItems, newItem];

        return { ...state, scopeItems: newItems, ...this.totalizeScopeItems(newItems), changed: true };
    }

    updateItem(state: IState, id: string, aorValue: IPropertyValue[], itemsValue: IPropertyValue[]): IState {
        const updatedItems = state.scopeItems.map(item => {
            if (item.id === id) {
                const aorChanged = item.aorValue !== aorValue;
                return { ...item, aorValue, itemsValue: aorChanged ? [] : itemsValue };
            } else {
                return item;
            }
        });

        return { ...state, scopeItems: updatedItems, ...this.totalizeScopeItems(updatedItems), changed: true };
    }

    removeItem(state: IState, id: string): IState {
        const updatedItems = state.scopeItems.filter(item => item.id !== id);

        return { ...state, scopeItems: updatedItems, ...this.totalizeScopeItems(updatedItems), changed: true };
    }

    totalizeScopeItems(items: IScopeItem[]): Pick<IState, 'totalAorValue' | 'totalItemsValue'> {
        return items.reduce((total, item) => ({
            totalAorValue: [...total.totalAorValue, ...item.aorValue],
            totalItemsValue: [...total.totalItemsValue, ...item.itemsValue.map(child => ({ ...child, parentId: item.aorValue[0].key }))]
        }), { totalAorValue: [], totalItemsValue: [] });
    }
}

const adapter = new StateAdapter();

const reducer = (state: IState, action: Action): IState => {
    switch (action.type) {
        case 'INIT':
            return adapter.init(action.totalAorValue, action.totalItemsValue);
        case 'ADD_ITEM':
            return adapter.addItem(state, action.aorValue);
        case 'UPDATE_ITEM':
            return adapter.updateItem(state, action.id, action.aorValue, action.itemsValue);
        case 'REMOVE_ITEM':
            return adapter.removeItem(state, action.id);
        case 'SET_TAGS':
            return { ...state, [action.key]: action.tags };
        case 'SET_UNCHANGED':
            return { ...state, changed: false };
        default:
            return { ...initialState };
    }
};

const mapToTagValue = (items: IPropertyValue[]) => (items || []).map((item) => ({ id: item.key, value: item.value }));

// tslint:disable-next-line:no-null-keyword
const mapToPropertyValue = (items: ITag<IReferencial>[]) => (items || []).map((item) => ({ key: item.id, value: item.value, parentId: null }));

const Field = styled.div`
    label {
        margin-bottom: 6px;
    }

    span {
        font-size: ${fontSize[13]};
        color: ${props => props.theme.colors.primary};
    }
`;

const TagpickerTag = styled(Tag)`
    padding: 0.3rem 0.5rem;
`;

export const AreaOfResponsabilities: FC<IStakeCoupleCollection> = ({ errors, couple, editStake, setEditingData }) => {
    const [aorProperty, itemsProperty] = couple;
    const [searchHandler, , , searching] = useReferentialsPicker();
    const [state, dispatch] = useReducer(reducer, { ...initialState });

    useEffect(() => {
        dispatch({ type: 'INIT', totalAorValue: aorProperty.value, totalItemsValue: itemsProperty.value });

        if (editStake) {
            try {
                const keys: TagKeys[] = ['aorTags', 'itemsTags'];
                [aorProperty, itemsProperty].forEach(async (property, index) => {
                    const context = property.configuration?.referentialContext;
                    const type = property.configuration?.referentialType;

                    const tags = await searchHandler(type, context, undefined);
                    dispatch({ type: 'SET_TAGS', key: keys[index], tags });
                });
            } catch (err) {
                console.log(`error: ${err}`);
            }
        }
    }, [editStake]);

    useEffect(() => {
        editStake && state?.totalAorValue?.length === 0 && addAorHandler();
    }, [editStake]);

    useEffect(() => {
        if (state.changed) {
            setEditingData([
                { ...aorProperty, value: state.totalAorValue },
                { ...itemsProperty, value: state.totalItemsValue }
            ]);

            dispatch({ type: 'SET_UNCHANGED' });
        }
    }, [state.changed]);

    const updateAorHandler = useCallback((id: string, aorValue: IPropertyValue[], itemsValue?: IPropertyValue[]) => {
        dispatch({ type: 'UPDATE_ITEM', id, aorValue, itemsValue });
    }, []);

    const addAorHandler = useCallback(() => {
        dispatch({ type: 'ADD_ITEM' });
    }, []);

    const removeAorHandler = useCallback((id: string) => {
        dispatch({ type: 'REMOVE_ITEM', id });
    }, []);

    const aorError = (errors?.aggregatedErrors || [])?.find(elem => elem?.PropertyId === aorProperty?.id)?.message;
    const itemsError = (errors?.aggregatedErrors || [])?.find(elem => elem?.PropertyId === itemsError?.id)?.message;

    return (
        <SpliceList
            editMode={editStake}
            items={state.scopeItems}
            onAddItem={addAorHandler}
            onRemoveItem={removeAorHandler}
            addButtonText={
                <FormattedMessage id="areaOfResponsability.addItem" />
            }
            itemRenderer={item => (
                <NewDesignStakeLayout stakeKey={aorProperty.key}>
                    <Field>
                        <Label>{aorProperty.name}</Label>
                        {editStake ? (
                            <>
                                <TagPicker
                                    limit={1}
                                    disabled={searching}
                                    tagColor={TagPickerColor.LightBlue}
                                    allTags={state.aorTags}
                                    selectedTags={mapToTagValue(item.aorValue)}
                                    onChange={tags => updateAorHandler(item.id, mapToPropertyValue(tags), item.itemsValue)}
                                />
                                {aorError && <ErrorText>{aorError}</ErrorText>}
                            </>
                        ) : (
                                <>
                                    {item.aorValue.length > 0 && (
                                        <TagpickerTag tagColor={TagPickerColor.LightBlue}>
                                            {item.aorValue[0].value}
                                        </TagpickerTag>
                                    )}
                                </>
                            )}
                    </Field>
                    <Field>
                        <Label>{itemsProperty.name}</Label>
                        {editStake ? (
                            <>
                                <TagPicker
                                    disabled={searching || !item.aorValue.length}
                                    tagColor={TagPickerColor.LightBlue}
                                    allTags={
                                        item.aorValue.length > 0 ? state.itemsTags.filter(tag => tag?.data?.parents?.includes(item?.aorValue[0]?.key)) : state.itemsTags
                                    }
                                    selectedTags={mapToTagValue(item.itemsValue)}
                                    onChange={tags => updateAorHandler(item.id, item.aorValue, mapToPropertyValue(tags))}
                                />
                                {itemsError && <ErrorText>{itemsError}</ErrorText>}
                            </>
                        ) : (
                                <span>
                                    {item.itemsValue.map(child =>
                                        <TagpickerTag key={child.key} tagColor={TagPickerColor.LightBlue}>
                                            {child.value}
                                        </TagpickerTag>
                                    )}
                                </span>
                            )}
                    </Field>
                </NewDesignStakeLayout>
            )}
        />
    );
};
