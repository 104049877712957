import { fontSize } from "../fontSizes";

export const lcrButton = `
    .lcr-button {
        display:inline-block;
        padding:12px 20px;
        color:#fff;
        cursor:pointer;
        font-weight:500;
        font-size: ${fontSize[13]};
        text-align:center;
        outline:none;
        border:none;
        background:#232c47;
        -webkit-border-radius:25px;
        -moz-border-radius:25px;
        border-radius:25px;
        -webkit-transition:all .3s;
        -o-transition:all .3s;
        transition:all .3s;
        background-image:none;
        -webkit-box-sizing:border-box;
        -moz-box-sizing:border-box;
        box-sizing:border-box
    }
    .lcr-button:visited {
        color:#FFF
    }
    .lcr-button:focus,
    .lcr-button:active,
    .lcr-button:hover {
        text-decoration:none !important;
        background-color:#121624;
        background-image:none;
        color:#fff;
    }
    .lcr-button:active {
        -webkit-box-shadow:0 4px 8px 0 rgba(0,0,0,0.2);
        box-shadow:0 4px 8px 0 rgba(0,0,0,0.2);
    }
    .lcr-button.lcr-dblock {
        display:block;
        width:100%;
        text-align:center;
    }
    .lcr-button.lcr-color2 {
        color:#232b46;
        background:#cfc19e;
        border-color:#cfc19e;
    }
    .lcr-button.lcr-color2:visited {
        color:#232b46;
    }
    .lcr-button.lcr-color2:focus,.lcr-button.lcr-color2:active,.lcr-button.lcr-color2:hover {
        background-image:linear-gradient(to left, #caba94, #cfc19e);
        color:#fff;
    }
    .lcr-button.lcr-cancel{color:#fff;
        background:#353535;
        border-color:#353535;
    }
    .lcr-button.lcr-cancel:focus,.lcr-button.lcr-cancel:active,.lcr-button.lcr-cancel:hover {
        background-image:linear-gradient(to left, #353535, #424242);
        color:#fff;
    }
    .lcr-button.lcr-delete{
        color:#fff;
        background:#ca0f0f;
        border-color:#ca0f0f;
    }
    .lcr-button.lcr-delete:focus,.lcr-button.lcr-delete:active,.lcr-button.lcr-delete:hover {
        background-image:linear-gradient(to left, #b20d0d, #ca0f0f);
        color:#fff;
    }
    .lcr-button[disabled],.lcr-button.lcr-disabled {
        color:#f9f9f9;
        cursor:default;
        background:#b4b4b4 !important;
        border-color:#b4b4b4;
        -webkit-box-shadow:none;
        box-shadow:none;
    }
    .lcr-button.lcr-bordered {
        padding-top:10px;
        padding-bottom:10px;
        color:#232b46;
        border:2px solid #232b46;
        background:none;
        -webkit-transition:all .3s;
        -o-transition:all .3s;
        transition:all .3s;
    }
    .lcr-button.lcr-bordered:focus,.lcr-button.lcr-bordered:active,.lcr-button.lcr-bordered:hover; {
        color:#fff;
        background:#232b46;
    }
    .lcr-button.lcr-bordered.lcr-color2 {
        border-color:#cfc19e;
    }
    .lcr-button.lcr-bordered.lcr-color2:focus,.lcr-button.lcr-bordered.lcr-color2:active,.lcr-button.lcr-bordered.lcr-color2:hover {
        background:#cfc19e;
    }
    .lcr-button.lcr-bordered.lcr-activities-button {
        margin:20px 0 0 0;
    }
    .lcr-button.lcr-intense {
        padding:18px;
        font-size: ${fontSize[13]};
        font-weight:500;
        text-transform:uppercase;
        color:#fff;
        background-image:linear-gradient(to left, #295181, #1f78b3);
    }
    .lcr-button.lcr-intense:focus,.lcr-button.lcr-intense:active,.lcr-button.lcr-intense:hover {
        background-image:linear-gradient(to left, #295181, #295181);
    }
    .lcr-button.lcr-large {
        padding:15px 25px;
    }
    .lcr-button[class^="lcr-icon-"]:before,.lcr-button[class*=" lcr-icon-"]:before {
        float:left;
        margin:-6px 10px -7px 0;
        font-size: ${fontSize[13]};
    }
    .lcr-button.lcr-icon-only{
        padding-left:10px;
        padding-right:10px;
    }
    .lcr-button.lcr-icon-only:before{
        margin:0;
    }
    .lcr-button.lcr-link {
        background:none;
        border:none;
        color:#232b46;
    }
    .lcr-button.lcr-ellipsis {
        padding:8px 10px;
    }
    .lcr-button.lcr-ellipsis:before {
        display:block;
        margin-top:-20px;
        margin-bottom:-5px;
        font-size: ${fontSize[25]};
        content:"\\2026";
    }
    .lcr-buttons-group {
        display:inline-block;
    }
    .lcr-buttons-group:before,.lcr-buttons-group:after {
        display:table;
        clear:both;
        content:'';
    }
    .lcr-buttons-group .lcr-button{
        float:left;
        border-left-width:0;
        -webkit-border-radius:0;
        -moz-border-radius:0;
        border-radius:0;
    }
    .lcr-buttons-group .lcr-button:first-of-type {
        border-left-width:1px;
        -webkit-border-radius:25px 0 0 25px;
        -moz-border-radius:25px 0 0 25px;
        border-radius:25px 0 0 25px;
    }
    .lcr-buttons-group .lcr-button:last-of-type {
        -webkit-border-radius:0 25px 25px 0;
        -moz-border-radius:0 25px 25px 0;
        border-radius:0 25px 25px 0;
    }
    .lcr-buttons-group .lcr-button.lcr-selected {
        background:#fff;
        color:#232b46;
        cursor:default;
    }
    .lcr-buttons-group .lcr-button.lcr-color3.lcr-selected {
        color:#fff;
        background:#4693a7;
    }
    .lcr-play{
        width:75px;
        height:75px;
        text-align:center;
        -webkit-border-radius:50%;
        -moz-border-radius:50%;
        border-radius:50%;
    }
    .lcr-play:after {
        display:inline-block;
        width:0;
        height:0;
        margin-top:10px;
        margin-left:6px;
        content:"";
        border-left:20px solid #fff;
        border-top:15px solid transparent;
        border-bottom:15px solid transparent;
    }
    .lcr-button {
        -webkit-transition:all .3s;
        -o-transition:all .3s;
        transition:all .3s;
        font-weight:600;
    }
    .lcr-button.lcr-intense {
        padding:18px;
        font-size: ${fontSize[13]};
        font-weight:500;
        text-transform:uppercase;
        color:#fff;
        background-image:linear-gradient(to left, #beab7c, #cfc19e);
    }
    .lcr-button.lcr-intense:focus,.lcr-button.lcr-intense:active,.lcr-button.lcr-intense:hover {
        background-image:linear-gradient(to left, #cfc19e, #cfc19e);
    }
    .lcr-button.lcr-color2:focus,.lcr-button.lcr-color2:active,.lcr-button.lcr-color2:hover {
        background:#beab7c;
    }
    .lcr-button.lcr-color3{
        color:#fff;
        background:#4693a7;
        border-color:#4693a7;
    }
    .lcr-button.lcr-color3:focus,.lcr-button.lcr-color3:active,.lcr-button.lcr-color3:hover {
        background:#377383;
    }
    .lcr-button.lcr-color4 {
        color:#232b46;
        background:#fff;
        border-color:#fff;
    }
    .lcr-button.lcr-color4:focus,.lcr-button.lcr-color4:active,.lcr-button.lcr-color4:hover {
        background:#e6e6e6;
    }
    .lcr-button.lcr-cancel {
        color:#fff;
        background:#353535;
        border-color:#353535;
    }
    .lcr-button.lcr-cancel:focus,.lcr-button.lcr-cancel:active,.lcr-button.lcr-cancel:hover {
        background:#1c1c1c;
    }
    .lcr-button.lcr-delete {
        color:#fff;
        background:#ca0f0f;
        border-color:#ca0f0f;
    }
    .lcr-button.lcr-delete:focus,.lcr-button.lcr-delete:active,.lcr-button.lcr-delete:hover {
        background:#9b0b0b;
    }
    .lcr-button[disabled],.lcr-button.lcr-disabled {
        color:#f9f9f9;
        cursor:default;
        background:#b4b4b4 !important;
        border-color:#b4b4b4;
        -webkit-box-shadow:none;
        box-shadow:none;
    }
    .lcr-button.lcr-bordered.lcr-color3 {
        color:#4693a7;
        border-color:#4693a7;
        background:none;
    }
    .lcr-button.lcr-bordered.lcr-color3:focus,
    .lcr-button.lcr-bordered.lcr-color3:active,
    .lcr-button.lcr-bordered.lcr-color3:hover,
    .lcr-button.lcr-bordered.lcr-color3.lcr-opened {
        color:#fff;
        background:#4693a7;
    }
    .lcr-button.lcr-bordered.lcr-color4 {
        color:#232b46;
        border-color:#fff;
        background:none;
    }
    .lcr-button.lcr-bordered.lcr-color4:focus,.lcr-button.lcr-bordered.lcr-color4:active,.lcr-button.lcr-bordered.lcr-color4:hover {
        background:#fff;
    }
`;
