import { PlatformPermissions } from '../entities/IPermissions';

export const isPlatformAdmin = (permissions: { [key: string]: boolean }) => {
    return permissions?.[PlatformPermissions.PlatformManagementView];
};

export const findPermmision = ((permissions: string[], elem: string) => {
    if (permissions?.includes(elem)) {
        return true;
    } else {
        return false;
    }
});