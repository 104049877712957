import React, { FC, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { IClusterStakeProperty } from '../../../../../entities/IClusterStake';
import { colorStack } from '../../../../../styleHelpers/colors';
import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { getReferentials } from '../../../../../actions/globalActions';
import { Tag } from '../../../../Common/TagPicker/Tag';
import { TagPicker, TagPickerColor, ITag } from '../../../../Common/TagPicker/TagPicker';
import { ErrorText } from './StakeStyles';

const TagpickerTag = styled(Tag)`
    padding: 0.3rem 0.5rem;
`;

const Wrapper = styled.div`
    span {
        font-size: ${fontSize[13]};
        color: ${colorStack.darkBlue};
    }
`;

type GetReferentials = ReturnType<typeof getReferentials>;

interface IMultiSelectContentProps {
    data: IClusterStakeProperty;
    editStake: boolean;
    propertieId: string;
    error: string;
    setEditingData(propertie: IClusterStakeProperty);
}

export const MultiSelectContent: FC<IMultiSelectContentProps> = ({ error, data, editStake, propertieId, setEditingData }) => {
    const dispatch = useDispatch();
    const [selectContent, setSelectContent] = useState<{ Name: string, id: string }[]>(undefined);
    const [selectValue, setSelectValue] = useState<ITag[]>([]);
    const [objectToSend, setObjectToSend] = useState<{ key: string; value: string; }[]>([]);
    const referentialIdentity = data.configuration?.referentialIdentity || data.configuration?.ReferentialIdentity;
    const referentialContext = data.configuration?.referentialContext || data.configuration?.ReferentialContext;
    const referentialType = data.configuration?.referentialType || data.configuration?.ReferentialType;

    useEffect(() => {
        if (editStake) {
            const context = data.configuration?.referentialContext || data.configuration?.ReferentialContext;
            const type = data.configuration?.referentialType || data.configuration?.ReferentialType;
            if (context && type) {
                dispatch<GetReferentials>(getReferentials('', type, context)).then(response => {
                    setSelectContent(response.map(elem => ({
                        Name: elem.name,
                        id: elem.id
                    })));
                });
            }
        }
        setSelectValue((data?.value || []).map(elem => ({ id: elem.key, value: elem.value })));
        setObjectToSend(data?.value || []);
    }, [editStake, data?.value]);

    const changeSelect = useCallback((tags: ITag[]) => {
        setSelectValue(tags);
        const newObjectToSend = tags.map(tag => ({ key: tag.id, value: tag.value }));
        setObjectToSend(newObjectToSend);
        setEditingData({
            ...data,
            value: newObjectToSend,
            id: propertieId
        });
    }, [objectToSend, selectValue, referentialIdentity, setEditingData, data, referentialContext, referentialType]);

    return (
        <Wrapper>
            {editStake ? (
                <>
                    {selectContent && (
                        <TagPicker
                            tagColor={TagPickerColor.LightBlue}
                            allTags={selectContent?.map(elem => ({
                                value: elem.Name,
                                id: elem.id
                            }))}
                            selectedTags={selectValue}
                            onChange={changeSelect}
                        />
                    )}
                    {error &&
                        <ErrorText>{error}</ErrorText>
                    }
                </>
            ) : (
                    <span>
                        {(selectValue || []).map(tag => (
                            <TagpickerTag key={tag.id} tagColor={TagPickerColor.LightBlue}>
                                {tag.value}
                            </TagpickerTag>
                        ))}
                    </span>
                )}
        </Wrapper>
    );
};