import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import RouteComponent from '../components/LegalEntities/EntityPreviewExport';

const Route: FC<RouteComponentProps<{ entityId: string }>> = (props) => (
    <RouteComponent {...props} />
);

export default Route;
