import React, { useEffect, useState, useCallback, FC } from 'react';
import styled, { css } from 'styled-components';
import { IDropdownOption } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { ConfigurationOfAvailableUnits, IClusterStakeProperty, PropertieKey, StakeDetailsSubType, StakeDetailsType } from '../../../../../entities/IClusterStake';
import { colorStack } from '../../../../../styleHelpers/colors';
import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { ErrorText } from './StakeStyles';
import { SelectInput } from '../../../../Common/SelectInput/SelectInput';
import { TextComponent } from '../../../../Common/Inputs/TextComponent';
import { IContextList, IReferencial, IViewReferential, Referentials } from '../../../../../entities/IGlobal';
import { getReferentials } from '../../../../../actions/globalActions';
import { TranslateReferentials } from '../../../../../tools/referentials';
import { numberFormatter } from '../../../../../tools/numberTools';

type GetReferentials = ReturnType<typeof getReferentials>;

const Wrapper = styled.div`
    span {
        font-size: ${fontSize[13]};
        color: ${colorStack.darkBlue};
    }
`;

const EditMode = styled.div`
    display: flex;
    align-items: center;
    > div {
        width: 48%;
        &:first-child {
            margin-right: 1%;
        }
    }
`;

interface INumberContentProps {
    data: IClusterStakeProperty;
    editStake: boolean;
    propertieId: string;
    error: {
        errorCode: number;
        message: string;
    };
    context?: 'PLE' | 'Cluster';
    base?: boolean;
    setEditingData(propertie: IClusterStakeProperty);
}

export const NumberContent: FC<INumberContentProps> = ({ base, context, error, data, editStake, propertieId, setEditingData }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    // tslint:disable-next-line:no-null-keyword
    const [content, setContent] = useState<string>(null);
    // tslint:disable-next-line:no-null-keyword
    const [unit, setUnit] = useState<IViewReferential>(null);
    // tslint:disable-next-line:no-null-keyword
    const [units, setUnits] = useState<IReferencial[]>(null);
    const referentialIdentity = data.configuration?.referentialIdentity;

    useEffect(() => {
        setContent(data?.value?.number || data?.value?.numericValue?.number as number);
        setUnit(data?.value?.unit || data?.value?.numericValue?.unit as string);
        if ((data?.configuration?.availableUnits?.configuration || data?.configuration?.availableUnits?.Configuration) === ConfigurationOfAvailableUnits.DynamicValues) {
            dispatch<GetReferentials>(getReferentials('', (data?.configuration?.availableUnits?.referentialType || data?.configuration?.availableUnits?.ReferentialType), (data?.configuration?.availableUnits?.referentialContext || data?.configuration?.availableUnits?.ReferentialContext)))
                .then(res => {
                    setUnits(res);
                    if (res.length === 1 && !unit) {
                        setUnit({
                            key: res[0].id,
                            // tslint:disable-next-line:no-null-keyword
                            parentId: null,
                            uniqueKey: res[0].Key
                        });
                        // tslint:disable-next-line:no-null-keyword
                        const value = ({ number: data?.value?.number || data?.value?.numericValue?.number, unit: { key: res[0].id, parentId: null, uniqueKey: res[0].Key } });
                        setEditingData({
                            ...data,
                            value: data.type === StakeDetailsType.NumberAndRisk ? { riskValue: data.value?.riskValue, numericValue: value } : value,
                            id: propertieId
                        });
                    }
                });
        } else if ((data?.configuration?.availableUnits?.configuration || data?.configuration?.availableUnits?.Configuration) === ConfigurationOfAvailableUnits.DefinedValues) {
            setUnits((data?.configuration?.availableUnits?.valueKeys || data?.configuration?.availableUnits?.ValueKeys)?.map(elem => ({
                // tslint:disable-next-line:no-null-keyword
                Key: null,
                id: elem,
                name: elem,
                // tslint:disable-next-line:no-null-keyword
                logicalId: null
            })));
        }
    }, [data, editStake]);

    const changeNumber = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        let text = e.target.value;
        const regex = /^\d*\.?(?:\d{1,6})?$/g;
        if (regex.test(text) || text === '') {
            if (data.subType === StakeDetailsSubType.Percentage  && parseInt(text, 10) > 100) {
                setContent('100');
                text = '100';
            } else {
                setContent(text !== '' ? text : null);
            }
            const value = referentialIdentity === 'Custom' ?
                // tslint:disable-next-line:no-null-keyword
                parseInt(text, 10) : ({ number: (text !== '' ? text.replace(' ', '') : null), unit: unit });
            setEditingData({
                ...data,
                value: data.type === StakeDetailsType.NumberAndRisk ? { riskValue: data.value?.riskValue, numericValue: value } : value,
                id: propertieId
            });
        }
    }, [data, unit, setEditingData, context, units, propertieId]);

    const changeUnit = useCallback((unitElem: IViewReferential) => {
        setUnit(unitElem);
        const value = ({ number: content, unit: { key: unitElem.key, parentId: unitElem.parentId, uniqueKey: unitElem.uniqueKey } });
        setEditingData({
            ...data,
            value: data.type === StakeDetailsType.NumberAndRisk ? { riskValue: data.value?.riskValue, numericValue: value } : value,
            id: propertieId
        });
    }, [data, content, setEditingData, context, units, propertieId]);

    return (
        <Wrapper>
            {editStake ? (
                <div>
                    <EditMode>
                        <TextComponent
                            type="Input"
                            onChange={changeNumber}
                            value={content !== null ? content : ''}
                            placeholder={intl.formatMessage({ id: 'cluster.placeholder.number' })}
                            error={error?.errorCode === 666}
                        />
                        {units?.length > 1 ? (
                            <SelectInput
                                selectedKey={unit?.key}
                                placeholder={intl.formatMessage({ id: 'global.selectValue' })}
                                onChange={(e, option: IDropdownOption) => changeUnit(option as any)}
                                options={units?.sort((a, b) => a.name.localeCompare(b.name))?.map(elem => ({
                                    key: elem.id,
                                    text: elem.name,
                                    uniqueKey: elem.Key,
                                    parentId: elem.referentialIdentityId
                                }))}
                            />
                        ) : (
                            <span><TranslateReferentials type={data?.configuration?.availableUnits?.referentialType || data?.configuration?.availableUnits?.ReferentialType} context={data?.configuration?.availableUnits?.referentialContext || data?.configuration?.availableUnits?.ReferentialContext} uniqueKey={units?.[0]?.Key} /></span>
                        )}
                    </EditMode>
                    {(error) &&
                        <ErrorText customColor={error.errorCode === 666 && colorStack.darkOrange}>{error?.message}</ErrorText>
                    }
                </div>
            ) : (
                <>
                    {(data.value?.number && !base) &&
                        <>
                            <span>{numberFormatter(data.value?.number)}</span>
                            {data.value?.unit && <span> <TranslateReferentials type={data.configuration.availableUnits.referentialType || data.configuration.availableUnits.ReferentialType} context={data.configuration.availableUnits.referentialContext || data.configuration.availableUnits.ReferentialContext} uniqueKey={data?.value?.unit?.uniqueKey} /></span>}
                        </>
                    }
                    {(data.value?.baseNumber && base) &&
                        <>
                            <span>{numberFormatter(data.value?.baseNumber)}</span>
                            {data.value?.baseUnit && <span> <TranslateReferentials type={data.configuration.availableUnits.referentialType || data.configuration.availableUnits.ReferentialType} context={data.configuration.availableUnits.referentialContext || data.configuration.availableUnits.ReferentialContext} uniqueKey={data.value.baseUnit?.uniqueKey} /></span>}
                        </>
                    }
                </>
            )}
        </Wrapper>
    );
};