import * as actionTypes from '../actions/actionTypes/menuTypes';
import { IMenuItem } from '../entities/IMenu';

export interface IMenuReducer {
    menuItem: IMenuItem;
}

const defaultState = (): IMenuReducer => ({
    menuItem: IMenuItem.home
});

export default (state = defaultState(), action): IMenuReducer => {
    switch (action.type) {
        case actionTypes.UPDATE_MENU_TITLE: {
            const payload: actionTypes.IMenuTypes['UPDATE_MENU_TITLE'] = action;
            return {
                ...state,
                menuItem: payload.menuItem
            };
        }
        default: {
            return state;
        }
    }
};
