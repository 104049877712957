import { StakeContainerType, IClusterStakeProperty } from '../entities/IClusterStake';

export const isStanderdView = (type: number) => {
    return (type === StakeContainerType.Standard
        || type === StakeContainerType.Shareholder
        || type === StakeContainerType.Participations
        || type === StakeContainerType.GeneralCommitment
        || type === StakeContainerType.ShareholderMeetingInformation
        || type === StakeContainerType.Notes
        || type === StakeContainerType.Contact
        || type === StakeContainerType.Economic
        || type === StakeContainerType.RelatedEntities
        || type === StakeContainerType.GiftDescription
        || type === StakeContainerType.GiftPartiesRelationship
        || type === StakeContainerType.AuthorizedActivities
        || type === StakeContainerType.MainRent
        || type === StakeContainerType.AdditionnalRent
        || type === StakeContainerType.BuildingFeesCommitment
        || type === StakeContainerType.QuotationPrice
        || type === StakeContainerType.LeaseTaxes
        || type === StakeContainerType.ConstructionWorkRepaire
        || type === StakeContainerType.RentCancellation
        || type === StakeContainerType.QuotationPresentation
        || type === StakeContainerType.QuotationRequestBody
        || type === StakeContainerType.LeaseAssignment
        || type === StakeContainerType.SecurityDeposit
        || type === StakeContainerType.RentIndexation
        || type === StakeContainerType.DamagePovision
        || type === StakeContainerType.ContractDuration
        || type === StakeContainerType.BuildingFees
        || type === StakeContainerType.Designation
        || type === StakeContainerType.LeaseContractTypology
        || type === StakeContainerType.RenewalProvision
        || type === StakeContainerType.SubLeasing
        || type === StakeContainerType. ProjectDescription
        || type === StakeContainerType.ContractTypologyMerieux
        || type === StakeContainerType.FinancingPlan
        || type === StakeContainerType.OwnershipOfRights
        || type === StakeContainerType.TransferOfRights
        || type === StakeContainerType.InvitationDescription
        || type === StakeContainerType.RentInsurance);
};

export const isPartyView = (type: number) => {
    return (type === StakeContainerType.LegalEntityParty
        || type === StakeContainerType.UserParty
        || type === StakeContainerType.GiftCreator
        || type === StakeContainerType.GiftSecondParty
        || type === StakeContainerType.GiftParty
        || type === StakeContainerType.ContractingParties
        || type === StakeContainerType.InvolvedParty
        || type === StakeContainerType.InvitationSecondParty
        || type === StakeContainerType.LeaseParties);
};

export const prepareProperties = (properties: IClusterStakeProperty[]) => {
    const { singles, couples } = (properties || []).reduce(({ byIds, indexesByIds, singles, couples }, property) => {
        byIds.set(property.id, property);

        if (!!property.configuration?.parentPropertyId) {
            couples.push([byIds.get(property.configuration.parentPropertyId), property]);
            singles.splice(indexesByIds.get(property.configuration?.parentPropertyId), 1);
        } else {
            indexesByIds.set(property.id, singles.push(property) - 1);
        }
        return { byIds, indexesByIds, singles, couples };
    }, { byIds: new Map(), indexesByIds: new Map(), singles: [], couples: [] });

    return [couples, singles];
};
