import React from 'react';
import styled from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';

const MandatoryField = styled.span`
    color: ${colorStack.darkRed};
`;

export const MandatoryFieldStar = () => {
    return (
        <MandatoryField>
            &nbsp;*&nbsp;
        </MandatoryField>
    );
};