import styled, { css } from 'styled-components';

import { colorStack } from '../../styleHelpers/colors';
import { fontSize } from '../../styleHelpers/fontSizes';
import { media } from '../../styleHelpers/breakpoint';

//Main - global styles
// please make sure when changing height that scrollbar for hole page doesn't appear
export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    .left-side{
        width: 70px;
        ${media.tabletSm`
            width: 40%;
        `}

        ${media.tablet`
            width: 25.5%;
        `}
    }
    .right-side{
        flex: 1;
    }
    .padding-inner{
        padding: 0 15px;
        display: flex;
        width: 100%;
    }
    .flex-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .button-wrapper {
        background: ${colorStack.lightBlue};
        color: ${colorStack.white};
        font-size: ${fontSize[13]};
        font-weight: 500;
        width: 80%;
        padding: 15px 0;
        border-radius: 100px;
        margin: 12px auto 0 auto;
        text-align: center;
        cursor: pointer;
    }
`;

export const MainContainerInner = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0;
    align-items: stretch;

    ${props => (props.theme || {}).iOS ? css`
        height: calc(100vh - 130px); /* this is fix for iOS Safari ;(*/
    ` : css`
        height: calc(100vh - 130px); /* this is fix for IE11 ;(*/
        ${media.desktop`
            height: calc(100vh - 140px); /* this is fix for IE11 ;(*/
        `}
    `}

    ${media.desktopLegacy`
        max-width: 1100px;
    `}

    .left-side, .right-side {
        display: flex;
        align-items: stretch;
    }
    .left-side > div, .right-side > div {
        flex: 1;
    }
`;

// TopBar styles
export const TopBarContainer = styled.div`
    background: ${colorStack.white};
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid ${colorStack.ligthGrey};
    margin: 1rem 0 0 0;
`;

export const TopBarContainerInner = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1100px;
    .new-message{
        width: 32px;
        margin: 16px 0 0 5px;
        cursor: pointer;
    }
    .back-arrow{
        cursor: pointer;
        width: 19px;
    }

    .left-side {
        width: auto;

        ${media.tabletSm`
            width: 40%;
        `}

        ${media.tablet`
            width: 25.5%;
        `}
    }
`;

export const UserPhoto = styled.div`
    width: 56px;
    height: 56px;
`;

export const UserBox = styled.div`
    padding: 0 0 0 17px;
    height: 79px;
    display: flex;
    align-items: center;
`;

export const UserMeta = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 0 15px;
    .name {
        font-weight: 500;
        color: ${colorStack.darkBlue};
        font-size: ${fontSize[13]};
        margin: 0 0 4px 0;

        &.members {
            color: ${colorStack.disabled};
            max-width: 350px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            cursor: pointer;
        }
    }
    .job, .company {
        color: ${colorStack.disabled};
        font-size: ${fontSize[13]};
    }
`;

export const Dots = styled.div`
    background: url('../assets/images/svgicons/dots.svg')  center center no-repeat;
    background-size: 17px;
    border: 1px solid ${colorStack.black};
    border-radius: 20px;
    width: 41px;
    height: 22px;
    cursor: pointer;
`;

export const RightSideInner = styled.div`
    height: 79px;
`;

export const SearchWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 0 5px;
    input {
        border: none;
        font-size: ${fontSize[13]};
        color: ${colorStack.darkBlue};
    }
`;

export const LeftWrapper = styled.div`
    display: flex;
    align-items: center;
`;

// Context Menu styles

export const ContextMenuContainer = styled.div`
    display: flex;
    padding: 0 19px 0 0px;
    svg {
        margin: 0 0 0 1rem;
    }
`;