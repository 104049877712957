import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { IMessagingReducer } from '../../../reducers/messagingReducer';
import { IState } from '../../../reducers';
import { ISingleMessage } from '../../../entities/Messaging/IMessaging';

import { ConversationMenu } from './ConversationMenu';
import { SingleConversationWindow } from './SingleConversationWindow';

interface IMessagingContainerProps {
    lastUploadedAttachment: ISingleMessage;
    lastReceivedMessage: ISingleMessage;
}

export const MessagingContainer: FC<IMessagingContainerProps> = ({ lastUploadedAttachment, lastReceivedMessage }) => {
    const { activeConversations } = useSelector<IState, IMessagingReducer>(state => state.messaging);

    return (
        <>
            <ConversationMenu />
            {(Object.keys(activeConversations || {}) || []).reverse().map((conversationId, index, allConversations) => (
                <SingleConversationWindow
                    key={conversationId}
                    conversation={activeConversations[conversationId].conversation}
                    position={405 + 365 * index}
                    lastUploadedAttachment={lastUploadedAttachment}
                    lastReceivedMessage={lastReceivedMessage}
                />
            ))}
        </>
    );
}

