import React, { FC, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';

interface IInnerSwitchProps {
    isActive?: boolean;
    allowNoValue?: boolean;
    isInteractive?: boolean;
    disabled?: boolean;
}

interface IOptionalSwitchColors {
    activeColor?: string;
    inactiveColor?: string;
}

const Circle = styled.div<IInnerSwitchProps>`
    background: ${colorStack.white};
    border-radius: 100%;
    width: 16px;
    height: 16px;
    position: relative;
    left: 0;
    transition: all 0.3s;
    ${({ isActive, allowNoValue }) => isActive === null && allowNoValue && css`
        left: 10px;
    `}
    ${({ isActive }) => isActive === true && css`
        left: 19px;
    `}
`;

const Box = styled.div<IInnerSwitchProps & IOptionalSwitchColors>`
    ${props => css`
        background: ${props.inactiveColor || colorStack.middleGrey};
    `}
    border-radius: 11px;
    width: 40px;
    height: 20px;
    padding: 2px;
    margin-right: 10px;
    ${props => props.isInteractive && css`
        cursor: pointer;
    `}
    transition: background-color .2s ease;
    ${({ isActive, allowNoValue }) => allowNoValue && isActive === false && css`
        background: ${colorStack.middleGrey};
    `}
    ${({ isActive, activeColor }) => isActive && css`
        background: ${activeColor || colorStack.lightBlue};
    `}
`;

const Wrapper = styled.div<IInnerSwitchProps & IOptionalSwitchColors>`
    display: flex;
    align-items: center;
    flex-direction: row;
    .text {
        font-size: ${fontSize[10]};
        transition: color .2s ease;
        ${props => css`
            color: ${props.inactiveColor || colorStack.middleGrey};
        `}
        ${({ isActive, allowNoValue }) => allowNoValue && isActive === false && css`
            color: ${colorStack.middleGrey};
        `}
        ${({ isActive, activeColor }) => isActive && css`
            color: ${activeColor || colorStack.darkBlue};
        `}
    }
    ${({ disabled }) => disabled && css`
        opacity: .5;
    `}
`;
interface IProps {
    state: boolean | null;
    showText?: boolean;
    customActiveColor?: string;
    customInactiveColor?: string;
    onlyView?: boolean;
    disabled?: boolean;
    allowNoValue?: boolean;
    className?: string;
    changeHandler?(value: boolean | null);
}

// tslint:disable-next-line:no-null-keyword
const values = [false, null, true];

export const Switch: FC<IProps> = ({onlyView, state, changeHandler, showText, customActiveColor, customInactiveColor, allowNoValue, className, disabled }) => {
    const intl = useIntl();
    const onChange = useCallback(() => {
        if (onlyView || disabled) {
            return;
        }

        if (allowNoValue) {
            const currentValueIndex = Math.max(values.indexOf(state));
            const nextValueIndex = currentValueIndex + 1;
            const nextValue = typeof values[nextValueIndex] === 'undefined' ? values[0] : values[nextValueIndex];
            changeHandler(nextValue);
        } else {
            changeHandler(!state);
        }
    }, [onlyView, disabled, changeHandler, state, allowNoValue]);

    const yesNoLabel = state
        ? intl.formatMessage({ id: 'global.switch.yes' })
        : intl.formatMessage({ id: 'global.switch.no' });
    return (
        <Wrapper activeColor={customActiveColor} inactiveColor={customInactiveColor} isActive={state} allowNoValue={allowNoValue} className={className} disabled={disabled}>
            <Box isActive={state} onClick={!!changeHandler ? onChange : undefined} isInteractive={!!changeHandler} activeColor={customActiveColor} inactiveColor={customInactiveColor} allowNoValue={allowNoValue}>
                <Circle isActive={state} allowNoValue={allowNoValue} />
            </Box>
            {showText &&
                <span className="text">
                    {(allowNoValue && state === null)
                        ? intl.formatMessage({ id: 'global.switch.noValue' })
                        : yesNoLabel
                    }
                </span>
            }
        </Wrapper>
    );
};
