import React, { useEffect, useState, useCallback, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IDropdownOption } from '@fluentui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons';

import { colorStack } from '../../../../../../styleHelpers/colors';
import { fontSize } from '../../../../../../styleHelpers/fontSizes';
import { IOrganizationMember } from '../../../../../../entities/IOrganization';
import { exportDatesAsICS } from '../../../../../../actions/datesActions';
import { getReferentials } from '../../../../../../actions/globalActions';
import { getOrganizationMembersSearch } from '../../../../../../actions/organizationActions';
import { Button } from '../../../../../Common/Buttons/Button';
import { DebouncedPeoplePicker } from '../../../../../Common/DebouncedPeoplePicker';
import { TextComponent } from '../../../../../Common/Inputs/TextComponent';
import { Referentials, IContextList, IReferencial } from '../../../../../../entities/IGlobal';
import { IDates, IDatesStatus, IDatesTypes, IRecuringType } from '../../../../../../entities/IDates';
import { IStakeConfiguration } from '../../../../../../entities/IClusterStake';
import { IState } from '../../../../../../reducers';
import { IClusterReducer } from '../../../../../../reducers/clustersReducer';
import { StatusDropdown } from './StatusDropdown';
import { inputsShadow } from '../../../../../../styleHelpers/mixins/shadow';
import { LabelWithEllipsis } from '../../../../../Common/Label/Label';
import { DatePickerWrapper } from '../../../../../Common/DatePicker/DatePicker';
import { OnOff } from '../../../../../Common/Buttons/OnOff';
import { SelectInput } from '../../../../../Common/SelectInput/SelectInput';
import { getDaysOfMonth, translatedDaysNames, translatedMonthNames } from '../../../../../../tools/date';

type GetOrganizationMembersSearch = ReturnType<typeof getOrganizationMembersSearch>;
type GetReferentials = ReturnType<typeof getReferentials>;
type ExportDatesAsICS = ReturnType<typeof exportDatesAsICS>;

const Wrapper = styled.div`
    width: 100%;
`;

const DateSelectBox = styled.div`
    padding: 1rem;
    margin: 0 0 1rem 0;
    background-color: ${colorStack.white};
    border-radius: 4px;
`;

const MainBoxHeader = styled.div<{ active: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    > div {
        width: 250px;
    }
    p {
        text-align: left;
        margin: 0;
        font-size: ${fontSize[20]};
    }
    ${props => props.active && css`
        span, svg {
            color: ${colorStack.middleBlue};
            stroke: ${colorStack.middleBlue};
        }
    `}
`;

const MainBoxItems = styled.div`
    width: 100%;
`;

const BoxItem = styled.div<{ isFilled?: boolean }>`
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    > div, > textarea {
        flex: 3;
        max-width: 75%;
        ${props => props.isFilled && css`
            border-bottom: 1px solid ${colorStack.darkRed};
        `}
    }
    .ms-TextField input {
        border: none;
        ${inputsShadow()};
        ${props => props.isFilled && css`
            border-bottom: 1px solid ${colorStack.darkRed};
        `}
    }
`;

const Status = styled.div`
    color: ${colorStack.label};
    font-size: ${fontSize[13]};
`;

const TwoBox = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-bottom: 20px;
`;
const SingleBox = styled.div`
    width: 48%;
    padding: 1rem;
    background-color: ${colorStack.white};
    border-radius: 4px;
`;

const Reminder = styled.div<{ TextColor: string }>`
    display: flex;
    cursor: pointer;
    align-items: center;
    font-size: ${fontSize[13]};
    margin-bottom: 10px;
    ${props => props.TextColor && css`
        color: ${props.TextColor};
    `}
    svg {
        width: 50px!important;
        margin-right: 1rem;
    }
`;
const Days = styled.div<{ TextColor: string }>`
    display: flex;
    cursor: pointer;
    align-items: center;
    font-size: ${fontSize[13]};
    margin-bottom: 10px;
    ${props => props.TextColor && css`
        color: ${props.TextColor};
    `}
`;

const InputWrapper = styled.div`
    width: 50px;
    margin-right: 1rem;
`;

const DateBoxItem = styled.div<{ active: boolean }>`
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    position: relative;
    left: -1rem;
    width: calc(100% + 2rem);
    padding: 1rem;
    background: ${colorStack.white};
    transition: all .3s;
    ${props => props.active && css`
        background: ${colorStack.whiteBlue};
    `}
`;

const DateBox = styled.div`
    display: flex;
    align-items: flex-end;
    > div:first-child {
        width: 250px;
        margin: 0 2rem 0 0;
    };
`;

const DaysName = styled.div`
    margin: 0 0 1rem 0;
`;

const MonthBox = styled.div`
    display: flex;
    width: 50%;
    align-self: flex-end;
    align-items: center;
    > span {
        display: block;
        margin: 0 .5rem 0 0;
    }
    > div {
        width: 60px;
    }
`;

const YearBox = styled.div`
    display: flex;
    width: 50%;
    align-self: flex-end;
    align-items: center;
    > span {
        display: block;
        margin: 0 .5rem 0 0;
    }
    > div {
        &:nth-child(1) {
            width: 60px;
            margin: 0 .5rem 0 0;
        }
        &:nth-child(2) {
            width: 180px;
        }
    }
`;

const DayNameItem = styled.span<{ active: boolean }>`
    width: 24px;
    height: 24px;
    cursor: pointer;
    font-size: ${fontSize[16]};
    border-radius: 100%;
    display: inline-flex;
    color: ${colorStack.content};
    align-items: center;
    justify-content: center;
    background: transparent;
    transition: all .3s;
    ${props => props.active && css`
        background: ${colorStack.middleBlue};
    `};
`;

const RepeatBox = styled.div`
    margin: 0 2rem 0 0;
    > div {
        width: 250px;
        display: flex;
        justify-content: space-between;
        > div {
            width: 72%;
            &:first-child {
                width: 25%;
            }
        }
    }
`;

const ChangeBox = styled.div`
    display: flex;
    padding: 1rem 0;
`;

enum RecuringTypes {
    Day = 'Day',
    Week = 'Week',
    Month = 'Month',
    Year = 'Year'
}

interface IProps {
    currentOrgId: string;
    currentDate: IDates;
    configuration: IStakeConfiguration;
    stakeFamilyKey?: string;
    stakeListKey?: string;
    hardTitle?: boolean;
    isValid(valid: boolean);
    getDataFromPopup?(currentDate: IDates);
}

export const DatesPopup: FC<IProps> = props => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [statuses, setStatuses] = useState<IReferencial[]>(undefined);
    const [titles, setTitles] = useState<IReferencial[]>(undefined);
    const [isReminderValid, setIsReminderValid] = useState<boolean>(false);
    const [currentDate, setCurrentDate] = useState<IDates>(props.currentDate || {
        actors: [],
        description: '',
        isReminderActivated: false,
        // tslint:disable-next-line:no-null-keyword
        reminder: null,
        // tslint:disable-next-line:no-null-keyword
        endAt: null,
        // tslint:disable-next-line:no-null-keyword
        startAt: null,
        // tslint:disable-next-line:no-null-keyword
        status: null,
        // tslint:disable-next-line:no-null-keyword
        title: null,
        // tslint:disable-next-line:no-null-keyword
        type: null,
        // tslint:disable-next-line:no-null-keyword
        recuring: null,
        // tslint:disable-next-line:no-null-keyword
        nextOccurrenceDate: null
    });

    const repeatSelectOption = [
        intl.formatMessage({ id: 'global.day' }),
        intl.formatMessage({ id: 'global.week' }),
        intl.formatMessage({ id: 'global.month' }),
        intl.formatMessage({ id: 'global.year' })
    ];

    const [isPropertyFilled, setIsPropertyFilled] = useState({
        isActorEmpty: false
    });

    const { currentClusterId } = useSelector<IState, IClusterReducer>(state => state.clusters);

    useEffect(() => {
        dispatch<GetReferentials>(getReferentials('', Referentials.KeyDateStatus, IContextList.Stake)).then(response => {
            setStatuses(response);
        });
        if (props.hardTitle) {
            dispatch<GetReferentials>(getReferentials('', props.configuration.titleReferentialIdentityType, props.configuration.titleReferentialIdentityContext)).then(response => {
                setTitles(response);
            });
        }
        setCurrentDate(props.currentDate);
    }, []);

    useEffect(() => {
        titles?.length > 0 && setCurrentDate(state => ({ ...state, title: titles?.[0] }));
    }, [titles]);

    const isRecuringTypeValid = useCallback((type: string) => {
        if (type === RecuringTypes.Day) {
            return true;
        }
        if (type === RecuringTypes.Week && currentDate?.recuring?.frequency?.daysOfWeek?.length > 0) {
            return true;
        }
        if (type === RecuringTypes.Month && currentDate?.recuring?.frequency?.dayOfMonth) {
            return true;
        }
        if (type === RecuringTypes.Year && currentDate?.recuring?.frequency?.dayOfMonth && currentDate?.recuring?.frequency?.month) {
            return true;
        }
        return false;
    }, [currentDate]);

    useEffect(() => {
        if ((( currentDate?.title?.name || currentDate?.recuring?.frequency?.endDate) && (currentDate?.isReminderActivated ? currentDate?.actors?.length !== 0 : true) &&
            (currentDate?.recuring ? (currentDate?.recuring?.frequency?.startDate && isRecuringTypeValid(currentDate?.recuring?.frequency?.type) && currentDate?.recuring?.frequency?.number && currentDate?.title?.name) : true))) {
            props.isValid(true);
            props.getDataFromPopup && props.getDataFromPopup(currentDate);
        } else {
            props.isValid(false);
        }
        if (currentDate?.actors?.length > 0 && currentDate?.title?.name && (currentDate?.endAt || currentDate?.recuring?.frequency?.endDate && currentDate?.recuring?.frequency?.startDate)) {
            setIsReminderValid(true);
        } else {
            setIsReminderValid(false);
        }
    }, [currentDate]);
    useEffect(() => {
        if (!currentDate?.recuring?.frequency ? (currentDate?.actors?.length < 1 || !currentDate?.title?.name || !currentDate?.endAt)
            : (currentDate?.actors?.length < 1 || !currentDate?.title?.name || !currentDate?.recuring?.frequency?.startDate || !currentDate?.recuring?.frequency?.endDate)) {
            return setCurrentDate(state => ({ ...state, isReminderActivated: false, reminder: { remindBeforeDays: 0, remindEachDays: 0 } }));
        }
    }, [currentDate?.actors, currentDate?.endAt, currentDate?.recuring?.frequency?.endDate, currentDate?.recuring?.frequency?.startDate, currentDate?.title?.name]);

    const changeStartDate = useCallback((date: Date) => {
        // tslint:disable-next-line:no-null-keyword
        const changeHour = date ? new Date(new Date(date).setHours(7)).toUTCString() : null;
        if (currentDate?.recuring) {
            setCurrentDate(state => ({
                ...state, recuring: {
                    ...state.recuring,
                    frequency: {
                        ...state.recuring.frequency,
                        startDate: changeHour
                    }
                }
            }));
        } else {
            setCurrentDate(state => ({ ...state, endAt: changeHour }));
        }
    }, [currentDate]);

    const changeEndDate = useCallback((endAt: Date) => {
        // tslint:disable-next-line:no-null-keyword
        const changeHour = endAt ? new Date(new Date(endAt).setHours(7)).toUTCString() : null;
        if (currentDate?.recuring) {
            setCurrentDate(state => ({
                ...state, recuring: {
                    ...state.recuring,
                    frequency: {
                        ...state.recuring.frequency,
                        endDate: changeHour
                    }
                }
            }));
        } else {
            setCurrentDate(state => ({ ...state, endAt: changeHour }));
        }
    }, [currentDate]);

    const transformPickerResult = useCallback((profile: IOrganizationMember) => ({
        id: profile.id,
        text: `${profile.firstName} ${profile.lastName}`,
        imageInitials: `${(profile.firstName || '').slice(0, 1)}${(profile.lastName || '').slice(0, 1)}`,
        imageUrl: profile.picture
    }), []);

    const resolvePeople = useCallback(async (query: string = ''): Promise<IOrganizationMember[]> => {
        const members = await dispatch<GetOrganizationMembersSearch>(getOrganizationMembersSearch(props.currentOrgId, query));
        return (members || []).map(person => ({
            firstName: person.firstName, lastName: person.lastName, jobTitle: person.jobTitle, picture: person.picture, id: person.id, cabinet: person.cabinet
        })) as IOrganizationMember[];
    }, []);

    const dateInputHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        // tslint:disable-next-line:no-null-keyword
        setCurrentDate(state => ({ ...state, reminder: { remindBeforeDays: Number(text), remindEachDays: null } }));
    }, []);

    const onExport = useCallback(() => {
        if (currentDate?.id) {
            dispatch<ExportDatesAsICS>(exportDatesAsICS(currentClusterId, currentDate.id));
        }
    }, [currentDate?.id]);

    const notesHandler = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const text = e.target.value;
        setCurrentDate(state => ({ ...state, description: text }));
    }, []);

    const changeStatus = useCallback((item: IDatesStatus) => {
        setCurrentDate(state => ({ ...state, status: item }));
    }, []);

    const changeTitle = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const text = e.target.value;
        setCurrentDate(state => ({
            ...state, title: ({
                // tslint:disable-next-line:no-null-keyword
                Key: null,
                // tslint:disable-next-line:no-null-keyword
                id: null,
                // tslint:disable-next-line:no-null-keyword
                logicalId: null,
                name: text
            })
        }));
    }, []);

    const changeActors = useCallback((pickerResults, items) => {
        setCurrentDate(state => ({ ...state, actors: items }));
        setIsPropertyFilled(state => ({
            ...state,
            isActorEmpty: !items
        }));
    }, []);

    const changeReminderStatus = useCallback(() => {
        isReminderValid &&
            setCurrentDate(state => ({ ...state, isReminderActivated: !state.isReminderActivated, reminder: { remindBeforeDays: 0, remindEachDays: 0 } }));
        setIsPropertyFilled(state => ({
            ...state,
            isActorEmpty: !currentDate?.actors?.length
        }));
    }, [currentDate, isReminderValid]);

    const onOffHandler = useCallback((stateButton: boolean) => {
        setCurrentDate(state => ({
            ...state,
            recuring: stateButton ? ({
                frequency: {
                    // tslint:disable-next-line:no-null-keyword
                    type: IRecuringType.Day,
                    // tslint:disable-next-line:no-null-keyword
                    number: null,
                    // tslint:disable-next-line:no-null-keyword
                    startDate: state.endAt,
                    // tslint:disable-next-line:no-null-keyword
                    endDate: null,
                    // tslint:disable-next-line:no-null-keyword
                    daysOfWeek: null,
                    // tslint:disable-next-line:no-null-keyword
                    dayOfMonth: null,
                    // tslint:disable-next-line:no-null-keyword
                    month: null
                }
                // tslint:disable-next-line:no-null-keyword
            }) : null
        }));
    }, []);

    const changeNumberOfRepeat = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        if (Number(text) === 0 || !Number(text) || isNaN(Number(text))) {
            setCurrentDate(state => ({
                ...state, recuring: {
                    ...state.recuring, frequency: {
                        ...state.recuring.frequency,
                        // tslint:disable-next-line:no-null-keyword
                        number: null
                    }
                }
            }));
        }
        if (currentDate?.recuring?.frequency?.type === IRecuringType.Day && (Number(text) < 8 && Number(text) > 0)) {
            setCurrentDate(state => ({
                ...state, recuring: {
                    ...state.recuring, frequency: {
                        ...state.recuring.frequency,
                        number: Number(text)
                    }
                }
            }));
        }
        if (currentDate?.recuring?.frequency?.type === IRecuringType.Week && (Number(text) < 53 && Number(text) > 0)) {
            setCurrentDate(state => ({
                ...state, recuring: {
                    ...state.recuring, frequency: {
                        ...state.recuring.frequency,
                        number: Number(text)
                    }
                }
            }));
        }
        if (currentDate?.recuring?.frequency?.type === IRecuringType.Month && (Number(text) < 13 && Number(text) > 0)) {
            setCurrentDate(state => ({
                ...state, recuring: {
                    ...state.recuring, frequency: {
                        ...state.recuring.frequency,
                        number: Number(text)
                    }
                }
            }));
        }
        if (currentDate?.recuring?.frequency?.type === IRecuringType.Year && Number(text) > 0) {
            setCurrentDate(state => ({
                ...state, recuring: {
                    ...state.recuring, frequency: {
                        ...state.recuring.frequency,
                        number: Number(text)
                    }
                }
            }));
        }
    }, [currentDate]);

    const changeRepeatOption = useCallback((event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        // currentDate?.recuring?.frequency?.type
        setCurrentDate(state => ({
            ...state, recuring: {
                ...state.recuring, frequency: {
                    ...state.recuring.frequency,
                    type: option.key as IRecuringType,
                    // tslint:disable-next-line:no-null-keyword
                    dayOfMonth: null,
                    // tslint:disable-next-line:no-null-keyword
                    number: null,
                    // tslint:disable-next-line:no-null-keyword
                    daysOfWeek: null,
                    month: 1
                }
            }
        }));
    }, []);

    const selectDayOfWeek = useCallback((day: number) => {
        setCurrentDate(state => ({
            ...state, recuring: {
                ...state.recuring, frequency: {
                    ...state.recuring.frequency,
                    daysOfWeek: [day]
                }
            }
        }));
    }, []);

    const changeOnDay = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        if (Number(text) === 0 || !Number(text) || isNaN(Number(text))) {
            setCurrentDate(state => ({
                ...state, recuring: {
                    ...state.recuring, frequency: {
                        ...state.recuring.frequency,
                        // tslint:disable-next-line:no-null-keyword
                        dayOfMonth: null
                    }
                }
            }));
        }
        if (currentDate?.recuring?.frequency?.type === IRecuringType.Month && Number(text) < 32 && Number(text) > 0) {
            setCurrentDate(state => ({
                ...state, recuring: {
                    ...state.recuring, frequency: {
                        ...state.recuring.frequency,
                        dayOfMonth: Number(text)
                    }
                }
            }));
        }
        if (currentDate?.recuring?.frequency?.type === IRecuringType.Year && Number(text) < getDaysOfMonth[currentDate?.recuring?.frequency?.month - 1].length + 1 && Number(text) > 0) {
            setCurrentDate(state => ({
                ...state, recuring: {
                    ...state.recuring, frequency: {
                        ...state.recuring.frequency,
                        dayOfMonth: Number(text)
                    }
                }
            }));
        }
    }, [currentDate]);

    const changeMonth = useCallback((event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        setCurrentDate(state => ({
            ...state, recuring: {
                ...state.recuring, frequency: {
                    ...state.recuring.frequency,
                    month: option.data
                }
            }
        }));
    }, []);

    return (
        <Wrapper>
            <DateSelectBox>
                <MainBoxHeader active={!!currentDate?.id}>
                    {(props.hardTitle || (props.currentDate && (props.currentDate?.type && props.currentDate?.type?.name !== IDatesTypes.KeyDate))) ? (
                        <span>{currentDate?.title?.name || titles?.[0].name}</span>
                    ) : (
                        <TextComponent onChange={changeTitle} value={currentDate?.title?.name} />
                    )}
                    <Button type="button" ico={faCalendarAlt} bgColor="transparent" fontColor={colorStack.middleBlue} onClick={onExport} disabled={!currentDate?.id}>
                        <FormattedMessage id="dates.button.sync" />
                    </Button>
                </MainBoxHeader>
                <MainBoxItems>
                    <DateBoxItem active={!!currentDate?.recuring}>
                        <DateBox>
                            {!currentDate?.recuring &&
                                <DatePickerWrapper
                                    value={currentDate?.endAt ? new Date(currentDate?.endAt) : undefined}
                                    onChange={changeStartDate}
                                    label={<FormattedMessage id="dates.label.startDate" />}
                                />
                            }
                            {currentDate?.recuring &&
                                <DatePickerWrapper
                                    value={currentDate?.recuring?.frequency?.startDate ? new Date(currentDate?.recuring?.frequency?.startDate) : undefined}
                                    onChange={changeStartDate}
                                    label={<FormattedMessage id="dates.label.startDate" />}
                                />
                            }
                            <OnOff getCurrentState={onOffHandler} state={!!currentDate?.recuring} data-lc="js-lc-button-dates-periodicity" />
                        </DateBox>
                        {currentDate?.recuring &&
                            <div>
                                <ChangeBox>
                                    <RepeatBox>
                                        <LabelWithEllipsis>
                                            <FormattedMessage id="dates.label.repeatEvery" />
                                        </LabelWithEllipsis>
                                        <div>
                                            <TextComponent value={currentDate?.recuring?.frequency?.number?.toString()} onChange={changeNumberOfRepeat} />
                                            <SelectInput
                                                selectedKey={currentDate?.recuring?.frequency?.type}
                                                onChange={changeRepeatOption}
                                                options={repeatSelectOption?.map((elem, index) => ({
                                                    key: Object.keys(IRecuringType)[index],
                                                    text: elem,
                                                    data: elem
                                                }))}
                                            />
                                        </div>
                                    </RepeatBox>
                                    {currentDate?.recuring?.frequency?.type === IRecuringType.Month &&
                                        <MonthBox>
                                            <FormattedMessage id="dates.label.onDay" />
                                            <TextComponent value={currentDate?.recuring?.frequency?.dayOfMonth?.toString()} onChange={changeOnDay} />
                                        </MonthBox>
                                    }
                                    {currentDate?.recuring?.frequency?.type === IRecuringType.Year &&
                                        <YearBox>
                                            <FormattedMessage id="dates.label.on" />
                                            <TextComponent value={currentDate.recuring.frequency?.dayOfMonth?.toString()} onChange={changeOnDay} />
                                            <SelectInput
                                                selectedKey={currentDate.recuring.frequency?.month?.toString()}
                                                onChange={changeMonth}
                                                options={translatedMonthNames(intl)}
                                            />
                                        </YearBox>
                                    }
                                </ChangeBox>
                                {currentDate?.recuring?.frequency?.type === IRecuringType.Week &&
                                    <DaysName>
                                        {translatedDaysNames(intl).map((elem, index) =>
                                            <DayNameItem key={elem.key} active={currentDate.recuring.frequency?.daysOfWeek?.[0] === Number(elem.key)} onClick={() => selectDayOfWeek(Number(elem.key))}>
                                                {elem.text[0]}
                                            </DayNameItem>
                                        )}
                                    </DaysName>
                                }
                                <DatePickerWrapper
                                    value={currentDate?.recuring?.frequency?.endDate ? new Date(currentDate?.recuring?.frequency?.endDate) : undefined}
                                    onChange={changeEndDate}
                                    label={<FormattedMessage id="dates.label.endDate" />}
                                />
                            </div>
                        }
                    </DateBoxItem>
                    <BoxItem isFilled={isPropertyFilled.isActorEmpty}>
                        <LabelWithEllipsis><FormattedMessage id="dates.label.actors" /></LabelWithEllipsis>
                        <DebouncedPeoplePicker
                            noResultsFoundText={intl.formatMessage({ id: 'cluster.placeholder.noresult' })}
                            resolveQuery={resolvePeople}
                            transformResults={transformPickerResult}
                            selectedItems={currentDate?.actors}
                            onChange={changeActors}
                            newDesign
                        />
                    </BoxItem>
                    <BoxItem>
                        <TextComponent
                            label={<FormattedMessage id="dates.label.notes" />}
                            type="Textarea"
                            onChange={notesHandler}
                            value={currentDate?.description}
                        />
                    </BoxItem>

                </MainBoxItems>
            </DateSelectBox>
            <TwoBox>
                <SingleBox>
                    <Status><FormattedMessage id="dates.label.status" /></Status>
                    {statuses &&
                        <StatusDropdown
                            selectedKey={currentDate?.status}
                            onChange={changeStatus}
                        />
                    }
                </SingleBox>
                <SingleBox>
                    <Reminder onClick={changeReminderStatus} TextColor={currentDate?.isReminderActivated ? colorStack.middleBlue : (isReminderValid ? colorStack.label : colorStack.middleGrey)} data-lc="js-lc-button-dates-reminder">
                        <FontAwesomeIcon icon={faBell} size="lg" />
                        <FormattedMessage id="dates.label.setReminder" />
                    </Reminder>
                    <Days TextColor={currentDate?.isReminderActivated ? colorStack.middleBlue : colorStack.middleGrey}>
                        <InputWrapper>
                            <TextComponent disabled={!currentDate?.isReminderActivated} onChange={dateInputHandler} value={currentDate?.reminder?.remindBeforeDays?.toString()} />
                        </InputWrapper>
                        <FormattedMessage id="dates.label.daysBefore" />
                    </Days>
                </SingleBox>
            </TwoBox>
        </Wrapper>
    );
};

export default DatesPopup;