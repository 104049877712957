export const fontSize = {
    10: '0.684rem', // === 10.944px
    13: '0.887rem', // === 14.192px
    16: '1.0rem', // === 16px
    20: '1.322rem', // === 21.152px
    25: '1.656rem', // === 26.496px
    31: '1.988rem', // === 31.808px
    39: '2.500rem', // === 40.000px
    49: '3.114rem', // === 49.824px
    61: '3.891rem', // === 62.256px
    76: '4.785rem', // === 76.560px
    95: '6.090rem'  // === 97.440px
};

export const fontSizeAndHeight = {
    10: 'font-size: 0.684rem; line-height: 0.75rem;',
    13: 'font-size: 0.887rem; line-height: 1rem;',
    16: 'font-size: 1rem; line-height: 1.25rem;',
    20: 'font-size: 1.322rem; line-height: 1.5rem;',
    25: 'font-size: 1.656rem; line-height: 1.9rem;',
    31: 'font-size: 1.988rem; line-height: 2.25rem;',
    39: 'font-size: 2.5rem; line-height: 2.9rem;',
    49: 'font-size: 3.114rem; line-height: 3.5rem;',
    61: 'font-size: 3.891rem; line-height: 4.3rem;',
    76: 'font-size: 4.785rem; line-height: 5.4rem;',
    95: 'font-size: 6.09rem; line-height: 6.3rem;'
};