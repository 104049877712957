import React, { FC, useEffect, useState, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { FormattedMessage } from 'react-intl';
import {
    PdfViewerComponent,
    Toolbar,
    Magnification,
    ThumbnailView,
    Navigation,
    LinkAnnotation,
    BookmarkView,
    TextSelection,
    TextSearch,
    FormFields,
    FormDesigner,
    Annotation,
    Print,
    Inject
} from '@syncfusion/ej2-react-pdfviewer';
import { faClose } from '@fortawesome/pro-light-svg-icons';

import store from '../../../store';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { FileIcon } from '../../Organization/ClustersV2/ClusterActivity/Components/FileIcon';
import { Button } from '../../Common/Buttons/Button';

const Wrapper = styled.div<{ $topBarVisible: boolean, $leftBarVisible: boolean }>`
    width: ${props => (props.$topBarVisible ? css`calc(100% - 65px)` : css`100%`)};
    height: ${props => (props.$leftBarVisible ? css`calc(100% - 45px)` : css`100%`)};
    position: fixed;
    overflow: auto;
    top: ${props => (props.$topBarVisible ? css`45px` : css`0`)};
    left: ${props => (props.$leftBarVisible ? css`65px` : css`0`)};
    background: ${rgba(colorStack.black, 0.8)};
    z-index: 99999;

    input[type="file"] {
        display: none;
    }
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
    background: ${rgba(colorStack.black, 0.6)};
    > div {
        display: flex;
        align-items: center;
        .pdf-ico {
            color: ${colorStack.white};
            font-size: ${fontSize[20]};
            margin-right: 10px;
        }
    }
    .pdf-name {
        color: ${colorStack.white};
        font-weight: 700;
        font-size: ${fontSize[20]};
    }
    .close-button {
        margin: 0 30px 0 auto;
    }
`;

const PdfContent = styled.div`
    pointer-events: auto;
    margin: 0 auto;
`;

interface IPdfWrapperProps {
    document: string | Uint8Array; // URL, base64 or binary Uint8Array
    pdfName: ReactNode;
    isOverlayActive?: boolean;
    onDownloadClick?(): void;
    onCloseClick?(): void;
}

export const PdfViewer: FC<IPdfWrapperProps> = props => {
    const state = store.getState();
    const { uriCommonApi } = state.config.appConfig;
    const pdfViewerApi = uriCommonApi + '/pdfviewer';
    const { token } = state.context;
    const { subKey } = state.config;
    const { document, pdfName, onCloseClick } = props;
    const [isDocumentUrl, setIsDocumentUrl] = useState<boolean>(false);
    const [documentLoading, setDocumentLoading] = useState<boolean>(true);

    useEffect(() => {
        if (typeof document === 'string') {
            if (document.match(/^https?:\/\//)) {
                setIsDocumentUrl(true);
            }
        }
    }, [document]);

    const onDocumentLoaded = () => {
        setDocumentLoading(false);
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };
    const onClose = (e) => {
        stopPropagation(e);
        onCloseClick();
    };

    return (
        <div onClick={stopPropagation}>
            <link href="https://cdn.syncfusion.com/ej2/20.4.48/bootstrap5.css" rel="stylesheet" />
            {!props.isOverlayActive ? (
                <Wrapper $leftBarVisible $topBarVisible>
                    <Header>
                        <div>
                            <div className="pdf-ico">
                                <FileIcon mimeType="pdf" />
                            </div>
                            <div className="pdf-name">{pdfName}</div>
                        </div>
                        <Button onClick={onClose} ico={faClose}>
                            <FormattedMessage id="global.close" />
                        </Button>
                    </Header>
                    {isDocumentUrl ? (
                        <PdfContent>
                            <PdfViewerComponent
                                id="pdfDocument"
                                documentLoad={onDocumentLoaded}
                                toolbarSettings={{
                                    showTooltip: true,
                                    toolbarItems: [
                                        'SelectionTool',
                                        'SearchOption',
                                        'PageNavigationTool',
                                        'MagnificationTool',
                                        'PanTool',
                                        'PrintOption',
                                        'DownloadOption'
                                    ]
                                }}
                                enableHyperlink
                                isThumbnailViewOpen
                                downloadFileName={pdfName.toString()}
                                documentPath={document as string}
                                style={{ display: 'block', height: '100vh' }}
                                serviceUrl={pdfViewerApi}
                                ajaxRequestSettings={{
                                    ajaxHeaders: [
                                        {
                                            headerName: 'Authorization',
                                            headerValue: `Bearer ${token}`
                                        },
                                        {
                                            headerName: 'Ocp-Apim-Subscription-Key',
                                            headerValue: subKey
                                        }
                                    ]
                                }}
                            >
                                <Inject
                                    services={[
                                        Toolbar,
                                        Navigation,
                                        BookmarkView,
                                        ThumbnailView,
                                        Magnification,
                                        TextSelection,
                                        TextSearch,
                                        FormFields,
                                        FormDesigner,
                                        Annotation,
                                        LinkAnnotation,
                                        Print
                                    ]}
                                />
                            </PdfViewerComponent>
                            {documentLoading && <FormattedMessage id="global.loadingDots" />}
                        </PdfContent>
                    // tslint:disable-next-line:no-null-keyword
                    ) : null}
                </Wrapper>
            // tslint:disable-next-line:no-null-keyword
            ) : null}
        </div>
    );
};
