import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextEncoder } from 'text-encoding-shim';

import { IState } from '../../reducers';
import { IConfigReducer } from '../../reducers/configReducer';
import { IContextReducer } from '../../reducers/contextReducer';
import { IAnalyticsReducer } from '../../reducers/analyticsReducer';
import { TrackingType } from '../../entities/IAnalytics';
import { addTrackingScript, isTrackingScriptAdded } from '../../tools/analyticsTools';
import { setTracking } from '../../actions/analyticsActions';
import { instanceConfig } from '../../instance';

export const Tracking: FC = () => {
    const dispatch = useDispatch();
    const { appConfig, isLoggedIn, pageContext } = useSelector<IState, IConfigReducer & IContextReducer & IAnalyticsReducer>(state => ({
        ...state.config,
        ...state.context,
        ...state.analytics
    }));

    const digestMessage = async (message: string) => {
        const msgUint8 = new TextEncoder().encode(message);
        const crypto = window.crypto || window.msCrypto;
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        return hashHex;
    };

    useEffect(() => {
        const lcTrackingData = appConfig?.smh;
        const sgTrackingData = appConfig?.sg;
        const userEmail = pageContext?.user?.email;
        const stonlyTracking = instanceConfig?.stonly;

        if (!isTrackingScriptAdded() && isLoggedIn) {
            const initCallback = async () => {
                try {

                    const hashedUserEmail = await digestMessage(userEmail);

                    const Matomo = window.Matomo || window.Piwik;

                    const lcTracker = lcTrackingData?.trackingUrl && Matomo?.getTracker?.(`${lcTrackingData.trackingUrl}matomo.php`, lcTrackingData.trackingId);
                    const sgTracker = sgTrackingData?.trackingUrl && Matomo?.getTracker?.(`${sgTrackingData.trackingUrl}piwik.php`, sgTrackingData.trackingId);

                    const department: string = pageContext?.user?.department;
                    const hasDepartment = department && department !== 'unknown';
                    const trimmedDepartment = hasDepartment && department.split('/').filter(val => val).slice(0, 3).join('/');
                    trimmedDepartment && sgTracker?.setCustomVariable?.(1, 'department', trimmedDepartment, 'visit');

                    [{
                        tracker: lcTracker,
                        trackingType: TrackingType.Smh
                    }, {
                        tracker: sgTracker,
                        trackingType: TrackingType.Sg
                    }].forEach(trackerObject => {
                        trackerObject.tracker?.setUserId?.(hashedUserEmail);
                        trackerObject.tracker?.setCustomUrl?.(window.location.href);
                        trackerObject.tracker?.trackPageView?.();
                        trackerObject.tracker && dispatch(setTracking(trackerObject.trackingType, trackerObject.tracker));
                    });
                } catch (error) {
                    console.error(error);
                }
            };

            window.matomoAsyncInit = initCallback;
            window.piwikAsyncInit = initCallback;

            const lcScript = lcTrackingData?.trackingUrl && `${lcTrackingData?.trackingUrl}matomo.js`;
            const sgScript = sgTrackingData?.trackingUrl && `${sgTrackingData?.trackingUrl}piwik.js`;
            const scriptUrl = (lcTrackingData?.trackingUrl && lcScript) || (sgTrackingData?.trackingUrl && sgScript);
            scriptUrl && addTrackingScript(scriptUrl);
        }

        if (stonlyTracking && !window.location.host.includes('local')) {
            const script = document.createElement('script');
            script.src = require('!!url-loader!../../tools/stonlyScript.js').default;
            script.async = true;
            document.body.appendChild(script);
        }

    }, [isLoggedIn]);

    return <></>;
};

export default Tracking;
