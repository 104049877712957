export enum TrackingType {
    Smh,
    Sg
}

export const AnalyticsScriptName = 'data-piwik-script';
export interface ITracker {
    trackPageView(customTitle?: [string]);
    setCustomUrl(url: string);
    setCustomVariable(index: number, name: string, value: string, scope: string);
    trackEvent(category: string, action: string, name?: string, value?: number);
    setUserId(userId: string);
}

export interface IMatomo {
    getTracker(trackerUrl: string, siteId: string): ITracker;
}