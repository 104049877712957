import React, { FC, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { tokenReload } from '../actions/contextActions';
import { colorStack } from '../styleHelpers/colors';
import { fontSizeAndHeight } from '../styleHelpers/fontSizes';
import { history } from '../history';
import { media } from '../styleHelpers/breakpoint';
import { instanceConfig } from '../instance';
import { Button } from './Common/Buttons/Button';

// tslint:disable-next-line:no-var-requires
const { IntercomAPI } = instanceConfig.intercom && require('react-intercom');

const Wrapper = styled.div`
    height: 100%;
    margin-bottom: -60px;
`;

const Inner = styled.div`
    display: flex;
    flex-direction: column;
    ${media.tablet`
        height: 100%;
        flex-direction: row;
    `}
`;

const LeftCol = styled.div`
    flex: 2;
    background: ${colorStack.whiteGreen};
    display: flex;
    height: 100%;
    align-items: center;

    > div {
        flex: 1;
        margin: 1rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        ${media.tablet`
            margin: 5rem 0;
            align-items: normal;
        `}
    }

    img {
        width: 120px;
        ${media.tablet`
            width: 130%;
            transform: translateX(-35%);
        `}
    }
`;

const LeftHeader = styled.div`
    color: ${colorStack.white};
    text-align: center;
    margin-bottom: 1rem;
    ${fontSizeAndHeight[31]};
    ${media.tablet`
        margin-bottom: 1.5rem;
        ${fontSizeAndHeight[49]};
    `}
`;

const RightCol = styled.div`
    flex: 3;
    display: flex;
    align-items: center;

    > div {
        width: 100%;
        padding: 1rem 0;
        ${media.tablet`
            padding: 1rem 2rem 3rem 2rem;
        `}
    }
`;

const RightText = styled.div`
    margin-bottom: 0.8rem;
`;

const Buttons = styled.div`
    margin-top: 0.8rem;
    padding-top: 0.8rem;
    border-top: 1px solid ${colorStack.ligthGrey};

    button {
        margin-right: 1rem;
        margin-bottom: 1rem;
    }
`;

export const PageNotFound: FC = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(tokenReload(false));
    }, []);

    const goToHomepage = useCallback(() => {
        history.push('/');
    }, []);

    const report = useCallback(() => {
        IntercomAPI?.('show');
    }, []);

    return (
        <Wrapper className="lcr-section lcr-margin-t2em">
            <Inner>
                <LeftCol>
                    <div>
                        <LeftHeader>Error <strong>404</strong></LeftHeader>
                        <img src={require('../../wwwroot/assets/images/cogs.svg')} />
                    </div>
                </LeftCol>
                <RightCol>
                    <div>
                        <RightText>ERROR 404</RightText>
                        <RightText><strong>Oops, the page you're looking for does not exist.</strong></RightText>
                        <RightText>You may want to head back to the homepage. <br />If you think something is broken, report a problem.</RightText>
                        <Buttons>
                            <Button onClick={goToHomepage}>
                                Go to homepage
                            </Button>
                            {!!IntercomAPI && (
                                <Button onClick={report}>
                                    Report a bug
                                </Button>
                            )}
                        </Buttons>
                    </div>
                </RightCol>
            </Inner>
        </Wrapper>
    );
};
