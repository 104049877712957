import React, { FC, useEffect, useState, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import browserLanguage from 'in-browser-language';
import moment from 'moment';

import { IState } from './reducers';
import { IContextReducer } from './reducers/contextReducer';
import { ILanguageReducer } from './reducers/languageReducer';
import { getLanguages, setUserLanguage, getTranslationsForCurrentLang, getUserPrefferedLanguage, savePrefferedLanguage } from './actions/languageActions';
import { IConfigReducer } from './reducers/configReducer';
import { Lcid } from './entities/ILanguage';
import { languageList } from './tools/languageTools';
import { useUrlQuery } from './tools/hooks';

export const IntlProviderComponent: FC = ({ children }) => {
    const [nonLoggedInRequested, setNonLoggedInRequested] = useState<boolean>(false);
    const [loggedInRequested, setLoggedInRequested] = useState<boolean>(false);
    const { isLoggedIn, managedLanguages, userLanguage, appConfig, translations } = useSelector<IState, IContextReducer & ILanguageReducer & IConfigReducer>(state => ({
        ...state.context,
        ...state.language,
        ...state.config
    }));
    const { lcid } = useUrlQuery<{ lcid: string }>();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getLanguages());
    }, []);

    const browserUserLanguage = useMemo(() => {
        return browserLanguage.pick(['fr', 'en', 'pl', 'es', 'it', 'uk', 'ru', 'de', 'pt'], appConfig.defaultLanguage);
    }, []); // no need for dependencies, we want browserUserLanguage to be picked just once, and appConfig needs to be guaranteed to be already loaded here

    // translations for non-logged in user based on browserUserLanguage
    useEffect(() => {
        if (!nonLoggedInRequested && !isLoggedIn && managedLanguages?.length) {
            setNonLoggedInRequested(true);
            const selectedLang = managedLanguages?.find(language => language.Code === browserUserLanguage);
            const selectedLcid = selectedLang?.Lcid || Lcid.Fr;
            moment.locale(languageList[selectedLcid]);
            if (lcid) {
                dispatch(setUserLanguage(Number(lcid)));
                dispatch(getTranslationsForCurrentLang(Number(lcid)));
            } else {
                dispatch(setUserLanguage(selectedLcid));
                dispatch(getTranslationsForCurrentLang(selectedLcid));
            }
        }
    }, [nonLoggedInRequested, isLoggedIn, managedLanguages]);

    // translations for logged in user
    useEffect(() => {
        if (!loggedInRequested && isLoggedIn && managedLanguages?.length) {
            setLoggedInRequested(true);

            (async () => {
                const userPrefferedLanguage = await dispatch(getUserPrefferedLanguage());
                const userHasLanguage = userPrefferedLanguage !== -1;
                const selectedLang = managedLanguages?.find(language => language.Code === browserUserLanguage);
                if (!userHasLanguage) {
                    moment.locale(languageList[selectedLang?.Lcid || Lcid.En]);
                    dispatch(savePrefferedLanguage(selectedLang?.Lcid || Lcid.En));
                } else {
                    dispatch(setUserLanguage(userPrefferedLanguage));
                    moment.locale(languageList[userPrefferedLanguage]);
                }

                const langToGetTranslations: Lcid = userHasLanguage ? userPrefferedLanguage : selectedLang?.Lcid || Lcid.En;
                await dispatch(getTranslationsForCurrentLang(langToGetTranslations));
            })();
        }
    }, [loggedInRequested, isLoggedIn, managedLanguages]);

    const messages = useMemo(() => translations[userLanguage], [translations, userLanguage]);
    const locale = useMemo(() => languageList[userLanguage || Lcid.En], [userLanguage]);

    return (
        <IntlProvider locale={locale} messages={messages || {}}>
            {children}
        </IntlProvider>
    );
};

export default IntlProviderComponent;
