import { Location } from 'history';
import queryString, { ParseOptions } from 'query-string-for-all';
import React, { FC, MutableRefObject, ReactNode, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { addAlert as addAlertAction } from '../actions/alertActions';
import { AlertType } from '../entities/IAlert';
import { TypeOfOrganization } from '../entities/IOrganization';
import { history } from '../history';

export const useDropdown = <T extends HTMLElement = HTMLDivElement>(): [
    RefObject<T>,
    boolean,
    () => void,
    () => void,
    () => void
] => {
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const wrapperRef = useRef<T>(null); // tslint:disable-line:no-null-keyword

    const toggleDropdown = useCallback(() => {
        setDropdownOpen(!dropdownOpen);
    }, [dropdownOpen]);

    const closeDropdown = useCallback(() => {
        setDropdownOpen(false);
    }, []);

    const openDropdown = useCallback(() => {
        setDropdownOpen(true);
    }, []);

    const handleClick = useCallback((e: MouseEvent) => {
        const target = e.target as any;
        if (wrapperRef && wrapperRef.current && !wrapperRef.current.contains(target) && !target.dataset.nonclick) {
            setDropdownOpen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
    }, []);

    return [wrapperRef, dropdownOpen, toggleDropdown, closeDropdown, openDropdown];
};

interface IUrlQuery {
    [key: string]: unknown;
}

export const useUrlQuery = <T = IUrlQuery>(options?: ParseOptions) => {
    // unfortunately hack, because, queryString's types don't want to play nice.
    // but useUrlQuery's types are nice.
    const [query, setQuery] = useState<T>(queryString.parse(window.location.search, options) as any as T);

    useEffect(() => {
        const unlisten = history.listen((location: Location) => {
            setQuery(queryString.parse(location.search, options) as any as T);
        });

        return () => {
            unlisten();
        };
    }, [options]);

    const pushQuery = useCallback(
        (nextQuery: T, replacePathname?: string) => {
            const nextQueryString = queryString.stringify(nextQuery, options);
            history.push(`${replacePathname || window.location.pathname}?${nextQueryString}`);
        },
        [options]
    );

    return { ...(query || ({} as T)), pushQuery };
};

interface IResizeState {
    innerWidth: number;
    innerHeight: number;
    onResize?: () => void;
}

export const useResize = (onResize?: () => void) => {
    const [sizeState, setSizeState] = useState<IResizeState>({
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
        onResize
    });

    const debounceRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

    const resizeHandler = useCallback(() => {
        if (debounceRef.current !== undefined) {
            clearTimeout(debounceRef.current);
        }

        debounceRef.current = setTimeout(() => {
            setSizeState({
                innerHeight: window.innerHeight,
                innerWidth: window.innerWidth,
                onResize
            });

            if (onResize) {
                onResize();
            }
        }, 600);
    }, [setSizeState]);

    useEffect(() => {
        window.addEventListener('resize', resizeHandler);

        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, [resizeHandler]);

    return sizeState;
};

interface IUseResizeProps {
    onResize?: () => void;
    children(resize: IResizeState): React.ReactElement;
}

export const UseResize: FC<IUseResizeProps> = ({ children, onResize }) => {
    const { innerHeight, innerWidth } = useResize(onResize);
    return children({ innerHeight, innerWidth });
};

export const useAlert = () => {
    const dispatch = useDispatch();

    const addAlert = useCallback((content: ReactNode, type: AlertType = AlertType.Success) => {
        dispatch(addAlertAction(content, type));
    }, []);

    return addAlert;
};

export const useOutsideClickListener = (ref: React.MutableRefObject<any>, action: () => void) => {
    useEffect(() => {
        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                action();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, action]);
};

export const useMedia = (query: string): boolean => {
    const [matches, setMatches] = useState<boolean>(window.matchMedia(query).matches);

    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }

        const listener = () => setMatches(media.matches);
        media.addListener(listener);

        return () => media.removeListener(listener);
    }, [query]);

    return matches;
};
export const useCancel = (organization: string, currentClusterType: string) => {
    const onCancelClick = useCallback(() => {
        switch (currentClusterType) {
            case TypeOfOrganization.Sponsoring:
            case TypeOfOrganization.Gift:
            case TypeOfOrganization.LegalSupport:
            case TypeOfOrganization.POA:
                history.push(`/orgs/${organization}/dashboard`);
                break;
            case TypeOfOrganization.Invitation:
                history.push(`/orgs/${organization}/clusters/gift-dashboard`);
                break;
            case TypeOfOrganization.COI:
                history.push(`/orgs/${organization}/clusters/coi-dashboard`);
                break;
            default:
                history.push(`/orgs/${organization}/dashboard`);
        }
    }, [organization]);

    return { onCancelClick };
};

const supportedCopyToClipboard = document.queryCommandSupported?.('copy') || !!navigator.clipboard?.writeText;

export const useCopyInputToClipboard = (ref: MutableRefObject<HTMLInputElement>) => {
    const select = useCallback(() => ref.current?.select(), []);
    const copy = useCallback(async () => {
        try {
            await navigator.clipboard.writeText(ref.current?.value);
        } catch (error) {
            select();
            document.execCommand?.('copy');
        }
    }, []);
    return [supportedCopyToClipboard, copy, select] as const;
};

const hiddenInput = document.createElement('input');

export const useCopyValueToClipboard = () => {
    const inputRef = useRef(hiddenInput);
    const [supported, copy] = useCopyInputToClipboard(inputRef);
    const copyValue = useCallback(async (value: string) => {
        try {
            await navigator.clipboard.writeText(value);
        } catch (error) {
            document.body.appendChild(inputRef.current);
            inputRef.current.value = value;
            copy();
            document.body.removeChild(inputRef.current);
        }
    }, []);
    return [supported, copyValue] as const;
};

export const useAutosizeTextArea = (textAreaRef: HTMLTextAreaElement | null, value: string) => {
    useEffect(() => {
        if (textAreaRef) {
            // We need to reset the height momentarily to get the correct
            // scrollHeight for the textarea
            textAreaRef.style.height = '0px';
            const scrollHeight = textAreaRef.scrollHeight;

            // We then set the height directly, outside of the render loop
            // Trying to set this with state or a ref will product an
            // incorrect value.
            textAreaRef.style.height = scrollHeight + 'px';
        }
    }, [textAreaRef, value]);
};
