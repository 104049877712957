export enum ProfileType {
    Lawyer = 1,
    NoLawyer = 0
}

export enum ProfileUtilityConstant {
    UndefinedIdExperience = 'undefinedcompany',
    UndefinedIdFormation = 'undefineddegree',
    UndefinedIdExpertise = 'undefinedexpertise',
    AllResolveSuggestion = 'all',
    BodyMailTo = 'Bonjour%2C%0A%0AJe%20vous%20prie%20Mr%2FMme%20de%20bien%20vouloir%20ajouter%20%3C%7BCompanyName%7D%3E%20comme%20%C3%A9tant%20un%20nouveau%20cabinet%20dans%20la%20liste%20de%20r%C3%A9f%C3%A9rentiel.%0A%0AJe%20vous%20remercie%20d%27avance%2C%0A%0ABien%20%C3%A0%20vous%0A%0A%7BUserDisplayName%7D%C2%A0%20%C2%A0%20%C2%A0%20%C2%A0%0A%0A%C2%A0%0A%0A------------------------------------------%0A%0A%C2%A0%0A%0AHello%2C%0A%0APlease%20Mr%2FMrs%20to%20add%C2%A0%20%3C%7BCompanyName%7D%3E%20as%20new%20company%20to%20the%20repository%20list.%0A%0AI%20thank%20you%20in%20advance%2C%0A%0ABest%20regards%2C%0A%0A%7BUserDisplayName%7D',
    UndefinedIdTalent = 'undefinedTalent',
    UndefinedIdLanguage = 'undefinedLanguage',
    UndefinedIdCountry = 'undefinedCountry',
    UndefinedIdBarAdmission = 'undefinedBar',
    UndefinedIdEspeciality = 'undefinedSpecialitie'
}

export enum ProfileDataType {
    School = 'School',
    Expert = 'Expert',
    Expertise = 'Expertise',
    Language = 'Language',
    Juridiction = 'Juridiction',
    Customer = 'Customer',
    Internal = 'Internal',
    Company = 'Company',
    Organization = 'Organization',
    Competence = 'Competence',
    BusinessSector = 'BusinessSector',
    Country = 'Country',
    Talent = 'Talent',
    FirstReferential = 'FirstReferential',
    SecondReferential = 'SecondReferential',
    ThirdReferential = 'ThirdReferential',
    RoleCategory = 'RoleCategory',
    RoleFunction = 'RoleFunction',
    Department = 'Department',
    Team = 'Team'
}

export enum ReferentialType {
    Language = 'Language',
    Expertises = 'Expertises',
    Competences = 'Competences',
    BusinessSectors = 'BusinessSectors',
    Juridictions = 'Juridictions',
    Schools = 'Schools',
    Countries = 'Countries',
    Talents = 'Talents',
    Companies = 'Companies',
    Organizations = 'Organizations'
}

export enum RelaionCategory {
    BusinessTag = 'BusinessTag',
    CommunityTag = 'CommunityTag'
}