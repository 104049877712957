import React, { FC, useMemo, useState, useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string-for-all';

import { IState } from '../../reducers';
import { IContextReducer } from '../../reducers/contextReducer';
import { media } from '../../styleHelpers/breakpoint';
import { Size } from '../../styleHelpers/sizes';
import { TopBar } from './TopBar/TopBar';
import { SideBar } from './SideBar/SideBar';
import { OverlayContextProvider } from '../NewDesignCommon/Overlay';
import { setExportView } from '../../actions/contextActions';
import { getWopiInfo } from '../../actions/clustersFilesystemActions';
import { wopiFunc } from '../../tools/wopi';
import { useAlert } from '../../tools/hooks';
import { AlertType } from '../../entities/IAlert';

type GetWopiInfo = ReturnType<typeof getWopiInfo>;

const ContentWrapper = styled.div<{ withNavigation?: boolean }>`
    ${({ withNavigation }) => withNavigation && css`
        padding-top: ${Size.TopBarHeightMobile}px;
        ${media.desktop`
            padding-top: ${Size.TopBarHeightDesktop}px;
        `}
    `}
    ${media.desktop`
        :not(.print-layout &) {
            padding-left: ${Size.Level1Width}px;
        }
    `}
`;

const Content = styled.main`
    padding: .65rem;
    position: relative;
    overflow: hidden;
`;

const WopieWrapper = styled.div`
    .custom-iframe-wopi {
        width: 100vw;
        height: 99vh;
    }
`;

interface IProps {
    isTeamsRoute?: boolean;
    isHomeRoute?: boolean;
    isTeamsConfigPage?: boolean;
}

const LayoutWrapper: FC<{ isPrintLayout?: boolean }> = ({ isPrintLayout, children }) => (
    <div className={isPrintLayout ? 'print-layout' : ''}>{children}</div>
);

export const Layout: FC<IProps> = ({ children, isTeamsRoute, isHomeRoute, isTeamsConfigPage }) => {
    const { isLoggedIn, isShadowAuth, isInExportView } = useSelector<IState, IContextReducer>(state => state.context);
    const dispatch = useDispatch();
    const addAlert = useAlert();
    const { search } = useLocation();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
    const [showWopi, setShowWopi] = useState<boolean>(false);
    const [wopiInfo, setWopiInfo] = useState<{ accessToken: string, accessTokenTtl: number, ownerId: string, userId: string, wopiSourceUrl: string }>(undefined);

    useEffect(() => {
        if (isMobileMenuOpen) {
            document.body.style.setProperty('overflow', 'hidden');
        } else {
            document.body.style.removeProperty('overflow');
        }
    }, [isMobileMenuOpen]);

    useEffect(() => {
        const params = new URLSearchParams(search);
        dispatch(setExportView(params.get('export') === 'true'));
    }, [search, dispatch]);

    useEffect(() => {
        const queryParam = queryString.parse(window.location.search);
        if (queryParam.wopieItemId) {
            dispatch<GetWopiInfo>(getWopiInfo(queryParam.wopieItemId as string, queryParam.edit === 'true' ? true : false)).then((res) => {
                setShowWopi(true);
                setWopiInfo(res);
                wopiFunc();
            }).catch((err) => {
                addAlert(err, AlertType.Error);
            });
        }
    }, [window.location.search]);

    const toggleMobileMenu = useCallback(() => setIsMobileMenuOpen(isOpen => !isOpen), []);

    const showNavigation = useMemo(() => !(!(isHomeRoute && !isLoggedIn) && (isTeamsConfigPage || isTeamsRoute)) && !isShadowAuth && !isInExportView, [isLoggedIn, isHomeRoute, isTeamsConfigPage, isInExportView]);

    return (
        <OverlayContextProvider>
            <LayoutWrapper isPrintLayout={isShadowAuth}>
                {showWopi ? (
                    <WopieWrapper>
                        <form id="office_form" name="office_form" target="office_frame" action={wopiInfo?.wopiSourceUrl} method="post" >
                            <input name="access_token" value={wopiInfo?.accessToken} type="hidden" />
                            <input name="access_token_ttl" value={wopiInfo?.accessTokenTtl} type="hidden" />
                            <input name="user_id" value={wopiInfo?.userId} type="hidden" />
                            <input name="owner_id" value={wopiInfo?.ownerId} type="hidden" />
                        </form>
                        <span id="frameholder" style={{ width: '500px', height: '550px' }}></span>
                    </WopieWrapper>
                ) : (
                    <>
                        {showNavigation && (
                            <TopBar onMobileMenuClick={toggleMobileMenu} />
                        )}
                        {(!isShadowAuth && !isTeamsRoute && !isInExportView) &&
                            <SideBar isMobileMenuVisible={isMobileMenuOpen} />
                        }
                        <ContentWrapper withNavigation={showNavigation}>
                            <Content>
                                {children}
                            </Content>
                        </ContentWrapper>
                    </>
                )}
            </LayoutWrapper>
        </OverlayContextProvider>
    );
};
