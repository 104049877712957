import React from 'react';
import { Icon } from '../../../entities/IGlobal';

export const LcLogo = (props: Icon) => (
    <svg width={props.width} height={props.height} {...props} viewBox="0 0 230 230" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 20C0 8.9543 8.95431 0 20 0H210C221.046 0 230 8.95431 230 20V210C230 221.046 221.046 230 210 230H20C8.9543 230 0 221.046 0 210V20Z" fill="#3C8BF8" />
        <path d="M10 121C43.8373 121 114 120 114 11" stroke="white" strokeWidth="15" strokeLinecap="round" />
        <path d="M219 119.5C185.163 119.5 115 120 115 10.9999" stroke="white" strokeWidth="12" strokeLinecap="round" />
        <path d="M219 121C185.163 121 115 121.891 115 219" stroke="white" strokeWidth="15" strokeLinecap="round" />
        <path d="M10 121C43.8373 121 114 121.891 114 219" stroke="white" strokeWidth="15" strokeLinecap="round" />
        <path d="M55.0547 58.1798C63.3413 86.8011 127.097 140.863 174.055 58.1796" stroke="white" strokeWidth="15" strokeLinecap="round" />
        <path d="M174.055 183.969C165.768 155.347 102.012 101.285 55.0547 183.969" stroke="white" strokeWidth="15" strokeLinecap="round" />
        <path d="M55.0547 184C80.2935 175.834 126.115 110.067 55.0143 58.0008" stroke="white" strokeWidth="15" strokeLinecap="round" />
        <path d="M174.289 183.98C148.537 175.827 101.808 110.084 174.392 57.9783" stroke="white" strokeWidth="15" strokeLinecap="round" />
        <rect x="95" width="39" height="17" fill="#3C8BF8" />
        <rect x="95" y="213" width="39" height="17" fill="#3C8BF8" />
        <rect x="211" y="135" width="39" height="17" transform="rotate(-90 211 135)" fill="#3C8BF8" />
        <rect y="135" width="39" height="17" transform="rotate(-90 0 135)" fill="#3C8BF8" />
    </svg>
);
