import { IntlShape } from 'react-intl';

import { AllStatusEnums, StatusMapper } from '../entities/IGlobal';

export const getStatus = (status: AllStatusEnums) => {
    return StatusMapper.find(value => value.key === status);
};

export const getStatusColor = (status: AllStatusEnums) => {
    return StatusMapper.find(value => value.key === status)?.data.color || '';
};

export const getStatusFontColor = (status: AllStatusEnums) => {
    return StatusMapper.find(value => value.key === status)?.data.fontColor || '';
};

export const getStatusText = (status: AllStatusEnums, intl: IntlShape) => {
    return StatusMapper.find(value => value.key === status)?.text ? intl.formatMessage({id: StatusMapper.find(value => value.key === status)?.text}) : '';
};