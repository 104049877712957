import 'react-app-polyfill/ie11';
import 'abortcontroller-polyfill';
import 'whatwg-fetch';
import 'intersection-observer';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Fabric } from '@fluentui/react';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';

import { GlobalStyle, LcrStyle, ThemeFontImport } from './styleHelpers/global';
import App from './App';
import store from './store';
import { ThemeProvider } from './tools/theme';

initializeIcons();
initializeFileTypeIcons();

function renderApp() {
    render(
        <Fabric>
            <LcrStyle />
            <Provider store={store}>
                <ThemeProvider>
                    <ThemeFontImport />
                    <GlobalStyle />
                    <App />
                </ThemeProvider>
            </Provider>
        </Fabric>,
        document.getElementById('react-app')
    );
}

renderApp();

// Allow Hot Module Replacement
if (module.hot) {
    module.hot.accept('./App', () => {
        renderApp();
    });
}
