import React, { ReactNode } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import TagManager from 'react-gtm-module';
import { HubConnection } from '@microsoft/signalr';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import moment from 'moment';

import { sizes } from '../styleHelpers/breakpoint';
import GoogleAnalyticsService from '../services/GoogleAnalyticsService';
import { GlobalLoader } from './GlobalLoader';
import { MessagingContainer } from './Messaging/PopupSystem/MessagingContainer';
import { AlertContainer } from './Alert/AlertContainer';
import { Tracking } from './Tracking/Tracking';
import { IState } from '../reducers/index';
import { IConfigReducer } from '../reducers/configReducer';
import { ILanguageReducer } from '../reducers/languageReducer';
import { IContextReducer } from '../reducers/contextReducer';
import { IProfileReducer } from '../reducers/profileReducer';
import { ICookiesReducer } from '../reducers/cookiesReducer';
import { IClusterReducer } from '../reducers/clustersReducer';
import { IMessagingReducer } from '../reducers/messagingReducer';
import { IAnalyticsReducer } from '../reducers/analyticsReducer';
import * as messagingActions from '../actions/messagingActions';
import * as clustersActions from '../actions/clustersActions';
import * as contextActions from '../actions/contextActions';
import * as notificationActions from '../actions/notificationsActions';
import * as profileActions from '../actions/profileActions';
import * as alertActions from '../actions/alertActions';
import * as languageActions from '../actions/languageActions';
import { ISingleMessage, ISingleConversation } from '../entities/Messaging/IMessaging';
import { ClusterErrors, IClusterSingleMessage, IClusterValidationRequestStatusUpdateMessage, ISingleChannelMessage } from '../entities/IClusters';
import { TrackingType } from '../entities/IAnalytics';
import { AlertType } from '../entities/IAlert';
import { BranchNotificationType } from '../entities/ISoge';
import { NotificationType } from '../entities/INotification';
import { instanceConfig } from '../instance';
import { UseResize } from '../tools/hooks';
import { history } from '../history';
import Cookies from './Cookies/Cookies';

const { intercom, gtm, getClusters, enableTracking } = instanceConfig;

const tagManagerArgs = {
    gtmId: 'GTM-WF74HF6'
};

const scrollExceptions: (string | RegExp)[] = [/\/rfp/];

// tslint:disable-next-line
const Intercom = intercom && require('react-intercom').default;
// tslint:disable-next-line: no-var-requires
const withAITracking = enableTracking && require('react-appinsights').withAITracking;

interface ILayoutState {
    gtmInitialized: boolean;
    lastUploadedAttachment: ISingleMessage;
    lastReceivedMessage: ISingleMessage;
    getProfileInit: boolean;
}

type LayoutProps = WrappedComponentProps & ILanguageReducer & IClusterReducer & IConfigReducer & IContextReducer & IProfileReducer & ICookiesReducer & IMessagingReducer & IAnalyticsReducer
    & typeof messagingActions & typeof contextActions & typeof notificationActions & typeof clustersActions & typeof profileActions & typeof alertActions & typeof languageActions;

export class GloablLayout extends React.Component<LayoutProps & RouteComponentProps<{ organization?: string }>, ILayoutState> {
    googleAnalyticsService: GoogleAnalyticsService = new GoogleAnalyticsService();
    siganlRConnection: HubConnection;

    state = {
        gtmInitialized: false,
        lastUploadedAttachment: undefined,
        lastReceivedMessage: undefined,
        getProfileInit: undefined
    };

    componentDidMount() {
        const {
            isLoggedIn,
            isShadowAuth,
            currentUserProfile,
            translations,
            getCurrentUserProfileNew,
            getNotificationsActiveData,
            getCurrentUserContextOrganizations,
            getAvailableClusterPhotos
        } = this.props;

        if (isLoggedIn && currentUserProfile) {
            instanceConfig.enableSignalR && this.initializeMessaging();
            getClusters && getAvailableClusterPhotos();
        }

        if (isLoggedIn && !isShadowAuth && Object.keys(translations)?.length > 0) {
            getCurrentUserProfileNew().then(res => {
                if (!res.isProfileValidated && this.props.finalRegistration) {
                    history.push('/registration-final-step');
                }
                if (res.lastLoginDate && !this?.props?.location?.search?.includes('wopieItemId') && !IS_TEAMS_INSTANCE) {
                    this?.props?.addAlert(<span dangerouslySetInnerHTML={{
                        __html: this.props.intl.formatMessage({ id: 'admin.lastLoginDate' }, {
                            userName: res.firstName,
                            lastLoginDate: moment.utc(res.lastLoginDate).local().format('DD MMM YYYY HH:mm:ss'),
                            br: (...chunks) => `${<br />}`
                        })
                    }} />, AlertType.Info);
                }
            });
            getNotificationsActiveData();
        }

        if (isShadowAuth) {
            getCurrentUserContextOrganizations();
            getClusters && getAvailableClusterPhotos();
        }
    }

    componentDidUpdate(prevProps: LayoutProps & RouteComponentProps<any>) {
        this.googleAnalyticsService.ActionEvent();
        if (!prevProps.isLoggedIn && this.props.isLoggedIn && !this.props.isShadowAuth || (!this.props.currentUserProfile && Object.keys(this.props.translations)?.length > 0 && !this.state.getProfileInit)) {
            this.setState({
                getProfileInit: true
            }, () => {
                this.props.getCurrentUserProfileNew().then(res => {
                    if (!res.isProfileValidated && this.props.finalRegistration) {
                        history.push('/registration-final-step');
                    }
                    if (res.lastLoginDate && !this?.props?.location?.search?.includes('wopieItemId') && !IS_TEAMS_INSTANCE) {
                        this?.props?.addAlert(<span dangerouslySetInnerHTML={{
                            __html: this.props.intl.formatMessage({ id: 'admin.lastLoginDate' }, {
                                userName: res.firstName,
                                lastLoginDate: moment.utc(res.lastLoginDate).local().format('DD MMM YYYY HH:mm:ss'),
                                br: (...chunks) => `${<br />}`
                            })
                        }} />, AlertType.Info);
                    }
                });
                this.props.getNotificationsActiveData();
            });
        }

        if (this.props.isLoggedIn && this.props.currentUserProfile &&
            (!prevProps.currentUserProfile || !prevProps.isLoggedIn || this.props.userLanguage !== prevProps.userLanguage)) {
            this.props.getUserPrefferedLanguage().then(() => {
                this.props.getCurrentUserContextOrganizations();
            });
        }

        if (this.props.isLoggedIn && !prevProps.currentUserProfile && this.props.currentUserProfile) {
            instanceConfig.enableSignalR && this.initializeMessaging();
            getClusters && this.props.getAvailableClusterPhotos();
        }

        if (this.props.cookieBlock === false && !this.state.gtmInitialized) {
            gtm && TagManager.initialize(tagManagerArgs);

            this.setState({
                gtmInitialized: true
            });
        }

        const currentLocation = this.props.location;
        const prevLocation = prevProps.location;
        const currentUrl = `${currentLocation.pathname}${currentLocation.search || ''}`;
        const prevUrl = `${prevLocation.pathname}${prevLocation.search || ''}`;

        if (prevUrl !== currentUrl) {
            if (!scrollExceptions.find(exception => !!currentUrl.match(exception))) {
                window.scrollTo(0, 0);
            }

            if (this.props.tracker[TrackingType.Smh]) {
                this.props.tracker[TrackingType.Smh].setCustomUrl(currentUrl);
                this.props.tracker[TrackingType.Smh].trackPageView();
            }

            if (this.props.tracker[TrackingType.Sg]) {
                this.props.tracker[TrackingType.Sg].setCustomUrl(currentUrl);
                this.props.tracker[TrackingType.Sg].trackPageView();
            }
        }

        if (!prevProps.isLoggedIn && this.props.isLoggedIn || !prevProps.currentUserProfile && this.props.currentUserProfile) {
            if (!this.siganlRConnection && instanceConfig.enableSignalR) {
                this.initializeMessaging();
            }
            getClusters && this.props.getAvailableClusterPhotos();
        }

        if (!prevProps.isShadowAuth && this.props.isShadowAuth) {
            this.props.getCurrentUserContextOrganizations();
            getClusters && this.props.getAvailableClusterPhotos();
        }
    }

    componentWillUnmount() {
        if (this.siganlRConnection) {
            this.siganlRConnection.off('receiveMessage');
            this.siganlRConnection.off('AssignedRoleCreated');
            this.siganlRConnection.off('receiveConversation');
            this.siganlRConnection.off('messageWasRead');
            this.siganlRConnection.off('attachmentUploaded');
            this.siganlRConnection.off('receiveNotification');
            this.siganlRConnection.off('conversationMemberRemoved');
            this.siganlRConnection.off('ReceiveChannelMessage');
            this.siganlRConnection.off('UpdateChannelMessage');
            this.siganlRConnection.off('ChannelMembersAdded');
            this.siganlRConnection.off('ChannelMemberRemoved');
            this.siganlRConnection.off('SystemDocumentsUploaded');
            this.siganlRConnection.off('ClusterValidationRequestStatusUpdateMessageDto');
        }
    }

    initializeMessaging = () => {
        const {
            appConfig,
            token,
            addUnreadConversation,
            createSignlaRHubConnection,
            addRcivedNotification
        } = this.props;

        const downloadClusterWhenMessageTypeIsValidationRequestPreview = (message: IClusterSingleMessage) => {
            const ValidationRequestPreview = 'ValidationRequestPreview';
            if (message.type === ValidationRequestPreview) {
                if (message.clusterId === this.props.currentClusterId) {
                    this.props.getClusterDataForRefreshApproval(message.clusterId);
                }
            }
        };

        this.siganlRConnection = createSignlaRHubConnection(appConfig.signalRUri, token);

        if (this.siganlRConnection) {
            this.siganlRConnection.on('receiveMessage', (message: ISingleMessage) => {
                const isMyMessage = message.senderUserId === this.props.currentUserProfile?.id;
                !isMyMessage && addUnreadConversation(message.conversationId);
                this.receiveMessage(message);
            });

            // tslint:disable-next-line
            this.siganlRConnection.on('receiveConversation', (conversation: ISingleConversation) => {
                this.props.newEventsHaveOccuredOn();
            });

            this.siganlRConnection.on('attachmentUploaded', (message: ISingleMessage) => {
                this.props.newEventsHaveOccuredOn();
                this.attachmentUploaded(message);
            });

            // tslint:disable-next-line
            this.siganlRConnection.on('messageWasRead', data => { });

            this.siganlRConnection.on('receiveNotification', notification => {
                console.log('notification global', notification);
                const notificationTranslation = <FormattedMessage
                    id={`notificationsLabels.${notification?.key}`}
                    values={{
                        ObjectName: notification?.data?.ObjectName,
                        LanguageCode: notification?.data?.LanguageCode,
                        BranchName: notification?.data?.BranchName
                    }}
                />;

                if (notification?.data?.IsSuccess || notification?.key === BranchNotificationType?.AutomaticallyCreatedBranch) {
                    this?.props?.addAlert(notificationTranslation, AlertType.Success);
                } else if (notification?.data?.IsSuccess === false) {
                    this?.props?.addAlert(notificationTranslation, AlertType.Error);
                }
                if (notification?.key === NotificationType.AddMemberToOrganization) {
                    this?.props?.getCurrentUserContextOrganizations();
                }
                if (notification?.key === NotificationType.RemoveMemberFromOrganization) {
                    this?.props?.getCurrentUserContextOrganizations();
                }
                addRcivedNotification(notification);
            });

            this.siganlRConnection.on('conversationMemberRemoved', this.onConversationMemberRemoved);

            this.siganlRConnection.on('ReceiveChannelMessage', (message: IClusterSingleMessage) => {
                console.log('ReceiveChannelMessage', message); // let's leave it for debugging
                downloadClusterWhenMessageTypeIsValidationRequestPreview(message);
                if (this.props.currentChannelId === message.teamChannelId) {
                    if (!message.isThread) {
                        this.props.newEventsHaveOccuredOn();
                        this.props.receiveThreadMessage(message);
                    } else {
                        const messageObj: ISingleChannelMessage = {
                            ...message,
                            threadMessages: []
                        };
                        this.props.receiveClusterMessage(messageObj);
                        if (message.type === 'SystemDocumentsUploaded') {
                            this.props.newEventsHaveOccuredOn();
                            this.props.getClusterDocuments();
                        }
                    }
                }
            });

            this.siganlRConnection.on('UpdateChannelMessage', (message: IClusterSingleMessage) => {
                console.log('UpdateChannelMessage', message); // let's leave it for debugging
                this.props.newEventsHaveOccuredOn();
                downloadClusterWhenMessageTypeIsValidationRequestPreview(message);
                // this.props.updateSingleMessage(message);
            });

            this.siganlRConnection.on('AssignedRoleCreated', (message) => {
                console.log('AssignedRoleCreated', message); // let's leave it for debugging
                this.props.getTeamsWithChannels(message.resource.childId).then(res => {
                    this.props.newEventsHaveOccuredOn();
                    this.props.getChannelMessages(0, 100, res[0].resourceChildId, res[0].teamChannels[0].teamId, res[0].teamChannels[0].id);
                });
            });

            this.siganlRConnection.on('ClusterValidationRequestStatusUpdateMessageDto', (message: IClusterValidationRequestStatusUpdateMessage) => {
                console.log('ClusterValidationRequestStatusUpdateMessageDto', message); // let's leave it for debugging
                this.props.getClusterDataForRefreshApproval(message.additionalInformation.clusterId);

            });

            this.siganlRConnection.on('ChannelMembersAdded', (res: any) => ({}));

            this.siganlRConnection.on('ChannelMemberRemoved', (res: any) => ({}));

            this.siganlRConnection.on('AssignToClusterCompleted', (res: any) => {
                if (this.props.currentClusterData?.errorCode === ClusterErrors.CreationInProgress) {
                    this.props.getSingleCluster(res.id);
                }
            });

            this.siganlRConnection.on('DeleteChannelMessage', (message: IClusterSingleMessage) => {
                if (!message.isThread) {
                    this.props.receiveThreadMessage(message);
                } else {
                    this.props.deleteChannelThreadFromState(message.threadId);
                }
            });
            this.siganlRConnection.start();
        }

    }

    onConversationMemberRemoved = (data: { id: string; conversationId: string; }) => {
        const {
            currentUserProfile,
            userRemovedFromConversation,
            activeConversationId,
            clearCurrentMessages,
            setActiveConversation,
            getMessages,
            conversations
        } = this.props;

        if (currentUserProfile.id === data.id) {
            userRemovedFromConversation(data.conversationId);

            if (conversations && conversations.length && activeConversationId && activeConversationId === data.conversationId) {
                const firstConv = conversations.filter((conversation: ISingleConversation) => conversation.id !== data.conversationId);
                const id = firstConv && firstConv[0] && firstConv[0].id;
                setActiveConversation(id);
                clearCurrentMessages();
                /* getMessages(); */
            }
        }
    }

    attachmentUploaded = (message: ISingleMessage) => {
        this.props.addReceiveAttachment(message.id, message.attachments[0]);
        this.setState({ lastUploadedAttachment: message });
    }

    receiveMessage = (message: ISingleMessage) => {
        this.setState({ lastReceivedMessage: message });
        this.props.newEventsHaveOccuredOn();
        if (!(this.props.activeConversationId || this.props.showConversationMenu)) {
            return;
        }

        const conversationElem = (this.props.conversations || []).find((conversation: ISingleConversation) => conversation.id === message.conversationId);

        if (!conversationElem) {
            /* TODO: waiting for api this.props.getConversation(message.conversationId).then(conversation => {
                this.updateConversations(message, conversation);
            }); */
        } else {
            this.updateConversations(message, conversationElem);
        }
    }

    updateConversations(message: ISingleMessage, conversationElem: ISingleConversation) {
        const { conversations, activeConversationId, currentUserProfile } = this.props;

        const dontUpdateUnreadMessagesCount = message.conversationId === activeConversationId || message.senderUserId === currentUserProfile?.id;

        const newConversationData = {
            ...conversationElem,
            unreadMessagesCount: dontUpdateUnreadMessagesCount ? 0 : (conversationElem?.unreadMessagesCount || 0) + 1,
            lastMessage: {
                creationDate: message.createdDate,
                messageId: message.id,
                preview: message.content.slice(0, 15) || ''
            }
        };

        if (message.tempId && conversations?.find((conversation: ISingleConversation) => conversation.id === message.tempId)) {
            this.props.removeTempConversation(message.tempId);
        }

        if ((message.conversationId === activeConversationId || message.tempId === activeConversationId) && message.type !== 'SystemRemovingMessage') {
            this.props.updateSingleConversation(newConversationData);
            this.props.setActiveConversation(message.conversationId);
        } else if (message.type !== 'SystemRemovingMessage') {
            this.props.updateSingleConversation(newConversationData);
            /* if (!activeConversationId?.includes('tempGroup-')) {
                this.props.getMessages();
            } */
        }

        if (message.type === 'SystemRemovingMessage') {
            this.props.updateSingleMessage(message);
        }
    }

    render() {
        const { appConfigLoaded, appConfig, children, intercomUser, location, tokenReloading, languageLoaded, contextOrganizations } = this.props;

        return (
            <UseResize>
                {({ innerWidth }) => (
                    <>
                        <AlertContainer />
                        <Tracking />
                        {(appConfigLoaded && languageLoaded && contextOrganizations) && (
                            <>
                                {(!!Intercom && innerWidth >= sizes.desktop) && (
                                    <Intercom appID={appConfig.intercomAppId} {...intercomUser} />
                                )}
                                {children}
                                {instanceConfig.headerMenu.messaging &&
                                    this.props.showConversationMenu &&
                                    innerWidth >= sizes.desktop &&
                                    <MessagingContainer lastUploadedAttachment={this.state.lastUploadedAttachment} lastReceivedMessage={this.state.lastReceivedMessage} />
                                }
                                <Cookies path={location.pathname} />
                            </>
                        )}
                        <GlobalLoader isLoading={!appConfigLoaded || !languageLoaded || tokenReloading} />
                    </>
                )}
            </UseResize>
        );
    }
}

export default connect(
    (state: IState) => ({
        ...state.config,
        ...state.context,
        ...state.profile,
        ...state.cookies,
        ...state.messaging,
        ...state.clusters,
        ...state.language,
        ...state.analytics
    }),
    {
        ...messagingActions,
        ...contextActions,
        ...notificationActions,
        ...clustersActions,
        ...profileActions,
        ...alertActions,
        ...languageActions
    }
)(injectIntl(withRouter(GloablLayout)));
