/*
    USE:

    handleCheckbox = (e, name) => {
        this.setState(currentState => ({
            [name]: !currentState[name]
        }))
    }

    <Checkbox name='PPublicChecked' onClick={this.handleCheckbox} checked={this.state.PPublicChecked}>Label</Checkbox>

*/
import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { colorStack } from '../colors';
import { fontSize } from '../fontSizes';

export const CheckboxStyle = styled.div`
    display: inline-block;
    > input {
        opacity: 0;
    }
    > input[type="checkbox"] + label {
        position: relative;
        padding: 3px 0 5px 30px;
        cursor: pointer;
        float: none;
        color: ${colorStack.darkBlue};
        font-size: ${fontSize[16]};
        &:before {
            content: '';
            position: absolute;
            left:0; top: 1px;
            width: 17px; height: 17px;
            border: 1px solid ${colorStack.ligthGrey};
            background: ${colorStack.white};
            border-radius: 3px;
        }
        &:after {
            content: '✔';
            position: absolute;
            top: 3px;
            left: 4px;
            font-size: ${fontSize[16]};
            color: ${colorStack.blue};
            transition: all .2s;
        }
    }
    > input:not(:checked) + label {
        &:after {
            opacity: 0;
            transform: scale(0);
        }
    }
    > input:disabled:not(:checked) + label {
        &:before {
            border-color: ${colorStack.ligthGrey};
            background-color: ${colorStack.white};
        }
    }
    > input:checked + label {
        &:after {
            opacity: 1;
            transform: scale(1);
        }
    }
    > input:disabled:checked + label {
        &:after {
            color: #999;
        }
    }
    > input:disabled + label {
        color: #aaa;
    }
    > input:checked:focus + label, input:not(:checked):focus + label {
        &:before {
            border: 1px solid ${colorStack.ligthGrey};
        }
    }
`;

type CheckboxProps = {
    name?: string;
    checked: boolean;
    disabled?: boolean;
    className?: string;
    children?: ReactNode;
    onClick?(e: React.MouseEvent<HTMLDivElement>, name: string): void;
};

export class Checkbox extends React.Component<CheckboxProps> {
    onClick = (e: React.MouseEvent<HTMLDivElement>) => {
        !this.props.disabled && this.props.onClick?.(e, this.props.name);
    }

    render(){
        return (
            <CheckboxStyle onClick={this.onClick} className={this.props.className || ''}>
                <input type="checkbox" checked={this.props.checked} disabled={this.props.disabled} />
                <label>{this.props.children}</label>
            </CheckboxStyle>
        );
    }
}

export default Checkbox;