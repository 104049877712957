import { faAlignLeft, faCheckSquare, faCaretCircleDown, faMapMarkedAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import styled from 'styled-components';

import { IClusterSingleDynamicStake, IClusterStakeProperty, PropertieKey } from '../../../../../../entities/IClusterStake';

import { colorStack } from '../../../../../../styleHelpers/colors';
import { fontSize } from '../../../../../../styleHelpers/fontSizes';
import { addressFormatterGlobal } from '../../../../../../tools/addressFormatter';
import { Checkbox } from '../../../../../../styleHelpers/global/checkbox';
import * as Common from '../Common';
import { AttachmentSection } from './AttachmentSection';

const Name = styled.span`
    font-size: ${fontSize[16]};
    font-weight: 500;
    color: ${colorStack.darkBlue};
`;

interface IDonorLayoutProps {
    stake: IClusterSingleDynamicStake;
    propertyByKey: {
        [key in PropertieKey]?: IClusterStakeProperty;
    }
    downloadHandler();
}

export const DonorLayout: FC<IDonorLayoutProps> = ({ stake, propertyByKey, downloadHandler }) => {
    return (
        <Common.Wrapper>
            <a id={stake.id} />
            <AttachmentSection
                title={stake.name}
                downloadHandler={downloadHandler}
            />
            <Common.PartyInfoWrapper>
                <Common.DetailSection>
                    <Common.LeftSide>
                        <Common.EntityDetails>
                            <Name>
                                {propertyByKey[PropertieKey.NonRegisteredUserFirstName]?.value || ''} {propertyByKey[PropertieKey.NonRegisteredUserLastName]?.value || ''}
                            </Name>
                            <Common.AdditionalInfo>
                                {propertyByKey[PropertieKey.NonRegisteredUserUserJob]?.value} - {propertyByKey[PropertieKey.NonRegisteredUserCabinet]?.value}
                            </Common.AdditionalInfo>
                        </Common.EntityDetails>
                    </Common.LeftSide>
                    <Common.RightSide>
                        {propertyByKey[PropertieKey.NonRegisteredUserAddress]?.value &&
                            <>
                                <Common.IconWrapper>
                                    <FontAwesomeIcon icon={faMapMarkedAlt} />
                                    <span>{propertyByKey[PropertieKey.NonRegisteredUserAddress]?.name}</span>
                                </Common.IconWrapper>
                                <Common.Representatives>
                                    {addressFormatterGlobal(propertyByKey[PropertieKey.NonRegisteredUserAddress].value)}
                                </Common.Representatives>
                            </>
                        }
                        {propertyByKey[PropertieKey.JourneyPartiesRelationshipNature]?.value?.value &&
                            <>
                                <Common.IconWrapper>
                                    <FontAwesomeIcon icon={faCaretCircleDown} />
                                    <span>{propertyByKey[PropertieKey.JourneyPartiesRelationshipNature]?.name}</span>
                                </Common.IconWrapper>
                                <Common.Representatives>
                                    {propertyByKey[PropertieKey.JourneyPartiesRelationshipNature].value.value}
                                </Common.Representatives>
                            </>
                        }
                    </Common.RightSide>
                </Common.DetailSection>
                <Common.BottomSection>
                    {typeof propertyByKey[PropertieKey.Boolean]?.value === 'boolean' &&
                        <Common.InlineProperty>
                            <Common.IconWrapper>
                                <FontAwesomeIcon icon={faCheckSquare} />
                                <span>{propertyByKey[PropertieKey.Boolean]?.name}</span>
                            </Common.IconWrapper>
                            <Checkbox checked={propertyByKey[PropertieKey.Boolean].value} />
                        </Common.InlineProperty>
                    }
                    {propertyByKey[PropertieKey.JourneyPartiesRelationshipDescription]?.value &&
                        <Common.InlineProperty>
                            <Common.IconWrapper>
                                <FontAwesomeIcon icon={faAlignLeft} />
                                <span>{propertyByKey[PropertieKey.JourneyPartiesRelationshipDescription]?.name}</span>
                            </Common.IconWrapper>
                            <Common.Representatives>
                                {propertyByKey[PropertieKey.JourneyPartiesRelationshipDescription].value}
                            </Common.Representatives>
                        </Common.InlineProperty>
                    }
                </Common.BottomSection>
            </Common.PartyInfoWrapper>
        </Common.Wrapper>
    );
};