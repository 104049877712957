import { fontSize } from "../fontSizes";

export const lcrTag = `
.lcr-tag {
    display:-webkit-inline-flex;
    display:-ms-inline-flexbox;
    display:-ms-inline-flex;
    display:inline-flex;
    align-items:center;
    -webkit-align-items:center;
    -ms-align-items:center;
    -o-align-items:center;
    min-height:25px;
    margin-right:15px;
    margin-bottom:20px;
    padding:5px 12px;
    font-size: ${fontSize[13]};
    border:1px solid #e8e8e8;
    background:#ffffff;
    -webkit-border-radius:3px;
    -moz-border-radius:3px;
    border-radius:3px;
}
.lcr-tag:before,
.lcr-tag:after {
    display:table;
    clear:both;
    content:'';
}
.lcr-tag:first-child {
    margin-left:0;
}
.lcr-tag a.lcr-icon-close {
    height:24px;
    width:24px;
    margin-left:10px;
    margin-right:-8px;
    text-align:center;
    -webkit-transition-duration:.3s;
    transition-duration:.3s;
    -webkit-transition-property:"background-color";
    transition-property:"background-color";
    font-size: ${fontSize[10]};
    -webkit-border-radius:4px;
    -moz-border-radius:4px;
    border-radius:4px;
}
.lcr-tag a.lcr-icon-close:hover,
.lcr-tag a.lcr-icon-close:active,
.lcr-tag a.lcr-icon-close:focus {
    text-decoration:none;
    background-color:#4d4d4d;
}
.lcr-tag a.lcr-icon-close:active,
.lcr-tag a.lcr-icon-close:focus,
.lcr-tag a.lcr-icon-close:hover {
    color:#FFF;
    text-decoration:none;
}
.lcr-tag a.lcr-icon-close:before {
    font-size:8px;
    font-weight:bold;
}
.lcr-tag.lcr-count {
    display:-webkit-inline-flex;
    display:-ms-inline-flexbox;
    display:-ms-inline-flex;
    display:inline-flex;
    align-items:center;
    -webkit-align-items:center;
    -ms-align-items:center;
    -o-align-items:center;
    min-height:25px;
    margin-right:15px;
    margin-bottom:20px;
    padding:5px 12px;
    border:1px solid #e8e8e8;
    background:#e8e8e8;
}
.lcr-tag.lcr-small {
    min-height:20px;
    margin-right:7px;
    margin-bottom:7px;
    padding:5px 7px;
    font-size: ${fontSize[13]};
}
.lcr-tag.lcr-color1 {
    color:#fff;
    background:#232b46;
    border:none;
}
.lcr-tag.lcr-color2 {
    color:#232b46;
    background:#cfc19e;
    border:none;
}
.lcr-tags-list {
    margin-bottom:20px;
}
.lcr-tags-list>li {
    margin-bottom:10px;
}
.lcr-tags-list>li .lcr-tag {
    margin-bottom:0;
}
.lcr-tags-list>ul {
    position:relative;
    padding-left:10px;
}
.lcr-tags-list>ul:before {
    position:absolute;
    left:0;
    top:0;
    bottom:7px;
    content:"";
    border-left:2px solid #E8E8E8;
}
.lcr-tags-list>ul>li {
    display:inline-block;
}
`;
