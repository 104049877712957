import { Referentials, IContextList } from '../entities/IGlobal';

export const referentialConfiguration = {
    Nationality: {
        referentialContext: IContextList.LegalEntity,
        referentialType: Referentials.Nationality,
        value: undefined
    },
    Group: {
        referentialContext: IContextList.LegalEntity,
        referentialType: Referentials.Group,
        value: undefined
    },
    Position: {
        referentialContext: IContextList.Corporate,
        referentialType: Referentials.Position,
        value: undefined
    },
    SecondaryEstablishmentsAndBranches: {
        referentialContext: IContextList.Corporate,
        referentialType: Referentials.QualityBranches,
        value: undefined
    },
    SubsidiaryAndParticipations: {
        referentialContext: IContextList.Corporate,
        referentialType: Referentials.QualityParticipations,
        value: undefined
    },
    TypeOfLegalEntity: {
        referentialContext: IContextList.LegalEntity,
        referentialType: Referentials.TypeOfLegalEntity,
        value: undefined
    },
    LegalForm: {
        referentialContext: IContextList.LegalEntity,
        referentialType: Referentials.LegalForm,
        value: undefined
    },
    FirstReferential: {
        referentialContext: IContextList.Platform,
        referentialType: Referentials.FirstReferential,
        value: undefined
    },
    SecondReferential: {
        referentialContext: IContextList.Platform,
        referentialType: Referentials.SecondReferential,
        value: undefined
    },
    ThirdReferential: {
        referentialContext: IContextList.Platform,
        referentialType: Referentials.ThirdReferential,
        value: undefined
    },
    Information: {
        referentialContext: IContextList.NormativeDocumentation,
        referentialType: Referentials.Information,
        value: undefined
    },
    Administration: {
        referentialContext: IContextList.NormativeDocumentation,
        referentialType: Referentials.Administration,
        value: undefined
    },
    Thematic: {
        referentialContext: IContextList.NormativeDocumentation,
        referentialType: Referentials.Thematic,
        value: undefined
    },
    Country: {
        referentialContext: IContextList.Platform,
        referentialType: Referentials.Country,
        value: undefined
    },
    QualityBranches: {
        referentialContext: IContextList.Corporate,
        referentialType: Referentials.QualityBranches,
        value: undefined
    },
    QualityParticipations: {
        referentialContext: IContextList.Corporate,
        referentialType: Referentials.QualityParticipations,
        value: undefined
    },
    NumberOfEmployees: {
        referentialContext: IContextList.LegalEntity,
        referentialType: Referentials.NumberOfEmployees,
        value: undefined
    },
    DocumentLabel: {
        referentialContext: IContextList.Corporate,
        referentialType: Referentials.DocumentLabel,
        value: undefined
    },
    Clauses: {
        referentialContext: IContextList.Contract,
        referentialType: Referentials.Clauses,
        value: undefined
    },
    TypeOfActivity: {
        referentialContext: IContextList.Corporate,
        referentialType: Referentials.TypeOfActivity,
        value: undefined
    },
    AliasType: {
        referentialContext: IContextList.Corporate,
        referentialType: Referentials.AliasType,
        value: undefined
    },
    GiftNature: {
        referentialContext: IContextList.GiftInvitation,
        referentialType: Referentials.GiftNature,
        value: undefined
    },
    GiftRelationshipNature: {
        referentialContext: IContextList.GiftInvitation,
        referentialType: Referentials.GiftRelationshipNature,
        value: undefined
    },
    GiftValue: {
        referentialContext: IContextList.GiftInvitation,
        referentialType: Referentials.GiftValue,
        value: undefined
    },
    GiftDescription: {
        referentialContext: IContextList.GiftInvitation,
        referentialType: Referentials.GiftDescription,
        value: undefined
    },
    TypeOfMilestone: {
        referentialContext: IContextList.Stake,
        referentialType: Referentials.TypeOfMilestone,
        value: undefined
    },
    ContractTypeReferential: {
        referentialContext: IContextList.Contract,
        referentialType: Referentials.ContractTypeReferential,
        value: undefined
    },
    Assignment: {
        referentialContext: IContextList.Contract,
        referentialType: Referentials.Assignment,
        value: undefined
    },
    NonCompeteProvision: {
        referentialContext: IContextList.Contract,
        referentialType: Referentials.NonCompeteProvision,
        value: undefined
    },
    ChangeOfControl: {
        referentialContext: IContextList.Contract,
        referentialType: Referentials.ChangeOfControl,
        value: undefined
    },
    GeographicalArea: {
        referentialContext: IContextList.Contract,
        referentialType: Referentials.GeographicalArea,
        value: undefined
    },
    PerformanceObligation: {
        referentialContext: IContextList.Contract,
        referentialType: Referentials.PerformanceObligation,
        value: undefined
    },
    TypeOfRenewal: {
        referentialContext: IContextList.Contract,
        referentialType: Referentials.TypeOfRenewal,
        value: undefined
    },
    BasicPrice: {
        referentialContext: IContextList.Contract,
        referentialType: Referentials.BasicPrice,
        value: undefined
    },
    Exclusivity: {
        referentialContext: IContextList.Contract,
        referentialType: Referentials.Exclusivity,
        value: undefined
    },
    ApplicableLaw: {
        referentialContext: IContextList.Contract,
        referentialType: Referentials.ApplicableLaw,
        value: undefined
    },
    ProductAndServices: {
        referentialContext: IContextList.Contract,
        referentialType: Referentials.ProductAndServices,
        value: undefined
    },
    Cancellation: {
        referentialContext: IContextList.Contract,
        referentialType: Referentials.Cancellation,
        value: undefined
    },
    ContractDuration: {
        referentialContext: IContextList.Contract,
        referentialType: Referentials.ContractDuration,
        value: undefined
    },
    GenericQualifiaction: {
        referentialContext: IContextList.Contract,
        referentialType: Referentials.GenericQualifiaction,
        value: undefined
    },
    ContractTermType: {
        referentialContext: IContextList.Contract,
        referentialType: Referentials.ContractTermType,
        value: undefined
    },
    BusinessSector: {
        referentialContext: IContextList.Contract,
        referentialType: Referentials.BusinessSector,
        value: undefined
    },
    Liability: {
        referentialContext: IContextList.Contract,
        referentialType: Referentials.Liability,
        value: undefined
    }
};