import React, { Component } from 'react';
import { NormalPeoplePicker, IPersonaProps } from '@fluentui/react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { colorStack } from '../../styleHelpers/colors';

const ColorBar = styled.div`
    background-color: ${colorStack.white};
    transition: background-color .3s;
    height: 2px;
    width: 100%;
    border-radius: 0 0 4px 4px;
`;

const Wrapper = styled.div<{newDesign: boolean}>`
    ${props => props.newDesign && css`
        &:hover ${ColorBar} {
            background-color: ${colorStack.middleBlue};
        }
        .ms-BasePicker-text {
            padding: 4px 10px;
            border: none;
            box-shadow: ${`0px .3px .9px ${rgba(colorStack.black, .07)}, 0px 1.6px 3.6px ${rgba(colorStack.black, .11)}`}
        }
    `}
`;

interface ApiObject {
    id: string;
    [key: string]: any; // this is any by design, not a bug
}

interface IDebouncedPeoplePickerProps {
    noResultsFoundText?: string;
    selectedItems: ApiObject[];
    itemLimit?: number;
    newDesign?: boolean;
    disabled?: boolean;
    transformResults(originalObject: ApiObject): IPersonaProps;
    resolveQuery(query: string);
    onChange(pickerResults: IPersonaProps[], apiResults: ApiObject[]);
}

interface IDebouncedPeoplePickerState {
    apiResults: ApiObject[];
    pickerResults: IPersonaProps[];
}

export class DebouncedPeoplePicker extends Component<IDebouncedPeoplePickerProps, IDebouncedPeoplePickerState> {
    debounce: ReturnType<typeof setTimeout>;

    state: IDebouncedPeoplePickerState = {
        apiResults: [],
        pickerResults: []
    };

    resolvePeople = (query: string = ''): IPersonaProps[] | PromiseLike<IPersonaProps[]> => {
        const { resolveQuery, transformResults, selectedItems } = this.props;
        clearTimeout(this.debounce);
        return new Promise(resolve => {
            this.debounce = setTimeout(async () => {
                const apiResults = await resolveQuery(query) || [];
                const pickerResults = apiResults.map(transformResults);
                const filteredResults = pickerResults.filter(itemFromApi => !selectedItems?.find(selectedItem => selectedItem.id === itemFromApi.id));
                this.setState({
                    apiResults,
                    pickerResults: filteredResults
                }, () => {
                    resolve(filteredResults);
                });
            }, 500);
        });
    }

    onResolveSuggestions = (filterText: string, currentPersonas: IPersonaProps[]): IPersonaProps[] | PromiseLike<IPersonaProps[]> => {
        if (!filterText.trim()) {
            return currentPersonas || [];
        }

        return this.resolvePeople(filterText);
    }

    onEmptyPeopleFocus = () => this.resolvePeople();

    onChange = (items?: IPersonaProps[]) => {
        const { apiResults } = this.state;
        const { onChange, selectedItems } = this.props;
        const itemsById = (items || []).reduce((arrayOfItems, item) => ({
            ...arrayOfItems,
            [item.id]: item
        }), {} as { [id: string]: IPersonaProps });

        const originalResults = [...(apiResults || []), ...(selectedItems || [])]
            .reduce((unique, item) => unique.find(el => el.id === item.id) ? unique : [...unique, item], []);

        onChange(items, originalResults.filter(item => !!itemsById[item.id]));
    }

    render() {
        const { transformResults, selectedItems, itemLimit, noResultsFoundText } = this.props;

        return (
            <Wrapper newDesign={this.props.newDesign}>
                <NormalPeoplePicker
                    {...noResultsFoundText ? { pickerSuggestionsProps: { noResultsFoundText } } : {}}
                    onResolveSuggestions={this.onResolveSuggestions}
                    onEmptyInputFocus={this.onEmptyPeopleFocus}
                    onChange={this.onChange}
                    selectedItems={(selectedItems || []).map(transformResults)}
                    itemLimit={itemLimit}
                    disabled={this.props.disabled}
                />
                {this.props.newDesign && <ColorBar />}
            </Wrapper>
        );
    }
}