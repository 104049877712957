import React, { useState, useMemo, useCallback, useRef, useLayoutEffect, useEffect, FC } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { faHomeLgAlt, faLink, faHistory } from '@fortawesome/pro-solid-svg-icons';
import { faUserFriends, faBuilding, faUserCrown, faNewspaper } from '@fortawesome/pro-duotone-svg-icons';

import { ILevel1MenuItem } from '../../../entities/IMenu';
import { PlatformPermissions } from '../../../entities/IPermissions';
import { IState } from '../../../reducers';
import { IOrganizationReducer } from '../../../reducers/organizationReducer';
import { IContextReducer } from '../../../reducers/contextReducer';
import { IProfileReducer } from '../../../reducers/profileReducer';
import { setOrganizationsName } from '../../../actions/organizationActions';
import { arrayToObject } from '../../../tools/arrayTools';
import { isPlatformAdmin } from '../../../tools/permissions';
import { typed } from '../../../tools/generalTools';
import { useResize } from '../../../tools/hooks';
import { MenuItem } from './MenuItem';
import { OrganizationsScroller } from '../../NavMenu/Components/OrganizationsScroller';
import { media } from '../../../styleHelpers/breakpoint';
import { UserMenuDropdown } from '../../NavMenu/UserMenuDropdown';
import { Filter as FilterIcon } from '../../Common/CutomIcons/Filter';
import { TextComponent } from '../../Common/Inputs/TextComponent';
import { useEscKey } from '../../../tools/keyboardTools';
import { useUsefulLinksPopup } from '../../../tools/usefulLinksHooks';
import { useOrganization } from '../../../tools/organizationHooks';
import { TypeOfOrganization } from '../../../entities/IOrganization';

const Wrapper = styled.div`
    height: 100%;
    overflow: hidden;
    > div {
        display: flex;
        flex-direction: column;
    }
`;

const UserMenu = styled.div`
    ${media.desktop`
        display: none;
    `}
`;

const Filter = styled.div`
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    > div {
        flex: 1;
        width: 100%;
    }
`;

const isSearchEnabled = CONFIG.sidebar.search;

interface IProps {
    fullMenu?: boolean;
    isDesktopMenuVisible?: boolean;
    onMenuItemClick();
    onFilterClick?();
}

export const SidebarMenu: FC<IProps> = ({ fullMenu, isDesktopMenuVisible, onMenuItemClick, onFilterClick }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const showUsefulLinksPopup = useUsefulLinksPopup();
    const wrapperRef = useRef<HTMLDivElement>();
    const listRef = useRef<HTMLDivElement>();
    const searchInputRef = useRef<HTMLInputElement>();
    const [searchInputValue, setSearchInputValue] = useState<string>('');
    const [visibleMenuItemsCount, setVisibleMenuItemsCount] = useState<number>(0);
    const { innerHeight, innerWidth } = useResize();
    const { currentUserProfile, contextOrganizations, currentUserPermissions, currentOrganizationUrlName } = useSelector<IState, IOrganizationReducer & IContextReducer & IProfileReducer>(state => ({
        ...state.organization,
        ...state.context,
        ...state.profile
    }));
    const { currentOrganization } = useOrganization(currentOrganizationUrlName);

    const escKeyHandler = useCallback(() => {
        if (!fullMenu) return;

        searchInputValue
            ? setSearchInputValue('')
            : onMenuItemClick();
    }, [searchInputValue, fullMenu, onMenuItemClick]);

    const showUsefulLinks = useCallback(() => {
        onMenuItemClick();
        showUsefulLinksPopup();
    }, [onMenuItemClick, showUsefulLinksPopup]);

    useEscKey(escKeyHandler);

    const configRoutes = useMemo(() => arrayToObject(CONFIG.routes, route => route), []);
    const menuItems = useMemo<ILevel1MenuItem[]>(() => [typed<ILevel1MenuItem>(contextOrganizations?.ids?.length > 1 && {
        name: intl.formatMessage({id: 'topnav.home'}),
        link: '/',
        faIcon: faHomeLgAlt
    })].concat(configRoutes['/news'] && {
        name: intl.formatMessage({id: 'topnav.news'}),
        link: '/news',
        faIcon: faNewspaper
    }).concat(configRoutes['/directory'] && currentUserPermissions?.[PlatformPermissions.PlatformDirectoryView] && {
        name: intl.formatMessage({id: 'topnav.main.people'}),
        link: '/directory',
        faIcon: faUserFriends
    }).concat(configRoutes['/companies'] && currentUserPermissions?.[PlatformPermissions.PlatformEntitiesView] && {
        name: intl.formatMessage({id: 'topnav.main.entities'}),
        link: '/companies',
        faIcon: faBuilding
    }).concat(configRoutes['/admin-console/:tab?'] && isPlatformAdmin(currentUserPermissions) && {
        name: intl.formatMessage({id: 'topnav.adminConsole'}),
        link: '/admin-console',
        faIcon: faUserCrown
    }).concat(configRoutes['/useful-links'] && currentUserPermissions?.[PlatformPermissions.UsefulLinksView] && {
        name: intl.formatMessage({id: 'orgshome.title.usefullinks'}),
        onClick: showUsefulLinks,
        faIcon: faLink
    }).concat(configRoutes['/resume-your-work'] && {
        name: intl.formatMessage({id: 'individualhome.title.resumeYourWork'}),
        link: '/resume-your-work',
        faIcon: faHistory
    }).concat(contextOrganizations?.ids?.map(organizationId => {
        const organization = contextOrganizations.byId[organizationId];
        const redirectToHome = organization?.details[CONFIG.orgContextFlag];
        return {
            name: organization?.details?.name,
            link: redirectToHome ? '/' : `/orgs/${organization.details.urlName}/dashboard`,
            imagePath: organization.details.logo
        };
    })).filter(value => !!value), [intl, contextOrganizations, showUsefulLinks]);

    const filteredMenuItems = useMemo(() => menuItems
        .filter(menuItem => menuItem
            ?.name
            ?.toLowerCase()
            ?.includes(searchInputValue.trim().toLowerCase())
        ), [menuItems, searchInputValue, intl, contextOrganizations]);

    useLayoutEffect(() => {
        if (!fullMenu) {
            const itemsCount = Array
                .from(listRef.current?.children || [])
                .reduce((sum, menuItem) => {
                    const item = menuItem as unknown as HTMLDivElement;
                    return item.offsetTop + item.offsetHeight < wrapperRef.current.offsetHeight ? ++sum : sum;
                }, 0);

            setVisibleMenuItemsCount(itemsCount);
        }
    }, [innerHeight, innerWidth, filteredMenuItems, fullMenu]);

    const onMenuClick = useCallback((organizationUrlName: string) => {
        onMenuItemClick();
        organizationUrlName && dispatch(setOrganizationsName(organizationUrlName));
    }, [onMenuItemClick]);

    const clearSearchResults = useCallback(() => setSearchInputValue(''), []);

    useLayoutEffect(() => {
        isDesktopMenuVisible && searchInputRef.current?.focus();
    }, [isDesktopMenuVisible]);

    const onChangeSearchValue = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (!isDesktopMenuVisible) onFilterClick?.();
        setSearchInputValue(e.target.value);
    }, [isDesktopMenuVisible, onFilterClick]);

    return (
        <Wrapper ref={wrapperRef}>
            <OrganizationsScroller ref={listRef} scrollerDisabled={!fullMenu}>
                {contextOrganizations?.ids?.length > 1 && (
                    <>
                        {fullMenu ? (
                            <Filter>
                                <div>
                                    <TextComponent
                                        ref={searchInputRef}
                                        value={searchInputValue}
                                        onChange={onChangeSearchValue}
                                        placeholder={intl.formatMessage({ id: 'global.filter' })}
                                        onClearClick={clearSearchResults}
                                    />
                                </div>
                            </Filter>
                        ) : (
                                <MenuItem
                                    onClick={onFilterClick}
                                    svgIcon={<FilterIcon height={32} width={32} />}
                                />
                            )}
                    </>
                )}
                {filteredMenuItems.map((menuItem, index) => (
                    <MenuItem
                        key={index}
                        name={menuItem.name}
                        fullMenu={fullMenu}
                        link={menuItem.link}
                        orgUrlName={menuItem.orgUrlName}
                        onClick={menuItem.onClick || onMenuClick}
                        imagePath={menuItem.imagePath}
                        faIcon={menuItem.faIcon}
                        isHidden={!fullMenu && (index > (visibleMenuItemsCount - 1))}
                    />
                ))}
                {fullMenu && (
                    <UserMenu>
                        <UserMenuDropdown currentUserProfile={currentUserProfile} closeDropdown={onMenuItemClick} />
                    </UserMenu>
                )}
            </OrganizationsScroller>
        </Wrapper>
    );
};
