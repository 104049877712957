import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';

interface IWrapperProps {
    inputVisible: boolean;
    newDesign: boolean;
}

export const ColorBar = styled.div<{ error?: boolean }>`
    background-color: ${colorStack.white};
    transition: background-color .3s;
    height: 2px;
    width: 100%;
    border-radius: 0 0 4px 4px;
    ${props => props.error && css`
        background-color: ${colorStack.red};
    `}
`;

export const Wrapper = styled.div<IWrapperProps>`
    position: relative;
    border-radius: 4px;
    transition: all 200ms;
    border: solid 1px ${colorStack.ligthGrey};
    min-height: 32px;
    background-color: ${colorStack.white};
    ${props => props.newDesign && css`
        min-height: 35px;
        box-shadow: 0px .3px .9px ${rgba(colorStack.black, .07)}, 0px 1.6px 3.6px ${rgba(colorStack.black, .11)};
        border-radius: 4px;
        border: none;
    `}

    &:focus {
        outline: 0;
    }

    ${props => props.inputVisible && css`
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    `}
`;

export const Inner = styled.div<{ newDesign: boolean }>`
    ${props => !props.newDesign && css`
        padding: 3px 25px 0 3px;
    `}
    display: flex;
    flex-wrap: wrap;
    position: relative;
    min-height: 30px;
    ${props => props.newDesign && css`
        input {
            outline: none;
            background: white;
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: ${fontSize[16]};
            padding: 4px 12px;
            flex: 1;
            border: 0;
            &:focus + ${ColorBar} {
                background-color: ${colorStack.middleBlue};
            }
        }
    `}
    ${props => !props.newDesign && css`
        input {
            flex: 1;
            min-width: 120px;
            border: 0;
            padding: 0;
            margin: 0;
            margin-bottom: 0;
            margin-left: 0.2rem;
            font-size: ${fontSize[13]};
            height: auto;
            min-height: 26px;
            &:focus {
                outline: 0;
            }

        }
    `}
`;

export const Placeholder = styled.div`
    color: ${colorStack.middleGrey};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0.3rem;
    font-size: ${fontSize[13]};
`;

export const ClearTagsButton = styled.button.attrs({ type: 'button' })`
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 10px;
    height: 10px;
    outline: 0;
    cursor: pointer;

    &:after, &:before {
        content: '';
        height: 2px;
        width: 14px;
        background: ${colorStack.darkBlue};
        position: absolute;
        left: 50%;
        top: 50%;
    }

    &:before {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
`;