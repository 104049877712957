import React, { FC, useCallback } from 'react';
import styled from 'styled-components';

import { IClusterStakeProperty, StakeDetailsType } from '../../../../../entities/IClusterStake';
import { SingleSelectContent } from './SingleSelectContent';
import { NumberContent } from './NumberContent';

const Wrapper = styled.div`
    display: flex;
    > div:first-of-type {
        margin: 0 1rem 0 0;
    }
`;

interface INumberAndSelectProps {
    data: IClusterStakeProperty;
    editStake: boolean;
    propertieId: string;
    error: {
        errorCode: number;
        message: string;
    };
    setEditingData(propertie: IClusterStakeProperty);
}

export const NumberAndSelect: FC<INumberAndSelectProps> = props => {
    const onContentUpdate = useCallback((currentData: IClusterStakeProperty) => {
        props.setEditingData({
            ...currentData,
            type: StakeDetailsType.NumberAndSelect,
            value: {
                SingleSelectValue: currentData.type === StakeDetailsType.SingleSelect ? currentData.value : props.data.value?.SingleSelectValue,
                NumericValue: currentData.type === StakeDetailsType.Number ? currentData.value : props.data.value?.NumericValue
            }
        });
    }, [props.setEditingData, props.data]);

    return (
        <Wrapper>
            <SingleSelectContent
                propertieId={props.data.id}
                error={props.error?.message}
                data={{
                    id: props.data.id,
                    name: props.data?.name,
                    value: props.data?.value?.SingleSelectValue || props.data?.value?.singleSelectValue || props.data?.configuration?.value?.SingleSelectValue || props.data?.configuration?.value?.singleSelectValue,
                    type: StakeDetailsType.SingleSelect,
                    subType: undefined,
                    configuration: props.data?.configuration,
                    key: props.data.key
                }}
                editStake={props.editStake}
                setEditingData={onContentUpdate}
            />
            <NumberContent
                propertieId={props.data.id}
                error={props.error}
                data={{
                    id: props.data.id,
                    name: props.data?.name,
                    value: props.data?.value?.NumericValue || props.data?.configuration?.value?.NumericValue || props.data?.value?.numericValue || props.data?.configuration?.value?.numericValue || props.data?.configuration?.value?.number,
                    type: StakeDetailsType.Number,
                    subType: undefined,
                    configuration: props.data?.configuration,
                    key: props.data.key
                }}
                editStake={props.editStake}
                setEditingData={onContentUpdate}
            />
        </Wrapper>
    );
};