// tslint:disable-next-line:no-var-requires
const stateToHTML = require('draft-js-export-html').stateToHTML;
import { convertFromRaw } from 'draft-js';

export const getTextFromDraft = (text: string) => {
    try {
        const parseResult = JSON.parse(text);
        const state = convertFromRaw(parseResult);

        if (state) {
            return state.getPlainText();
        }

        return '';
    } catch (e) {
        return text;
    }
};

export const rawStringToHtml = (content: string): string => {
    try {
        const parseResult = JSON.parse(content);
        const state = convertFromRaw(parseResult);

        if (state) {
            return stateToHTML(state);
        }

        return '';
    } catch (e) {
        return content;
    }
};

export const getAsHtml = (editorContent: string, editorState: any) => {
    // tslint:disable-next-line:no-null-keyword
    return !!getTextFromDraft(editorContent) ? stateToHTML(editorState) : null;
};