import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize, fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { modalsShadow } from '../../../styleHelpers/mixins/shadow';

const Wrapper = styled.div<{ directionBottom?: boolean, fixedWidth: string }>`
    position: relative;
    z-index: 999;
    ${props => props.fixedWidth && css`
        width: ${`${props.fixedWidth}px`};
    `}
    > div {
        cursor: pointer;
        margin: 0 .5rem;
        & + div {
            visibility: hidden;
        };
        &:hover + div {
            visibility: visible;
            display: inline-table;
            margin-left: 2rem;
            ${props => props.directionBottom && css`
                top:0;
                bottom: auto;
            `};
        };
    };
`;

const WrapperWithoutIco = styled.div<{ fixedWidth: string }>`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 999;
    ${props => props.fixedWidth && css`
        width: ${`${props.fixedWidth}px`};
    `}
    > div {
        display: none;
        white-space: nowrap;
    }
    &:hover {
        > div {
            display: inline-table;
        }
    }
`;

const Content = styled.div<{ colorsSchema: 'dark' | 'light', width: number }>`
    color: ${colorStack.white};
    background: ${colorStack.label};
    position: absolute;
    top: calc(100% + 16px);
    ${fontSizeAndHeight[13]};
    ${modalsShadow};
    border-radius: 8px;
    z-index: 999999;
    padding: .5rem 1rem;
    width: auto;
    white-space: nowrap;
    z-index: 1;
    ${props => props.width && css`
        left: ${`calc(50% - ${props.width / 2}px)`};
    `}
    ${props => props.colorsSchema === 'light' && css`
        color: ${colorStack.content};
        background: ${colorStack.white};
    `}
`;

const Triangle = styled.div<{ colorsSchema: 'dark' | 'light' }>`
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    border-top: 16px solid transparent;
    border-right: 14px solid transparent;
    border-left: 14px solid transparent;
    border-bottom: 16px solid ${colorStack.label};
    bottom: 100%;
    width: 16px;
    ${props => props.colorsSchema === 'light' && css`
        border-bottom: 16px solid ${colorStack.white};
    `}
`;

interface Props {
    children: React.ReactNode;
    withIco?: boolean;
    fixedWidth?: string;
    description?: string;
    colorsSchema?: 'dark' | 'light';
}

export const Tooltip: FC<Props> = props => {
    const wrapperRef = useRef<HTMLDivElement>();
    const [contentWidth, setContentWidth] = useState<number>(0);
    const [hover, setHover] = useState<boolean>(false);

    useEffect(() => {
        setContentWidth(wrapperRef?.current?.scrollWidth);
    }, [wrapperRef, props.children, hover]);

    const checkHover = () => {
        setHover(state => !state);
    };

    return (
        <>
            {props.withIco ? (
                <Wrapper fixedWidth={props.fixedWidth} onMouseEnter={checkHover} onMouseLeave={checkHover}>
                    <div>
                        <FontAwesomeIcon icon={faCircleInfo} />
                    </div>
                    <Content ref={wrapperRef} colorsSchema={props.colorsSchema || 'dark'} width={contentWidth}>
                        <Triangle colorsSchema={props.colorsSchema || 'dark'} />
                        {props.children}
                    </Content>
                </Wrapper>
            ) : (
                <WrapperWithoutIco fixedWidth={props.fixedWidth} onMouseEnter={checkHover} onMouseLeave={checkHover}>
                    <Content ref={wrapperRef} colorsSchema={props.colorsSchema || 'dark'} width={contentWidth}>
                        <Triangle colorsSchema={props.colorsSchema || 'dark'} />
                        {props.children}
                    </Content>
                </WrapperWithoutIco>
            )}
        </>
    );
};
