import React, { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PlacesAutocomplete, { } from 'react-places-autocomplete';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { IState } from '../../../reducers';
import { IContextReducer } from '../../../reducers/contextReducer';
import { colorStack } from '../../../styleHelpers/colors';
import { IAddress } from '../../../entities/IProfile';
import { IAddressGlobal } from '../../../entities/IGlobal';
import { cardsShadow } from '../../../styleHelpers/mixins/shadow';

const DropdownWrapper = styled.div<{ disabled: boolean }>`
    position: relative;
    input {
        height: 42px;
        padding: 4px 42px 4px 12px;
        box-shadow: inset 0px 1px 4px ${colorStack.ligthGrey}, inset 0px 3px 9px rgba(255, 255, 255, 0.5);
        border: none;
        ${props => props.disabled && css`
            background: ${colorStack.ligthGrey}!important;
            color: ${colorStack.disabled}!important;
        `}
        &::placeholder {
            color: ${colorStack.middleGrey};
            font-weight: 400!important;
            font-style: italic;
            font-family: 'Roboto', sans-serif;
            &:disabled {
                color: ${colorStack.middleGrey};
            }
        }
    }
`;

const Dropdown = styled.div`
    position: absolute;
    width: 100%;
    z-index: 8;
    ${cardsShadow()};
    border-radius: 4px;
    background: ${colorStack.white};
    margin: 3px 0 0 0;
    overflow: hidden;
`;

const Suggestion = styled.div<{ elementNumber: number }>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    width: 100%;
    z-index: 8;
    padding: 10px 5px;
    min-height: 40px;
    background: ${colorStack.white};
    transition: background-color .2s ease;
    &:hover {
        background: ${colorStack.ligthGrey};
    }
`;

export enum CountrySwitch {
    FR = 'FR',
    US = 'US',
    AUS = 'AUS',
    GB = 'GB'
}

export interface IAddressObject {
    street_number?: string;
    route?: string;
    neighborhood?: string;
    locality?: string;
    administrative_area_level_1?: string;
    country?: string;
    postal_code?: string;
}

interface IProps {
    value?: IAddress;
    address?: IAddressGlobal;
    placeholder?: string;
    disabled?: boolean;
    onChangeAddress(value: string);
    onSelectAddress(value: string);
}

export const GoogleAddressEditor: FC<IProps> = props => {
    const intl = useIntl();
    const state = useSelector<IState, IContextReducer>(currentState => ({
        ...currentState.context
    }));

    const valueCondition = useMemo(() => {
        let stringAddress = '';

        if (props.address) {
            stringAddress = props.address.country !== '' ? (
                Object.values(CountrySwitch).includes(props.address.countryShort as CountrySwitch) ?
                    `${props.address?.number}${` ${props.address?.street}`}${(props.address?.street || props.address?.number) && ', '}${props.address?.zipCode ? `${props.address.zipCode} ` : ''}${props.address?.city || props.address?.state}${(props.address?.city || props.address?.state) && ', '}${props.address?.country}` :
                    `${props.address?.street}${(props.address?.street || props.address?.number) && ' '}${props.address?.number}${(props.address?.street || props.address?.number) && ', '}${props.address?.zipCode ? `${props.address.zipCode} ` : ''}${props.address?.city || props.address?.state}${(props.address?.city || props.address?.state) && ', '}${props.address?.country}`
            ) : (props.address.city || props.address.state);
        } else if (props.value) {
            // value interface is deprecated but still in use
            stringAddress = props.value.Country !== '' ? (
                Object.values(CountrySwitch).includes(props.value.CountryShort as CountrySwitch) ?
                    `${props.value?.Number}${` ${props.value?.Street}`}${(props.value?.Street || props.value?.Number) && ', '}${props.value?.ZipCode ? `${props.value.ZipCode} ` : ''}${props.value?.City || props.value?.State}${(props.value?.City || props.value?.State) && ', '}${props.value?.Country}` :
                    `${props.value?.Street}${(props.value?.Street || props.value?.Number) && ' '}${props.value?.Number}${(props.value?.Street || props.value?.Number) && ', '}${props.value?.ZipCode ? `${props.value.ZipCode} ` : ''}${props.value?.City || props.value?.State}${(props.value?.City || props.value?.State) && ', '}${props.value?.Country}`
            ) : (props.value.City || props.value.State);
        }
        return stringAddress;
    }, [props.address, props.value, CountrySwitch]);

    const onChangeAddressHandler = useCallback((newValue: string) => {
        props.onChangeAddress(newValue);
    }, [props.onChangeAddress]);

    const onSelectAddressHandler = useCallback((newValue: string) => {
        props.onSelectAddress(newValue);
    }, [props.onSelectAddress]);

    return (
        <>
            {state?.googlePlacesLoaded &&
                <div className="lcr-form-block">
                    <div className="ms-TextField">
                        <PlacesAutocomplete
                            value={valueCondition}
                            onChange={onChangeAddressHandler}
                            onSelect={onSelectAddressHandler}
                            searchOptions={{ types: ['geocode'] }}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <DropdownWrapper className="autocomplete" disabled={props.disabled}>
                                    <input
                                        {...getInputProps({
                                            placeholder: props.placeholder || intl.formatMessage({ id: 'global.searchPlaces' }),
                                            className: 'ms-TextField-field',
                                            disabled: props.disabled
                                        })}
                                    />
                                    {suggestions.length > 0 &&
                                        <Dropdown className="autocomplete-dropdown-container">
                                            {loading && <div><FormattedMessage id="global.loadingDots" /></div>}
                                            {suggestions.map((suggestion, i) => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';

                                                return (
                                                    <Suggestion
                                                        key={suggestion.id || i}
                                                        elementNumber={i}
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </Suggestion>
                                                );
                                            })}
                                        </Dropdown>
                                    }
                                </DropdownWrapper>
                            )}
                        </PlacesAutocomplete>
                    </div>
                </div>
            }
        </>
    );
};
