import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';

const SWrapper = styled.div<{ active: boolean, noPadding?: boolean }>`
    flex-direction: column;
    padding: 1rem 5%;
    display: none;
    ${props => props.active && css`
        display: flex;
    `}
    ${props => props.noPadding && css`
        padding: 0;
    `}
`;

const SHeader = styled.div<{ active: boolean }>`
    display: flex;
    flex-direction: column;
    color: ${colorStack.darkBlue};
    margin-bottom: 1rem;
    div {
        margin-top: 20px;
        color: ${colorStack.darkGrey};
        &:nth-child(2) {
            margin-top: 10px;
            color: ${colorStack.darkBlue};
            font-size: ${fontSize[20]};
            font-weight: 400;
            margin-bottom: 20px;
        }
    }
`;

const SContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

interface IProps {
    data: {
        title: string;
        name?: string;
    };
    isActive: boolean;
    noPadding?: boolean;
}

export const SingleStep: FC<IProps> = props => {
    return (
        <SWrapper active={props.isActive} noPadding={props.noPadding}>
            {props.isActive && (
                <>
                    {(props.data.name || props.data.title) &&
                        <SHeader active={props.isActive}>
                            {props.data?.name &&
                                <div>
                                    {props.data?.name}
                                </div>
                            }
                            {props.data?.title &&
                                <div>
                                    {props.data?.title}
                                </div>
                            }
                        </SHeader>
                    }
                    <SContent>
                        {props.children}
                    </SContent>
                </>
            )}
        </SWrapper>
    );
};