import React, { useCallback, useState, useEffect, FC } from 'react';
import styled, { css } from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import Scrollbars from 'react-custom-scrollbars';
import { rgba } from 'polished';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDropdown } from '../../../tools/hooks';
import { NewClose } from '../../../components/Common/Icons';
import { useEscKey } from '../../../tools/keyboardTools';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import boxShadow from '../../../styleHelpers/mixins/shadow';
import { Button } from '../Buttons/Button';
import { IPopupSizes } from '../../../entities/IGlobal';
import { SingleStep } from './SingleStep';
import { TemplateTypes } from '../../../entities/IClusters';

const SWrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background: ${rgba(colorStack.label, .75)};
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
`;

const RightMenuWrapper = styled.div`
    display: flex;
`;

const SWrapperInner = styled.div<{ size: IPopupSizes }>`
    background: ${colorStack.white};
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.5px;
    border-radius: 4px;
    ${boxShadow()};
    max-width: 100%;
    ${props => props.size === IPopupSizes.Small && css`
        width: 700px;
    `}
    ${props => props.size === IPopupSizes.Medium && css`
        width: 768px;
    `}
    ${props => props.size === IPopupSizes.Big && css`
        width: 1024px;
    `}
    ${props => props.size === IPopupSizes.Monster && css`
        width: 1200px;
    `}
`;

const ContextMenuWrapper = styled.div`
    position: relative;
    > svg {
        color: ${colorStack.middleBlue};
        margin: 0 1rem 0 0;
        font-size: ${fontSize[20]};
        cursor: pointer;
    }
`;

const SHeader = styled.div`
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 1rem 24px 1rem 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${colorStack.ligthGrey};
    svg {
        fill: ${colorStack.disabled};
        &:hover{
            fill: ${colorStack.red}
        }
    }
`;
const STitle = styled.div`
    font-weight: 700;
    color: ${colorStack.blue};
    font-size: ${fontSize[20]};
    font-weight: 500;
`;
const SClosePopup = styled.div`
    cursor: pointer;
`;

const SContent = styled.div`
    background: ${colorStack.whiteGrey};
`;

const SpinnerWrapper = styled.div`
    display: flex;
    align-items: center;
`;
const SFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${colorStack.white};
    padding: 1rem 24px 1rem 24px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
`;
const SSteps = styled.div`
    width:55%;
    span {
        margin-left: 24px;
        font-size: ${fontSize[13]};
        color: ${colorStack.middleGrey};
    }
`;
const SButtonBox = styled.div`
    display: flex;
    justify-content: space-between;
    button {
        margin: 0 0 0 .5rem;
    }
`;

const SLine = styled.div<{ percent: number }>`
    border-radius: 4px;
    height: 8px;
    width: 100%;
    background: ${colorStack.ligthGrey};
    overflow: hidden;
    display: flex;
    align-items: center;
    margin: .3rem 0 0 0;
    &:before {
        content: '';
        display: block;
        background: ${colorStack.middleBlue};
        border-radius: 4px;
        height: 8px;
        width: calc(100% - 2px);
        position: relative;
        transition: right .3s;
        ${props => props.percent && css`
            right: ${`calc(100% - ${props.percent}%)`};
        `}
    }
`;

export interface IProps {
    showPopup: boolean;
    size?: IPopupSizes;
    title: React.ReactNode;
    confirmDisabled: boolean;
    steps: {
        title?: string;
        content: React.ReactNode;
        name?: string;
        nextStepButtonText?: string;
        showSteps?: boolean
    }[];
    isNextButtonDisabled?: boolean;
    confirmFinished?: boolean;
    sendButtonText?: React.ReactNode;
    lastButtonText?: React.ReactNode;
    showDeclareNew?: boolean;
    wizardType?: string;
    isFormValid?: boolean;
    dirty?: boolean;
    lastThanksScreen?: number;
    sendButtonType?: 'button' | 'submit' | 'reset';
    contextMenu?: React.ReactNode;
    noPadding?: boolean;
    loadingData?: boolean;
    confirmHandler?();
    finishHandler?();
    clearForm?();
}

export const StepsPopup: FC<IProps> = props => {
    const intl = useIntl();
    const [wrapperRef, dropdownOpen, toggleDropdown, closeDropdown] = useDropdown();
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEscKey(() => setShowPopup(false));
    useEffect(() => {
        if (props.showPopup) {
            setCurrentStep(1);
        }
        setShowPopup(props.showPopup);
    }, [props.showPopup]);

    const handleScrollbarsMount = useCallback((scrollbar) => {
        scrollbar?.scrollToTop();
    }, [currentStep]);

    const addStep = useCallback(() => {
        setCurrentStep(step => step + 1);
    }, []);

    useEffect(() => {
        props.confirmFinished && addStep();
    }, [props.confirmFinished]);

    const substractStep = useCallback(() => {
        setCurrentStep(step => step - 1);
    }, []);

    const confirm = useCallback(() => {
        setLoading(true);
        props.confirmHandler?.().then(() => setLoading(false));
    }, [props.confirmHandler]);

    const finish = useCallback(() => {
        setLoading(false);
        props.finishHandler();
    }, [props.finishHandler]);

    const declareNew = useCallback(() => {
        props.clearForm();
        setCurrentStep(1);
    }, [currentStep]);

    const openContextMenu = useCallback(() => {
        toggleDropdown();
    }, [toggleDropdown]);

    return (
        <>
            {showPopup &&
                <SWrapper>
                    <SWrapperInner size={props.size || IPopupSizes.Small}>
                        <SHeader>
                            <STitle>{props.title}</STitle>
                            <RightMenuWrapper>
                                {props.contextMenu &&
                                    <ContextMenuWrapper ref={wrapperRef} onClick={openContextMenu}>
                                        <FontAwesomeIcon icon={faEllipsisH} />
                                        {dropdownOpen &&
                                            <>
                                                {props.contextMenu}
                                            </>
                                        }
                                    </ContextMenuWrapper>
                                }
                                <SClosePopup onClick={props.finishHandler}>
                                    <NewClose width={24} height={24} />
                                </SClosePopup>
                            </RightMenuWrapper>
                        </SHeader>
                        <SContent>
                            <Scrollbars
                                autoHeight
                                autoHeightMin="60vh"
                                autoHeightMax="60vh"
                                ref={handleScrollbarsMount}
                            >
                                {props.steps.map((step, index) =>
                                    <SingleStep
                                        key={index}
                                        data={({
                                            title: step.title,
                                            name: step.showSteps && (step.name || `${intl.formatMessage({ id: 'global.step' }, { index: index.toString() })}`)
                                        })}
                                        isActive={currentStep - 1 === index}
                                        noPadding={props.noPadding}
                                    >
                                        {step.content}
                                    </SingleStep>
                                )}
                            </Scrollbars>
                        </SContent>
                        <SFooter>
                            <SSteps>
                                {props?.steps?.length > 2 && currentStep !== props.steps?.length && (
                                    <>
                                        <FormattedMessage id="cluster.stepsPopup.steps" values={{ currentStep: currentStep, stepsLength: props.steps?.length - 1 }} />
                                        <SLine percent={(currentStep / (props.steps?.length - 1)) * 100} />
                                    </>
                                )}
                            </SSteps>
                            <SButtonBox>
                                {(currentStep > 1 && currentStep !== props.steps.length) &&
                                    <Button onClick={substractStep} disabled={loading || props.loadingData} bgColor={colorStack.white} borderColor={colorStack.middleGrey} fontColor={colorStack.darkGrey} data-lc="js-lc-button-back"><FormattedMessage id="global.back" /></Button>
                                }
                                {currentStep < props.steps.length - 1 &&
                                    <Button
                                        onClick={addStep}
                                        disabled={!(props.isFormValid && props.dirty)}
                                        data-lc="js-lc-button-next"
                                    >
                                        <FormattedMessage id="global.next" />
                                    </Button>
                                }
                                {currentStep === props.steps.length && (
                                    <>
                                        {props.showDeclareNew && (
                                            <Button onClick={declareNew} bgColor={colorStack.white} borderColor={colorStack.middleGrey} fontColor={colorStack.darkGrey}  data-lc="js-lc-button-anotherDeclaration">
                                                {props.wizardType === TemplateTypes.COI && <FormattedMessage id="coiwizard.declareAnotherLink" />}
                                                {props.wizardType === TemplateTypes.Gift && <FormattedMessage id="giftinvitation.startAnotherDeclaration" />}
                                            </Button>
                                        )}
                                        <Button bgColor={colorStack.white} borderColor={colorStack.middleGrey} fontColor={colorStack.darkGrey} data-lc="js-lc-button-close" onClick={finish}>{props.lastButtonText || <FormattedMessage id="global.close" />}</Button>
                                    </>
                                )}
                                {(currentStep === props.steps.length - 1 || (props.confirmHandler && props.steps.length === 1)) &&
                                    <>
                                        <SpinnerWrapper>
                                            <Button onClick={confirm} data-lc="js-lc-button-send" type={props.sendButtonType || 'submit'} disabled={loading || !props.isFormValid || props.loadingData} loading={loading}>
                                                {props.sendButtonText || <FormattedMessage id="global.send" />}
                                            </Button>
                                        </SpinnerWrapper>
                                    </>
                                }
                            </SButtonBox>
                        </SFooter>
                    </SWrapperInner>
                </SWrapper>
            }
        </>
    );
};