import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../../../../styleHelpers/breakpoint';
import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { useNewDesignContext } from '../../../../NewDesignCommon/NewDesignSwitch/NewDesignSwitch';

const specificLayouts = {
    'scope': css`
        & > :nth-child(7),
        & > :nth-child(8) {
            grid-column: 1 / span 2;
        }
    `,
    'status-and-formality': css`
        & > :nth-child(3) {
            grid-column: 1 / span 2;
        }
    `,
    'attachment-poa': css`
        & > :nth-child(2) {
            grid-column: 1 / span 2;
        }
    `,
    'notes': css`
        & > :nth-child(2) {
            grid-column: 1 / span 2;
        }
    `
};

const DefaultLayout = styled.div<{ withPadding?: boolean; stakeKey?: string }>`
    padding: ${({ withPadding }) => withPadding ? `1rem ${fontSize[25]}` : '0'};
    ${({ stakeKey }) => specificLayouts[stakeKey]}

    && > * {
        display: block;
        margin: 0;
    }
`;

const DefaultGridLayout = styled(DefaultLayout)`
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 1rem;
`;

const TwoColumnsLayout = styled(DefaultGridLayout)`
    ${media.tabletSm`
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 2rem;
    `};
`;

export const ThreeColumnsLayout = styled(TwoColumnsLayout)`
    ${media.desktop`
        grid-template-columns: repeat(3, minmax(0, 1fr));
    `}
`;

interface INewDesignStakeLayout {
    stakeKey: string;
    withPadding?: boolean;
}

export const NewDesignStakeLayout: FC<INewDesignStakeLayout> = ({ stakeKey, children, withPadding }) => {
    const newDesign = useNewDesignContext();

    if (!newDesign.active) {
        return <>{children}</>;
    }

    if (['poa-parties'].includes(stakeKey)) {
        return <ThreeColumnsLayout withPadding={withPadding}>{children}</ThreeColumnsLayout>;
    }

    if (['area-of-resp', 'scope', 'delegation-duration', 'status-and-formality', 'attachment-poa', 'notes'].includes(stakeKey)) {
        return <TwoColumnsLayout withPadding={withPadding} stakeKey={stakeKey}>{children}</TwoColumnsLayout>;
    }

    return <DefaultLayout withPadding={withPadding}>{children}</DefaultLayout>;
};
