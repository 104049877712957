import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useUrlQuery } from '../../tools/hooks';
import { setAccessToken } from '../../actions/contextActions';

export const ShadowAuth: FC = ({ children }) => {
    const dispatch = useDispatch();
    const { shadowAuth, token } = useUrlQuery<{ shadowAuth: string; token: string }>();
    const isShadowAuth = shadowAuth === '1';

    useEffect(() => {
        if (isShadowAuth) {
            dispatch(setAccessToken(token, true));
        }
    }, [isShadowAuth, token]);

    return <>{children}</>;
};
