import { rgba } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colorStack } from '../../../../../styleHelpers/colors';
import { fontSize } from '../../../../../styleHelpers/fontSizes';
import boxShadow from '../../../../../styleHelpers/mixins/shadow';

export const Wrapper = styled.div`
    ${boxShadow()};
    border-radius: 4px;
    margin: 1rem 0;
    background: ${colorStack.white};
    display: flex;
    flex-direction: column;
`;

export const TopSection = styled.div`
    display: flex;
    justify-content: space-between;
    background: ${rgba(colorStack.darkBlue, .05)};
`;

export const BottomSection = styled.div`
    display: flex;
    flex-direction: column;
`;

export const DetailSection = styled.div`
    display: flex;
`;

export const PartyInfoWrapper = styled.div`
    padding: 1rem 2rem;
`;

export const Position = styled.div`
    font-size: ${fontSize[16]};
    font-weight: 600;
    color: ${colorStack.darkBlue};
    margin: .5rem;
`;

export const Attachment = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${colorStack.darkBlue};
    span {
        font-weight: 500;
        margin: 0 .5rem;
    }
`;

export const LeftSide = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    > div {
        align-items: center;
        display: flex;
    }
`;

export const EntityDetails = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 0 1rem;
`;

export const RightSide = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 0 auto;
    justify-content: center;
    width: 50%;
`;

export const RightSideRow = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0 0 0 auto;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 50%;
`;

export const ImageWrapper = styled.div`
    width: 80px;
    height: 80px;
    margin: 0 1rem 0 0;
`;

export const SmallImage = styled.div`
    width: 20px;
    height: 20px;
    margin: 0 1rem 0 0;
`;

export const AdditionalInfo = styled.span`
    font-size: ${fontSize[13]};
    color: ${colorStack.disabled};
    margin: 1rem 0;
`;

export const Name = styled(Link)`
    font-size: ${fontSize[16]};
    font-weight: 500;
    color: ${colorStack.darkBlue};
    &:visited {
        color: ${colorStack.darkBlue};
    }
    &:hover {
        color: ${colorStack.darkBlue};
    }
`;

export const RepresentativeName = styled(Name as any)`
    font-size: ${fontSize[13]};
    margin: 0 1rem 0 0;
`;

export const DateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    > div {
        margin: 0 1rem 2rem 0;
    }
`;

export const LawFirmPicture = styled.div<{ url: string }>`
    background-image: url(${props => props.url});
    border-radius: 4px;
    width: 60px;
    height: 60px;
    background-size: cover;
    background-repeat: no-repeat;
`;

export const Date = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${fontSize[13]};
    margin: 0 1rem 0 0;
`;

export const IconWrapper = styled.div`
    min-width: 100px;
    color: ${colorStack.disabled};
    margin: .5rem .5rem .5rem 0;
    svg {
        font-size: ${fontSize[20]};
        &:last-child {
            color: ${colorStack.middleBlue};
        }
    }
    span {
        font-size: ${fontSize[13]};
        margin: 0 1rem;
        font-weight: normal !important;
    }
`;

export const Representatives = styled.div`
    font-size: ${fontSize[13]};
    display: flex;
    flex-direction: column;
    margin: 0 0 .5rem 0;
`;

export const InlineProperty = styled.div`
    display: flex;
    align-items: center;
`;

export const MiddleSection = styled.div`
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
`;

export const Terms = styled.div`
    margin: 1rem 0 0 0;
`;