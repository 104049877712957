import React, { FC, useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { faTimes, faAngleDown, faAngleUp, faExpandAlt, faCommentsAlt as readMessages } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Scrollbars from 'react-custom-scrollbars';

import { history } from '../../../history';
import boxShadow from '../../../styleHelpers/mixins/shadow';
import { colorStack } from '../../../styleHelpers/colors';
import ScrollBox from '../../Common/ScrollBox/ScrollBox';
import { Loader } from '../../Common/Loader/Loader';
import { MessageBoxContainerInner, MessagesWrapper } from '../MessageBox/Components';
import { IState } from '../../../reducers';
import { IMessagingReducer } from '../../../reducers/messagingReducer';
import { IProfileReducer } from '../../../reducers/profileReducer';
import { getMessages, removeActiveConversation, setCurrentConversation } from '../../../actions/messagingActions';
import { ISingleConversation, ISingleMessage, MessageSystemTypes } from '../../../entities/Messaging/IMessaging';
import { ISearchResponse } from '../../../entities/IGlobal';
import { getConversationTitle } from '../../../tools/messaging';
import SingleMessage from '../SingleMessage';
import { updateObjectsInArray } from '../../../tools/arrayTools';
import { Avatar } from '../Avatar';
import SendMessage from '../SendMessage';

const Wrapper = styled.div<{ isOpen: boolean; position?: number; isHidden?: boolean }>`
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 9999;
    width: 280px;
    ${props => props.isOpen && css`
        width: 360px;
    `}
    ${props => props.isHidden && css`
        display: none;
    `}
    border-radius: 4px 4px 0 0;
    ${boxShadow()};
    bottom: 0px;
    right: 105px;
    ${props => props.position && css`
        right: ${props.position}px;
    `}
    background: ${colorStack.white};
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 34px;
`;

const IconSection = styled.div`
    display: flex;
    justify-items: center;
    svg {
        cursor: pointer;
        margin: 0 0 0 .5rem;
    }
    margin: 0 .5rem 0 0;
`;

const ContextInfo = styled.div<{ newMessages?: boolean }>`
    display: flex;
    align-items: center;
    svg {
        margin: .5rem;
    }
    img {
        margin: .5rem;
    }
    ${props => props.newMessages && css`
        font-weight: 500;
    `}
`;

const Conversations = styled.div`
    height: 455px;
`;

const ImageWrapper = styled.div`
    width: 16px;
    height: 16px;
    position: relative;
    margin: 0;
    align-self: center;
`;

interface SingleConversationWindowProps {
    position: number;
    conversation: ISingleConversation;
    lastUploadedAttachment: ISingleMessage;
    lastReceivedMessage: ISingleMessage;
}

export const SingleConversationWindow: FC<SingleConversationWindowProps> = ({ conversation, position, lastUploadedAttachment, lastReceivedMessage }) => {
    const dispatch = useDispatch();
    // tslint:disable-next-line:no-null-keyword
    const scrollListWrapperRef = useRef<HTMLDivElement>(null);
    // tslint:disable-next-line:no-null-keyword
    const scrollComponent = useRef<Scrollbars>(null);
    const { currentUserProfile } = useSelector<IState, IMessagingReducer & IProfileReducer>(state => ({
        ...state.messaging,
        ...state.profile
    }));
    const [nextPage, setNextPage] = useState<number>(1);
    const [messages, setMessages] = useState<ISingleMessage[]>(undefined);
    const [messagesReceived, setMessagesReceived] = useState<number>(0);
    const [messagesCount, setMessagesCount] = useState<number>(0);
    const [conversationVisible, setConversationVisible] = useState<boolean>(true);
    const hasMoreMessages = messagesCount && (messagesCount > messages?.length);

    const reducedCurrentConversationMembers = conversation?.conversationMembers.reduce((allMembers, member) => ({
        ...allMembers,
        [member.id]: member
    }), {});

    const getRef = (scrollRef: Scrollbars) => {
        scrollComponent.current = scrollRef;
    };

    const autoScrollToBottom = () => {
        scrollComponent.current?.scrollToBottom();
    };

    useEffect(() => {
        /* if (conversation?.id) {
            dispatch(getMessages(20, conversation.id, 0, true))
                .then((messages: ISearchResponse<ISingleMessage>) => {
                    setMessages((messages?.items || []).sort((a, b) => (new Date(a.createdDate) as any) - (new Date(b.createdDate) as any)));
                    setMessagesCount(messages?.count);
                    autoScrollToBottom();
                })
            setNextPage(1);
        } */
    }, [conversation]);

    const updateMessages = useCallback((message: ISingleMessage) => {
        setMessages(messages => [...messages, message]);
    }, []);

    useEffect(() => {
        if (lastUploadedAttachment?.id && lastUploadedAttachment?.conversationId === conversation.id) {
            setMessages(messages => {
                return updateObjectsInArray(messages, message => message.id === lastUploadedAttachment.id, {
                    attachments: lastUploadedAttachment.attachments
                });
            });
            autoScrollToBottom();
        }
    }, [lastUploadedAttachment?.id, conversation.id]);

    useEffect(() => {
        if (lastReceivedMessage?.id && lastReceivedMessage?.conversationId === conversation.id) {
            const isRemovingMessage =  lastReceivedMessage.type === MessageSystemTypes.SystemRemovingMessage;
            if (isRemovingMessage) {
                setMessages(messages => {
                    return updateObjectsInArray(messages, message => message.id === lastReceivedMessage.tempId, lastReceivedMessage);
                });
            } else {
                setMessages(messages => {
                    const isAlreadyReceived = (messages || []).some(msg => msg.id === lastReceivedMessage.id);
                    return isAlreadyReceived ? messages : [...(messages || []), lastReceivedMessage];
                });
            }
            setMessagesReceived(received => received + 1);
        }
    }, [lastReceivedMessage?.id, conversation.id]);

    useEffect(() => {
        autoScrollToBottom();
    }, [messagesReceived]);

    const onChangeVisibility = useCallback(() => {
        setConversationVisible(state => !state);
    }, []);

    const onClose = useCallback(() => {
        dispatch(removeActiveConversation(conversation.id));
    }, [conversation?.id]);

    const onMaximize = useCallback(() => {
        dispatch(setCurrentConversation(conversation));
        /* dispatch(getMessages(50, conversation.id))
            .then(() => history.push('/messaging')); */
    }, [conversation]);

    const getMoreMessages = useCallback(() => {
        const scrollHeight = scrollComponent?.current?.getScrollHeight();
        /* if (hasMoreMessages) {
            dispatch(getMessages(20, conversation?.id, nextPage, true))
                .then((messages: ISearchResponse<ISingleMessage>) => {
                    setMessages((messages?.items || []).sort((a, b) => (new Date(a.createdDate) as any) - (new Date(b.createdDate) as any)));
                    setMessagesCount(messages?.count);
                    setNextPage(currentPage => currentPage + 1);
                    scrollComponent.current?.scrollTop(scrollComponent.current?.getScrollHeight() - scrollHeight);
                })
        } */
    }, [nextPage, hasMoreMessages, conversation?.id]);

    const currentConvMember = useMemo(() => {
        return (conversation?.conversationMembers || []).find(member => member.id !== currentUserProfile.id) || conversation?.conversationMembers?.[0];
    }, [conversation, currentUserProfile]);

    return (
        <Wrapper isOpen={conversationVisible} position={position} isHidden={window.innerWidth - position < 365}>
            <Header>
                <ContextInfo>
                    <ImageWrapper>
                        <Avatar members={conversation.conversationMembers} currentUserId={currentUserProfile.id} />
                    </ImageWrapper>
                    <span>{getConversationTitle(conversation.conversationMembers, conversation, currentConvMember)}</span>
                </ContextInfo>
                <IconSection>
                    <FontAwesomeIcon icon={faExpandAlt} onClick={onMaximize} />
                    <FontAwesomeIcon icon={conversationVisible ? faAngleDown : faAngleUp} onClick={onChangeVisibility} />
                    <FontAwesomeIcon icon={faTimes} onClick={onClose} />
                </IconSection>
            </Header>
            {conversationVisible &&
                <Conversations>
                    <MessageBoxContainerInner>
                        <MessagesWrapper>
                            {(messages?.length > 0) ? (
                                <ScrollBox
                                    onScrollResults={getMoreMessages}
                                    isReverse
                                    hasMore={hasMoreMessages}
                                    getRef={getRef}
                                    innerRef={scrollListWrapperRef}
                                >
                                    {messages.map((message: ISingleMessage, index) => {
                                        const sender = reducedCurrentConversationMembers?.[message.senderUserId];

                                        return (
                                            <SingleMessage
                                                isPopup
                                                isFirstMessage={index === 0}
                                                user={message.senderUserId === currentUserProfile.id ? 'current' : 'other'}
                                                data={{
                                                    ...message,
                                                    senderName: sender && sender.displayName,
                                                    senderPicture: sender && sender.picture
                                                }}
                                                key={message.id}
                                            // setPdfDocument={this.props.setPdfDocument}
                                            // setImageDocument={this.props.setImageDocument}
                                            />
                                        )
                                    })}
                                </ScrollBox>
                            ) : (
                                    <Loader loading={!messages} size={'medium'} label={<FormattedMessage id="loaders.messages" />} className="spinner" />
                                )}
                        </MessagesWrapper>
                        <SendMessage sendAction={autoScrollToBottom} conversationIdToDispatch={conversation.id} updateMessages={updateMessages} isPopup />
                    </MessageBoxContainerInner>
                </Conversations>
            }
        </Wrapper>
    );
};