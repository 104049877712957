import React, { FC } from 'react';
import { faCalendarDay, faBell } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import { IClusterStakeProperty, PropertieKey } from '../../../../../../entities/IClusterStake';
import * as Common from '../Common';

interface IDateSectionProps {
    propertyByKey: {
        [key in PropertieKey]?: IClusterStakeProperty;
    }
}

export const DateSection: FC<IDateSectionProps> = ({ propertyByKey }) => (
    <Common.DateWrapper>
        {(propertyByKey?.['date-start']?.value?.date || propertyByKey['date-start']?.value?.endAt) &&
            <Common.Date>
                <Common.IconWrapper>
                    <FontAwesomeIcon icon={faCalendarDay} />
                    <span>{propertyByKey['date-start']?.name}</span>
                    {propertyByKey['date-start']?.value?.isReminderActivated &&
                        <FontAwesomeIcon icon={faBell} />
                    }
                </Common.IconWrapper>
                <span>{moment.utc(propertyByKey['date-start']?.value?.date || propertyByKey['date-start'].value?.endAt).local().format('DD MMM YYYY')}</span>
            </Common.Date>
        }
        <Common.Date>
            <Common.IconWrapper>
                <FontAwesomeIcon icon={faCalendarDay} />
                <span>{propertyByKey?.['date-end']?.name}</span>
                {propertyByKey['date-end']?.value?.isReminderActivated &&
                    <FontAwesomeIcon icon={faBell} />
                }
            </Common.IconWrapper>
            <span>{
                (propertyByKey?.['date-end']?.value?.date || propertyByKey['date-end']?.value?.endAt) ?
                    moment.utc(propertyByKey['date-end'].value?.date || propertyByKey['date-end']?.value?.endAt).local().format('DD MMM YYYY')
                    : <FormattedMessage id="cluster.label.indetermined"  />
            }</span>
        </Common.Date>
        {(propertyByKey?.['last-renewal-date']?.value?.date || propertyByKey['last-renewal-date']?.value?.endAt) &&
            <Common.Date>
                <Common.IconWrapper>
                    <FontAwesomeIcon icon={faCalendarDay} />
                    <span>{propertyByKey?.['last-renewal-date']?.name}</span>
                    {propertyByKey['last-renewal-date']?.value?.isReminderActivated &&
                        <FontAwesomeIcon icon={faBell} />
                    }
                </Common.IconWrapper>
                <span>{
                    (propertyByKey?.['last-renewal-date']?.value?.date || propertyByKey['last-renewal-date']?.value?.endAt) ?
                        moment.utc(propertyByKey['last-renewal-date'].value?.date || propertyByKey['last-renewal-date']?.value?.endAt).local().format('DD MMM YYYY')
                        : <FormattedMessage id="cluster.label.indetermined"  />
                }</span>
            </Common.Date>
        }
    </Common.DateWrapper>
);