import React, { FC, Fragment, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { IconDefinition, faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { cardsShadow } from '../../../styleHelpers/mixins/shadow';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { useDropdown } from '../../../tools/hooks';

const Wrapper = styled.div<{openFromRight?: boolean}>`
    border-radius: 8px;
    background: ${colorStack.white};
    ${cardsShadow()};
    position: absolute;
    z-index: 999;
    min-width: 220px;
    ${props => props.openFromRight && css`
        right: 0;
    `}
`;

const Link = styled.button`
    color: ${colorStack.content};
    border: none;
    background: none;
    ${fontSizeAndHeight[16]};
    padding: .5rem;
    font-weight: 500;
    width: 100%;
    text-align: left;
    transition: all .3s;
    cursor: pointer;
    margin: 0!important;
    white-space: nowrap;
    display: block;
    svg {
        margin: 0 .5rem 0 0;
        transition: all .3s;
    }
    &[disabled],
    &[disabled]:hover {
        transition: none;
        cursor: not-allowed;
        background: ${colorStack.middleGrey};
    }

    &:hover {
        background: ${colorStack.whiteBlue};
    }
`;

const ExpandedElem = styled.div`
    color: ${colorStack.content};
    ${fontSizeAndHeight[16]};
    padding: .5rem;
    font-weight: 500;
    text-align: left;
    transition: all .3s;
    cursor: pointer;
    margin: 0!important;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
        margin: 0 0 0 2rem;
        transition: all .3s;
    }
    &:hover {
        background: ${colorStack.whiteBlue};
    }
    > div {
        left: 100%;
        top: 0
    }
`;

export interface ISingleLink {
    name?: React.ReactNode;
    visible: boolean;
    disabled?: boolean;
    ico?: IconDefinition;
    dataLc?: string;
    icoColor?: string;
    customRender?: React.ReactNode;
    links?: ISingleLink[];
    action?(e?);
}

interface IProps {
    links: ISingleLink[];
    openFromRight?: boolean;

}

const SingleLinkElem: FC<ISingleLink> = props => {
    const [wrapperRef, dropdownOpen, toggleDropdown, closeDropdown] = useDropdown();

    const onMouseLeaveElement = useCallback(() => {
        setTimeout(() => {
            closeDropdown();
        }, 100);
    }, [closeDropdown]);

    return (
        <>
            {props.links?.length > 0 ? (
                <>
                    <ExpandedElem ref={wrapperRef} onMouseEnter={toggleDropdown} onMouseLeave={onMouseLeaveElement}>
                        {props.ico && <FontAwesomeIcon icon={props.ico} color={props.icoColor} />}
                        {props.name}
                        <FontAwesomeIcon icon={faCaretRight} />
                        {dropdownOpen &&
                            <Wrapper>
                                {props.links.map((link, index) =>
                                    <SingleLinkElem {...link} key={index} data-lc={link.dataLc} disabled={props.disabled} />
                                )}
                            </Wrapper>
                        }
                    </ExpandedElem>
                </>
            ) : (
                <>
                    {props.customRender ? (
                        <>
                            {props.customRender}
                        </>
                    ) : (

                        <Link onClick={props.action} type="button" data-lc={props.dataLc} disabled={props.disabled}>
                            {props.ico && <FontAwesomeIcon icon={props.ico} color={props.icoColor} />}
                            {props.name}
                        </Link>
                    )}
                </>
            )}
        </>
    );
};

export const ContextMenu: FC<IProps> = props => {
    return (
        <Wrapper openFromRight={props.openFromRight}>
            {props.links?.filter(elem => elem.visible)?.map((link, index) =>
                <Fragment key={index}>
                    {link.customRender ? (
                        <>
                            {link.customRender}
                        </>
                    ) : (
                        <SingleLinkElem {...link} />
                    )}
                </Fragment>
            )}
        </Wrapper>
    );
};
