/*
    USE: <Avatar members={...array of members...} currentUserId={...current login user id...}/>
*/

import React from 'react';
import styled from 'styled-components';

import { IProfileType } from '../../entities/IGlobal';
import { ConversationMember } from '../../entities/Messaging/IMessaging';
import { colorStack } from '../../styleHelpers/colors';
import { Avatar as NewAvatar } from '../Common/Avatar/Avatar';

const MultiWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    span {
        width: 70%;
        height: 70%;
        display: block;
        position: absolute;
        left: 0;
        bottom: 2px;
        padding: 0;
        border: 3px solid ${colorStack.white};
        &:nth-child(2) {
            right: 0;
            top: 2px;
            left: auto;
        }
    }
`;

interface IProps {
    members: ConversationMember[];
    currentUserId: string;
}

export const Avatar: React.FC<IProps> = (props) => {

    const singleMember = props.members.find((user: ConversationMember) => user.id !== props.currentUserId) || props.members[0];
    return (
        <>
            {props.members.length > 2 ? (
                <MultiWrapper>
                    <NewAvatar
                        picture={props.members[0].picture}
                        firstName={props.members[0].displayName}
                        lastName=""
                        id={props.members[0].id}
                        type={IProfileType.Company}
                        size={{
                            width: 24,
                            height: 24
                        }}
                    />
                    <NewAvatar
                        picture={props.members[1].picture}
                        firstName={props.members[1].displayName}
                        lastName=""
                        id={props.members[0].id}
                        type={IProfileType.Company}
                        size={{
                            width: 24,
                            height: 24
                        }}
                    />
                </MultiWrapper>
            ) : (
                <NewAvatar
                    picture={singleMember.picture}
                    firstName={singleMember.displayName}
                    lastName=""
                    id={singleMember.id}
                    type={IProfileType.Company}
                    size={{
                        width: 24,
                        height: 24
                    }}
                />
            )}
        </>
    );
};