import * as React from 'react';
import Editor, { composeDecorators } from 'draft-js-plugins-editor';
import { EditorState, convertFromRaw } from 'draft-js';
import createImagePlugin from 'draft-js-image-plugin';
import createAlignmentPlugin from 'draft-js-alignment-plugin';
import createResizeablePlugin from 'draft-js-resizeable-plugin';
import createBlockDndPlugin from 'draft-js-drag-n-drop-plugin';
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import 'draft-js/dist/Draft.css';
import 'draft-js-static-toolbar-plugin/lib/plugin.css';
import 'draft-js-linkify-plugin/lib/plugin.css';
import 'draft-js-image-plugin/lib/plugin.css';
import 'draft-js-alignment-plugin/lib/plugin.css';

const linkifyPlugin = createLinkifyPlugin();
const resizeablePlugin = createResizeablePlugin();
const blockDndPlugin = createBlockDndPlugin();
const alignmentPlugin = createAlignmentPlugin();
const decorator = composeDecorators(
    alignmentPlugin.decorator,
    resizeablePlugin.decorator,
    blockDndPlugin.decorator
);
const imagePlugin = createImagePlugin({ decorator });
const plugins = [
    blockDndPlugin,
    alignmentPlugin,
    resizeablePlugin,
    linkifyPlugin,
    imagePlugin
];

export interface IRichTextViewProps {
    content: string;
}

interface IRichTextViewState {
    editorState: EditorState;
}

export class RichTextView extends React.Component<IRichTextViewProps, IRichTextViewState> {
    state = {
        // tslint:disable-next-line:no-null-keyword
        editorState: null
    };

    componentDidMount() {
        const contentJson = this.parseJSON(this.props.content);
        setTimeout(() => {
            this.setState({ editorState: !!contentJson?.blocks && EditorState.createWithContent(convertFromRaw(contentJson)) });
        } , 100);
    }

    componentDidUpdate(prevProps: IRichTextViewProps, prevState) {
        if (prevProps.content !== this.props.content) {
            const contentJson = this.parseJSON(this.props.content);
            this.setState({ editorState: contentJson && EditorState.createWithContent(convertFromRaw(contentJson)) });
        }
    }

    onEditoStateChange = (editorState) => this.setState({ editorState });

    parseJSON(content: string = '') {
        try {
            return JSON.parse(content);
        } catch (e) {
            return undefined;
        }
    }

    render() {
        const { editorState } = this.state;

        return (
            <div>
                {editorState && (
                    <Editor
                        onChange={this.onEditoStateChange}
                        editorState={editorState}
                        plugins={plugins}
                        readOnly={true}
                    />
                )}
            </div>
        );
    }
}
