import { IReferencial, IUserBrief } from './IGlobal';

export enum IDatesStatus {
    NotStarted = 'NotStarted',
    InProgress = 'InProgress',
    Done = 'Done',
    Overdue = 'Overdue'
}
export enum IDatesTypes {
   KeyDate = 'KeyDate'
}

export enum IRecuringType {
    Day = 'Day',
    Week = 'Week',
    Month = 'Month',
    Year = 'Year'
}
export interface IDates {
    actors: IUserBrief[];
    description: string;
    id?: string;
    isAllDays?: boolean;
    isReferenceDate?: boolean;
    isReminderActivated: boolean;
    reminder: {
        remindBeforeDays: number;
        remindEachDays: number;
    }
    endAt: string;
    startAt: string;
    status: IDatesStatus;
    title: IReferencial;
    type: IReferencial;
    recuring: {
        frequency: {
            type: IRecuringType;
            number: number;
            startDate: string;
            endDate: string;
            daysOfWeek: number[];
            dayOfMonth: number;
            month: number;
        }
    };
    nextOccurrenceDate: string;
}

export interface IOverviewDates {
    [key: string]: {
        [key: string]: IDates[];
    }
}