import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { AllStatusEnums } from '../../../entities/IGlobal';
import { colorStack } from '../../../styleHelpers/colors';
import { getStatusColor, getStatusText } from '../../../tools/statusHelper';

const Dot = styled.div<{ color: string, size: number }>`
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
    margin-right: 0.5rem;
    background: ${props => props.color || colorStack.white};
    ${props => props.size && css`
        width: ${props.size}px;
        height: ${props.size}px;
    `};
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

const Name = styled.span<{color: string}>`
    color: ${props => props.color || colorStack.content};
    width: calc(100% - 1rem);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

interface IStatusDotProps {
    status: AllStatusEnums;
    size?: number;
    extended?: boolean;
}

export const StatusDot: FC<IStatusDotProps> = props => {
    const intl = useIntl();
    return (
        <Wrapper>
            <Dot size={props.size} color={getStatusColor(props.status)} />
            {props.extended &&
                <Name color={getStatusColor(props.status)}>{getStatusText(props.status, intl)}</Name>
            }
        </Wrapper>
    );
};
