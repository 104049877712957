import { Dispatch } from 'redux';
import moment from 'moment';

import { IState } from '../reducers';
import { ILanguage, ITranslations, IUpdateTranslation, Lcid, IClusterMessageTranslation, ISingleLcidTranslations } from '../entities/ILanguage';
import * as actionTypes from './actionTypes/languageTypes';
import { action, IDispatch } from '../tools/reduxTools';
import { languageList } from '../tools/languageTools';
import { Http } from '../tools/http';

export const getLanguages = () => action<Promise<ILanguage[]>>((dispatch, getState, http: Http) => {
    return http.languageApiGet('/languages').then((managedLanguages: ILanguage[]) => {
        dispatch({
            type: actionTypes.SET_MANAGE_LANGUAGES,
            managedLanguages
        });

        return managedLanguages;
    });
});

export const getUserPrefferedLanguage = () => action<Promise<Lcid>>((dispatch, getState, http: Http) => {
    return http.profilesApiGet(`/users/me/settings/preferred-language`);
});

export const getTranslationsForCurrentLang = (lcid: Lcid = Lcid.En) => action<Promise<ISingleLcidTranslations>>(async (dispatch: Dispatch, getState, http: Http) => {
    const code: string = languageList[lcid];

    type SetTranslations = IDispatch<actionTypes.ILanguageTypes['SET_TRANSLATIONS']>;
    const enTrans = await code !== 'en' ? http.languageApiGet<ISingleLcidTranslations>(`/translations/en`) : {};
    return http.languageApiGet<ISingleLcidTranslations>(`/translations/${code}`).then((translations) => {
        dispatch<SetTranslations>({
            type: actionTypes.SET_TRANSLATIONS,
            lcid,
            translations: { ...enTrans, ...translations }
        });
        return translations;
    }).catch(() => {
        dispatch<SetTranslations>({
            type: actionTypes.SET_TRANSLATIONS,
            lcid,
            translations: {}
        });
        return [];
    });
});

export const getTranslations = () => action<Promise<ITranslations>>((dispatch, getState, http: Http) => {
    return http.languageApiGet(`/translations`);
});

export const updateTranslation = (groupName: string, translation: IUpdateTranslation) => action<Promise<ITranslations>>((dispatch, getState, http: Http) => {
    return http.languageApiPut(`/translations/${groupName}`, translation);
});

export const removeTranslation = (groupName: string, labelKey: string) => action<Promise<ITranslations>>((dispatch, getState, http: Http) => {
    return http.languageApiDelete(`/translations/${groupName}/${labelKey}`);
});

export const setUserLanguage = (lcid: number) => ({
    type: actionTypes.SET_USER_LANGUAGE,
    lcid
});
export const loadNewLang = () => ({
    type: actionTypes.LOAD_NEW_LANG
});

export const savePrefferedLanguage = (lcid: Lcid) => action<Promise<ILanguage[]>>((dispatch, getState, http: Http) => {
    return http.profilesApiPost(`/users/me/settings/preferred-language?lcid=${lcid}`).then(() => {
        dispatch({
            type: actionTypes.SET_USER_LANGUAGE,
            lcid
        });
    });
});

export const getMessageTranslations = () => action<Promise<IClusterMessageTranslation>>((dispatch, getState, http: Http) => {
    const userLanguage = getState().language.userLanguage || Lcid.En;
    return http.languageApiGet(`/translations/messages/${languageList[userLanguage]}`).then((response: IClusterMessageTranslation) => {
        dispatch({
            type: actionTypes.SET_CLUSTER_TRANSLATION_MESSAGES,
            clusterActivityTranslation: response
        });
    });
});

export const changeInterfaceLanguage = (selectedLcid: Lcid) => action<Promise<Lcid>, IState>(async (dispatch, getState, http) => {
    const { managedLanguages } = getState().language;
    const availableLanguages = managedLanguages || await dispatch(getLanguages());
    const selectedLangIsAvailable = availableLanguages.some(language => language.Lcid === selectedLcid);

    if (selectedLangIsAvailable) {
        await dispatch(getTranslationsForCurrentLang(selectedLcid));
        await dispatch(savePrefferedLanguage(selectedLcid));
        moment.locale(languageList[selectedLcid]);
        return selectedLcid;
    }
});
