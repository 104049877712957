import React, { FC } from 'react';
import styled from 'styled-components';

import { IClusterStakeProperty } from '../../../../../entities/IClusterStake';
import { TextComponent } from '../../../../Common/Inputs/TextComponent';
import { RichTextView } from '../../../../Common/RichText/RichTextView';
import { RichTextEditor } from '../../../../Common/RichText/RichTextEditor';
import { ErrorText } from './StakeStyles';

const Wrapper = styled.div`
    span {
        overflow-wrap: break-word;
    }
`;

interface ITextContentProps {
    data: IClusterStakeProperty;
    editStake: boolean;
    propertieId: string;
    error: string;
    simple?: boolean;
    setEditingData(propertie: IClusterStakeProperty);
}

export const TextContent: FC<ITextContentProps> = ({ simple, error, data, editStake, propertieId, setEditingData }) => {

    const changeText = (value: string) => {
        setEditingData({
            ...data,
            value,
            id: propertieId
        });
    };

    return (
        <Wrapper>
            {editStake ? (
                <>
                    {simple ? (
                        <TextComponent type="Input" value={data.value} onChange={e => changeText(e.target.value)} />
                    ) : (
                            <RichTextEditor
                                initData={data.value}
                                onChanged={value => changeText(value)}
                                buttons={{
                                    boldButton: true,
                                    italicButton: true,
                                    underlineButton: true,
                                    h1Button: true,
                                    h2Button: true,
                                    h3Button: true,
                                    pointListButton: true,
                                    numberListButton: true,
                                    blockquoteButton: true
                                }}
                            />
                        )}
                    {error &&
                        <ErrorText>{error}</ErrorText>
                    }
                </>
            ) : (
                    <>
                        {simple ?
                            <div>{data.value}</div>
                            :
                            <RichTextView content={data.value} />
                        }
                    </>
                )}
        </Wrapper>
    );
};