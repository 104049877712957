import styled from 'styled-components';
import { rgba } from 'polished';

import { colorStack } from '../../../styleHelpers/colors';
import { media } from '../../../styleHelpers/breakpoint';

export const MessageBoxContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    padding: 0 0 0 20px;
    position: relative;
    ${media.tabletSm`
        padding: 0 0 0 20px;
    `}
`;

export const MessageBoxContainerInner = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: ${colorStack.whiteGrey};
    .spinner {
        margin-top: 50px;
    }
`;

export const MessageMenuWrapper = styled.div`
    position: absolute;
    right: 0;
    width: 300px;
    background: ${colorStack.white};
    margin: 0 0 0 13px;
    ${media.tabletSm`
        position: static;
        height: 100%;
    `}
    > div {
        height: 100%;
    }
`;

export const MessagesWrapper = styled.div`
    flex: 1;
    margin: -36px 0 10px 0;
    .scroll-wrapper {
        padding: 1rem 1rem 1.2rem 0;
        ${media.desktop`
            padding: 50px 1rem 0 1rem;
        `}
    }
    mark {
        background-color: ${colorStack.middleGrey};
    }
    ${media.desktop`
        margin: 0 0 10px 0;
    `}
`;

export const ClearBar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${rgba(colorStack.white, 0.95)};
    padding: 1.5rem;
    position: sticky;
    bottom: 0;
    overflow-y: auto;
    margin-top: 2rem; 
`;

