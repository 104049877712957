import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/pro-duotone-svg-icons';
import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment-timezone';

import { useUrlQuery } from '../../tools/hooks';
import { DateStandardFormat } from '../Common/Date/DateStandardFormat';
import { fontSize } from '../../styleHelpers/fontSizes';
import { colorStack } from '../../styleHelpers/colors';
import { WhiteBox } from '../Common/WhiteBox/WhiteBox';
import { referentialConfiguration } from '../../tools/selectConfiguration';
import { WhiteCollapsedSection } from '../Common/WhiteCollapsedSection/WhiteCollapsedSection';
import { StakesWrapper } from '../Common/CLEComponents/StakesWrapper';
import { MainInformation } from '../Common/CLEComponents/MainInformation';
import { Presentation } from '../Common/CLEComponents/Presentation';
import { DatesContainer } from '../Organization/ClustersV2/ClusterOverview/StakeContainers/DatesContainer';
import { hasRichTextContent } from '../../tools/RichTextHelper';
import { IState } from '../../reducers';
import { setAccessToken } from '../../actions/contextActions';
import { getCompanyById } from '../../actions/legalEntitiesActions';
import { ISIngleLegalEntity } from '../../entities/ILegalEntities';
import { StakeDetailsType, PropertieKey } from '../../entities/IClusterStake';
import { IContextReducer } from '../../reducers/contextReducer';

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    @media print {
        height: 297mm;
    }
`;

const TabWrapper = styled(WhiteBox)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Title = styled.span`
    font-size: ${fontSize[16]};
    margin: 0 0 0 1rem;
    color: ${colorStack.darkBlue};
    font-weight: 600;
`;

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    > img, > svg {
        width: 2rem;
        display: block;
        border-radius: 100%;
    }
`;

const ItemCount = styled.div`
    padding: 4px 8px;
    border-radius: 2px;
    background: ${colorStack.whiteGreen};
    color: ${colorStack.middleBlue};
    margin: 0 0 0 1rem;
`;

const DocumentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const DocumentTable = styled.div`
    border: 1px solid ${colorStack.ligthGrey};
`;

const HeadTable = styled.div`
    border-bottom: 1px solid ${colorStack.ligthGrey};
    display: flex;
    div {
        padding: .5rem;
        font-weight: 600;
        color: ${colorStack.darkBlue};
        &:first-child {
            width: 220px;
            border-right: 1px solid ${colorStack.ligthGrey};
        }
        &:nth-child(2) {
            width: calc(100% - 270px);
            border-right: 1px solid ${colorStack.ligthGrey};
        }
        &:nth-child(3) {
            width: 50px;
        }
    }
`;

const TableRow = styled.div`
    display: flex;
    align-items: center;
    button {
        cursor: pointer;
    }
    > div {
        padding: .5rem;
        &:first-child {
            width: 220px;
            border-right: 1px solid ${colorStack.ligthGrey};
        }
        &:nth-child(2) {
            display: flex;
            align-items: center;
            width: calc(100% - 270px);
            border-right: 1px solid ${colorStack.ligthGrey};
            input {
                margin: 0 1rem 0 0;
            }
        }
        &:nth-child(3) {
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                color: ${colorStack.darkRed};
                cursor: pointer;
            }
        }
    }
`;

const StyledDate = styled(DateStandardFormat)`
    text-align: right;
`;

const BodyTable = styled.div`
    background: ${colorStack.whiteGrey};
`;

interface IPreviewEntityExportQuery {
    token: string;
}

const Top = ({ companyData }) => {
    return (
        <>
            <TabWrapper>
                <TitleWrapper>
                    {companyData?.picture ? (
                        <img src={companyData?.picture} />
                    ) : (
                        <FontAwesomeIcon icon={faBuilding} />
                    )}
                    <Title>{companyData?.name}</Title>
                </TitleWrapper>
                <StyledDate />
            </TabWrapper>

            <MainInformation
                data={({
                    picture: companyData?.picture,
                    address: companyData?.address,
                    typeOfLegalEntity: companyData?.typeOfLegalEntity,
                    legalForm: companyData?.legalForm,
                    registrations: companyData?.registrations,
                    registeredCapital: { number: companyData?.registeredCapital?.number, unit: companyData?.registeredCapital?.unit },
                    websiteUrl: companyData?.websiteUrl,
                    phoneNumber: companyData?.phoneNumber,
                    name: companyData?.name,
                    nationality: { id: companyData?.nationality?.id, name: companyData?.nationality?.name },
                    isPublic: companyData?.isPublic,
                    group: { id: companyData?.group?.id, name: companyData?.group?.name }
                })}
                editMode={false}
                context="PLE"
            />
        </>
    );
};

interface MainInfoProps {
    companyData: any,
    entityId: string,
    intl: any,
    titleEntity: string
}

const MainInfo: React.FC<MainInfoProps> = ({ companyData, entityId, intl, titleEntity }) => {

    const query = useUrlQuery<{ timeZone: string }>();

    return (
        <>
            <Presentation
                editMode={false}
                data={({
                    description: companyData?.presentation,
                    peopleInCharge: companyData?.personsInCharge?.map(person => ({
                        firstName: person.FirstName || person.firstName,
                        lastName: person.LastName || person.lastName,
                        jobTitle: person.JobTitle || person.jobTitle,
                        department: person.Department || person.department,
                        picture: person.Picture || person.picture,
                        id: person.id
                    })),
                    firstReferential: companyData?.firstReferential,
                    secondReferential: companyData?.secondReferential,
                    thirdReferential: companyData?.thirdReferential,
                    startDate: companyData?.dateBegin,
                    endDate: companyData?.dateEnd,
                    tags: companyData?.Tags?.map(tag => ({ id: tag.id, name: tag.name, isDeleted: tag.isDeleted })),
                    name: companyData?.name
                })}
                context="PLE"
                pageBreakOn={true}
            />

            {(entityId && (companyData?.dateOfCreation || companyData?.balanceSheetDate || companyData?.expirationDate)) && !companyData?.canEdit &&
                <WhiteCollapsedSection
                    title={intl.formatMessage({ id: 'ple.title.entityInformation' })}
                    pageBreakOn={true}
                >
                    <StakesWrapper
                        stake={({
                            name: intl.formatMessage({ id: 'ple.title.lifecycle' }),
                            properties: [{
                                name: intl.formatMessage({ id: 'ple.title.incorporation' }),
                                value: !!query?.timeZone ?
                                    { date: moment(companyData?.dateOfCreation)?.tz(query?.timeZone)?.format('YYYY-MM-DD') }
                                    :
                                    { date: companyData?.dateOfCreation },
                                type: StakeDetailsType.Date,
                                subType: undefined,
                                id: 'dateOfCreation',
                                configuration: undefined,
                                referentialIdentity: 'Custom',
                                key: undefined
                            },
                            {
                                name: intl.formatMessage({ id: 'ple.title.balanceSheetDate' }),
                                value: !!query?.timeZone ?
                                    { date: moment(companyData?.balanceSheetDate)?.tz(query?.timeZone)?.format('YYYY-MM-DD') }
                                    :
                                    { date: companyData?.balanceSheetDate },
                                type: StakeDetailsType.Date,
                                subType: undefined,
                                id: 'balanceSheetDate',
                                configuration: undefined,
                                referentialIdentity: 'Custom',
                                key: undefined
                            },
                            {
                                name: intl.formatMessage({ id: 'ple.title.expiration' }),
                                value: !!query?.timeZone ?
                                    { date: moment(companyData?.expirationDate)?.tz(query?.timeZone)?.format('YYYY-MM-DD') }
                                    :
                                    { date: companyData?.expirationDate },
                                type: StakeDetailsType.Date,
                                subType: undefined,
                                id: 'expirationDate',
                                configuration: undefined,
                                referentialIdentity: 'Custom',
                                key: undefined
                            }],
                            type: 0
                        })}
                        editMode={false}
                        context="PLE"
                        singleIndex={0}
                    />
                </WhiteCollapsedSection>
            }
            {!companyData?.canEdit && entityId &&
                <WhiteCollapsedSection
                    title={intl.formatMessage({ id: 'ple.title.keyDate' })}
                >
                    <DatesContainer
                        legalEntityId={companyData?.id}
                    />
                </WhiteCollapsedSection>
            }
            {(companyData?.contact?.people?.length > 0 || (companyData?.contact?.informations && hasRichTextContent(companyData?.contact?.informations))) &&
                <WhiteCollapsedSection
                    title={intl.formatMessage({ id: 'ple.title.contact' })}
                    pageBreakOn={true}
                >
                    <StakesWrapper
                        stake={({
                            name: intl.formatMessage({ id: titleEntity }),
                            properties: [{
                                name: intl.formatMessage({ id: 'ple.title.contactName' }),
                                value: companyData?.contact?.people?.map(people => ({
                                    firstName: people.firstName,
                                    lastName: people.lastName,
                                    jobTitle: people.jobTitle,
                                    department: people.department,
                                    picture: people.picture,
                                    cabinet: typeof people.cabinet === 'string' ? people.cabinet : people.cabinet?.name,
                                    id: people.id
                                })),
                                type: StakeDetailsType.MultiplePeople,
                                subType: undefined,
                                id: `contact.people`,
                                configuration: undefined,
                                referentialIdentity: 'Custom',
                                key: undefined
                            },
                            {
                                name: intl.formatMessage({ id: 'ple.title.information' }),
                                value: companyData?.contact?.informations,
                                type: StakeDetailsType.Text,
                                subType: undefined,
                                id: 'contact.informations',
                                configuration: undefined,
                                referentialIdentity: 'Custom',
                                key: undefined
                            }],
                            type: 0
                        })}
                        editMode={false}
                        context="PLE"
                        singleIndex={0}
                    />
                </WhiteCollapsedSection>
            }
            {(companyData?.documents && companyData?.documents?.length > 0 && entityId) &&
                <WhiteCollapsedSection
                    title={intl.formatMessage({ id: 'ple.title.documents' })}
                    pageBreakOn={true}
                >
                    <DocumentsWrapper>
                        <DocumentTable>
                            <HeadTable>
                                <div><FormattedMessage id="ple.title.documents" /></div>
                                <div><FormattedMessage id="ple.label.file" /></div>
                                <div></div>
                            </HeadTable>
                            <BodyTable>
                                {companyData?.documents?.map(document =>
                                    <TableRow key={document.id}>
                                        <div>
                                            <span>{document?.label?.name}</span>
                                        </div>
                                        <div>
                                            <span>{document?.name}</span>
                                        </div>
                                    </TableRow>
                                )}
                            </BodyTable>
                        </DocumentTable>
                    </DocumentsWrapper>
                </WhiteCollapsedSection>
            }
        </>
    );
};

interface RelatedEntitiesProps {
    companyData: any,
    intl: any,
    titleQuality: string,
    titlePersCharge: string,
    persCharge: string
}

const RelatedEntities: React.FC<RelatedEntitiesProps> = ({ companyData, intl, titleQuality, titlePersCharge, persCharge }) => {
    return (
        <>
            {companyData?.subsidiaryAndParticipations && companyData?.subsidiaryAndParticipations.length > 0 && !companyData?.canEdit &&
                <WhiteCollapsedSection
                    title={intl.formatMessage({ id: 'ple.subsidiariesAndParticipations' })}
                    counter={<ItemCount>{companyData?.subsidiaryAndParticipations?.length || 0}</ItemCount>}
                    pageBreakOn={true}
                >
                    {companyData?.subsidiaryAndParticipations ? (
                        <>
                            {companyData?.subsidiaryAndParticipations?.map((elem, index) =>
                                <StakesWrapper
                                    key={index}
                                    isMultistake
                                    stake={({
                                        name: elem.quality?.name,
                                        type: 0,
                                        properties: [{
                                            value: {
                                                value: elem.quality?.id,
                                                key: elem.quality?.id
                                            },
                                            name: intl.formatMessage({ id: titleQuality }),
                                            type: StakeDetailsType.SingleSelect,
                                            subType: undefined,
                                            id: `subsidiaryAndParticipations[${index}].quality`,
                                            configuration: referentialConfiguration.QualityParticipations,
                                            referentialIdentity: 'Custom',
                                            key: undefined
                                        },
                                        {
                                            name: intl.formatMessage({ id: 'ple.title.person' }),
                                            value: elem.LegalEntity,
                                            type: StakeDetailsType.LegalEntity,
                                            subType: undefined,
                                            id: `subsidiaryAndParticipations[${index}].legalEntity`,
                                            configuration: undefined,
                                            referentialIdentity: 'Custom',
                                            key: undefined
                                        },
                                        {
                                            name: intl.formatMessage({ id: titlePersCharge }),
                                            value: elem.representative && ({
                                                picture: elem.representative?.picture,
                                                id: elem.representative?.id,
                                                jobTitle: elem.representative?.jobTitle,
                                                cabinet: elem.representative?.cabinet?.name,
                                                firstName: elem.representative?.firstName,
                                                lastName: elem.representative?.lastName,
                                                phoneNumber: elem.representative?.phoneNumber,
                                                department: elem.representative?.department
                                            }),
                                            type: StakeDetailsType.People,
                                            subType: undefined,
                                            id: `subsidiaryAndParticipations[${index}].representative`,
                                            configuration: undefined,
                                            referentialIdentity: 'Custom',
                                            key: undefined
                                        },
                                        {
                                            name: intl.formatMessage({ id: 'ple.title.ownershipShare' }),
                                            value: { number: elem.Share, unit: '%' },
                                            type: StakeDetailsType.Number,
                                            subType: undefined,
                                            id: `subsidiaryAndParticipations[${index}].share`,
                                            configuration: undefined,
                                            referentialIdentity: 'Custom',
                                            key: undefined
                                        }]
                                    })}
                                    editMode={false}
                                    context="PLE"
                                    editField="subsidiaryAndParticipations"
                                    pleMainObj={companyData?.subsidiaryAndParticipations}
                                    singleIndex={index}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            <StakesWrapper
                                isMultistake
                                stake={({
                                    name: undefined,
                                    type: 0,
                                    properties: [{
                                        value: undefined,
                                        name: intl.formatMessage({ id: titleQuality }),
                                        type: StakeDetailsType.SingleSelect,
                                        subType: undefined,
                                        id: `subsidiaryAndParticipations[0].quality`,
                                        configuration: referentialConfiguration.QualityParticipations,
                                        referentialIdentity: 'Custom',
                                        key: undefined
                                    },
                                    {
                                        name: intl.formatMessage({ id: 'ple.title.person' }),
                                        value: undefined,
                                        type: StakeDetailsType.LegalEntity,
                                        subType: undefined,
                                        id: `subsidiaryAndParticipations[0].legalEntity`,
                                        configuration: undefined,
                                        referentialIdentity: 'Custom',
                                        key: undefined
                                    },
                                    {
                                        name: intl.formatMessage({ id: titlePersCharge }),
                                        value: undefined,
                                        type: StakeDetailsType.People,
                                        subType: undefined,
                                        id: `subsidiaryAndParticipations[0].representative`,
                                        configuration: undefined,
                                        referentialIdentity: 'Custom',
                                        key: undefined
                                    },
                                    {
                                        name: intl.formatMessage({ id: 'ple.title.ownershipShare' }),
                                        value: { number: undefined },
                                        type: StakeDetailsType.Number,
                                        subType: undefined,
                                        id: `subsidiaryAndParticipations[0].share`,
                                        configuration: undefined,
                                        referentialIdentity: 'Custom',
                                        key: undefined
                                    }]
                                })}
                                editMode={false}
                                editField="subsidiaryAndParticipations"
                                pleMainObj={companyData?.subsidiaryAndParticipations}
                                context="PLE"
                                singleIndex={0}
                            />
                        </>
                    )}
                </WhiteCollapsedSection>
            }
        </>
    );
};

interface SecEstBranchesProps {
    companyData: any,
    intl: any,
    entityId: string,
    titleEntity: string,
    titleQuality: string,
    titlePersCharge: string,
    persCharge: string
}

const SecondaryEstablishmentsAndBranches: React.FC<SecEstBranchesProps> = ({ companyData, intl, entityId, titleEntity, titleQuality, titlePersCharge, persCharge }) => {
    return (
        <>
            {((companyData?.secondaryEstablishmentsAndBranches || companyData?.subsidiaryAndParticipations) && entityId) && !companyData?.canEdit &&
                <WhiteCollapsedSection
                    title={intl.formatMessage({ id: 'ple.title.relatedEntities' })}
                >
                    <RelatedEntities
                        companyData={companyData}
                        intl={intl}
                        titleQuality={titleQuality}
                        titlePersCharge={titlePersCharge}
                        persCharge={persCharge}
                    />
                    {companyData?.secondaryEstablishmentsAndBranches && companyData?.secondaryEstablishmentsAndBranches.length > 0 &&
                        <WhiteCollapsedSection
                            title={intl.formatMessage({ id: 'ple.title.secondaryEstablishmentBranches' })}
                            counter={<ItemCount>{companyData?.secondaryEstablishmentsAndBranches?.length || 0}</ItemCount>}
                            pageBreakOn={true}
                        >
                            {companyData?.secondaryEstablishmentsAndBranches ? (
                                <>
                                    {companyData?.secondaryEstablishmentsAndBranches?.map((elem, index) =>
                                        <StakesWrapper
                                            key={index}
                                            isMultistake
                                            stake={({
                                                name: elem.quality?.name,
                                                type: 0,
                                                properties: [{
                                                    value: {
                                                        value: elem.Quality?.id,
                                                        key: elem.Quality?.id
                                                    },
                                                    name: intl.formatMessage({ id: titleQuality }),
                                                    type: StakeDetailsType.SingleSelect,
                                                    subType: undefined,
                                                    id: `secondaryEstablishmentsAndBranches[${index}].quality`,
                                                    configuration: referentialConfiguration.QualityBranches,
                                                    referentialIdentity: 'Custom',
                                                    key: undefined
                                                },
                                                {
                                                    name: intl.formatMessage({ id: titleEntity }),
                                                    value: elem.LegalEntity,
                                                    type: StakeDetailsType.LegalEntity,
                                                    subType: undefined,
                                                    id: `secondaryEstablishmentsAndBranches[${index}].legalEntity`,
                                                    configuration: undefined,
                                                    referentialIdentity: 'Custom',
                                                    key: undefined
                                                },
                                                {
                                                    name: intl.formatMessage({ id: titlePersCharge }),
                                                    value: elem.representative && ({
                                                        picture: elem.representative?.picture,
                                                        id: elem.representative?.id,
                                                        jobTitle: elem.representative?.jobTitle,
                                                        cabinet: elem.representative?.cabinet?.name,
                                                        firstName: elem.representative?.firstName,
                                                        lastName: elem.representative?.lastName,
                                                        phoneNumber: elem.representative?.phoneNumber,
                                                        department: elem.representative?.department
                                                    }),
                                                    type: StakeDetailsType.People,
                                                    subType: undefined,
                                                    id: `secondaryEstablishmentsAndBranches[${index}].representative`,
                                                    configuration: undefined,
                                                    referentialIdentity: 'Custom',
                                                    key: undefined
                                                },
                                                {
                                                    name: intl.formatMessage({ id: 'ple.title.address' }),
                                                    value: elem.address && ({
                                                        number: elem.address?.number,
                                                        street: elem.address?.street,
                                                        city: elem.address?.city,
                                                        state: elem.address?.state,
                                                        country: elem.address?.country,
                                                        countryShort: elem.address?.countryShort,
                                                        zipCode: elem.address?.zipCode
                                                    }),
                                                    type: StakeDetailsType.Address,
                                                    subType: undefined,
                                                    id: `secondaryEstablishmentsAndBranches[${index}].address`,
                                                    configuration: undefined,
                                                    referentialIdentity: 'Custom',
                                                    key: PropertieKey.DateStart
                                                }]
                                            })}
                                            editMode={false}
                                            editField="secondaryEstablishmentsAndBranches"
                                            pleMainObj={companyData?.secondaryEstablishmentsAndBranches}
                                            context="PLE"
                                            singleIndex={index}
                                        />
                                    )}
                                </>
                            ) : (
                                <StakesWrapper
                                    isMultistake
                                    stake={({
                                        name: undefined,
                                        type: 0,
                                        properties: [{
                                            value: undefined,
                                            name: intl.formatMessage({ id: titleQuality }),
                                            type: StakeDetailsType.SingleSelect,
                                            subType: undefined,
                                            id: `secondaryEstablishmentsAndBranches[0].quality`,
                                            configuration: referentialConfiguration.QualityBranches,
                                            referentialIdentity: 'Custom',
                                            key: undefined
                                        },
                                        {
                                            name: intl.formatMessage({ id: titleEntity }),
                                            value: undefined,
                                            type: StakeDetailsType.LegalEntity,
                                            subType: undefined,
                                            id: `secondaryEstablishmentsAndBranches[0].legalEntity`,
                                            configuration: undefined,
                                            referentialIdentity: 'Custom',
                                            key: undefined
                                        },
                                        {
                                            name: intl.formatMessage({ id: titlePersCharge }),
                                            value: undefined,
                                            type: StakeDetailsType.People,
                                            subType: undefined,
                                            id: `secondaryEstablishmentsAndBranches[0].representative`,
                                            configuration: undefined,
                                            referentialIdentity: 'Custom',
                                            key: undefined
                                        },
                                        {
                                            name: intl.formatMessage({ id: 'ple.title.address' }),
                                            value: undefined,
                                            type: StakeDetailsType.Address,
                                            subType: undefined,
                                            id: `secondaryEstablishmentsAndBranches[0].address`,
                                            configuration: undefined,
                                            referentialIdentity: 'Custom',
                                            key: PropertieKey.DateStart
                                        }]
                                    })}
                                    editMode={false}
                                    context="PLE"
                                    editField="secondaryEstablishmentsAndBranches"
                                    pleMainObj={companyData?.secondaryEstablishmentsAndBranches}
                                    singleIndex={0}
                                />
                            )}
                        </WhiteCollapsedSection>
                    }
                </WhiteCollapsedSection>

            }
        </>
    );
};

export const EntityPreviewExport: FC<RouteComponentProps<{ entityId: string }>> = ({ match }) => {
    const intl = useIntl();
    const entityId = match.params.entityId;
    const dispatch = useDispatch();
    const { token } = useUrlQuery<IPreviewEntityExportQuery>();
    const authContext = useSelector<IState, IContextReducer>(state => state.context);
    const [companyData, setCompanyData] = useState<ISIngleLegalEntity>(undefined);

    const titleEntity = 'ple.title.entity';
    const titleQuality = 'ple.title.quality';
    const titlePersCharge = 'ple.title.personInCharge';
    const persCharge = 'Person in charge';

    useEffect(() => {
        token && dispatch(setAccessToken(token));
    }, [token]);

    const getCompanyData = async () => {
        const res = await dispatch(getCompanyById(entityId));
        setCompanyData(res);
    };

    useEffect(() => {
        authContext.token && getCompanyData();
    }, [authContext.token]);

    return (
        <>
            {authContext.token && companyData && (
                <Wrapper>
                    <Top companyData={companyData} />
                    <MainInfo companyData={companyData} entityId={entityId} intl={intl} titleEntity={titleEntity} />
                    <SecondaryEstablishmentsAndBranches
                        companyData={companyData}
                        entityId={entityId}
                        intl={intl}
                        titleEntity={titleEntity}
                        titleQuality={titleQuality}
                        titlePersCharge={titlePersCharge}
                        persCharge={persCharge}
                    />
                    {(companyData?.legalRepresentatives && companyData?.legalRepresentatives.length > 0 && entityId) && !companyData?.canEdit &&
                        <WhiteCollapsedSection
                            title={intl.formatMessage({ id: 'ple.title.legalRepresentatives' })}
                        >
                            <WhiteCollapsedSection
                                title={intl.formatMessage({ id: 'ple.title.representatives' })}
                                counter={<ItemCount>{companyData?.legalRepresentatives?.length || 0}</ItemCount>}
                            >
                                {companyData?.legalRepresentatives ? (
                                    <>
                                        {companyData?.legalRepresentatives?.map((representative, index) =>
                                            <StakesWrapper
                                                key={index}
                                                stake={({
                                                    name: representative.position?.name,
                                                    properties: [{
                                                        name: intl.formatMessage({ id: 'ple.title.start' }),
                                                        value: { date: representative.startDate },
                                                        type: StakeDetailsType.Date,
                                                        subType: undefined,
                                                        id: `legalRepresentatives[${index}].startDate`,
                                                        configuration: undefined,
                                                        referentialIdentity: 'Custom',
                                                        key: PropertieKey.DateStart
                                                    },
                                                    {
                                                        name: intl.formatMessage({ id: 'ple.title.end' }),
                                                        value: { date: representative.endDate },
                                                        type: StakeDetailsType.Date,
                                                        subType: undefined,
                                                        id: `legalRepresentatives[${index}].endDate`,
                                                        configuration: undefined,
                                                        referentialIdentity: 'Custom',
                                                        key: PropertieKey.DateEnd
                                                    },
                                                    {
                                                        name: intl.formatMessage({ id: 'ple.title.position' }),
                                                        value: {
                                                            value: representative.position?.name,
                                                            key: representative.position?.id
                                                        },
                                                        type: StakeDetailsType.SingleSelect,
                                                        subType: undefined,
                                                        id: `legalRepresentatives[${index}].position`,
                                                        configuration: referentialConfiguration.Position,
                                                        referentialIdentity: 'Custom',
                                                        key: PropertieKey.Position
                                                    },
                                                    {
                                                        name: intl.formatMessage({ id: 'ple.title.userRepresentative' }),
                                                        value: representative.representatives && representative.representatives.map(user => ({
                                                            picture: user?.picture,
                                                            id: user.id,
                                                            jobTitle: user.jobTitle || '',
                                                            cabinet: user.cabinet?.name || '',
                                                            firstName: user.firstName || '',
                                                            lastName: user.lastName || '',
                                                            phoneNumber: user.phoneNumber || '',
                                                            department: user.department || ''
                                                        })),
                                                        type: StakeDetailsType.People,
                                                        subType: undefined,
                                                        id: `legalRepresentatives[${index}].user`,
                                                        configuration: undefined,
                                                        referentialIdentity: 'Custom',
                                                        key: PropertieKey.UserRepresentative
                                                    },
                                                    {
                                                        name: intl.formatMessage({ id: 'ple.title.mandateHolder' }),
                                                        value: representative.mandateHolder,
                                                        type: StakeDetailsType.Party,
                                                        subType: undefined,
                                                        id: `legalRepresentatives[${index}].mandateHolder`,
                                                        configuration: undefined,
                                                        referentialIdentity: 'Custom',
                                                        key: PropertieKey.MandateHolder
                                                    }],
                                                    type: 10
                                                })}
                                                editMode={false}
                                                context="PLE"
                                                singleIndex={0}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <StakesWrapper
                                        stake={({
                                            name: undefined,
                                            properties: [{
                                                name: intl.formatMessage({ id: 'ple.title.start' }),
                                                value: { date: undefined },
                                                type: StakeDetailsType.Date,
                                                subType: undefined,
                                                id: `legalRepresentatives[0].startDate`,
                                                configuration: undefined,
                                                referentialIdentity: 'Custom',
                                                key: PropertieKey.DateStart
                                            },
                                            {
                                                name: intl.formatMessage({ id: 'ple.title.end' }),
                                                value: { date: undefined },
                                                type: StakeDetailsType.Date,
                                                subType: undefined,
                                                id: `legalRepresentatives[0].endDate`,
                                                configuration: undefined,
                                                referentialIdentity: 'Custom',
                                                key: PropertieKey.DateEnd
                                            },
                                            {
                                                value: undefined,
                                                name: intl.formatMessage({ id: 'ple.title.position' }),
                                                type: StakeDetailsType.SingleSelect,
                                                subType: undefined,
                                                id: `legalRepresentatives[0].position`,
                                                configuration: referentialConfiguration.QualityParticipations,
                                                referentialIdentity: 'Custom',
                                                key: PropertieKey.Position
                                            },
                                            {
                                                name: intl.formatMessage({ id: 'ple.title.userRepresentative' }),
                                                value: undefined,
                                                type: StakeDetailsType.People,
                                                subType: undefined,
                                                id: `legalRepresentatives[0].user`,
                                                configuration: undefined,
                                                referentialIdentity: 'Custom',
                                                key: PropertieKey.UserRepresentative
                                            }],
                                            type: 10
                                        })}
                                        editMode={false}
                                        context="PLE"
                                        singleIndex={0}
                                    />
                                )}
                            </WhiteCollapsedSection>
                        </WhiteCollapsedSection>
                    }
                </Wrapper>
            )}
        </>
    );
};

export default EntityPreviewExport;
