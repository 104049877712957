
import * as actionTypes from '../actions/actionTypes/clustersTypes';
import {
    ISingleSearchCluster, ISingleCluster, ITeam, IChannel, ISingleChannelMessage, IRequestAccessUser, IChannels, ISIngleDocument, IClusterTags,
    IClusterSingleMessage, ClusterAccessError, IClustersListCluster, IClusterTemplate
} from '../entities/IClusters';
import { MessageSystemTypes } from '../entities/Messaging/IMessaging';
import { ClusterPermissions } from '../entities/IPermissions';

export interface IClusterReducer {
    clustersListIds: string[];
    clusterTags: IClusterTags;
    clustersListById: {
        [id: string]: IClustersListCluster;
    };
    clustersSearchResult: ISingleSearchCluster[];
    clustersListLoaded: boolean;
    channelsListLoaded: boolean;
    requestAccessUserLoaded: boolean;
    documentListLoaded: boolean;
    clusterTagsLoaded: boolean;
    currentClusterDataLoaded: boolean;
    clustersSearchResultLoaded: boolean;
    currentClusterId: string;
    currentChannelName: string;
    currentTeamId: string;
    currentTeamChannelId: string;
    currentChannelId: string;
    currentClusterData: ISingleCluster;
    allMyTeams: ITeam[];
    currentChannelMessages: ISingleChannelMessage[];
    currentChannelMessagesLoaded: boolean;
    teamChannels: IChannel[];
    currentChannelMessagesCount: number;
    currentMessagePage: number;
    documentList: ISIngleDocument[];
    clusterPhotos: string[];
    requestAccessUser: { requestedBy: IRequestAccessUser }[];
    channelsList: IChannels[];
    clusterPropertiesView: boolean;
    clusterMembersView: boolean;
    channelMessageError: ClusterAccessError;
    clusterTemplates: IClusterTemplate[];
    clusterPermissions: {
        [key in ClusterPermissions]?: boolean;
    };
    updatedMessage: ISingleChannelMessage;
}

const defaultState = (): IClusterReducer => ({
    documentList: [],
    clustersListIds: [],
    channelsList: [],
    requestAccessUser: [],
    clusterTags: undefined,
    documentListLoaded: false,
    channelsListLoaded: false,
    clusterTagsLoaded: false,
    currentClusterDataLoaded: false,
    requestAccessUserLoaded: false,
    clustersListById: {},
    clustersSearchResult: [],
    clustersListLoaded: false,
    clustersSearchResultLoaded: false,
    allMyTeams: [],
    teamChannels: [],
    currentChannelMessagesCount: 0,
    currentChannelMessages: [],
    currentChannelMessagesLoaded: false,
    currentClusterId: undefined,
    currentTeamId: undefined,
    currentChannelId: undefined,
    currentClusterData: undefined,
    currentTeamChannelId: undefined,
    clusterPhotos: [],
    currentChannelName: '',
    currentMessagePage: 0,
    clusterPropertiesView: false,
    clusterMembersView: false,
    channelMessageError: undefined,
    clusterPermissions: undefined,
    clusterTemplates: undefined,
    updatedMessage: undefined
});

export default (state = defaultState(), action): IClusterReducer => {
    switch (action.type) {

        case actionTypes.CLEAR_CLUSTERS_LIST: {
            return {
                ...state,
                clustersListLoaded: false,
                clustersListIds: [],
                clustersListById: {}
            };
        }

        case actionTypes.CHANGE_CLUSTER_PROPERTIES_VIEW: {
            return {
                ...state,
                clusterPropertiesView: !state.clusterPropertiesView,
                clusterMembersView: false
            };
        }

        case actionTypes.CHANGE_CLUSTER_MEMBERS_VIEW: {
            return {
                ...state,
                clusterMembersView: !state.clusterMembersView,
                clusterPropertiesView: false
            };
        }

        case actionTypes.CHANGE_CLUSTER_DOCUMENTS_VIEW: {
            return {
                ...state,
                clusterMembersView: false,
                clusterPropertiesView: false
            };
        }

        case actionTypes.SET_CURRENT_TEAM_CHANNEL_ID: {
            return {
                ...state,
                currentTeamChannelId: action.currentTeamChannelId
            };
        }

        case actionTypes.SET_MESSAGE_PAGE: {
            return {
                ...state,
                currentMessagePage: state.currentMessagePage + 1
            };
        }

        case actionTypes.CLEAR_MESSAGE_PAGE: {
            return {
                ...state,
                currentMessagePage: 0
            };
        }

        case actionTypes.SET_CURRENT_CHANNEL_NAME: {
            return {
                ...state,
                currentChannelName: action.currentChannelName
            };
        }

        case actionTypes.GET_AREQUEST_ACCESS_USER: {
            return {
                ...state,
                requestAccessUser: action.requestAccessUser,
                requestAccessUserLoaded: true
            };
        }

        case actionTypes.CLUSTERS_SEARCH_LOADING: {
            return {
                ...state,
                clustersSearchResult: [],
                clustersSearchResultLoaded: false
            };
        }

        case actionTypes.GET_ALL_TAGS: {
            return {
                ...state,
                clusterTags: action.clusterTags,
                clusterTagsLoaded: true
            };
        }

        case actionTypes.GET_CLUSTER_DOCUMENTS: {
            return {
                ...state,
                documentList: action.documentList,
                documentListLoaded: true
            };
        }

        case actionTypes.CLEAR_CLUSTER_DOCUMENTS: {
            return {
                ...state,
                documentList: [],
                documentListLoaded: false
            };
        }

        case actionTypes.GET_CLUSTERS_LIST: {
            const clustersList = ((action.clustersList || []) as IClustersListCluster[]);

            return {
                ...state,
                clustersListIds: clustersList.map(cluster => cluster.id),
                clustersListById: clustersList.reduce((clustersListById, cluster) => ({
                    ...clustersListById,
                    [cluster.id]: cluster
                }), {}),
                clustersListLoaded: true
            };
        }

        case actionTypes.GET_CLUSTERS_SEARCH_RESULT: {
            return {
                ...state,
                clustersSearchResult: action.clustersSearchResult.items as ISingleSearchCluster[],
                clustersSearchResultLoaded: true,
                clusterTags: action.clustersSearchResult.facets,
                clusterTagsLoaded: true
            };
        }

        case actionTypes.RECEIVE_MESSAGE: {
            return {
                ...state,
                currentChannelMessages: [action.message, ...state.currentChannelMessages]
            };
        }

        case actionTypes.UPDATE_MESSAGE: {
            return {
                ...state,
                updatedMessage: action.message
            };
        }

        case actionTypes.RECEIVE_THREAD_MESSAGE: {
            const message = action.message as IClusterSingleMessage;

            return {
                ...state,
                currentChannelMessages: state.currentChannelMessages.map((elem) => elem.id === message.threadId ? {
                    ...elem,
                    threadMessages: (message.type === MessageSystemTypes.SystemRemovingMessage
                        ? elem.threadMessages.map(threadMessage => threadMessage.id === ((message.additionalInformation && message.additionalInformation.removedMessageId) || message.id) ? message : threadMessage)
                        : [action.message, ...elem.threadMessages]
                    )
                } : elem)
            };
        }

        case actionTypes.REMOVE_CLUSTER_CHANNEL_THREAD: {
            return {
                ...state,
                currentChannelMessages: state.currentChannelMessages.filter(message => message.id !== action.threadId),
                currentChannelMessagesCount: state.currentChannelMessagesCount - 1
            };
        }

        case actionTypes.CLEAR_CHANNEL_MESSAGES: {
            return {
                ...state,
                currentChannelMessages: [],
                currentChannelMessagesLoaded: false
            };
        }

        case actionTypes.GET_CURRENT_CLUSTER: {
            return {
                ...state,
                currentClusterData: action.currentClusterData,
                currentClusterDataLoaded: true
            };
        }

        case actionTypes.GET_INITIAL_CLUSTER_DATA: {
            const payload: actionTypes.IClusterTypes['GET_INITIAL_CLUSTER_DATA'] = action;
            return {
                ...state,
                currentClusterData: payload.clusterData,
                clusterPermissions: (payload.permissions || []).reduce((permissions, actionData) => ({
                    ...permissions,
                    [actionData]: true
                }), {}),
                currentClusterDataLoaded: true
            };
        }

        case actionTypes.REFRESH_APPROVAL_INFO: {
            const payload: actionTypes.IClusterTypes['REFRESH_APPROVAL_INFO'] = action;
            return {
                ...state,
                currentClusterData: {
                    ...state.currentClusterData,
                    validationStatus: payload.clusterData.validationStatus,
                    validationStatusModifiedAt: payload.clusterData.validationStatusModifiedAt
                }
            };
        }

        case actionTypes.GET_CURRENT_CLUSTER_TEMPLATES: {
            const payload: actionTypes.IClusterTypes['GET_CURRENT_CLUSTER_TEMPLATES'] = action;
            return {
                ...state,
                clusterTemplates: payload.clusterTemplates
            };
        }

        case actionTypes.UPDATE_CLUSTER_PERMISSIONS: {
            const payload: actionTypes.IClusterTypes['UPDATE_CLUSTER_PERMISSIONS'] = action;
            return {
                ...state,
                clusterPermissions: (payload.permissions || []).reduce((permissions, actionData) => ({
                    ...permissions,
                    [actionData]: true
                }), {})
            };
        }

        case actionTypes.GET_ALL_CHANNELS: {
            return {
                ...state,
                teamChannels: action.teamChannels
            };
        }

        case actionTypes.GET_CHANNEL_MESSAGES: {
            return {
                ...state,
                currentChannelMessages: [...state.currentChannelMessages, ...action.currentChannelMessages.items],
                currentChannelMessagesCount: action.currentChannelMessages.count,
                currentChannelMessagesLoaded: true
            };
        }

        case actionTypes.GET_SINGLE_CHANNEL_MESSAGE: {
            return {
                ...state,
                currentChannelMessages: action?.currentChannelMessage ? [action?.currentChannelMessage] : [...state.currentChannelMessages],
                currentChannelMessagesCount: 1,
                currentChannelMessagesLoaded: true,
                channelMessageError: action?.channelMessageError
            };
        }

        case actionTypes.SET_CURRENT_CLUSTER: {
            return {
                ...state,
                currentClusterId: action.currentClusterId
            };
        }

        case actionTypes.SET_CURRENT_TEAM: {
            return {
                ...state,
                currentTeamId: action.currentTeamId
            };
        }

        case actionTypes.GET_ALL_MY_TEAMS: {
            return {
                ...state,
                allMyTeams: action.allMyTeams
            };
        }

        case actionTypes.SET_CURRENT_CHANNEL: {
            return {
                ...state,
                currentChannelId: action.currentChannelId
            };
        }

        case actionTypes.GET_CLUSTER_PHOTOS: {
            return {
                ...state,
                clusterPhotos: action.clusterPhotos
            };
        }

        case actionTypes.CLEAR_CLUSTER_DATA: {
            return {
                ...state,
                currentClusterData: undefined,
                clusterPermissions: undefined,
                currentClusterId: undefined,
                currentClusterDataLoaded: false
            };
        }

        default: {
            return state;
        }
    }
};