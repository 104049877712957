import React, { SFC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { downloadMessagingFileAsBlob } from '../../../actions/messagingActions';
import { Loader } from '../../Common/Loader/Loader';
import { IAttachment } from '../../../entities/Messaging/IMessaging';

const Wrapper = styled.div`
    justify-content: center;
    img {
        max-width: 100%;
        margin: 0 auto;
        display: block;
    }
`;

interface IImageBoxProps {
    file: IAttachment;
    fileHandler();
}

type GetImageAsBase64 = ReturnType<typeof downloadMessagingFileAsBlob>;

export const ImageBox: SFC<IImageBoxProps> = (props) => {
    const { file, fileHandler } = props
    const dispatch = useDispatch();
    const [image, setImage] = useState<any>(undefined)

    useEffect(() => {
        dispatch<GetImageAsBase64>(downloadMessagingFileAsBlob(file.uri, true)).then((blob: Blob) => {
            let reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                const base64data = reader.result;
                setImage(base64data);
            }
        })
    }, [])

    return (
        <Wrapper>
            <Loader loading={!image}>
                <img src={image} onClick={fileHandler}/>
            </Loader>
        </Wrapper>
    )
};

