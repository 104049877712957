import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

interface ITitleProps {
    smallImage: boolean;
    hideTitle?: boolean;
    smallText?: boolean;
}

const Title = styled.div<ITitleProps>`
    display: flex;
    align-items: center;
    opacity: 1;
    transition: opacity .4s ease;
    ${props => props.hideTitle && css`
        opacity: 0;
    `}

    > span {
        color: ${colorStack.darkBlue};
        font-size: ${fontSize[16]};
        font-weight: 500;
        ${({ smallText }) => smallText && css`
            font-size: ${fontSize[13]};
        `}
    }

    > img {
        width: 30px;
        margin: 0 7px 0 0;

        ${props => props.smallImage && css`
            width: 1rem;
        `}
    }

    > svg {
        margin: 0 7px 0 0;
    }
`;

const Right = styled.div`
    > img {
        width: 18px;
        cursor: pointer;
    }

    > a {
        color: ${colorStack.lightBlue};
        cursor: pointer;
    }
`;

interface IWhiteBoxHeaderProps {
    rightSide?: React.ReactNode;
    className?: string;
    smallImage?: boolean;
    smallText?: boolean;
    hideTitle?: boolean;
}

export const WhiteBoxHeader: FC<IWhiteBoxHeaderProps> = ({ children, rightSide, smallImage, smallText, className = '', hideTitle }) => (
    <Wrapper className={className}>
        <Title hideTitle={hideTitle} smallImage={smallImage} className="header-title" smallText={smallText}>
            {children}
        </Title>
        {rightSide && (
            <Right className="header-menu">
                {rightSide}
            </Right>
        )}
    </Wrapper>
);
