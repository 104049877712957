import React from 'react';

interface SelfProps {
    to: string;
    className?: string;
    title?: string;
    onClick?: any;
}

export const ExternalLink: React.SFC<SelfProps> = (props) => {
    return (
        <a href={props.to} rel="noopener noreferrer" target="_blank" className={props.className} title={props.title} onClick={props.onClick}>
            {props.children}
        </a>
    );
};