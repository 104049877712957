import React, { FC, useEffect } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import queryString from 'query-string-for-all';
import { useSelector } from 'react-redux';

import { localStorage } from '../tools/storage';
import { uuidv4 } from '../tools/authTools';
import { IState } from '../reducers';
import { IConfigReducer } from '../reducers/configReducer';

import { GlobalLoader } from './GlobalLoader';

export const TeamsAuthStart: FC = () => {
    const { appConfig: { applicationConfig } } = useSelector<IState, IConfigReducer>(state => state.config);

    useEffect(() => {
        microsoftTeams.initialize();

        microsoftTeams.getContext((context) => {
                const state = uuidv4();
                const nonce = uuidv4();
                localStorage.setItem('simple.state', state);
                localStorage.removeItem('simple.error');

                    const queryParams = {
                        client_id: applicationConfig.clientID,
                        response_type: 'id_token token',
                        response_mode: 'fragment',
                        scope: `${applicationConfig.b2cScopes[0]} openid profile email`,
                        redirect_uri: `${window.location.origin}/teams/auth-end`,
                        nonce,
                        state,
                        login_hint: context.loginHint
                    };

                    const authorizeEndpoint = `${applicationConfig.authority}/oauth2/v2.0/authorize?${queryString.stringify(queryParams)}`;
                    window.location.assign(authorizeEndpoint);
            });
    }, []);

    return (
        <GlobalLoader isLoading />
    );
}
