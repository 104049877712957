import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { faTimes, faAngleDown, faAngleUp, faExpandAlt, faCommentsAlt as readMessages } from '@fortawesome/pro-regular-svg-icons';
import { faCommentsAlt as unreadMessages } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { history } from '../../../history';

import boxShadow from '../../../styleHelpers/mixins/shadow';
import { colorStack } from '../../../styleHelpers/colors';
import ScrollBox from '../../Common/ScrollBox/ScrollBox';
import { Loader } from '../../Common/Loader/Loader';

import { IMessagingReducer } from '../../../reducers/messagingReducer';
import { IState } from '../../../reducers';
import { searchConversations, setConversationMenu, setConversationMenuExpanded, clearConversations } from '../../../actions/messagingActions';
import { TriggerType } from '../../../entities/Messaging/IMessaging';

import SingleConversation from '../SingleConversation';

const Wrapper = styled.div<{isOpen: boolean}>`
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 9999;
    min-width: 220px;
    ${props => props.isOpen && css`
        min-width: 280px;
    `}
    border-radius: 4px 4px 0 0;
    ${boxShadow()};
    bottom: 0px;
    right: 105px;
    background: ${colorStack.white};
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 34px;
`;

const IconSection = styled.div`
    display: flex;
    justify-items: center;
    svg {
        cursor: pointer;
        margin: 0 0 0 .5rem;
    }
    margin: 0 .5rem 0 0;
`;

const ContextInfo = styled.div<{ newMessages?: boolean }>`
    display: flex;
    align-items: center;
    svg {
        margin: .5rem;
    }
    img {
        margin: .5rem;
    }
    ${props => props.newMessages && css`
        font-weight: 500;
    `}
`;

const Conversations = styled.div`
    height: 455px;
`;

export const ConversationMenu: FC = () => {
    const dispatch = useDispatch();
    const { showConversationMenu, conversations, conversationsLoaded, conversationsCount, conversationsExpandend } = useSelector<IState, IMessagingReducer>(state => state.messaging);
    const [nextPage, setNextPage] = useState<number>(1);
    const hasMoreConversations = conversations && (conversationsCount > conversations.length);

    useEffect(() => {
        dispatch(searchConversations(TriggerType.Scroller, '', 0));
        setNextPage(1);
    }, [showConversationMenu]);

    const onChangeVisibility = useCallback(() => {
        dispatch(setConversationMenuExpanded(!conversationsExpandend));
    }, [conversationsExpandend]);

    const onClose = useCallback(() => {
        dispatch(setConversationMenu(false, false));
    }, []);

    const onMaximize = useCallback(() => {
        dispatch(clearConversations());
        onClose();
        history.push('/messaging');
    }, []);

    const getMoreConversations = useCallback(() => {
        if (hasMoreConversations) {
            dispatch(searchConversations(TriggerType.Scroller, '', nextPage)).then(() => {
                setNextPage(currentPage => currentPage + 1)
            });
        }
    }, [nextPage, hasMoreConversations]);

    return (
        <Wrapper isOpen={conversationsExpandend}>
            <Header>
                <ContextInfo>
                    <FontAwesomeIcon icon={unreadMessages} />
                    <FormattedMessage id="messaging.messagesWithCount" />
                </ContextInfo>
                <IconSection>
                    <FontAwesomeIcon icon={faExpandAlt} onClick={onMaximize} />
                    <FontAwesomeIcon icon={conversationsExpandend ? faAngleDown : faAngleUp} onClick={onChangeVisibility} />
                    <FontAwesomeIcon icon={faTimes} onClick={onClose} />
                </IconSection>
            </Header>
            {conversationsExpandend &&
                <Conversations>
                    {(conversationsLoaded && !!conversations?.length) ? (
                        <ScrollBox
                            autoHide
                            hasMore={hasMoreConversations}
                            onScrollResults={getMoreConversations}
                        >
                            {conversations.map(elem =>
                                <SingleConversation data={elem} key={elem.id} unread={elem.unreadMessagesCount > 0} isPopup />
                            )}
                        </ScrollBox>
                    ) : (
                            <Loader loading={!conversationsLoaded} size={'medium'} label={<FormattedMessage id="loaders.conversations" />} className="spinner">
                                <FormattedMessage id="messaging.nomessage" >
                                    {txt => <span className="no-conversations">{txt}</span>}
                                </FormattedMessage>

                            </Loader>
                        )}
                </Conversations>
            }
        </Wrapper>
    )
}