import { TrackingType, ITracker } from '../../entities/IAnalytics';

export const ADD_TRACKING = 'ADD_TRACKING';

export interface IAnalyticsTypes {
    [ADD_TRACKING]: {
        trackingType: TrackingType;
        tracker: ITracker;
    }
}
