import * as actionTypes from '../actions/actionTypes/stakesTypes';
import { ISingleStakeDefinitionList, StakeContainerType } from '../entities/IClusterStake';

export interface IStakesReducer {
    stakesDefinitionList: ISingleStakeDefinitionList[];
    stakesDefinitionListLoaded: boolean;
    stakesContainerTypeById: {
        [key: string]: StakeContainerType;
    };
}

const defaultState = (): IStakesReducer => ({
    stakesDefinitionList: undefined,
    stakesDefinitionListLoaded: false,
    stakesContainerTypeById: {}
});

export default (state = defaultState(), action) => {
    switch (action.type) {

        case actionTypes.GET_STAKES_DEFINISION_LIST: {
            const payload: actionTypes.IStakeTypes['GET_STAKES_DEFINISION_LIST'] = action;
            return {
                ...state,
                stakesDefinitionList: payload.stakesDefinitionList,
                stakesDefinitionListLoaded: true,
                stakesContainerTypeById: (payload.stakesDefinitionList || []).reduce((stakesById, stake) => ({
                    ...stakesById,
                    [stake.id]: stake.type
                }), {})
            };
        }

        default: {
            return state;
        }
    }
};