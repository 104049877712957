export interface ILanguage {
    DefaultLanguage: boolean,
    Lcid: number,
    Name: string,
    DataType: string,
    id: string
    Code: string;
}

export interface ITranslations {
    [key: string]: ISingleTranslate[];
}

export interface ISingleLcidTranslations {
    [key: string]: string;
}

export interface ISingleTranslate {
    Label: string;
    [langKey: string]: string
}

export interface IUpdateTranslation {
    label: string;
    translations: {
        [key: string]: string
    }
}

export enum Lcid {
    None = -1,
    All = 0,
    En = 1033,
    Es = 1034,
    Fr = 1036,
    Pt = 1046,
    Ru = 1049,
    Uk = 1058,
    De = 1031,
    Pl = 1045,
    Nl = 2067,
    It = 1040,
    Ms = 1086,
    Vi = 1066,
    Hr = 1050,
    Hu = 1038,
    Sk = 1051,
    Sl = 1060,
    Ar = 1401,
    Ro = 1048,
    Fil = 1124,
    Th = 30,
    Da = 1030,
    Fi = 1035,
    Id = 1057,
    Ja = 1041,
    Tr = 1055,
    Zh = 2052
}

export enum LangCode {
    En = 'en',
    Fr = 'fr',
    Es = 'es',
    Pt = 'pt',
    Ru = 'ru',
    Uk = 'uk',
    De = 'de',
    Pl = 'pl',
    It = 'it',
    Nl = 'nl',
    Ms = 'ms',
    Vi = 'vi',
    Hr = 'hr',
    Hu = 'hu',
    Sk = 'sk',
    Sl = 'sl',
    Ar = 'ar',
    Ro = 'ro',
    Fil = 'fil',
    Th = 'th',
    Da = 'da',
    Fi = 'fi',
    Id = 'id',
    Ja = 'ja',
    Tr = 'tr',
    Zh = 'zh'
}

export interface IClusterMessageTranslation {
    ConversationCreated: string;
    ConversationTitleChanged: string;
    DocumentRemoved: string;
    MemberAdded: string;
    MemberJoined: string;
    MemberLeft: string;
    MemberRemoved: string;
    MessageRemoved: string;
    MessageRemovedBySender: string;
    OwnerDeclared: string;
    OwnerStatusRevoked: string;
    RemovingMultipleVirtualFileSystem: string;
    RemovingVirtualFileSystem: string;
    UploadingMultipleVirtualFileSystem: string;
    UploadingVirtualFileSystem: string;
    UserLeftConversation: string;
    UserLeftConversationNamedNewOwner: string;
    UserRemovedFromConversation: string;
}