import { ReactNode } from 'react';

export interface ITagGroup<T = any> {
    groupId: string;
    header: ReactNode;
    items: ITag<T>[];
    singleGroup?: boolean;
}

export interface ITag<T = any> {
    id?: string;
    value: string;
    picture?: string;
    Key?: string;
    data?: T;
    groupId?: string;
    index?: number;
    firstName?: string,
    lastName?: string
}

export enum TagPickerColor {
    Brown,
    White,
    ActionBlue,
    Gray,
    LightBlue,
    Expertise,
    Speciality,
    middleBlue
}
