// to order color please use this: https://elektrobild.org/tools/sort-colors
export const colorStack = {
    white: '#FFFFFF',
    black: '#000000',

    darkBlue: '#232C47',
    middleBlue: '#3C8BF8',
    blue: '#68A8FF',
    lightBlue: '#CDE2FE',
    whiteBlue: '#E4F0FF',

    darkGreen: '#095107',
    middleGreen: '#008001',
    green: '#53A450',
    lightGreen: '#97D0A5',
    whiteGreen: '#D4EDD9',

    darkOrange: '#B45917',
    middleOrange: '#FFB901',
    orange: '#F3A83B',
    lightOrange: '#ECD081',
    whiteOrange: '#F2E5C4',

    darkPurple: '#521776',
    middlePurple: '#9945B7',
    purple: '#D689F1',
    lightPurple: '#EFC2FF',
    whitePurple: '#FCE8FF',

    darkRed: '#CA100E',
    middleRed: '#FF0100',
    red: '#F18989',
    lightRed: '#F5C6CA',
    whiteRed: '#FFE8EB',

    content: '#242424',
    label: '#424242',
    darkGrey: '#616161',
    disabled: '#858585',
    middleGrey: '#C5C5C5',
    ligthGrey: '#E0E0E0',
    whiteGrey: '#F5F5F5',
    bodyBg: '#F0F0F0'
};
