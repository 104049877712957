import React, { lazy, Suspense } from 'react';

import { ChunkLoader } from '../components/Common/ChunkLoader';

const LazyComponent = lazy(() => import('../components/Registration/Registration'));

export default (props) => (
    <Suspense fallback={<ChunkLoader/>}>
        <LazyComponent {...props} />
    </Suspense>
);
