import React, { useCallback, useContext, useReducer, Reducer } from 'react';

import { useEscKey } from '../../../tools/keyboardTools';
import { IOverlayProps, Overlay } from './Overlay';

interface IOverlayContext {
    isVisible: boolean;
    showOverlay: <T>(config: IOverlayProps<T>) => void;
    hideOverlay: () => void;
}

interface IOverlayState {
    isVisible: boolean;
    config: IOverlayProps;
}

const OverlayContext = React.createContext<IOverlayContext>({
    isVisible: false,
    showOverlay: (_: IOverlayProps) => {},
    hideOverlay: () => {},
});

const initialState: IOverlayState = {
    isVisible: false,
    config: null,
};

type Action =
    | { type: 'SHOW_OVERLAY'; config: IOverlayProps }
    | { type: 'HIDE_OVERLAY' };

const reducer: Reducer<IOverlayState, Action> = (state, action) => {
    switch (action.type) {
        case 'SHOW_OVERLAY':
            return {
                ...state,
                isVisible: true,
                config: action.config,
            };
        default:
            return { ...initialState };
    }
};

export const useOverlayContext = () => {
    return useContext(OverlayContext);
};

export const OverlayContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, { ...initialState });

    const showHandler = useCallback(<T,>(config: IOverlayProps<T>): void => {
        dispatch({ type: 'SHOW_OVERLAY', config });
    }, []);

    const hideHandler = useCallback((): void => {
        dispatch({ type: 'HIDE_OVERLAY' });
    }, []);

    useEscKey(() => hideHandler());

    return (
        <OverlayContext.Provider
            value={{
                isVisible: state.isVisible,
                showOverlay: showHandler,
                hideOverlay: hideHandler,
            }}
        >
            {state.isVisible && <Overlay {...state.config} />}
            {children}
        </OverlayContext.Provider>
    );
};
