import { ADD_TRACKING, IAnalyticsTypes } from '../actions/actionTypes/analyticsTypes';
import { TrackingType, ITracker } from '../entities/IAnalytics';

export interface IAnalyticsReducer {
    readonly trackingAdded: {
        readonly [key in TrackingType]?: boolean;
    };
    readonly tracker: {
        readonly [key in TrackingType]?: ITracker;
    };
}

const defaultState = (): IAnalyticsReducer => ({
    trackingAdded: {},
    tracker: {}
});

export default (state = defaultState(), action): IAnalyticsReducer => {
    switch (action.type) {
        case ADD_TRACKING: {
            const payload: IAnalyticsTypes['ADD_TRACKING'] = action;
            return {
                ...state,
                [payload.trackingType]: true,
                tracker: {
                    ...state.tracker,
                    [payload.trackingType]: payload.tracker
                }
            };
        }

        default: {
            return state;
        }
    }
};
