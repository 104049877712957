import { Lcid, ISingleLcidTranslations } from '../../entities/ILanguage';

export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';
export const SET_MANAGE_LANGUAGES = 'SET_MANAGE_LANGUAGES';
export const LOAD_NEW_LANG = 'LOAD_NEW_LANG';
export const FINISH_LOAD_NEW_LANG = 'FINISH_LOAD_NEW_LANG';
export const SET_CLUSTER_TRANSLATION_MESSAGES = 'SET_CLUSTER_TRANSLATION_MESSAGES';
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS';

export interface ILanguageTypes {
    SET_TRANSLATIONS: {
        lcid: Lcid;
        translations: ISingleLcidTranslations;
    }
}
