import React, { FC, useCallback } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

const Name = styled.span`
    ${fontSizeAndHeight[13]};
    color: ${colorStack.disabled};
    margin: 0 1rem 0 .5rem;
`;

const SelectDot = styled.button<{ isSelected: boolean }>`
    cursor: pointer;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: relative;
    transition: all .2s ease;
    border: 1px solid ${colorStack.disabled};
    &::after {
        background: ${colorStack.darkBlue};
        opacity: 0;
        position: absolute;
        content: "";
        border-radius: 50%;
        width: 8px;
        height: 8px;
        top: 3px;
        left: 3px;
        transition: all .2s ease;
        ${props => props.isSelected && css`
            opacity: 1;
        `}
    }
    &:hover {
        border-color: ${colorStack.darkBlue};
    }
    ${props => props.isSelected && css`
        border-color: ${colorStack.darkBlue};
    `}
`;

interface SingleRadioItemProps {
    selectedItemId: string;
    name: string;
    id: string;
    onSelect(dataObj: {id: string, Name: string} );
}

export const SingleRadioItem: FC<SingleRadioItemProps> = ({ selectedItemId, name, id, onSelect }) => {

    const selectHandler = useCallback(() => {
        if (id !== selectedItemId) {
            onSelect({id, Name: name});
        }
    }, [onSelect, id, selectedItemId, name]);

    return (
        <Wrapper>
            <SelectDot
                type="button"
                onClick={selectHandler}
                isSelected={selectedItemId === id}
            />
            <Name>{name}</Name>
        </Wrapper>
    );
};