import React, { FC, ComponentClass } from 'react';
import ReactDOM from 'react-dom';

export interface IOverlayProps<T = {}> {
    component: FC<T> | ComponentClass<T>;
    componentProps: T;
}

export const Overlay: FC<IOverlayProps> = ({
    component: Component,
    componentProps
}) =>
    ReactDOM.createPortal(
        <Component {...componentProps} />,
        document.getElementById('overlay-root')
    );
