import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { StakeDetailsType, IClusterStakeProperty, StakeDetailsSubType } from '../../../../../entities/IClusterStake';
import { media } from '../../../../../styleHelpers/breakpoint';
import { colorStack } from '../../../../../styleHelpers/colors';
import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { SetPropertiesIcon } from '../StakeComponents/SetPropertiesIcon';
import { NumberContent } from '../StakeComponents/NumberContent';
import { TextContent } from '../StakeComponents/TextContent';
import { BooleanContent } from '../StakeComponents/BooleanContent';
import { DateContent } from '../StakeComponents/DateContent';
import { LegalDateContent } from '../StakeComponents/LegalDateContent';
import { SingleSelectContent } from '../StakeComponents/SingleSelectContent';
import { MultiSelectContent } from '../StakeComponents/MultiSelectContent';
import { LegalEntityContent } from '../StakeComponents/LegalEntityContent';
import { NewDesignLegalEntityContent } from '../StakeComponents/NewDesignLegalEntityContent';
import { MultipleLegalEntityContent } from '../StakeComponents/MultipleLegalEntityContent';
import { PeopleContent } from '../StakeComponents/PeopleContent';
import { NewDesignPeopleContent } from '../StakeComponents/NewDesignPeopleContent';
import { MultiplePeopleContent } from '../StakeComponents/MultiplePeopleContent';
import { AttachmentContent } from '../StakeComponents/AttachmentContent';
import { PartyContent } from '../StakeComponents/PartyContent';
import { AddressContent } from '../StakeComponents/AddressContent';
import { NumberAndSelect } from '../StakeComponents/NumberAndSelectContent';
import { CheckboxContent } from '../StakeComponents/CheckboxContent';
import { useNewDesignContext } from '../../../../NewDesignCommon/NewDesignSwitch/NewDesignSwitch';
import { newDesignThemeSwitch } from '../../../../NewDesignCommon/NewDesignSwitch/newDesignTheme';
import { Label } from '../../../../Common/Label/Label';
import { EnumSelectContent } from '../StakeComponents/EnumSelectContent';
import { InvolvedPartiesContent } from '../StakeComponents/InvolvedPartiesContent';

const RowLeft = styled.div`
    display: flex;
    font-size: ${fontSize[13]};
    color: ${colorStack.disabled};
    width: 100%;
    font-weight: 500;
    margin: 0 0 1rem 0;
    ${media.desktop`
        width: 25%;
        margin: 0;
    `};
    span {
        margin: 0 0 0 1rem;
    }
    svg {
        width: 16px!important;
    }
`;

const RowRight = styled.div<{ withMargin?: boolean }>`
    word-wrap: break-word;
    width: 100%;
    ${media.desktop`
        width: 75%;
    `};
    ${(props) => newDesignThemeSwitch(props, () =>
    css`
            margin-top: ${props.withMargin ? '6px' : '0'};
            ${media.desktop`
                width: 100%;
            `};
        `
)}
`;

interface IStakeRowContentProps {
    stakeDefinitionId: string;
    propertie: IClusterStakeProperty;
    editStake: boolean;
    errors: any // because we receive different objects;
    context: 'PLE' | 'Cluster';
    stakeOptions?: {
        peopleContent?: {
            fullWidth?: boolean;
        }
    };
    stakeListName?: string;
    currentClusterId?: string;
    organizationId?: string;
    base?: boolean;
    setEditingData(propertie: IClusterStakeProperty);
}

export enum ItemNameById {
    Name = 'referencedItem.referencedItem.name',
    WebSite = 'referencedItem.referencedItem.websiteUrl',
    PhoneNumber = 'referencedItem.referencedItem.phoneNumber'
}

const checkFieldTitle = (data: IStakeRowContentProps) => {
    const canEditField = data.propertie.id === ItemNameById.Name ? false : data.editStake;
    return canEditField;
};

export const StakeCollection: FC<IStakeRowContentProps> = (props) => {
    const newDesign = useNewDesignContext();
    const withMargin = newDesign.active && ![
        StakeDetailsType.LegalEntity?.toLocaleLowerCase(),
        StakeDetailsType.People?.toLocaleLowerCase()
    ].includes(props.propertie?.type?.toLocaleLowerCase());

    return (
        <>
            <a id={props.propertie.id} />
            {!newDesign.active &&
                <RowLeft>
                    <SetPropertiesIcon type={props.propertie?.customIcon || props.propertie?.subType || props.propertie.type} />
                    <span>{props.propertie?.name}</span>
                </RowLeft>
            }
            {newDesign.active && <Label>{props.propertie?.name}</Label>}
            <RowRight withMargin={withMargin}>
                {(props.propertie?.type?.toLocaleLowerCase() === StakeDetailsType.Number.toLocaleLowerCase() || props.propertie?.type?.toLocaleLowerCase() === StakeDetailsType.NumberAndRisk.toLocaleLowerCase()) &&
                    <>
                        {(props.propertie?.value?.number > 0 || props.propertie.isEditable) &&
                            <NumberContent
                                propertieId={props.propertie.id}
                                error={(props?.errors?.aggregatedErrors || props?.errors?.length > 0 ? props.errors?.aggregatedErrors : [])?.find(elem => elem.propertyId === props.propertie.id)}
                                data={props.propertie}
                                editStake={props.editStake && props.propertie.isEditable !== false}
                                setEditingData={props.setEditingData}
                                context={props.context}
                                base={props.base}
                            />
                        }
                    </>
                }
                {props.propertie?.type?.toLocaleLowerCase() === StakeDetailsType.Text.toLocaleLowerCase() &&
                    <TextContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || props?.errors?.length > 0 ? props?.errors?.aggregatedErrors : [])?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={checkFieldTitle(props)}
                        setEditingData={props.setEditingData}
                        simple={props.propertie.subType === StakeDetailsSubType.Simple}
                    />
                }
                {(props.propertie?.type?.toLocaleLowerCase() === StakeDetailsType.Boolean.toLocaleLowerCase() &&
                    props.propertie?.subType?.toLocaleLowerCase() === StakeDetailsType.CheckboxBoolean.toLocaleLowerCase()) &&
                    <CheckboxContent
                        data={props.propertie}
                        propertieId={props.propertie.id}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        error={(props?.errors?.aggregatedErrors || props?.errors?.length > 0 ? props?.errors : [])?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        setEditingData={props.setEditingData}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === StakeDetailsType.Boolean.toLocaleLowerCase() &&
                    props.propertie?.subType?.toLocaleLowerCase() !== StakeDetailsType.CheckboxBoolean.toLocaleLowerCase() &&
                    <BooleanContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || props?.errors?.length > 0 ? props?.errors?.aggregatedErrors : [])?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === StakeDetailsType.Date.toLocaleLowerCase() &&
                    <DateContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || props?.errors?.length > 0 ? props?.errors?.aggregatedErrors : [])?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === StakeDetailsType.LegalDate.toLocaleLowerCase() &&
                    <LegalDateContent
                        propertieId={props.propertie.id}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        organizationId={props.organizationId}
                        setEditingData={props.setEditingData}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === StakeDetailsType.Attachment.toLocaleLowerCase() &&
                    <AttachmentContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || props?.errors?.length > 0 ? props?.errors?.aggregatedErrors : [])?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        stakeDefinitionId={props.stakeDefinitionId}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === StakeDetailsType.MultipleAttachments.toLocaleLowerCase() &&
                    <AttachmentContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || props?.errors?.length > 0 ? props?.errors : [])?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        multipleAttachment={true}
                        stakeDefinitionId={props.stakeDefinitionId}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === StakeDetailsType.LegalEntity.toLocaleLowerCase() && !newDesign.active &&
                    <LegalEntityContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || props?.errors?.length > 0 ? props?.errors : [])?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                        context={props.propertie?.subType?.toLocaleLowerCase() !== StakeDetailsSubType.Subsidiary.toLocaleLowerCase() ? 'Cluster' : props.context}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === StakeDetailsType.MultipleLegalEntity.toLocaleLowerCase() &&
                    <MultipleLegalEntityContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : [])?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === StakeDetailsType.People.toLocaleLowerCase() && !newDesign.active &&
                    <PeopleContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : [])?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                        context={props.context}
                        stakeOptions={props.stakeOptions?.peopleContent}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === StakeDetailsType.MultiplePeople.toLocaleLowerCase() &&
                    <MultiplePeopleContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : [])?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                        context={props.context}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === StakeDetailsType.SingleSelect.toLocaleLowerCase() &&
                    <SingleSelectContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : [])?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === StakeDetailsType.MultipleSelect.toLocaleLowerCase() &&
                    <MultiSelectContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : [])?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === StakeDetailsType.Party.toLocaleLowerCase() &&
                    props.propertie?.subType?.toLocaleLowerCase() !== StakeDetailsType.Cluster.toLocaleLowerCase() &&
                    <PartyContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : [])?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                        context={props.context}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === StakeDetailsType.MultipleParty.toLocaleLowerCase() &&
                    <PartyContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : [])?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                        context={props.context}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === StakeDetailsType.Address.toLocaleLowerCase() &&
                    <AddressContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : [])?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === StakeDetailsType.NumberAndSelect.toLocaleLowerCase() &&
                    <NumberAndSelect
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : [])?.find(elem => elem.propertyId === props.propertie.id)}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === StakeDetailsType.LegalEntity.toLocaleLowerCase() && newDesign.active &&
                    <NewDesignLegalEntityContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : [])?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                        context={props.context}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === StakeDetailsType.People.toLocaleLowerCase() && newDesign.active &&
                    <NewDesignPeopleContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : [])?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        setEditingData={props.setEditingData}
                        context={props.context}
                        stakeOptions={props.stakeOptions?.peopleContent}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === StakeDetailsType.Enum.toLocaleLowerCase() &&
                    <>
                        <EnumSelectContent
                            propertieId={props.propertie.id}
                            error={(props?.errors?.aggregatedErrors || props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : [])?.find(elem => elem.propertyId === props.propertie.id)?.message}
                            data={props.propertie}
                            editStake={props.editStake && props.propertie.isEditable !== false}
                            setEditingData={props.setEditingData}
                        />
                    </>
                }
                {props.propertie?.type?.toLocaleLowerCase() === StakeDetailsType.Party.toLocaleLowerCase() &&
                    props.propertie?.subType?.toLocaleLowerCase() === StakeDetailsType.Cluster.toLocaleLowerCase() && newDesign.active &&
                    <InvolvedPartiesContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : [])?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        organizationId={props.organizationId}
                        setEditingData={props.setEditingData}
                    />
                }
                {props.propertie?.type?.toLocaleLowerCase() === StakeDetailsType.Cluster.toLocaleLowerCase() &&
                    <InvolvedPartiesContent
                        propertieId={props.propertie.id}
                        error={(props?.errors?.aggregatedErrors || props?.errors?.length > 0 ? (props?.errors?.aggregatedErrors || props?.errors) : [])?.find(elem => elem.propertyId === props.propertie.id)?.message}
                        data={props.propertie}
                        editStake={props.editStake && props.propertie.isEditable !== false}
                        organizationId={props.organizationId}
                        setEditingData={props.setEditingData}
                    />
                }
            </RowRight>
        </>
    );
};
