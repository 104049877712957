import React, { FC, useCallback, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { FormattedMessage } from 'react-intl';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ITag } from './ITagPicker';
import { isFontAwesomeIcon } from '../../../tools/generalTools';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';

interface IListProps {
    visible: boolean;
}

export const List = styled.ul<IListProps>`
    position: absolute;
    margin: 0;
    padding: 0;
    z-index: 12;
    max-height: 1px;
    opacity: 0;
    height: auto;
    overflow: auto;
    left: -1px;
    right: -1px;
    transition: all 200ms;
    border: 1px solid ${colorStack.ligthGrey};
    background: ${colorStack.white};
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    ${props => props.visible && css`
        max-height: 230px;
        opacity: 1;
    `}
`;

export const Add = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;

    svg {
        width: 1rem;
        height: 1rem;
        stroke-width: 3px;
        stroke-linecap: square;
        margin-right: 0.3rem;
    }
`;

const IconWrapper = styled.span`
    margin-left: -.5rem;
    margin-right: .5rem;
`;

type IListItemProps = IStyledListItemProps & {
    tag: ITag;
    index: number;
    icon?: ReactNode | IconDefinition;
    innerRef?: React.RefObject<HTMLLIElement>;
    add?: boolean;
    onClick(tag: ITag);
    onMouseEnter?(index: number);
    renderSuggestion?(tag: ITag): ReactNode;
};

interface IStyledListItemProps {
    active?: boolean;
    selected?: boolean;
}

const StyledListItem = styled.li<IStyledListItemProps>`
    padding: .5rem 1rem;
    user-select: none;
    display: flex;
    align-items: center;
    font-size: ${fontSize[13]};

    ${props => props.active && css`
        font-weight: bold;
        background-color: #f3f3f3;
    `}

    ${props => props.selected && css`
        background-color: ${rgba(colorStack.middleGrey, 0.5)};
        color: ${colorStack.darkBlue};
    `}
`;

export const ListItem: FC<IListItemProps> = (props) => {
    const { tag: { value, id }, innerRef, add, index, renderSuggestion } = props;

    const onClick = useCallback(() => {
        props.onClick?.(props.tag);
    }, [value, id, index]);

    const onMouseEnter = useCallback(() => {
        props.onMouseEnter?.(index);
    }, [value, id, index]);

    return (
        <StyledListItem
            ref={innerRef}
            onClickCapture={onClick}
            onMouseEnter={onMouseEnter}
            selected={props.selected}
        >
            {props.icon && (
                <IconWrapper>
                    {isFontAwesomeIcon(props.icon) ? <FontAwesomeIcon icon={props.icon} /> : props.icon}
                </IconWrapper>
            )}
            {renderSuggestion?.(props.tag) || value}
            {add && (
                <Add>
                    <FormattedMessage id="tagpicker.addnewtag"  />
                </Add>
            )}
        </StyledListItem>
    );
};

export const ListTextWrapper = styled.li`
    padding: 0.5rem 0;
    text-align: center;
`;

export const GroupHeader = styled.li`
    padding: 0.8rem 1rem .3rem 1rem;
    font-size: ${fontSize[13]};
    opacity: .8;
`;
