import React, { FC, useCallback } from 'react';
import styled, { css } from 'styled-components';

import { ITag, TagPickerColor } from './ITagPicker';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import boxShadow from '../../../styleHelpers/mixins/shadow';

interface IWrapperProps {
    tagColor?: TagPickerColor;
    withRemoveBtn?: boolean;
    noShadow?: boolean;
}

const Wrapper = styled.span<IWrapperProps>`
    ${boxShadow()}
    position: relative;
    display: inline-flex;
    flex-wrap: nowrap;
    background: ${colorStack.whiteGrey};
    color: ${colorStack.darkBlue};
    border: 1px solid ${colorStack.whiteGrey};
    margin-bottom: 0.2rem;
    margin-right: 0.3rem;
    border-radius: 3px;
    font-size: ${fontSize[13]};
    align-items: center;
    justify-content: center;
    ${props => props.withRemoveBtn ? css`
        padding: 0 1.75rem 0 .5rem;
    ` : css`
        padding: .4rem .5rem;
    `}
    min-height: 1.5rem;
    ${props => props.noShadow && css`
        box-shadow: none;
    `}

    > button {
        background: none;
        border: 1px solid ${colorStack.darkBlue};
        width: 16px;
        height: 16px;
        position: absolute;
        margin-left: 0.5rem;
        cursor: pointer;
        outline: 0;
        border-radius: 50%;
        right: 7px;

        &:after, &:before {
            content: '';
            height: 1px;
            width: 9px;
            background: ${colorStack.darkBlue};
            position: absolute;
            left: 50%;
            top: 50%;

        }


        &:before {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
`;

interface ITagProps {
    tag?: ITag;
    tagColor?: TagPickerColor;
    className?: string;
    noShadow?: boolean;
    onRemove?(tag: ITag);
}

export const Tag: FC<ITagProps> = ({ tag, onRemove, tagColor, children, className, noShadow }) => {
    const onRemoveClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();

        onRemove?.(tag);
    }, [tag?.value, tag?.id]);

    return (
        <Wrapper tagColor={tagColor} className={className || ''} withRemoveBtn={!!onRemove} noShadow={noShadow}>
            {tag?.value || children}
            {!!onRemove && (
                <button type="button" onClick={onRemoveClick} />
            )}
        </Wrapper>
    );
};
