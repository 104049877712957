import React, { useCallback, Reducer, useReducer, useMemo, FC } from 'react';
import { Formik} from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import * as Yup from 'yup';

import { history } from '../../../../history';
import { sizes } from '../../../../styleHelpers/breakpoint';
import { ClusterPrivacy, IClusterTemplate } from '../../../../entities/IClusters';
import { useCreateNewCluster, NewClusterFormValues } from '../../../../tools/clusterTools';
import { StepsPopup } from '../../../Common/StepsPopup/StepsPopup';
import { CreatePOAClusterStep, ICreatePOAClusterStep } from './CreatePOAClusterStep';
import { POAClusterCreatedStep } from './POAClusterCreatedStep';

const Wrapper = styled.div`
    width: 100%;
    max-width: ${sizes.tablet}px;
`;

interface ICreatedCluster {
    id: string;
    name: string;
    organizationUrlName: string;
}

interface State {
    creating: boolean;
    createdCluster: ICreatedCluster;
    errors: ICreatePOAClusterStep['parentErrors'];
}

const initialState: State = {
    creating: false,
    createdCluster: undefined,
    errors: undefined
};

type Action =
    | { type: 'CREATE_CLUSTER' }
    | { type: 'CREATE_CLUSTER_SUCCESS'; createdCluster: ICreatedCluster }
    | { type: 'CREATE_CLUSTER_ERROR'; errors: ICreatePOAClusterStep['parentErrors'] };

const reducer: Reducer<State, Action> = (state, action) => {
    switch (action.type) {
        case 'CREATE_CLUSTER':
            return { ...state, creating: true, createdCluster: undefined, errors: undefined };
        case 'CREATE_CLUSTER_SUCCESS':
            return { ...state, creating: false, createdCluster: action.createdCluster };
        case 'CREATE_CLUSTER_ERROR':
            return { ...state, creating: false, errors: action.errors };
        default:
            return { ...initialState };
    }
};

export interface ICreatePOACluster {
    clusterTemplateId: IClusterTemplate['id'];
    onComplete: () => void;
}

export const CreatePOACluster: FC<ICreatePOACluster> = ({ clusterTemplateId, onComplete }) => {
    const createNewCluster = useCreateNewCluster();
    const intl = useIntl();
    const [state, dispatch] = useReducer(reducer, { ...initialState });
    const clusterValidationSchema = useMemo(() =>
        Yup.object().shape({
            name: Yup.string().required().max(100),
            privacy: Yup.string().required()
        }), []);

    const formChangeHandler = useCallback(() => {
        if (state.errors !== null) {
            dispatch({ type: 'CREATE_CLUSTER_ERROR', errors: undefined });
        }
    }, [state.errors]);

    const finishHandler = useCallback(() => {
        onComplete();

        if (state.createdCluster) {
            const { id, organizationUrlName } = state.createdCluster;
            history.push(`/orgs/${organizationUrlName}/cluster/${id}/overview?edit=true`);
        }
    }, [state.createdCluster, onComplete]);

    const submitHandler = useCallback(async (form: NewClusterFormValues) => {
        try {
            dispatch({ type: 'CREATE_CLUSTER' });
            const response = await createNewCluster({ ...form, clusterTemplateId });
            const createdCluster = { id: response.id, organizationUrlName: response.organizationUrlName, name: form.name.trim() };
            dispatch({ type: 'CREATE_CLUSTER_SUCCESS', createdCluster });
        } catch (err) {
            const errors = err.data.errorCode === 2250 ? { name: err.data.message } : undefined;
            dispatch({ type: 'CREATE_CLUSTER_ERROR', errors });
        }
    }, []);

    return (
        <Formik<NewClusterFormValues>
            initialValues={{ name: '', privacy: ClusterPrivacy.Private }}
            validationSchema={clusterValidationSchema}
            onSubmit={submitHandler}
        >
            {({ isValid, dirty, submitForm }) => (
                <StepsPopup
                    showPopup={true}
                    lastThanksScreen={2}
                    title={intl.formatMessage({
                        id: 'poa.wizard.title'
                    })}
                    sendButtonText={
                        <FormattedMessage id="poa.wizard.button.create" />
                    }
                    lastButtonText={
                        <FormattedMessage id="poa.wizard.button.start" />
                    }
                    confirmDisabled={state.creating}
                    confirmFinished={!!state.createdCluster}
                    confirmHandler={submitForm}
                    finishHandler={finishHandler}
                    isFormValid={isValid}
                    dirty={dirty}
                    steps={[
                        {
                            title: intl.formatMessage({
                                id: 'poa.wizard.step1.title'
                            }),
                            content: (
                                <Wrapper>
                                    <CreatePOAClusterStep isDisabled={state.creating} parentErrors={state.errors} onChange={formChangeHandler} />
                                </Wrapper>
                            )
                        },
                        {
                            content: (
                                <Wrapper>
                                    <POAClusterCreatedStep clusterName={state.createdCluster?.name} />
                                </Wrapper>
                            )
                        }
                    ]}
                ></StepsPopup>
            )}
        </Formik>
    );
};
