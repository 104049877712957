import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { colorStack } from '../../styleHelpers/colors';
import { media } from '../../styleHelpers/breakpoint';
import { fontSize } from '../../styleHelpers/fontSizes';

export const CookieMain = styled.div`
    background: rgba(35,44,71,0.95);
    width: 100%;
    position: fixed;
    bottom: 0;
    color: ${colorStack.white};
    z-index: 999999;
    opacity: 1;
    transition: opacity .5s;
`;

export const DescriptionWrapper = styled.div`
    display:flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: center;
    ${media.desktop`
        flex-direction: row;
    `};
`;

export const CookieWrapper = styled.div`
    max-width: 1170px;
    margin: 0 auto;
    width: 90%;
    position:relative;
    padding: 30px 0 20px 0;
    transition: height .5s;
    ${media.desktop`
        height: 150px;
        padding: 12px 0 20px 0;
    `};
`;

export const ButtonWrapper = styled.div`
    max-width: 290px;
    width: 64vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px auto 0 0;
    ${media.tabletSm`
        margin: 30px auto 0 auto;
    `}
    ${media.desktop`
        width: 100px;
        margin: 0;
        display: block;
    `};
`;

export const Title = styled.h3`
    color: ${colorStack.white};
    text-align: center;
    font-size: ${fontSize[25]};
    font-weight: 700;
    margin: 0 0 4px 0;
`;

export const Description = styled.div`
    font-size: ${fontSize[13]};
    ${media.desktop`
        padding: 0 40px 0 0;
    `};
    button {
        display: inline
    }
`;

export const Close = styled.div`
    position: absolute;
    display: inline-block;
    width: 20px;
    height: 20px;
    top:10px;
    right:-10px;
    overflow: hidden;
    cursor:pointer;
    ${media.desktop`
        right:-30px;
    `};
    &::before, &::after {
        content: '';
        position: absolute;
        height: 3px;
        width: 100%;
        top: 50%;
        left: 0;
        margin-top: -2px;
        background: ${colorStack.white};
    }
    &::before {
        transform: rotate(45deg);
    }
    &::after {
        transform: rotate(-45deg);
    }
`;

export const Learn = styled(Link)`
    text-decoration: underline;
    font-weight: 700;
    font-size: ${fontSize[16]};
    outline: none;
    display:  'inline';
    color: ${colorStack.white} !important;
    cursor: pointer;
    margin: 0px 0 0 34px;
`;

export const Accept = styled.button`
    background: ${colorStack.white};
    border-radius: 20px;
    padding: 8px 0;
    color: ${colorStack.darkBlue};
    width: 102px;
    font-size: ${fontSize[10]};
    text-transform: uppercase;
    outline: none;
`;
