import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';
import Highlight from 'react-highlighter';

import { media } from '../../styleHelpers/breakpoint';
import { colorStack } from '../../styleHelpers/colors';
import { fontSize } from '../../styleHelpers/fontSizes';
import MessageMenu from '../Common/MessagesMenu';
import { Avatar } from '../Common/Avatar/Avatar';
import { ISingleMessage, IAttachment, MessageSystemTypes } from '../../entities/Messaging/IMessaging';
import { ExternalLink } from '../Common/ExternalLink';
import { AttachmentBox } from './Components/AttachmentBox';
import * as messageActions from '../../actions/messagingActions';

const SingleMessageContainer = styled.div<{ isMine: boolean; isSystem: boolean; isFirstMessage: boolean }>`
    align-self: flex-start;
    ${props => props.isMine && css`
        align-self: flex-end;
    `}
    ${props => props.isSystem && css`
        align-self: center;
    `}
    margin: .5rem 1rem 0 0;
    max-width: 70vw;
    width: 100%;
    .error {
        color: ${colorStack.red};
        font-size: ${fontSize[13]};
        width: 100%;
        display: block;
        margin: 4px 0 0 0;
    }
    ${props => props.isFirstMessage && css`
        margin-top: auto;
    `}
    ${media.desktop`
        margin: 12px 0 0 0;
        max-width: 441px;
    `}
`;

const SingleMessageContainerInner = styled.div<{ isMine: boolean; isSystem: boolean }>`
    background: ${colorStack.white};
    align-self: flex-start;
    width: 100%;
    padding: .12rem 0 .23rem 0;
    border-radius: 20px 20px 20px 3px;
    box-shadow: 0px 1px 0px ${rgba(colorStack.black, 0.13)};

    ${props => props.isMine && css`
        background: ${rgba(colorStack.darkBlue, .2)};
        padding: 12px 0;
        border-radius: 20px 20px 3px 20px;
        .meta{
            a {
                color: ${colorStack.white};
            }
        }
        .header {
            display: flex;
            flex-direction: row-reverse;
        }
    `}
    ${props => props.isSystem && css`
        align-self: center;
        border-radius: 20px;
        color: ${colorStack.darkBlue};
        .meta{
            .date{
                color: ${colorStack.darkBlue};
            }
            a {
                color: ${colorStack.black};
            }
        }
    `}
    .image-wrapper {
        width: 16px;
        height: 16px;
        margin: 0 .5rem;
    }
    .header {
        display: flex;
        justify-content: space-between;
        padding: 0 15px;
    }
    .menu-wrapper{
        position: relative;
        cursor: pointer;
        img{
            width: 15px;
        }
    }
    .meta{
        display: flex;
        flex-direction: column;
        .name {
            font-size: ${fontSize[13]};
            color: ${colorStack.darkBlue};
        }
        .date{
            font-size:${fontSize[10]};
            color: ${colorStack.disabled};
        }
    }
    .user-box {
        margin: 0 0 1px 0;
        display: flex;
        align-items: center;
    }
    .message{
        .text{
            padding: 0 35px 0 15px;
            display: block;
            color: ${colorStack.darkBlue};
            font-size: ${fontSize[13]};
            word-break: break-word;
            pre {
                word-break: break-word;
                white-space: pre-wrap;
            }
            a {
                color: ${colorStack.black};
                font-weight: 600;
            }
        }
    }
    .attach-spinner {
        .ms-Spinner-label {
            font-size: ${fontSize[10]};
        }
    }
`;

interface IProps {
    user: 'current' | 'other';
    type?: string;
    data?: ISingleMessage & {
        senderName: string,
        senderPicture: string
    };
    search?: string;
    isFirstMessage?: boolean;
    isPopup?: boolean;
}

interface ISingleMessageState {
    showMenu: boolean;
}

type SingleMessageProps = IProps & typeof messageActions & {
    setPdfDocument?(document: string | ArrayBuffer, pdfName: string, documentId: string);
    setImageDocument?(imageName: string, downloadImageUri: string);
};

class SingleMessage extends React.Component<SingleMessageProps, ISingleMessageState> {

    wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

    state = {
        showMenu: false
    };

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside, true);
    }

    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.menuHandler();
        }
    }

    menuHandler = () => {
        this.setState(currentState => ({
            showMenu: !currentState.showMenu
        }), () => {
            if (this.state.showMenu) {
                document.addEventListener('mousedown', this.handleClickOutside, true);
            } else {
                document.removeEventListener('mousedown', this.handleClickOutside, true);
            }
        });
    }

    trySend = () => {
        if (this.props.data.status === 'Failed') {
            this.props.createMessages(this.props.data).then((res: ISingleMessage) => {
                if (res) {
                    if (this.props.data.attachData) {
                        this.props.uploadAttachment(res.id, this.props.data.attachData);
                    }
                    this.props.removeDoubleMessage(res.tempId);
                }
            });
        }
    }

    removeMessage = (messageId: string) => {
        const { data, removeMesssage } = this.props;
        removeMesssage(messageId, data.conversationId);
    }

    render() {
        const { user, data, search, isFirstMessage, isPopup, setImageDocument, setPdfDocument } = this.props;
        const { showMenu } = this.state;
        const dataTextArray = data.content.split(/(https?:\/\/[^\s]+)/g);

        return (
            <SingleMessageContainer data-id={data.id} isMine={user === 'current'} onClick={this.trySend} isSystem={MessageSystemTypes[data.type] === 'System'} isFirstMessage={isFirstMessage}>
                <SingleMessageContainerInner isMine={user === 'current'} onClick={this.trySend} isSystem={MessageSystemTypes[data.type] === 'System'}>
                    {!MessageSystemTypes[data.type] && (
                        <div className="header">
                            <div className="user-box">
                                {user === 'other' ? (
                                    <>
                                        <Avatar
                                            picture={data.senderPicture}
                                            firstName={data.senderName}
                                            lastName=""
                                            id={data.senderUserId}
                                            size={{
                                                width: 32,
                                                height: 32
                                            }}
                                        />
                                        <div className="meta">
                                            <span className="name">{data.senderName}</span>
                                            <span className="date">{moment.utc(data.createdDate).local().format('DD MMM YYYY HH:mm:ss')}</span>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="meta">
                                                <span className="date">{moment.utc(data.createdDate).local().format('DD MMM YYYY HH:mm:ss')}</span>
                                        </div>
                                        <Avatar
                                            picture={data.senderPicture}
                                            firstName={data.senderName}
                                            lastName=""
                                            id={data.senderUserId}
                                            size={{
                                                width: 32,
                                                height: 32
                                            }}
                                        />
                                    </>
                                )}
                            </div>
                            <div className="menu-wrapper" ref={this.wrapperRef}>
                                {user !== 'other' && <div onClick={this.menuHandler} > <FontAwesomeIcon icon={faAngleDown} /> </div>}
                                {showMenu && <MessageMenu messageId={data.id} removeMessage={this.removeMessage} align={isPopup ? 'left' : 'right'} />}
                            </div>
                        </div>
                    )}
                    <div className="message">
                        {(data.attachments && data.attachments.length > 0) && data.attachments.map((attach: IAttachment, index: number) =>
                            <AttachmentBox user={user} attach={attach} search={search} key={index} setPdfDocument={setPdfDocument} setImageDocument={setImageDocument} />
                        )}
                        <span className="text">
                            {dataTextArray.map((text: string, index: any) => text.slice(0, 4) === 'http' ? (
                                <ExternalLink to={text} key={index}><Highlight search={search}>{text} </Highlight></ExternalLink>
                            ) : (
                                <span key={index}><Highlight search={search}><pre>{text}</pre></Highlight></span>
                            ))}
                        </span>
                    </div>
                </SingleMessageContainerInner>
                {data.status === 'Failed' &&
                    <FormattedMessage id="messaging.message.notsent" >
                        {txt => <span className="error">{txt}</span>}
                    </FormattedMessage>
                }
            </SingleMessageContainer>
        );
    }
}

export default connect(
    undefined, {
    ...messageActions
}
)(SingleMessage);
