import React, { useMemo, useCallback, useEffect, useState, FC } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { IDropdownOption } from '@fluentui/react';
import styled from 'styled-components';

import { IState } from '../../../../../reducers';
import { ILanguageReducer } from '../../../../../reducers/languageReducer';
import { IClusterStakeProperty, ITranslationItem } from '../../../../../entities/IClusterStake';
import { ErrorText } from './StakeStyles';
import { SelectInput } from '../../../../Common/SelectInput/SelectInput';
import { StatusDot } from '../../../../Common/StatusDot/StatusDot';
import { EStatus } from '../../../../../entities/IGlobal';

const Wrapper = styled.div``;

interface IEnumSelectContent {
    data: IClusterStakeProperty;
    propertieId: string;
    error: string;
    editStake: boolean;
    setEditingData(propertie: IClusterStakeProperty);
}

export const EnumSelectContent: FC<IEnumSelectContent> = ({ data, editStake, error, setEditingData }) => {
    const intl = useIntl();
    const { userLanguage, translations } = useSelector<IState, ILanguageReducer>(state => state.language);
    const placeholder = useMemo(() => intl.formatMessage({ id: 'global.selectValue' }), []);
    const emptyItem: ITranslationItem = { enumElement: 'empty', translationKey: 'global.selectValue' };
    const options = useMemo(() => [
        { key: emptyItem.enumElement, text: placeholder, data: emptyItem },
        ...(data?.configuration?.items || []).map(item => ({ key: item.enumElement, text: translations[userLanguage][item.translationKey], data: item }))
    ], [emptyItem, placeholder, translations, userLanguage, data.configuration.items]);
    // tslint:disable-next-line:no-null-keyword
    const [selectedItem, setSelectedItem] = useState<ITranslationItem>(null);

    useEffect(() => {
        // tslint:disable-next-line:no-null-keyword
        setSelectedItem(data?.value || null);
    }, [data, editStake]);

    const changeHandler = useCallback((dataObj: ITranslationItem) => {
        // tslint:disable-next-line:no-null-keyword
        setEditingData({ ...data, value: dataObj.enumElement !== 'empty' ? dataObj : null });
    }, [data, setEditingData]);

    return (
        <Wrapper>
            {editStake ? (
                <>
                    <SelectInput
                        selectedKey={selectedItem?.enumElement}
                        placeholder={placeholder}
                        onChange={(_, option: IDropdownOption) => changeHandler(option.data)}
                        options={options}
                    />
                    {error && <ErrorText>{error}</ErrorText>}
                </>
            ) : (
                <>
                    {selectedItem && <StatusDot status={selectedItem.enumElement as EStatus} extended />}
                </>
            )}
        </Wrapper>
    );
};
