import * as actionTypes from '../actions/actionTypes/fileRepositoryTypes';
import { IAccount, IAccountListItem } from '../entities/FileRepository/IAccount';

export interface IFileRepositoryReducer {
    selectedDriveAccount: IAccountListItem;
    driveAccountList: IAccountListItem[];
    driveAccountsListLoaded: boolean;
    doesNotHaveDriveAccount: boolean;
    uploadDriveFileModalVisible: boolean;
    driveIsUploading: boolean;
    driveUploadProgress: number;
    driveUploadError: boolean;
    driveUploadErrorMessage: string;
    driveSearchResultsApiError: string;
    driveAccountByEmail: {
        [email: string]: IAccount;
    };
    driveInitialError: boolean;
    driveInitialErrorMessage: string;
    legaldriveActivated: boolean;
    isConfiguredLoaded: boolean;
    activationLink: string;
    driveRootPath: string;
}

const defaultState = (): IFileRepositoryReducer => ({
    selectedDriveAccount: undefined,
    driveAccountList: [],
    driveAccountsListLoaded: false,
    doesNotHaveDriveAccount: false,
    driveAccountByEmail: {},
    uploadDriveFileModalVisible: false,
    driveIsUploading: false,
    driveUploadProgress: 0,
    driveUploadError: false,
    driveUploadErrorMessage: '',
    driveSearchResultsApiError: undefined,
    driveInitialError: false,
    driveInitialErrorMessage: '',
    legaldriveActivated: false,
    isConfiguredLoaded: false,
    activationLink: '',
    driveRootPath: ''
});

export default (state = defaultState(), action): IFileRepositoryReducer => {
    switch (action.type) {
        case actionTypes.CLEAR_FILE_REPOSITORY_STATE: {
            return defaultState();
        }

        case actionTypes.LOAD_FILE_REPOSITORY_ACCOUNTS: {
            return {
                ...state,
                driveAccountsListLoaded: true,
                driveAccountList: action.hasAccount ? action.accountList : [],
                doesNotHaveDriveAccount: !action.hasAccount,
                selectedDriveAccount: action.hasAccount && action.accountList[0]
            };
        }

        case actionTypes.SELECT_FILE_REPOSITORY_ACCOUNT: {
            return {
                ...state,
                selectedDriveAccount: state.driveAccountList.find(account => account.key === action.account)
            };
        }

        case actionTypes.SET_INITIAL_FILE_REPOSITORY_ACCOUNTS: {
            return {
                ...state,
                driveAccountByEmail: action.driveAccountByEmail
            };
        }

        case actionTypes.LOAD_FILE_REPOSITORY_DIRECTORY_REQUESTED: {
            return {
                ...state,
                driveSearchResultsApiError: undefined,
                driveAccountByEmail: {
                    ...state.driveAccountByEmail,
                    [action.accountEmail]: {
                        ...state.driveAccountByEmail[action.accountEmail],
                        filesLoaded: false,
                        path: action.path
                    }
                }
            };
        }

        case actionTypes.LOAD_FILE_REPOSITORY_DIRECTORY_COMPLETED: {
            if (!action.error) {
                return {
                    ...state,
                    driveSearchResultsApiError: undefined,
                    driveAccountByEmail: {
                        ...state.driveAccountByEmail,
                        [action.accountEmail]: {
                            ...state.driveAccountByEmail[action.accountEmail],
                            filesLoaded: true,
                            files: action.files
                        }
                    }
                };
            } else {
                return {
                    ...state,
                    driveSearchResultsApiError: action.error,
                    driveAccountByEmail: {
                        ...state.driveAccountByEmail,
                        [action.accountEmail]: {
                            ...state.driveAccountByEmail[action.accountEmail],
                            filesLoaded: true
                        }
                    }
                };
            }
        }

        case actionTypes.FILE_REPOSITORY_SHOW_UPLOAD_DIALOG: {
            return {
                ...state,
                uploadDriveFileModalVisible: true,
                driveUploadError: false,
                driveUploadErrorMessage: ''
            };
        }

        case actionTypes.FILE_REPOSITORY_HIDE_UPLOAD_DIALOG: {
            return {
                ...state,
                uploadDriveFileModalVisible: false
            };
        }

        case actionTypes.FILE_REPOSITORY_UPLOAD_START: {
            return {
                ...state,
                uploadDriveFileModalVisible: true,
                driveIsUploading: true,
                driveUploadProgress: 0,
                driveUploadError: false,
                driveUploadErrorMessage: ''
            };
        }

        case actionTypes.FILE_REPOSITORY_UPLOAD_PROGRESS: {
            return {
                ...state,
                driveUploadProgress: action.progress
            };
        }

        case actionTypes.FILE_REPOSITORY_UPLOAD_ERROR: {
            return {
                ...state,
                uploadDriveFileModalVisible: true,
                driveIsUploading: false,
                driveUploadError: true,
                driveUploadErrorMessage: action.error
            };
        }

        case actionTypes.FILE_REPOSITORY_UPLOAD_COMPLETED: {
            return {
                ...state,
                uploadDriveFileModalVisible: false,
                driveIsUploading: false,
                driveUploadError: false,
                driveUploadErrorMessage: '',
                driveAccountByEmail: {
                    ...state.driveAccountByEmail,
                    [action.accountEmail]: {
                        ...state.driveAccountByEmail[action.accountEmail],
                        filesLoaded: true,
                        files: action.files
                    }
                }
            };
        }

        case actionTypes.FILE_REPOSITORY_INITIAL_FAILED: {
            return {
                ...state,
                driveInitialError: true,
                driveInitialErrorMessage: action.errorMessage,
                driveAccountsListLoaded: true,
                driveAccountList: []
            };
        }

        case actionTypes.LOAD_LEGALDRIVE_ISCONFIGURED_COMPLETE: {
            return {
                ...state,
                legaldriveActivated: action.isActivated,
                isConfiguredLoaded: true,
                activationLink: action.redirectUrlActivation
            };
        }

        case actionTypes.LOAD_LEGALDRIVE_ROOT_COMPLETED: {
            return {
                ...state,
                driveRootPath: action.driveRootPath
            };
        }

        default: {
            return state;
        }
    }
};