import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { FormattedMessage } from 'react-intl';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { FileIcon } from '../../Organization/ClustersV2/ClusterActivity/Components/FileIcon';
import { Loader } from '../../Common/Loader/Loader';
import { Button } from '../Buttons/Button';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: ${rgba(colorStack.black, .8)};
    z-index: 99999;
    overflow: hidden;
`;

const DocumentWrapper = styled.div`
    width: 100%;
    height: calc(100% - 59px);
    > div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    img {
        max-width: 100%;
        display: block;
        max-height: calc(90vh - 59px);
    }
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
    background: ${rgba(colorStack.black, .6)};
    > div {
        display: flex;
        align-items: center;
        .image-ico {
            color: ${colorStack.white};
            ${fontSizeAndHeight[20]};
            margin-right: 10px;
        }
    }
    .image-name {
        color: ${colorStack.white};
        font-weight: 700;
        ${fontSizeAndHeight[20]};
    }
    .close-button {
        margin: 0 30px 0 auto;
    }
`;

interface IImageViewerProps {
    imageName: ReactNode;
    image: string;
    onDownloadClick(): void;
    onCloseClick(): void;
}

export const ImageViewer: FC<IImageViewerProps> = ({ imageName, image, onDownloadClick, onCloseClick }) => {

    const onClose = (e) => {
        e.stopPropagation();
        onCloseClick();
    };

    return (
        <Wrapper onClick={onClose}>
            <Header>
                <div>
                    <div className="image-ico">
                        <FileIcon mimeType="png" />
                    </div>
                    <div className="image-name">{imageName}</div>
                </div>
                <Button bgColor={colorStack.white} borderColor={colorStack.darkBlue} fontColor={colorStack.darkBlue} onClick={onClose}>
                    <FormattedMessage id="global.close" />
                </Button>
                <Button onClick={onDownloadClick}>
                    <FormattedMessage id="editprofile.button.download" />
                </Button>
            </Header>
            <DocumentWrapper>
                <Loader loading={!image}>
                    <img src={image} />
                </Loader>
            </DocumentWrapper>
        </Wrapper>
    );
};
