import { faEye } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useCallback } from 'react';
import { IntlShape } from 'react-intl';
import styled from 'styled-components';

import { history } from '../../../../history';
import { colorStack } from '../../../../styleHelpers/colors';

const Wrapper = styled.button`
    cursor: pointer;
    color: ${colorStack.darkBlue};
    display: flex;
    align-items: center;
    span {
        margin: 0 0 0 .5rem;
        font-weight: 500;
    }
`;

interface IMultiStakeTemplateProps {
    props: any;
    field: string;
    intl: IntlShape;
}

export const MultiStakeTemplate: FC<IMultiStakeTemplateProps> = ({ props, field, intl }) => {
    const handleRedirect = useCallback(() => {
        history.push(`/orgs/${props.organizationName}/cluster/${props.id}/overview?id=${props[field]}`);
    }, [props, field]);

    return (
        <Wrapper onClick={handleRedirect}>
            <FontAwesomeIcon icon={faEye} size="1x" />
            <span>{intl.formatMessage({id: 'cluster.seeAllValues'})}</span>
        </Wrapper>
    );
};