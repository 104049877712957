import { ConversationMember, ISingleConversation } from '../entities/Messaging/IMessaging';

export const roleHelper = {
    Owner: 10,
    Member: 30
};

export const roleToStringHelper = {
    10: 'Owner',
    30: 'Member'
};

export enum roles {
    Owner = 'Owner',
    Member = 'Member'
}

export const getConversationTitle = (conversationMembers: ConversationMember[], data: ISingleConversation, currentConversationMember: ConversationMember) => (conversationMembers.length > 2) ? data.title.length > 85 ? `${data.title.slice(0, 85)}...` : data.title : currentConversationMember.displayName;
