import React, { FC, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { useDropdown } from '../../../tools/hooks';
import { colorStack } from '../../../styleHelpers/colors';
import { Size } from '../../../styleHelpers/sizes';
import { media } from '../../../styleHelpers/breakpoint';
import { SidebarMenu } from './SidebarMenu';

const MenuWrapper = styled.div`
    background: ${colorStack.white};
    width: 90%;
    transform: translate3d(-100%, 0, 0);
    transition: transform 300ms;
    height: 100%;
    ${media.desktop`
        width: auto;
        transform: none;
    `}
`;

const SidebarNav = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    top: ${Size.TopBarHeightMobile}px;
    height: calc(100% - ${Size.TopBarHeightMobile}px);
    ${media.desktop`
        top: ${Size.TopBarHeightDesktop}px;
        height: calc(100% - ${Size.TopBarHeightDesktop}px);
    `}
`;

const FullNav = styled.div<{ isMobileMenuVisible: boolean }>`
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    background: ${rgba(colorStack.darkBlue, .6)};
    top: ${Size.TopBarHeightMobile}px;
    visibility: hidden;
    opacity: 0;
    transition: all 300ms;
    ${({ isMobileMenuVisible }) => isMobileMenuVisible && css`
        visibility: visible;
        opacity: 1;
        transition: all 300ms;
        ${MenuWrapper} {
            transform: translate3d(0, 0, 0);
            transition: transform 300ms;
        }
    `}
    ${media.desktop`
        top: ${Size.TopBarHeightDesktop}px;
        right: auto;
        max-width: 300px;
        opacity: 1;
        visibility: hidden;
        transition: visibility 0s;
        transition-delay: 0s;
        background: ${colorStack.white};
    `}
`;

const Wrapper = styled.div<{ isDesktopMenuVisible: boolean }>`
    position: fixed;
    left: 0;
    top: 0;
    height: ${Size.TopBarHeightMobile}px;
    z-index: 9999;
    width: ${Size.Level1Width}px;
    padding: .5rem .5rem;
    ${media.desktop`
        background: ${colorStack.white};
        bottom: 0;
        height: 100%;
        &:hover {
            ${FullNav} {
                visibility: visible;
                transition-delay: 1s;
            }
        }
        ${({ isDesktopMenuVisible }) => isDesktopMenuVisible && css`
            ${FullNav} {
                visibility: visible;
                transition: none;
            }
        `}
    `}
`;

interface IProps {
    isMobileMenuVisible: boolean;
}

export const SideBar: FC<IProps> = ({ isMobileMenuVisible }) => {
    const [wrapperRef, isDesktopMenuVisible, toggleDesktopMenu, closeDesktopMenu] = useDropdown();
    const isClusterContext = location.pathname.split('/').includes('cluster');
    const isOrgContext = location.pathname.split('/').includes('orgs');

    const isVisible = useMemo(() => {
        return true;

        // Removes top bar on Teams app. Initially created for MS validation.
        // Disabled due to the deployment of the app to BYCN.

        // let visibility = true;

        // if (IS_TEAMS_INSTANCE) {
        //     if (isClusterContext || isOrgContext) {
        //         visibility = false;
        //     }
        // }
        // return visibility;
    }, [IS_TEAMS_INSTANCE, isClusterContext]);

    return (
        <>
            {isVisible &&
                <Wrapper isDesktopMenuVisible={isDesktopMenuVisible} ref={wrapperRef}>
                    <SidebarNav>
                        <MenuWrapper>
                            <SidebarMenu
                                onMenuItemClick={closeDesktopMenu}
                                onFilterClick={toggleDesktopMenu}
                            />
                        </MenuWrapper>
                    </SidebarNav>
                    <FullNav isMobileMenuVisible={isMobileMenuVisible}>
                        <MenuWrapper>
                            <SidebarMenu
                                fullMenu
                                onMenuItemClick={closeDesktopMenu}
                                onFilterClick={toggleDesktopMenu}
                                isDesktopMenuVisible={isDesktopMenuVisible}
                            />
                        </MenuWrapper>
                    </FullNav>
                </Wrapper>
            }
        </>
    );
};
