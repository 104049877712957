enum CountrySwitch {
    FR = 'FR',
    US = 'US',
    AUS = 'AUS',
    GB = 'GB'
}
// IAddress (in addressFormatter) is deprecated - please use IAddressGlobal with addressFormatterGlobal and require such an object from the backend
export const addressFormatter = (value) => {
    return value ? (
        value.Country !== '' ? (
            Object.values(CountrySwitch).includes(value.CountryShort as CountrySwitch) ?
                `${value?.Number}${` ${value?.Street}`}${(value?.Street || value?.Number) && ', '}${value?.ZipCode ? `${value.ZipCode} ` : ''}${value?.City || value?.State}${(value?.City || value?.State) && ', '}${value?.Country}` :
                `${value?.Street}${(value?.Street || value?.Number) && ' '}${value?.Number}${(value?.Street || value?.Number) && ', '}${value?.ZipCode ? `${value.ZipCode} ` : ''}${value?.City || value?.State}${(value?.City || value?.State) && ', '}${value?.Country}`
        ) : (value.City || value.State)
    ) : '';
};

export const addressFormatterGlobal = (value) => {
    return value ? (
        value.country !== '' ? (
            Object.values(CountrySwitch).includes(value.countryShort as CountrySwitch) ?
                `${value?.number}${` ${value?.street}`}${(value?.street || value?.number) && ', '}${value?.zipCode ? `${value.zipCode} ` : ''}${value?.city || value?.state}${(value?.city || value?.state) && ', '}${value?.country}` :
                `${value?.street}${(value?.street || value?.number) && ' '}${value?.number}${(value?.street || value?.number) && ', '}${value?.zipCode ? `${value.zipCode} ` : ''}${value?.city || value?.state}${(value?.city || value?.state) && ', '}${value?.country}`
        ) : (value.city || value.State)
    ) : '';
};