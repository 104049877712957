import React, { FC, useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';
import styled from 'styled-components';

import { GlobalLoader } from '../GlobalLoader';

import { getHashParameters, AuthState, AuthConstants, MsalErrorCode } from '../../tools/authTools';
import { localStorage, getWithExpiry } from '../../tools/storage';
import { setLoggedInUser } from '../../actions/contextActions';
import { history } from '../../history';

const Wrapper = styled.div`
    padding: 1rem;
`;

export const AuthEnd: FC = () => {
    const dispatch = useDispatch();
    const [errorMsg, setErrorMsg] = useState<string>(undefined);

    const authSuccessCallback = useCallback((accessToken: string, idToken: string, nextRequestUrl: string) => {
        localStorage.setItem(AuthConstants.IDKey, idToken);
        const userId = jwtDecode(idToken);

        dispatch(setLoggedInUser(accessToken, userId.oid, {
            email: userId?.email,
            displayName: userId?.name,
            department: userId?.department,
            userOrigin: userId?.user_origin
        }));

        history.push(decodeURIComponent(nextRequestUrl || '') || '/');
    }, []);

    useEffect(() => {
        const response = getHashParameters(window.location.hash);

        if (response.error) {
            const errorDesc = response.error_description;

            if (errorDesc.match(MsalErrorCode.PasswordResetRequested)) {
                history.push('/reset-password');
            } else if (errorDesc.match(MsalErrorCode.PasswordResetCancelled)) {
                history.push('/login');
            } else {
                setErrorMsg(errorDesc);
            }
        } else if (response.access_token) {
            const expectedStateCsrf = getWithExpiry(AuthConstants.CSRFState, true);

            try {
                const state: AuthState = JSON.parse(atob(response.state));

                if (expectedStateCsrf !== state.csrf) {
                    setErrorMsg('We seem to have a minor issue. This often happens when opening too many tabs at the same time.  Going back and clicking again on the links should fix it.');
                } else {
                    if (response.id_token && response.access_token) {
                        authSuccessCallback(response.access_token, response.id_token, state.nextRequestUrl);
                    } else {
                        setErrorMsg('No id_token or access_token');
                    }
                }
            } catch (e) {
                setErrorMsg(`Unable to parse state: ${e}`);
            }
        } else {
            setErrorMsg('Unexpected failure');
        }
    }, []);

    return errorMsg ? (
        <Wrapper>
            <p>{errorMsg}</p>
        </Wrapper>
    ) : (
        <GlobalLoader isLoading />
    );
};