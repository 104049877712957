import * as actionTypes from '../actions/actionTypes/approvalTypes';
import { ValidationResponse } from '../entities/IValidation';

export interface IApprovalReducer {
    approvalOverviewInformations: ValidationResponse;
}

const defaultState = (): IApprovalReducer => ({
    approvalOverviewInformations: undefined
});

export default (state = defaultState(), action): IApprovalReducer => {
    switch (action.type) {
        case actionTypes.SET_APPROVAL_OVERVIEW_INFORMATIONS: {
            const payload: actionTypes.IApprovalTypes['SET_APPROVAL_OVERVIEW_INFORMATIONS'] = action;
            return {
                ...state,
                approvalOverviewInformations: payload.singleValidation
            };
        }
        default: {
            return state;
        }
    }
};
