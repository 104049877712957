import React, { FC } from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';

import { Layout } from '../components/Layout/Layout';

const Route: FC<RouteComponentProps> = (props) => (
    <Layout>
        <Redirect to="/admin-console/devComponents" />
    </Layout>
);

export default Route;
