import { faFile, faFileImage, faTimes, faFileWord } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { rgba } from 'polished';
import React, { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import { downloadAttachment } from '../../../../../actions/stakeActions';

import { colorStack } from '../../../../../styleHelpers/colors';
import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { Spinner } from '../../../../Common/Spinner/Spinner';

type DownloadAttachment = ReturnType<typeof downloadAttachment>;

const Wrapper = styled.div`
    color: ${colorStack.darkBlue};
    font-size: ${fontSize[13]};
    font-weight: 500;
    padding: .5rem;
    margin: 0 0 .2rem 0;
    position: relative;
    svg {
        margin: 0 .5rem 0 0;
    }
`;

const ProgressBar = styled.div<{ progress: number, isError: boolean; }>`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: ${rgba(colorStack.darkBlue, .05)};
    transition: max-width 1s ease;
    max-width: ${props => props.progress}%;
    ${props => props.isError && css`
        background: ${rgba(colorStack.middleRed, .1)};
    `}
`;

const FrontSection = styled.div`
    display: flex;
    cursor: pointer;
    z-index: 2;
    white-space: nowrap;
    overflow: hidden;
`;

const EndSection = styled.div`
    display: flex;
`;

const ContentWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const UploadingMessage = styled.div<{ isHidden: boolean }>`
    margin: 0 2rem 0 0;
    color: ${colorStack.darkBlue};
    font-size: ${fontSize[13]};
    opacity: 1;
    transition: opacity .4s ease;
    ${props => props.isHidden && css`
        opacity: 0;
    `}
`;

const CloseIcon = styled(FontAwesomeIcon)`
    z-index: 2;
    cursor: pointer;
`;

export enum UploadStatus {
    Success,
    Error,
    Pending
}

interface ISingleAttachmentProps {
    currentClusterId: string;
    value: {
        name: string;
        directUri: string;
    };
    status: UploadStatus;
    progress: number;
    guid: string;
    isEditMode: boolean;
    onDelete?(guid: string);
}

export const SingleAttachment: FC<ISingleAttachmentProps> = ({ currentClusterId, value, progress, isEditMode, guid, status, onDelete }) => {
    const dispatch = useDispatch();
    const uploadProggress = typeof progress === 'number' ? Math.floor(progress * 100) : 100;
    const hideUploadMessage = uploadProggress === 100;

    const downloadHandler = useCallback(() => {
        !!currentClusterId && dispatch<DownloadAttachment>(downloadAttachment(currentClusterId, 'Overview Files', value.name)).then(response => {
            response?.directUri && (window.location.href = response?.directUri);
        });
    }, [value, currentClusterId]);

    const deleteHandler = useCallback(() => {
        onDelete?.(guid);
    }, [onDelete, guid]);

    const fileIcon = useCallback(() => {
        const splittedFileName = value?.name?.split('.');
        const extension = splittedFileName[splittedFileName.length - 1];
        if (['jpeg', 'jpg', 'png', 'gif'].includes(extension)) {
            return faFileImage;
        } else if (['doc', 'docm', 'docx'].includes(extension)) {
            return faFileWord;
        } else {
            return faFile;
        }
    }, [value.name]);

    return (
        <Wrapper>
            <ContentWrapper>
                <FrontSection>
                    {value?.name &&
                        <>
                            <FontAwesomeIcon icon={fileIcon()} />
                            <span onClick={value?.directUri ? downloadHandler : undefined}>{value?.name}</span>
                        </>
                    }
                </FrontSection>
                {isEditMode &&
                    <EndSection>
                        <UploadingMessage isHidden={hideUploadMessage}>
                            <FormattedMessage id="global.uploadingNow"  />
                        </UploadingMessage>
                        <UploadingMessage isHidden={status !== UploadStatus.Error}>
                            <FormattedMessage id="cluster.error.attachmentWontBeSaved"  />
                        </UploadingMessage>
                        {(status === UploadStatus.Pending && hideUploadMessage) &&
                            <Spinner size="xSmall" />
                        }
                        {(status === UploadStatus.Success || status === UploadStatus.Error) &&
                            <CloseIcon onClick={deleteHandler} icon={faTimes} />
                        }
                    </EndSection>
                }
            </ContentWrapper>
            <ProgressBar progress={uploadProggress} isError={status === UploadStatus.Error} />
        </Wrapper>
    );
};